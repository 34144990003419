import React from "react";

const TimeBadge = ({ rdvsExpired, rdv }) => {
  return rdvsExpired.find((re) => re.id == rdv.id) ? (
    <span
      style={{ fontSize: 12, height: 20 }}
      className={
        "d-flex align-items-center badge font-weight-normal mr-2 justify-content-center " +
        (rdvsExpired.find((re) => re.id == rdv.id).isLate
          ? "badge-danger"
          : "badge-light")
      }
    >
      {rdvsExpired.find((re) => re.id == rdv.id).diff}
    </span>
  ) : (
    <></>
  );
};

export default TimeBadge;
