import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Loader from "../../../common/Loader";
import Table from "../../../common/Table";
import DownloadARDoc from "./DownloadARDoc";

const EPARList = ({ epData }) => {
  const [state] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const constants = state.constants.items;
  const [arList, setArList] = useState([]);
  useEffect(() => {
    getArList();
  }, []);

  const getArList = () => {
    setIsLoading(true);
    dataService.get(
      `eps/${epData.id}/sendings`,
      setArList,
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <h3>Liste des AR</h3>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          fullWidth
          fields={[
            {
              path: "userRecipient",
              name: "Allocataire",
              render: (el) => {
                var recipientRoute =
                  el.model == "UserRecipient"
                    ? "recipients"
                    : el.model == "UserRecipientDSP"
                    ? "recipients-temp"
                    : "recipients-ep";
                return (
                  <a
                    target="_BLANK"
                    href={`/${recipientRoute}/${el.id}`}
                    className="btn btn-link text-left"
                  >
                    {tools.getFullname(el)}
                    <div>{el.nir}</div>
                  </a>
                );
              },
            },
            {
              path: "*",
              name: "Convocation EP",
              render: (el) => (
                <DownloadARDoc elem={el} docData={el.convocation} epBeginAt={epData.beginAt} />
              ),
            },
            {
              path: "*",
              name: "Notification de décision",
              render: (el) =>
                el.notification?.historics.length ? (
                  <DownloadARDoc elem={el} docData={el.notification} />
                ) : el.directorNotice && el.directorNotice.decision == 2 ? (
                  "NC - A SURSEOIR"
                ) : (
                  "Notification non envoyée"
                ),
            },
          ]}
          datas={arList}
        />
      )}
    </>
  );
};

export default EPARList;
