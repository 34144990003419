import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useContext } from "react";
import colors from "../../../helpers/colors";
import useCERData from "../../Hooks/useCERData/useCERData";
import AmendmentOperatorsList from "./AmendmentOperatorsList";
import { GoalContext } from "./GoalContext";
import OperatorsList from "./OperatorsList";
import { StepContext } from "./StepContext";

const AmendmentStepsList = () => {
  const { cerSteps } = useCERData();
  const objective = useContext(GoalContext);

  var styles = StyleSheet.create({
    container: {
      paddingLeft: 20,
      paddingBottom: 4,
    },
  });

  const getStatusStyles = (step) => {
    const titleStyles = {
      fontSize: 10,
      paddingBottom: 3,
    };
    if (step.updateType) {
      switch (step.updateType) {
        case "DELETED":
          return {
            ...titleStyles,
            color: colors.danger,
            textDecoration: "line-through",
          };
        case "ADDED":
          return {
            ...titleStyles,
            color: colors.success,
          };
      }
    }

    return {
      ...titleStyles,
      color: "#7f7f7f",
    };
  };

  return objective.steps.map((step, sk) => {
    var stepRef = cerSteps.find((o) => o.id == step.step);
    var stepNum = parseInt(sk) + 1;
    const titleStyles = getStatusStyles(step);
    return (
      <View style={{ ...styles.container }}>
        <Text style={{ ...titleStyles }}>{stepRef?.name}</Text>
        <StepContext.Provider value={step}>
          <AmendmentOperatorsList />
        </StepContext.Provider>
      </View>
    );
  });
};

export default AmendmentStepsList;
