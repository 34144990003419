import { useAtom } from "jotai";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import {
  cerListAtom,
  cerListFiltersAtom,
  isSearchingCerListAtom,
  cerListFiltersModel,
} from "./atoms/cerListAtoms";

const useCERList = () => {
  const [state] = useStore();
  const [cerList, setCerList] = useAtom(cerListAtom);
  const [cerListFilters, setCerListFilters] = useAtom(cerListFiltersAtom);
  const [isSearchingCerList, setIsSearchingCerList] = useAtom(isSearchingCerListAtom);

  const getCerList = ({ overrideFilters = null }) => {
    setIsSearchingCerList(true);
    dataService.post(
      `cers/search`,
      overrideFilters || cerListFilters,
      setCerList,
      (err) => {},
      () => setIsSearchingCerList(false)
    );
  };

  const updateCerListFilters = (e) => {
    const { name, value, type, checked } = e.target;
    var realVal = value;
    if (type == "checkbox") realVal = checked ? true : false;
    setCerListFilters({
      ...cerListFilters,
      filters: {
        ...cerListFilters.filters,
        [name]: realVal,
      },
    });
  };

  const resetCerListFilters = () => {
    setCerListFilters({ ...cerListFiltersModel });
    getCerList({ overrideFilters: cerListFiltersModel });
  };

  const updateCerListPage = (page) => {
    setCerListFilters({
      ...cerListFilters,
      page,
    });
  };

  const changePage = (i) => {
    const newFilters = { ...cerListFilters, page: i };
    getCerList({ overrideFilters: newFilters });
    setCerListFilters(newFilters);
  };
  const changeNbItemsByPage = (i) => {
    const newFilters = { ...cerListFilters, nbItemsByPage: i };
    getCerList({ overrideFilters: newFilters });
    setCerListFilters(newFilters);
  };

  const filterByDraftCER2Months = () => {
    const newFilters = {
      ...cerListFiltersModel,
      filters: {
        ...cerListFiltersModel.filters,
        status: ["DRAFT"],
        userManager: state.auth.user.id,
        createdAt: DateTime.local().minus({ months: 2 }).toFormat("yyyy-MM-dd"),
      },
    };
    setCerListFilters(newFilters);
    getCerList({ overrideFilters: newFilters });
  };
  const filterByDraftCERToEnd = () => {
    const newFilters = {
      ...cerListFiltersModel,
      filters: {
        ...cerListFiltersModel.filters,
        status: ["SIGNED"],
        userManager: state.auth.user.id,
        endAt: DateTime.local().plus({ months: 2 }).toFormat("yyyy-MM-dd"),
      },
    };
    setCerListFilters(newFilters);
    getCerList({ overrideFilters: newFilters });
  };
  const filterByMyCER = () => {
    const newFilters = {
      ...cerListFiltersModel,
      filters: {
        ...cerListFiltersModel.filters,
        userManager: state.auth.user.id,
      },
    };
    setCerListFilters(newFilters);
    getCerList({ overrideFilters: newFilters });
  };

  return {
    cerList,
    cerListFilters,
    isSearchingCerList,
    actions: {
      getCerList,
      updateCerListFilters,
      updateCerListPage,
      filterByDraftCER2Months,
      filterByDraftCERToEnd,
      filterByMyCER,
      resetCerListFilters,
      changePage,
      changeNbItemsByPage,
    },
  };
};

export default useCERList;
