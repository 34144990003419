import React, { useEffect, useState } from "react";
import tools from "../../../helpers/tools";
import FormGenerator from "../../common/FormGenerator/FormGenerator";

import TimeBadge from "./TimeBadge";
import ModalBtn from "../../common/ModalBtn";
import useStore from "../../../context/useStore";
import { modalActions } from "../../../context/actions/modalActions";
import dataService from "../../../helpers/dataService";
import AddRecipientToCrossInterview from "../RecipientDetail/AddRecipientToCrossInterview";
import CreateRdvOnTheFly from "../RecipientDetail/common/CreateRdvOnTheFly/CreateRdvOnTheFly";
import AddRecipientToEp from "../RecipientDetail/AddRecipientToEp";

const questionLabels = {
  rdvOccured: "Le RDV a t-il eu lieu ?",
  decisionTaken: "Quelle décision a été prise ?",
  newPartner: "Sélectionnez le nouveau partenaire",
  rdvLocation: "Sélectionnez le lieu de RDV",
  newRdvReason: "Sélectionnez un motif pour le prochain RDV",
  dateRdvSamePartner: "Sélectionnez une date pour le prochain RDV",
  dateRdvNewPartner:
    "Sélectionnez une date pour le prochain RDV sur le site choisi",
  cancelReason: "Quel est le motif de l'annulation ?",
  reportReason: "Quel est le motif du report ?",
  reportDate: "A quelle date souhaitez-vous reporter le RDV ?",
  cancelOrigin: "Qui est à l'origine de l'annulation du RDV ?",
  accompany: "Par qui se fera l'accompagnement ?",
  operator: "Choisissez l'opérateur en charge de l'accompagnement",
  comment: "Rajoutez un commentaire",
};

const RdvCERDetail = ({
  rdv,
  setRdv,
  getRdvs,
  rdvsExpired,
  preventBack,
  openModal,
  setErrors,
}) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const [rdvProcess, setRdvProcess] = useState(false);
  const [step, setStep] = useState(0);
  const [rdvFormConfig, setRdvFormConfig] = useState({
    sections: ["Déroulement de l'entretien"],
    questions: [
      {
        id: 1,
        question: questionLabels.rdvOccured,
        name: "rdvOccured",
        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "Le RDV a eu lieu comme prévu" },
          {
            value: 2,
            label: "Le RDV est annulé OU l'allocataire n'est plus SDD",
          },
        ],
        condition: "",
      },
      {
        id: 2,
        question: questionLabels.decisionTaken,
        name: "decisionTaken",
        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "Sortie du dispositif accompagnement CAF" },
          {
            value: 2,
            label:
              "Fixer une date pour le prochain RDV (accompagnement ou renouvellement)",
          },
        ],
        condition: "q1==1",
      },
      {
        id: 3,
        question: "Quel est le motif de cette sortie du dispositif ?",
        name: "exitReason",
        type: 4,
        section: 0,
        answers: state.constants.items.RDV_EXIT_REASONS.map((d) => ({
          ...d,
          value: d.id,
          label: d.name,
        })),
        condition: "q1==1&&q2==1",
      },
      {
        id: 4,
        question: "Quels types de résultats ont été observés ?",
        name: "exitResultTypes",
        type: 7,
        section: 0,
        answers: [
          { value: 1, label: "Résultats sociaux" },
          { value: 2, label: "Résultats socio-professionnels" },
          {
            value: 3,
            label: "Aucun résultat (ne pas cumuler avec un autre choix)",
          },
        ],
        condition: "q1==1",
      },
      {
        id: 5,
        question: "Sélectionnez les résultats sociaux :",
        name: "exitSocialResults",
        type: 7,
        section: 0,
        answers: state.constants.items.RDV_EXIT_SOCIAL_RESULTS.map((d) => ({
          ...d,
          value: parseInt(d.id),
          label: d.name,
        })),
        condition: "q4in1&&q1==1",
      },
      {
        id: 6,
        question: "Sélectionnez les résultats sociaux-professionnels :",
        name: "exitSocioProResults",
        type: 7,
        section: 0,
        answers: state.constants.items.RDV_EXIT_PRO_RESULTS.map((d) => ({
          ...d,
          value: parseInt(d.id),
          label: d.name,
        })),
        condition: "q4in2&&q1==1",
      },
      {
        id: 7,
        question: "Actions à effectuer sur le CER",
        name: "cerActions",
        section: 0,
        condition: "q2==2",
        text: "Ouvrez la fiche de l'allocataire à l'aide du bouton 'Voir son parcours' et apportez les modifications nécessaires à son contrat CER. Déroulez ensuite les étapes du rendez-vous jusqu'à être invité(e) à fixer la prochaine date de rendez-vous.",
        answers: [],
      },

      {
        id: 8,
        question: questionLabels.cancelReason,
        name: "cancelReason",
        type: 4,
        section: 0,
        answers: [
          {
            value: 1,
            label:
              "Aucun report possible actuellement : L'allocataire ne s'est pas présenté ou l'agent n'a pas pu prendre en charge le rendez-vous",
          },
          { value: 2, label: "Le RDV doit être reporté" },
          { value: 3, label: "L'allocataire n'est plus soumis au RSA" },
          {
            value: 4,
            label: "L'allocataire a déménagé ou quitté le département",
          },
        ],
        condition: "q1==2",
      },
      {
        id: 9,
        question: questionLabels.cancelOrigin,
        name: "cancelOrigin",
        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "L'allocataire" },
          { value: 2, label: "L'agent en charge" },
        ],
        condition: "q1==2&&q8!=3&&q8!=4",
      },
      {
        id: 10,
        question: questionLabels.reportReason,
        name: "reportReason",
        type: 4,
        section: 0,
        answers: state.constants.items.RDV_REPORT_TYPES.map((r) => {
          return { value: r.id, label: r.name };
        }),
        condition: "q1==2&&q9==1",
      },

      {
        id: 11,
        question: questionLabels.comment,
        name: "comment",
        type: 1,
        section: 0,
        answers: [],
        condition: "",
      },
    ],
  });
  const [rdvClosed, setRdvClosed] = useState(false);
  const [isSavingRdv, setIsSavingRdv] = useState(false);
  const [isFromCafEg, setIsFromCafEg] = useState(false);

  //pour réserver les slots de RDV

  useEffect(() => {
    if (rdvClosed) {
      save();
    }
  }, [rdvClosed]);

  //on récupère les données des organ/sites/rdvs dispo aux étapes spécifiques du formulaire
  function updateFormAnswers(ans) {
    setRdvProcess(ans);
  }

  function save() {
    var decisionTakenRef = rdvProcess.find((r) => r.name == "decisionTaken");
    var exitReasonRef = rdvProcess.find((r) => r.name == "exitReason");
    var cancelReasonRef = rdvProcess.find((r) => r.name == "cancelReason");
    if (
      (decisionTakenRef?.value != "1" && decisionTakenRef?.value) ||
      cancelReasonRef?.value == "2"
    ) {
      mActions.updateModal({
        isOpen: true,
        noClose: true,
        content: (
          <CreateRdvOnTheFly
            organizationSlugName="CAF"
            recipient={rdv.userRecipient}
            onSaved={finalSave}
          />
        ),
      });
    } else if (
      decisionTakenRef?.value == "1" &&
      exitReasonRef?.value == "REORIENTATION"
    ) {
      mActions.updateModal({
        isOpen: true,
        noClose: true,
        content: (
          <AddRecipientToCrossInterview
            recipient={rdv.userRecipient}
            onSaved={finalSave}
          />
        ),
      });
    } else if (decisionTakenRef?.value == "1" && exitReasonRef?.value == "EP") {
      mActions.updateModal({
        isOpen: true,
        noClose: true,
        content: (
          <AddRecipientToEp recipient={rdv.userRecipient} refresh={finalSave} />
        ),
      });
    } else {
      finalSave();
    }
  }

  function finalSave(modalReturnedData) {
    var rdvOccured = rdvProcess.find((r) => r.name == "rdvOccured").value;
    var decisionTaken = rdvProcess.find((r) => r.name == "decisionTaken").value;
    var exitReason = rdvProcess.find((r) => r.name == "exitReason").value;
    var cancelReason = rdvProcess.find((r) => r.name == "cancelReason").value;
    var cancelOrigin = rdvProcess.find((r) => r.name == "cancelOrigin").value;
    var reportReason = rdvProcess.find((r) => r.name == "reportReason").value;
    var exitResultTypes = rdvProcess.find(
      (r) => r.name == "exitResultTypes"
    ).value;
    var exitSocialResults = rdvProcess.find(
      (r) => r.name == "exitSocialResults"
    ).value;
    var exitSocioProResults = rdvProcess.find(
      (r) => r.name == "exitSocioProResults"
    ).value;
    var comment = rdvProcess.find((r) => r.name == "comment").value;

    var otherPostParams = {};

    var closeType = 0;
    if (rdvOccured == 1) {
      closeType = 1; //RDV effectué - Parcours d'insertion en cours
      if (decisionTaken == 1) {
        closeType = 8; //sortie du dispositif
        otherPostParams = {
          exitReason,
          exitSocialResults,
          exitProResults: exitSocioProResults,
        };
      }
      if (decisionTaken == 2) {
        otherPostParams = {
          exitSocialResults,
          exitProResults: exitSocioProResults,
        };
      }
    }
    if (decisionTaken == 2) closeType = 2; //RDV effectué - Nécessite un nouveau RDV
    if (decisionTaken == 3) closeType = 3; //RDV effectué - Nécessite une nouvelle orientation

    if (rdvOccured == 2) {
      if (cancelReason == 4) closeType = 9; //Allocataire plus dans le depts.
      if (cancelReason == 3) closeType = 7; //Allocataire non soumis RSA
      if (cancelReason == 2) closeType = 4; //Report
      if (cancelReason == 1) closeType = 5; //Allocataire non présent
      if (closeType == 7 || closeType == 9) {
        cancelOrigin = 2;
      }
    }

    if (modalReturnedData?.rdvOnTheFly) {
      otherPostParams.dateLock = modalReturnedData.rdvOnTheFly.slotCalendar;
    }
    if (modalReturnedData?.reorientationPartner) {
      otherPostParams.newOrientation = modalReturnedData.reorientationPartner;
    }
    //return;
    dataService.patch(
      `slotcalendars/${rdv.id}/close`,
      {
        closeType: closeType,
        reportType: closeType == 2 || closeType == 4 ? cancelReason : null,
        cancelOrigin,
        comment,
        ...otherPostParams,
      },
      (data) => {
        setRdv(false);
        setRdvProcess(false);
        setStep(0);
        getRdvs();
      },
      setErrors
    );
  }

  return (
    <>
      <button
        className="btn btn-default mb-3"
        onClick={() => {
          setRdv(false);
          setRdvProcess(false);
          setStep(0);
        }}
      >
        <i className="fa fa-arrow-left mr-2" />
        Revenir à la liste
      </button>
      <div className="row">
        <div className="col-12">
          <div className="card animated fadeInUp faster">
            <div className="card-header d-flex align-items-center">
              <h2 className="mb-0 text-white d-flex justify-content-between align-items-center w-100">
                <span>{tools.getFullname(rdv.userRecipient)}</span>
                <ModalBtn
                  label="Voir son parcours"
                  btnClass="btn btn-default btn-sm bg-white"
                  id="recipientDetailModal"
                  onClick={() => {
                    openModal(rdv.userRecipient.id);
                  }}
                />
                {rdv.userRecipient.spouse && (
                  <ModalBtn
                    label="Voir le conjoint"
                    btnClass="btn btn-default btn-sm bg-white"
                    id="recipientDetailModal"
                    onClick={() => {
                      openModal(rdv.userRecipient.spouse.id);
                    }}
                  />
                )}
                <TimeBadge rdvsExpired={rdvsExpired} rdv={rdv} />
                <span>{tools.getPeriodFormated(rdv.start, rdv.end)}</span>
              </h2>
            </div>
            <div className="card-body px-5">
              <span className="d-none">{step}</span>
              <FormGenerator
                isDspFinished={false}
                setIsDspFinished={setRdvClosed}
                setFormData={() => {}}
                formConfig={rdvFormConfig}
                updateFormAnswers={updateFormAnswers}
                step={step}
                setStep={setStep}
                isVertical={true}
                preventBack={preventBack}
                isLoading={isSavingRdv}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RdvCERDetail;
