import React from "react";
import Page from "../../common/layout/Page";
import AllRecipientsFilters from "./AllRecipientsFilters";
import AllRecipientsList from "./AllRecipientsList";

const AllRecipients = () => {
  return (
    <>
      <Page className="container-fluid" title="Tous les allocataires">
        <AllRecipientsFilters />
      </Page>
      <div className="container-fluid">
        <AllRecipientsList />
      </div>
    </>
  );
};

export default AllRecipients;
