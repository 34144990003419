import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import Table from "../../common/Table";
import Page from "../../common/layout/Page";
import SuiviAnomalyListFilters from "./SuiviAnomalyListFilters/SuiviAnomalyListFilters";
import useSuiviAnomalyList from "./useSuiviAnomalyList";
import tools from "../../../helpers/tools";
import { DateTime } from "luxon";
import { useEffect } from "react";
import Pagination from "../../common/Pagination";

function SuiviAnomalyList() {
  const {
    filteredAnomalies: anomalies,
    paginatedAnomalies,

    constants,
    isLoading,
    errors,
    actions,
    nbResults,
    nbPages,
    pagination,
  } = useSuiviAnomalyList();

  useEffect(() => {
    actions.searchData();
  }, []);

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        title={"Suivi BRSA SDD sans RDSP ou sans RDV EG"}
        subTitle="Cette liste affiche la totalité des anomalies. La recherche initiale est automatique et peut prendre un peu de temps, mais vous pouvez ensuite filtrer les résultats en temps réel."
        errors={errors}
      >
        <SuiviAnomalyListFilters
          constants={constants}
          isLoading={isLoading}
          nbResults={nbResults}
          nbResultsFiltered={anomalies.length}
          actions={actions}
        />
      </Page>
      {isLoading && <Loader />}
      {constants.length == 0 ? (
        <Loader />
      ) : (
        <div className="mx-auto mt-2">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : (
            <>
              <Pagination
                nbPages={nbPages}
                page={pagination.page}
                changePage={actions.changePage}
                nbItemsByPage={pagination.nbItemsByPage}
                changeNbItemsByPage={actions.changeNbItemsByPage}
              />
              <Table
                datas={paginatedAnomalies}
                exportOptions={{
                  datas: anomalies.map((r) => ({
                    ...r,
                    name: tools.getFullname(r),
                    recipientAddress: `${r.address || ""} ${
                      r.location?.locationName || ""
                    } ${r.location?.postalCode || ""}`,
                    recipientType: tools.getRecipientTypeLibelle(r.model),
                    RDSPPresente: r.RDSPPresente ? "Oui" : "Non",
                    rdvEGPresent: r.rdvEGPresent ? "Oui" : "Non",
                    typeAnomalie: tools.findIn(
                      constants.ANOMALY_TYPES,
                      "id",
                      r.typeAnomalie
                    )?.name,
                    dateEnvoiNotif1: r.dateEnvoiNotif1
                      ? tools.formatDate(r.dateEnvoiNotif1)
                      : "",
                    dateEnvoiCourrierJ16: r.dateEnvoiCourrierJ16
                      ? tools.formatDate(r.dateEnvoiCourrierJ16)
                      : "",
                    dateEnvoiCourrierJ16Reelle: r.dateEnvoiCourrierJ16Reelle
                      ? tools.formatDate(r.dateEnvoiCourrierJ16Reelle)
                      : "Non envoyé",
                    suspendedMonth: r.moisSuspension
                      ? tools.formatDate(r.moisSuspension, "MMMM yyyy")
                      : "",
                  })),

                  excludedFields: ["Voir"],
                  objectFields: {
                    location: {
                      dataIndexes: ["name"],
                    },
                  },
                  additionalFields: [
                    { name: "Adresse allocataire", path: "recipientAddress" },
                    { name: "NIR", path: "nir" },
                    { name: "Matr.", path: "CAFId" },
                    { name: "Type alloc", path: "recipientType" },
                    {
                      name: "Date envoi courrier J+16 réelle",
                      path: "dateEnvoiCourrierJ16Reelle",
                    },
                  ],
                }}
                fields={[
                  {
                    name: "Allocataire",
                    path: "*",
                    exportPath: "name",
                    render: (elem) => (
                      <>
                        <div
                          className={`badge badge-${tools.getRecipientTypeColor(
                            elem.model
                          )}`}
                        >
                          {tools.getRecipientTypeLibelle(elem.model)}
                        </div>
                        <a
                          target="_blank"
                          rel="noreferer"
                          href={tools.getRecipientLink(elem)}
                          className={`btn btn-link text-left ${
                            elem.archivedAt ? "text-black-50" : ""
                          }`}
                        >
                          {tools.getFullname(elem)}
                        </a>
                        <small className="d-block">NIR : {elem.nir}</small>
                        <small className="d-block">Matr. : {elem.CAFId}</small>
                        <small className="d-block">{elem.email}</small>
                        {elem.archivedAt && (
                          <small className="badge badge-dark d-block text-center">
                            <i className="fa fa-archive mr-1" />
                            Archivé le{" "}
                            {DateTime.fromISO(elem.archivedAt).toFormat(
                              "dd/MM/yyyy"
                            )}
                          </small>
                        )}
                      </>
                    ),
                  },
                  {
                    name: "Date envoi 1ère solicitation",
                    path: "*",
                    exportPath: "dateEnvoiNotif1",
                    render: (elem) =>
                      elem.dateEnvoiNotif1
                        ? DateTime.fromISO(elem.dateEnvoiNotif1).toFormat(
                            "dd/MM/yyyy"
                          )
                        : "",
                  },
                  {
                    name: "Date envoi du courrier J+16",
                    path: "*",
                    exportPath: "dateEnvoiCourrierJ16",
                    render: (elem) => {
                      return (
                        <>
                          <div>
                            <small className="d-block font-weight-bold">
                              Prévue :
                            </small>
                            <small className="d-block">
                              {elem.dateEnvoiCourrierJ16
                                ? DateTime.fromISO(
                                    elem.dateEnvoiCourrierJ16
                                  ).toFormat("dd/MM/yyyy")
                                : ""}
                            </small>
                          </div>
                          <div>
                            <small className="d-block font-weight-bold">
                              Réelle :{" "}
                            </small>
                            <small className="d-block">
                              {elem.dateEnvoiCourrierJ16Reelle ? (
                                DateTime.fromISO(
                                  elem.dateEnvoiCourrierJ16
                                ).toFormat("dd/MM/yyyy")
                              ) : (
                                <i className="text-danger">Non envoyé</i>
                              )}
                            </small>
                          </div>
                        </>
                      );
                    },
                  },
                  {
                    name: "Mois de suspension",
                    path: "*",
                    exportPath: "suspendedMonth",
                    render: (elem) =>
                      tools.formatDate(elem.moisSuspension, "MMMM yyyy"),
                  },
                  {
                    name: "Type anomalie",
                    exportPath: "typeAnomalie",
                    path: "typeAnomalie",
                    render: (elem) => (
                      <small>
                        {
                          tools.findIn(constants.ANOMALY_TYPES, "id", elem)
                            ?.name
                        }
                      </small>
                    ),
                  },
                  {
                    name: "Type de droits",
                    exportPath: "typeDroits",
                    path: "typeDroits",
                  },
                  {
                    name: "RDSP",
                    path: "RDSPPresente",
                    exportPath: "RDSPPresente",
                    render: (elem) => (
                      <div
                        className={`badge badge-${elem ? "success" : "danger"}`}
                      >
                        <i className={`fa fa-${elem ? "check" : "times"}`} />
                      </div>
                    ),
                  },
                  {
                    name: "RDV EG",
                    path: "rdvEGPresent",
                    exportPath: "rdvEGPresent",
                    render: (elem) => (
                      <div
                        className={`badge badge-${elem ? "success" : "danger"}`}
                      >
                        <i className={`fa fa-${elem ? "check" : "times"}`} />
                      </div>
                    ),
                  },
                  {
                    name: "Voir",
                    path: "*",
                    render: (elem) => (
                      <a
                        target="_blank"
                        rel="noreferer"
                        href={tools.getRecipientLink(elem)}
                        className="btn btn-primary w-100 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-eye"></i>
                      </a>
                    ),
                  },
                ]}
              />
              <Pagination
                nbPages={nbPages}
                page={pagination.page}
                changePage={actions.changePage}
                nbItemsByPage={pagination.nbItemsByPage}
                changeNbItemsByPage={actions.changeNbItemsByPage}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default SuiviAnomalyList;
