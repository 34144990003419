import { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useCERElementsCount = ({ url, year }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState(false);

  const getDatas = () => {
    setIsLoading(true);
    dataService.post(url, { year }, setDatas);
    setIsLoading(false);
  };

  return {
    isLoading,
    getDatas,
    datas,
  };
};

export default useCERElementsCount;
