import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import tools from "../../../../helpers/tools";

const PVAnnexes = ({ styles, datas, recipients, orgas, constants, title }) => {
  const {
    EP_REASONS_1,
    EP_REASONS_2,
    EP_SANCTION_REASONS_1,
    EP_SANCTION_REASONS_2,
    EP_ADDITIONAL_REASONS,
    CONTRACT_TYPES,
    EP_SANCTION_PRESENCES,
    EP_TYPES,
  } = constants;

  const getMotifs = (recipient) => {
    var motifs = [];
    if (recipient.type == "SANCTION") {
      recipient.reasons.forEach((reason) => {
        motifs.push({
          reason1: EP_SANCTION_REASONS_1.find((rea) => rea.id == reason.reason1).name,
          reason2: reason.reasons2.map(
            (rea2) => EP_SANCTION_REASONS_2.find((rea) => rea.id == rea2).name
          ),
        });
      });
    }
    if (recipient.type == "REORIENTATION_KO") {
      recipient.reasons.forEach((reason) => {
        motifs.push({
          reason1: tools.getReason1NameFromId(constants, reason.reason1),
          reason2: reason.reasons2.map((rea2) => tools.getReason2NameFromId(constants, rea2)),
        });
      });
    }
    if (recipient.type == "REORIENTATION_OK") {
      recipient.reasons.forEach((reason) => {
        motifs.push({
          reason1: tools.getReason1NameFromId(constants, reason.reason1),
          reason2: reason.reasons2.map((rea2) => tools.getReason2NameFromId(constants, rea2)),
        });
      });
    }
    if (recipient.type == "SOCIAL_PATH_MAINTAIN") {
      motifs.push({
        reason1: "Maintien du parcours social",
        reason2: [],
      });
    }
    return motifs;
  };

  const getSanctions = (recipient) => {
    if (recipient.type == "SANCTION") {
      return tools.getSanctionSentence(recipient.EPNotice, constants);
    }
    if (recipient.type == "REORIENTATION_KO") {
      return tools.getReorientationSentence(recipient.EPNotice, constants, orgas);
    }
    if (recipient.type == "REORIENTATION_OK") {
      return tools.getReorientationOkSentence(recipient.EPNotice, constants, orgas);
    }
    if (recipient.type == "SOCIAL_PATH_MAINTAIN") {
      return "Maintien du parcours social";
    }
    return "";
  };

  const getDirectorDecision = (recipient) => {
    if (recipient.directorNotice?.decision) {
      return {
        decision: recipient.directorNotice.decision == 1 ? "ACCORD" : "A SURSEOIR",
        comment: recipient.directorNotice.comment,
      };
    }
    return "";
  };

  const colConfig = [
    {
      name: "N°CAF",
      size: "small",
      path: "CAFId",
    },
    {
      name: "Allocataire",
      size: "small",
      path: "recipient",
    },

    {
      name: "Adresse",
      size: "small",
      path: "address",
    },

    {
      name: "Date naiss.",
      size: "small",
      path: "birthdate",
    },
    {
      name: "Type",
      size: "small",
      path: "type",
    },
    {
      name: "Motif convoc.",
      size: "large",
      path: "reasons",
    },
    {
      name: "Présence alloc.",
      size: "small",
      path: "presence",
    },

    {
      name: "Décision EP",
      size: "large",
      path: "decisionEP",
    },
    {
      name: "Décision directeur",
      size: "small",
      path: "decisionDirector",
    },
  ];

  const renderLines = () => {
    return recipients
      .sort(
        (a, b) => {
          const directorDecisionA = getDirectorDecision(a);
          const directorDecisionB = getDirectorDecision(b);
          return directorDecisionA > directorDecisionB ? 1 : -1;
        },
        { decision: 1 }
      )
      .map((rec, reck) => {
        const motifs = getMotifs(rec);
        const sanctions = getSanctions(rec);
        const directorDecision = getDirectorDecision(rec);
        let orgaSaisine = orgas.find((o) => o.id == rec.organizationOrigin);

        orgaSaisine = orgaSaisine ? orgaSaisine.name : null;
        return (
          <View
            key={`allocdecline${reck}`}
            style={{
              ...styles.tableRowCustom,
              paddingLeft: 20,
              paddingRight: 20,
            }}
            wrap={false}
          >
            <View
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "CAFId").size]}%`,
              }}
            >
              <Text>{rec.userRecipient.CAFId}</Text>
              {rec.userRecipient.origin == "STOCK" ? (
                <Text
                  style={{
                    marginTop: 5,
                    backgroundColor: "black",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  STOCK
                </Text>
              ) : null}
            </View>
            <View
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "recipient").size]}%`,
              }}
            >
              <Text style={{ marginBottom: 5 }}>{tools.getFullname(rec.userRecipient)}</Text>

              {rec.userRecipient?.orientation?.partner && (
                <View style={{ marginBottom: 5 }}>
                  <Text>Réf. unique : {rec.userRecipient.orientation.partner}</Text>
                </View>
              )}
            </View>

            <Text
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "address").size]}%`,
              }}
            >
              {rec.userRecipient.address || ""} {rec.userRecipient.location?.postalCode || ""}{" "}
              {rec.userRecipient.location?.name || ""}
            </Text>

            <Text
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "birthdate").size]}%`,
              }}
            >
              {rec.userRecipient.birthdate &&
                DateTime.fromISO(rec.userRecipient.birthdate).toFormat("dd/MM/yyyy")}
            </Text>
            <Text
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "type").size]}%`,
              }}
            >
              {EP_TYPES.find((c) => c.id == rec.type).name}
            </Text>
            <View
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "reasons").size]}%`,
              }}
            >
              {orgaSaisine && (
                <View style={{ marginBottom: 5 }}>
                  <Text>Organisme de saisine : {orgaSaisine}</Text>
                </View>
              )}

              {motifs.map((m, mk) => (
                <View key={`reas1${mk}${m.reason1}`} style={{ marginBottom: 5 }}>
                  <Text>- {m.reason1}</Text>
                  {m.reason2.map((r2, r2k) => (
                    <Text key={`reas2${r2}${mk}${r2k}`} style={{ paddingLeft: 7 }}>
                      - {r2}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
            <Text
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "presence").size]}%`,
              }}
            >
              {EP_SANCTION_PRESENCES.find((pres) => pres.id == rec.presence.answer).name}
            </Text>

            <Text
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "decisionEP").size]}%`,
              }}
            >
              {sanctions}
            </Text>
            <View
              style={{
                ...styles.tableCell17,
                width: `${sizes[colConfig.find((cf) => cf.path == "decisionDirector").size]}%`,
              }}
            >
              <Text>{directorDecision.decision}</Text>
              <Text>{directorDecision.comment}</Text>
            </View>
          </View>
        );
      });
  };

  const largeColsSize = 16;
  const nbLargeCols = colConfig.filter((col) => col.size == "large").length;
  const sizes = {
    small: (100 - largeColsSize * nbLargeCols) / (colConfig.length - nbLargeCols),
    large: largeColsSize,
  };

  return (
    <Page wrap size="A4" orientation="landscape">
      <View style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
        <View
          style={{
            fontSize: 12,
            textAlign: "center",
          }}
        >
          {title}
        </View>
        <Text
          style={{
            ...styles.title,
          }}
        >
          Liste des allocataires et décisions
        </Text>
      </View>
      <View style={{ ...styles.tableRowCustom, paddingLeft: 20, paddingRight: 20 }}>
        {colConfig.map((col, colk) => (
          <Text
            key={`mycol${colk}`}
            style={{
              ...styles.tableCell17,
              ...styles.tableHead,
              width: `${sizes[col.size]}%`,
            }}
          >
            {col.name}
          </Text>
        ))}
      </View>
      {renderLines()}
    </Page>
  );
};

export default PVAnnexes;
