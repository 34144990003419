import React, { useContext } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERData from "../../../../../../../../Hooks/useCERData/useCERData";
import useCERModule from "../../../../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { StepContext } from "../StepContext";
import { OperatorContext } from "./OperatorContext";

const IconBlock = ({ icon, children }) => {
  return (
    <div className="text-black-50">
      <i className={`fa fa-${icon} pr-1`} />
      {children}
    </div>
  );
};

const OperatorView = ({ goToEditMode }) => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const operator = useContext(OperatorContext);
  const { operators } = useCERData();
  const { recipientCER, events, isRecipientCERDraft } = useCERModule();
  const operatorRef = operators.find((o) => o.id == operator.operator);
  var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);
  var operatorIndex = tools.getOperatorIndex(step.operators, operator);

  const deleteOperator = () => {
    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((s, sk) => {
          return sk == stepIndex
            ? { ...s, operators: s.operators.filter((o, ok) => ok != operatorIndex) }
            : s;
        }),
      ],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
  };

  const linksClassName = "px-0 bg-transparent";

  return (
    <div
      className="d-flex align-items-center justify-content-between item-list-view"
      style={{ fontSize: 12 }}
    >
      <div>
        <strong className="text-black-50">{operatorRef?.name}</strong>
        <div style={{ paddingLeft: 10 }}>
          {operatorRef?.address && (
            <IconBlock icon="compass">
              {operatorRef.address} {operatorRef.location?.postalCode} {operatorRef.location?.name}
            </IconBlock>
          )}
          {operatorRef?.phone && (
            <IconBlock icon="phone">
              <a className={linksClassName} href={`tel:${operatorRef.phone}`}>
                {tools.formatPhone(operatorRef.phone)}
              </a>
            </IconBlock>
          )}
          {operatorRef?.email && (
            <IconBlock icon="at">
              <a className={linksClassName} href={`mailto:${operatorRef.email}`}>
                {operatorRef.email}
              </a>
            </IconBlock>
          )}
          {operatorRef?.link && (
            <IconBlock icon="link">
              <a className={linksClassName} target="_blank" href={operatorRef.link}>
                {operatorRef.link}
              </a>
            </IconBlock>
          )}
        </div>
      </div>

      {isRecipientCERDraft && (
        <div>
          <button className="btn btn-sm" onClick={() => goToEditMode(operator.operator)}>
            <i className="fa fa-edit text-black-50" />
          </button>
          <button className="btn btn-sm" onClick={deleteOperator}>
            <i className="fa fa-times text-black-50" />
          </button>
        </div>
      )}
    </div>
  );
};

export default OperatorView;
