import { Provider } from "jotai";
import React from "react";
import useCERData from "../../../../Hooks/useCERData/useCERData";
import useCERModule from "../../CERModule/hooks/useCERModule";
import CEREditSection from "../CEREditSection/CEREditSection";
import AddObjectiveForm from "./AddObjectiveForm/AddObjectiveForm";
import ObjectivesList from "./ObjectivesList/ObjectivesList";
import ObjectivesListSigned from "./ObjectivesList/ObjectivesListSigned";
import PathTypeBlock from "./PathTypeBlock/PathTypeBlock";

const CERGoalsForm = () => {
  const { recipientCER, isRecipientCERDraft } = useCERModule();
  const { pathTypes } = useCERData();

  return (
    <div className="row mx-0 my-3">
      <div className="col-12 mb-2">
        <div>
          <strong>Objectifs</strong>
        </div>
      </div>
      <div className="col-12">
        {recipientCER.id ? (
          <div>
            {pathTypes.map((pathType) => (
              <PathTypeBlock pathType={pathType} key={`pathtype${pathType.id}`}>
                {isRecipientCERDraft ? (
                  <ObjectivesList pathType={pathType} />
                ) : (
                  <ObjectivesListSigned pathType={pathType} />
                )}
                {isRecipientCERDraft ? <AddObjectiveForm pathType={pathType} /> : null}
              </PathTypeBlock>
            ))}
          </div>
        ) : (
          <div>
            <p className="font-italic my-2">
              Enregistrez le contrat pour pouvoir éditer les objectifs
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CERGoalsForm;
