import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import Table from "../../common/Table";
import tools from "../../../helpers/tools";
import { DateTime } from "luxon";
import SaveBtn from "../../common/SaveBtn";
import dataService from "../../../helpers/dataService";

var strTimeout = null;

const RecipientTempList = (props) => {
  const [recipients, setRecipients] = useState([]);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("RecipientTempList", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          user: {
            searchString: "",
            noEmail: false,
            isPublicCaf: false,
          },
          page: 1,
          nbItemsByPage: 25,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);

  function searchRecipients(overrideFilter) {
    setErrors({});
    const realSearch = overrideFilter || search;
    tools.saveState("RecipientTempList", "search", JSON.stringify(realSearch));
    setIsLoading(true);
    dataService.post(
      "recipients_dsp/search",
      realSearch,
      (data) => {
        setRecipients(data.data);
        setNbPages(Math.ceil(parseInt(data.count) / realSearch.nbItemsByPage));
        setIsLoading(false);
        setNbResults(data.count);
      },
      setErrors,
      () => setIsLoading(false)
    );
  }
  function updateSearch(e) {
    var value = e.target.value;
    var su = { ...search };

    su.user[e.target.name] = value;

    setSearch(su);
  }

  function changePage(i) {
    const newSearch = { ...search, page: i };
    setSearch(newSearch);
    searchRecipients(newSearch);
  }
  function changeNbItemsByPage(i) {
    const newSearch = { ...search, nbItemsByPage: i };
    setSearch(newSearch);
    searchRecipients(newSearch);
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    searchRecipients();
  };

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        title={"Allocataires temporaires"}
      >
        <form onSubmit={onSubmitSearch}>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 justify-content-center">
              <Control
                margin={"my-0"}
                label={
                  <>
                    Recherche
                    <div>
                      <i className="text-muted" style={{ fontSize: "0.7rem" }}>
                        Recherchez un NIR, Matricule, Nom, Prénom, Téléphone,
                        Email
                      </i>
                    </div>
                  </>
                }
                name="searchString"
                value={search.user.searchString}
                change={updateSearch}
                suffix={
                  <i
                    className="fa fa-search ml-2"
                    style={{ lineHeight: "34px" }}
                  ></i>
                }
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-center">
              <Control
                margin={"my-0"}
                label={"Ne possédant aucun email"}
                name="noEmail"
                type="checkbox"
                checked={search.user.noEmail}
                change={(e) =>
                  setSearch({
                    ...search,
                    user: { ...search.user, noEmail: e.target.checked },
                  })
                }
                suffix={
                  <i
                    className="fa fa-search ml-2"
                    style={{ lineHeight: "34px" }}
                  ></i>
                }
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-center">
              <Control
                margin={"my-0"}
                label={
                  <>
                    public CAF
                    <div>
                      <i className="text-muted" style={{ fontSize: "0.7rem" }}>
                        uniquement les moins de 18 ans et les 25 ans et plus
                      </i>
                    </div>
                  </>
                }
                name="isPublicCaf"
                type="checkbox"
                checked={search.user.isPublicCaf}
                change={(e) =>
                  setSearch({
                    ...search,
                    user: {
                      ...search.user,
                      isPublicCaf: e.target.checked,
                    },
                  })
                }
                suffix={
                  <i
                    className="fa fa-search ml-2"
                    style={{ lineHeight: "34px" }}
                  ></i>
                }
              />
            </div>
            {/* <div className="col-12 col-md-6 col-lg-4 justify-content-center">
         <Control
                label="Début des droits"
                name="rightsDBegin"
                value={search.recipient.product}
                change={updateRecipientSearch}
                type="select"
                datas={products}
                suffix={
                  <i
                    className="fa fa-search ml-2 pt-2"
                    style={{ lineHeight: "40px" }}
                  ></i>
                }
              /> 
        </div> */}
            <div className="col-12">
              <SaveBtn
                type="btn-primary"
                className="w-100 d-center py-3"
                typeBtn="submit"
                isSaving={isLoading}
                text="Rechercher"
              />
            </div>
            <div className="col-12 d-center mt-2">
              {nbResults} allocataires temporaires trouvés
            </div>
          </div>
        </form>
      </Page>
      {state.constants.items.length == 0 || isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12 p-4">
              {errors && errors.other ? (
                <div className="text-danger m-auto text-center">
                  {errors.other}
                </div>
              ) : isLoading ? (
                <Loader />
              ) : (
                <>
                  <Table
                    datas={recipients}
                    exportOptions={{
                      fetchDatas: "recipients_dsp/search",
                      fetchParams: { ...search, nbItemsByPage: "*" },
                      formatDatasFn: (datas) =>
                        datas.map((r) => ({
                          ...r,
                          name: tools.getFullname(r),
                          updatedFromSIDAt: r.updatedFromSIDAt
                            ? DateTime.fromISO(r.updatedFromSIDAt).toFormat(
                                "dd/MM/yyyy"
                              )
                            : "",
                          createdAt: r.createdAt
                            ? DateTime.fromISO(r.createdAt).toFormat(
                                "dd/MM/yyyy"
                              )
                            : "",
                          recipient_rights:
                            r.rights &&
                            constants.RSA_TYPES.find(
                              (m) => m.id == r.rights.type
                            ) &&
                            constants.RSA_TYPES.find(
                              (m) => m.id == r.rights.type
                            ).name,
                          age: r.birthdate
                            ? tools.getAgeFromBirthdate(r.birthdate)
                            : "",
                        })),
                      excludedFields: ["Voir", "Droits"],
                      objectFields: {
                        location: {
                          dataIndexes: ["name", "postalCode"],
                        },
                      },
                      additionalFields: [
                        {
                          name: "Droits alloc.",
                          path: "recipient_rights",
                        },
                        {
                          name: "Date de création ESPOAR",
                          path: "createdAt",
                        },
                        { name: "Date maj SID", path: "updatedFromSIDAt" },
                      ],
                    }}
                    fields={[
                      {
                        name: "Nom",
                        exportPath: "name",
                        path: "*",
                        render: (elem) => (
                          <Link
                            to={"/recipients-temp/" + elem.id}
                            className="btn btn-link btn-sm d-flex align-items-center justify-content-center"
                          >
                            {tools.getFullname(elem)}
                          </Link>
                        ),
                      },
                      {
                        name: "NIR",
                        path: "nir",
                      },
                      {
                        name: "Age",
                        exportPath: "age",
                        path: "*",
                        render: (elem) =>
                          elem && elem.birthdate
                            ? tools.getAgeFromBirthdate(elem.birthdate)
                            : "",
                      },
                      {
                        name: "Matr.",
                        path: "CAFId",
                      },
                      {
                        name: "Email",
                        path: "email",
                      },
                      {
                        name: "Tél",
                        path: "phone",
                      },
                      {
                        name: "Lieu",
                        path: "location",
                        render: (elem) =>
                          elem && elem.postalCode
                            ? elem.postalCode + " " + elem.name
                            : "",
                      },
                      {
                        name: "Origine",
                        path: "origin",
                        render: (elem) =>
                          elem &&
                          constants.RECIPIENT_ORIGINS.find((m) => m.id == elem)
                            .name,
                      },
                      {
                        name: "Droits",
                        path: "rights",
                        render: (elem) =>
                          elem &&
                          constants.RSA_TYPES.find((m) => m.id == elem.type) &&
                          constants.RSA_TYPES.find((m) => m.id == elem.type)
                            .name,
                      },
                      {
                        name: "Voir",
                        path: "id",
                        render: (elem) => (
                          <Link
                            to={"/recipients-temp/" + elem}
                            className="btn btn-primary w-100 btn-sm d-flex align-items-center justify-content-center"
                          >
                            <i className="fa fa-eye"></i>
                          </Link>
                        ),
                      },
                    ]}
                  />
                </>
              )}
              <Pagination
                nbPages={nbPages}
                page={search.page}
                changePage={changePage}
                nbItemsByPage={search.nbItemsByPage}
                changeNbItemsByPage={changeNbItemsByPage}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecipientTempList;
