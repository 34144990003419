import React from "react";
import colors from "../../../../../../helpers/colors";
import useAmendmentModule from "../../hooks/useAmendmentModule";
import useCERModule from "../../hooks/useCERModule";
import CERActionButtons from "./CERActionButtons/CERActionButtons";
import CERAmendmentButtons from "./CERAmendmentButtons/CERAmendmentButtons";
import CERInfosList from "./CERInfosList/CERInfosList";
import CERTimeline from "./CerTimeline/CERTimeline";

const CERDisplay = () => {
  const { isRecipientCERDraft } = useCERModule();
  const { cerAmendments } = useAmendmentModule();
  const getCERStatusBadge = () => {
    const badgeStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 0,
      fontSize: 16,
    };
    if (isRecipientCERDraft) {
      return (
        <div style={{ ...badgeStyle }} className="badge badge-dark">
          BROUILLON
        </div>
      );
    } else {
      return (
        <div style={{ ...badgeStyle }} className="badge badge-success">
          SIGNÉ
        </div>
      );
    }
  };

  const color = isRecipientCERDraft ? colors.dark : colors.success;

  return (
    <>
      <div
        className="custom-card mx-auto mb-0 mt-3 position-relative"
        style={{ maxWidth: 340, borderTop: `4px solid ${color}` }}
      >
        <div className="d-center flex-column">
          <div className="text-center mb-3">
            <h4 className="d-flex align-items-center">
              <span className="pr-2">Contrat CER</span>
              {getCERStatusBadge()}
            </h4>
          </div>
          <CERInfosList />
          <CERActionButtons />
        </div>
      </div>
      {!isRecipientCERDraft && (
        <div className=" mx-auto mb-3" style={{ maxWidth: 340 }}>
          <CERAmendmentButtons />
          {cerAmendments.length > 0 && <CERTimeline />}
        </div>
      )}
    </>
  );
};

export default CERDisplay;
