import React, { useEffect, useMemo, useState } from "react";

const Accordion = ({
  items,
  k = "1",
  className = "",
  btnClassName = "",
  cardClassName = "",
  btnStyle = {},
  btnContainerClassName = "",
  bodyClassName = "p-0",
  style = {},
  noChevron = false,
  year,
}) => {
  const [opened, setOpened] = useState(items.map((i) => false));
  var accordionId = `accordionnewss${k}`;

  const toggleItem = (idx) => {
    let newOpened = [...opened];
    if (opened[idx]) {
      newOpened[idx] = false;
      setOpened((o) => newOpened);
    } else {
      newOpened[idx] = true;

      setOpened((o) => newOpened);
    }
  };

  return useMemo(() => {
    return (
      <div
        id={accordionId}
        className={" " + className}
        key={"accnewsss" + k}
        style={style}
      >
        {items.map((it, itk) => {
          var itemKey = `${itk}${k}`;
          var headId = `headinghh${itemKey}`;
          return (
            <div
              className={`mb-1  px-3 rounded py-2 ${cardClassName}`}
              key={`accnewss${itemKey}`}
              style={{ background: "#e3e3e3" }}
            >
              <div className=" p-0 bg-transparent" id={headId}>
                <strong className={`mb-0  ${btnContainerClassName}`}>
                  <div
                    onClick={() => toggleItem(itk)}
                    style={{
                      ...btnStyle,
                    }}
                    className={`cursor-pointer d-flex align-items-center justify-content-between w-100  text-primary ${btnClassName}`}
                  >
                    {it.title}
                    {!noChevron && it.content ? (
                      <i className="fa fa-chevron-down text-primary p-2" />
                    ) : (
                      <div style={{ width: 30 }}></div>
                    )}
                  </div>
                </strong>
              </div>

              <div
                style={{
                  display: opened[itk] ? "block" : "none",
                }}
                className="overflow-hidden"
              >
                <div
                  className={`${bodyClassName} p-2`}
                  style={{
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  {it.content}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [opened, year]);
};

export default Accordion;
