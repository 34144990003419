import React, { useContext, useEffect, useState } from "react";
import tools from "../../../../../../../../helpers/tools";
import useCERModule from "../../../../../CERModule/hooks/useCERModule";
import AddEntityForm from "../../../common/AddEntityForm";
import { ObjectiveContext } from "../../ObjectiveContext";
import StepSelect from "./StepSelect";

const AddStepForm = () => {
  const objective = useContext(ObjectiveContext);
  const { recipientCER, events } = useCERModule();

  var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);

  const [newStep, setNewStep] = useState({
    step: "",
    operators: [],
    detail: { status: "UNFINISH" },
  });

  const handleNewStepChange = (e) => {
    const { name, value } = e.target;
    setNewStep({ ...newStep, [name]: value });
  };

  const handleAddStep = () => {
    const newObjective = {
      ...objective,
      steps: [...objective.steps, newStep],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
    setNewStep({ step: "", operators: [], detail: { status: "UNFINISH" } });
  };

  useEffect(() => {
    if (newStep.step) {
      handleAddStep();
    }
  }, [newStep]);

  return (
    <AddEntityForm onSubmit={handleAddStep} pbClass="pb-0">
      <StepSelect
        handleNewStepChange={handleNewStepChange}
        objective={objective}
        step={newStep}
        k={`addStep`}
      />
      {/* <div className="d-flex justify-content-end">
        <button className="btn btn-sm" type="submit" disabled={!newStep.step}>
          <i className="fa fa-plus" />
        </button>
      </div> */}
    </AddEntityForm>
  );
};

export default AddStepForm;
