import React from "react";
import ReactApexChart from "react-apexcharts";
import SaveBtn from "../../../common/SaveBtn";
import colors from "../../../../helpers/colors";
import useNbOrientByPartNoEG from "./useNbOrientByPartNoEG";

const NbOrientByPartNoEG = ({
  url,
  year,
  displayTotal,
  showLine = true,
  showPie = true,
  otherPostParams = {},
}) => {
  const { isLoading, chartState, getDatas, hasDatas, total, totalML } = useNbOrientByPartNoEG(
    url,
    year,
    otherPostParams
  );

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          color={hasDatas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas ? (
        <>
          <div className="col-12 col-md-6">
            {showLine && (
              <ReactApexChart
                options={chartState.line1.options}
                series={chartState.line1.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className="col-12 col-md-6">
            {showPie && (
              <ReactApexChart
                options={chartState.pie1.options}
                series={chartState.pie1.series}
                type="pie"
                height={350}
              />
            )}
          </div>
          {displayTotal ? (
            <div className="col-12 text-center font-weight-bold mb-3">TOTAL : {total}</div>
          ) : null}

          <div className="col-12 col-md-6 mt-3">
            <h5>Détail par mission locale</h5>

            {showLine && (
              <ReactApexChart
                options={chartState.line2.options}
                series={chartState.line2.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className="col-12 col-md-6">
            <h5>Détail par mission locale</h5>

            {showPie && (
              <ReactApexChart
                options={chartState.pie2.options}
                series={chartState.pie2.series}
                type="pie"
                height={350}
              />
            )}
          </div>
          {displayTotal ? (
            <div className="col-12 text-center font-weight-bold mb-3">TOTAL ML : {totalML}</div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default NbOrientByPartNoEG;
