import React, { useEffect, useRef } from "react";
import Clouds from "./Clouds";
import $ from "jquery";

const Progress = ({
  step,
  maxStep,
  color = "#002789",
  imageSrc = "knight_loader.png",
}) => {
  const height = 2;
  useEffect(() => {
    var progressIcon = $("#progress-icon");
    if (step > 0) {
      if (prevCount > step) {
        progressIcon.css("transform", "scaleX(-1)");
        setTimeout(() => {
          progressIcon.css("transform", "scaleX(1)");
        }, 1000);
      } else {
        progressIcon.css("transform", "scaleX(1)");
      }
    } else {
      progressIcon.css("transform", "scale(0)");
    }
  }, [step]);

  const prevCountRef = useRef();

  useEffect(() => {
    prevCountRef.current = step;
  }, [step]);

  const prevCount = prevCountRef.current;

  return (
    <div
      className="w-100  position-relative"
      style={{
        //background: "linear-gradient(0deg, #bae9ff, transparent)",
        height: height,
        boxShadow: "0 -4px 4px -5px grey",
      }}
    >
      {/* <span
        style={{
          width: Math.round((step / maxStep) * 100 * 100) / 100 + "%",
          background: "url(/images/grass.png)",
          backgroundSize: "40px 9px",
          backgroundRepeat: "repeat-x",
          backgroundPosition: "bottom",
          position: "absolute",
          height: 12,
          transition: "1000ms",
          bottom: 0,
          borderTopRightRadius: "90%",
        }}
      ></span> */}
      <span
        style={{
          width: Math.round((step / maxStep) * 100 * 100) / 100 + "%",
          background: "linear-gradient(90deg,white, " + color + ")",
          display: "inline-block",
          height: height,
          transition: "1000ms",
          position: "relative",
          verticalAlign: "top",
        }}
      >
        {/* <img
          id="progress-icon"
          src={"/images/" + imageSrc}
          className=""
          style={{
            position: "absolute",
            left: "calc(100% - 20px)",
            bottom: -9,
            width: 30,
            transition: "500ms",
          }}
        /> */}
      </span>
    </div>
  );
};

export default Progress;
