import React from "react";
import colors from "../../../../../../../../../helpers/colors";
import useCERData from "../../../../../../../../Hooks/useCERData/useCERData";

const ObstacleSelect = ({ objective, step, obstacle, handleNewObstacleChange = () => {}, k }) => {
  const { cerObstacles } = useCERData();

  return (
    <select
      className="form-control border-0 text-black-50"
      style={{ width: "auto", opacity: "0.5" }}
      type="select"
      name="obstacle"
      onChange={handleNewObstacleChange}
      value={obstacle.obstacle}
    >
      <option value="">+ Ajouter un frein</option>
      {cerObstacles.map((op) => (
        <option
          disabled={op.isDisabled}
          value={op.id}
          key={`
        selop${k}${objective.goal}${step.step}${op.id}
        `}
        >
          {op.name}
        </option>
      ))}
    </select>
  );
};

export default ObstacleSelect;
