import React from "react";
import useCERModule from "../../CERModule/hooks/useCERModule";

const BackToListButton = () => {
  const { setCurrentCERView } = useCERModule();

  return (
    <button className="btn text-default" onClick={() => setCurrentCERView("CER_LIST")}>
      <i className="fa fa-arrow-left mr-2" />
      Retour
    </button>
  );
};

export default BackToListButton;
