import { DateTime } from "luxon";
import React from "react";
import tools from "../../../../../../../helpers/tools";
import useStoreState from "../../../Context/Store/useStoreState";

const AllocInfos = () => {
  const { items, gState } = useStoreState();
  const recipient = items.utils.currentAlloc.userRecipient;
  const { MARITAL_STATUS, CONTRACT_TYPES, PROFESSIONAL_SITUATIONS } = gState.constants.items;

  return (
    <div className="custom-card">
      <div className="row">
        <div className="col-12">
          <strong className="text-primary">Informations de l'allocataire</strong>
        </div>
        <div className="col-12 col-md-4">
          <div>
            <strong>{tools.getFullname(recipient)}</strong>
          </div>
          <div>NIR : {recipient.nir || <small className="text-black-50">non renseigné</small>}</div>
          <div>
            Matricule : {recipient.CAFId || <small className="text-black-50">non renseigné</small>}
          </div>
          <div>
            Date de naissance :{" "}
            {recipient.birthdate ? (
              DateTime.fromISO(recipient.birthdate).toFormat("dd/MM/yyyy")
            ) : (
              <small className="text-black-50">non renseigné</small>
            )}
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div>
            Adresse : {recipient.address} {tools.getFullAddress(recipient.location)}
          </div>
          <div>
            Situation familiale :{" "}
            {recipient.maritalStatus ? (
              MARITAL_STATUS.find((ms) => ms.id == recipient.maritalStatus) ? (
                MARITAL_STATUS.find((ms) => ms.id == recipient.maritalStatus).name
              ) : (
                ""
              )
            ) : (
              <small className="text-black-50">non renseigné</small>
            )}
          </div>
          <div>
            Nombre d'enfants à charge au sens des PF :{" "}
            {!isNaN(recipient.nbDependantChildren) ? (
              recipient.nbDependantChildren
            ) : (
              <small className="text-black-50">non renseigné</small>
            )}
          </div>
          <div>
            Nombre de personnes à charge :{" "}
            {!isNaN(recipient.nbChildrenOrDependant) ? (
              recipient.nbChildrenOrDependant
            ) : (
              <small className="text-black-50">non renseigné</small>
            )}
          </div>
          <div>
            Situation professionnelle :{" "}
            {recipient.professionalSituation ? (
              PROFESSIONAL_SITUATIONS.find((ms) => ms.id == recipient.professionalSituation) ? (
                PROFESSIONAL_SITUATIONS.find((ms) => ms.id == recipient.professionalSituation).name
              ) : (
                ""
              )
            ) : (
              <small className="text-black-50">non renseignée</small>
            )}
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div>
            Droits :{" "}
            <ul>
              {tools.getRights(recipient).map((r) => (
                <li>
                  {r.type} - du {DateTime.fromISO(r.beginAt).toFormat("dd/MM/yyyy")}{" "}
                  {r.endAt && `au ${DateTime.fromISO(r.endAt).toFormat("dd/MM/yyyy")}`}
                </li>
              ))}
            </ul>
          </div>
          <div>
            Droits et devoirs :{" "}
            <ul>
              {tools.getRightsAndDuties(recipient).map((r) => (
                <li>
                  du {DateTime.fromISO(r.beginAt).toFormat("dd/MM/yyyy")}{" "}
                  {r.endAt && `au ${DateTime.fromISO(r.endAt).toFormat("dd/MM/yyyy")}`}
                </li>
              ))}
            </ul>
          </div>
          <div>
            Contrats :{" "}
            <ul>
              {tools.getContracts(recipient).map((r) => (
                <li>
                  {CONTRACT_TYPES.find((ct) => ct.id == r.type)
                    ? CONTRACT_TYPES.find((ct) => ct.id == r.type).name
                    : ""}{" "}
                  - du {DateTime.fromISO(r.beginAt).toFormat("dd/MM/yyyy")}{" "}
                  {r.endAt && `au ${DateTime.fromISO(r.endAt).toFormat("dd/MM/yyyy")}`}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllocInfos;
