import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Control from "../../common/Control";

const ChangeEpAddress = ({ myEp, setMyEp, errors, sectors, isDisabled }) => {
  const addressInitialState = {
    address1: "",
    address2: "",
    postalCode: "",
    city: "",
  };

  useEffect(() => {
    if (myEp.sector && sectors.length) {
      setIsOpen(false);
      setMyEp({
        ...myEp,
        ...addressInitialState,
      });
    }
  }, [myEp.sector]);

  const [isOpen, setIsOpen] = useState(false);

  const getAddress = () => {
    if (myEp.address1) {
      return `${myEp.address1} ${myEp.address2} ${myEp.postalCode} ${myEp.city}`;
    } else {
      //get sector default address
      var add = getSectorAddress();
      return `${add.address1} ${add.address2} ${add.postalCode} ${add.city}`;
    }
  };

  const getSectorAddress = () => {
    var selectedSector = sectors.find((sec) => sec.id == myEp.sector);
    if (myEp.address1) {
      return {
        address1: myEp.address1,
        address2: myEp.address2,
        postalCode: myEp.postalCode,
        city: myEp.city,
      };
    }
    if (!selectedSector) {
      return addressInitialState;
    }
    return {
      address1: selectedSector.address1,
      address2: selectedSector.address2,
      postalCode: selectedSector.postalCode,
      city: selectedSector.city,
    };
  };

  const modifyAddress = () => {
    var selectedSector = sectors.find((sec) => sec.id == myEp.sector);
    if (!selectedSector) return false;
    if (!isOpen) {
      setIsOpen(true);
      setMyEp({
        ...myEp,
        ...getSectorAddress(),
      });
    } else {
      setIsOpen(false);
      setMyEp({
        ...myEp,
        ...addressInitialState,
      });
    }
  };

  return (
    <>
      <div className="alert alert-primary">
        Adresse : <p>{getAddress()}</p>
        <button
          className="btn btn-primary btn-sm"
          onClick={modifyAddress}
          disabled={isDisabled}
          data-priv="modify_epdetails"
        >
          {isOpen ? "Rétablir l'adresse par détaut" : "Modifier l'adresse"}
        </button>
      </div>

      {isOpen && (
        <>
          <Control
            disabled={isDisabled}
            label="Adresse 1"
            type="text"
            name="address1"
            value={myEp.address1}
            change={(e) => setMyEp({ ...myEp, address1: e.target.value })}
            error={errors}
          />
          <Control
            disabled={isDisabled}
            label="Adresse 2"
            type="text"
            name="address2"
            value={myEp.address2}
            change={(e) => setMyEp({ ...myEp, address2: e.target.value })}
            error={errors}
          />
          <Control
            disabled={isDisabled}
            label="Code postal"
            type="text"
            name="postalCode"
            value={myEp.postalCode}
            change={(e) => setMyEp({ ...myEp, postalCode: e.target.value })}
            error={errors}
          />
          <Control
            disabled={isDisabled}
            label="Ville"
            type="text"
            name="city"
            value={myEp.city}
            change={(e) => setMyEp({ ...myEp, city: e.target.value })}
            error={errors}
          />
        </>
      )}
    </>
  );
};

export default ChangeEpAddress;
