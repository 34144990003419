import React, { useState } from "react";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import useCERData from "../../Hooks/useCERData/useCERData";

const AddStepItem = ({ objective }) => {
  const { addCerStep } = useCERData();

  const [newStep, setNewStep] = useState({
    name: "",
    cerGoal: objective.id,
  });

  const handleChangeStep = (e) => {
    const { name, value } = e.target;
    setNewStep({ ...newStep, [name]: value });
  };

  const handleClickAddStep = () => {
    addCerStep(newStep);
    setNewStep({
      ...newStep,
      name: "",
    });
  };

  return (
    <li className="list-group-item bg-body d-flex flex-row align-items-center">
      <span className="badge badge-dark mr-2">démarche</span>

      <Control
        className="flex-fill py-0"
        placeholder="Nouvelle démarche"
        type="text"
        name="name"
        change={handleChangeStep}
        value={tools.capitalize(newStep.name)}
      />
      {newStep.name && (
        <button className="btn btn-success" onClick={handleClickAddStep}>
          Ajouter
        </button>
      )}
    </li>
  );
};

export default AddStepItem;
