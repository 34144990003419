import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import colors from "../../../../../../../helpers/colors";
import tools from "../../../../../../../helpers/tools";
import useCERModule from "../../../../CERModule/hooks/useCERModule";
import SelectStatus from "../../common/SelectStatus";
import StatusIcon from "../../common/StatusIcon";
import { ObjectiveContext } from "../ObjectiveContext";

const ObjectiveStatus = ({ disabled = false }) => {
  const objective = useContext(ObjectiveContext);
  const { recipientCER, events, isLoadingCERGoal } = useCERModule();
  var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const onChangeObjectiveStatus = (status) => {
    if (disabled) return false;
    const newObjective = { ...objective, detail: { status, date: DateTime.local().toISO() } };
    setIsLoading(true);
    events.onUpdateObjectiveAndSave(newObjective, objectiveIndex);
    toggleStatuses();
  };

  const toggleStatuses = () => {
    if (disabled) return false;
    setIsStatusOpen(!isStatusOpen);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [objective.detail.status]);

  return (
    <div
      style={{
        position: "absolute",
        top: 13,
        left: 0,
        zIndex: "9",
      }}
    >
      <StatusIcon
        disabled={disabled}
        onClick={toggleStatuses}
        isLoading={isLoadingCERGoal}
        status={objective.detail.status}
        type="GOAL"
        title={`Depuis le ${tools.formatDate(objective.detail.date)}`}
      />

      {isStatusOpen && <SelectStatus type="GOAL" onSelectStatus={onChangeObjectiveStatus} />}
    </div>
  );
};

export default ObjectiveStatus;
