import React, { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { DateTime } from "luxon";
import dataService from "../../helpers/dataService";
import Loader from "./Loader";
import { ClipLoader } from "react-spinners";

const ExportCSV = ({
  fields,
  datas,
  exportOptions = {
    excludedFields: [],
    arrayFields: [],
    objectFields: {},
  },
}) => {
  const [launch, setLaunch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  var { excludedFields, arrayFields, objectFields } = exportOptions;
  arrayFields = arrayFields || [];
  objectFields = objectFields || [];
  async function formatToCsv() {
    if (exportOptions.onBeforeExport) exportOptions.onBeforeExport();
    setLaunch(false);
    var exportDatas = datas;
    if (exportOptions.fetchDatas) {
      setIsLoading(true);
      exportDatas = await new Promise((res, rej) => {
        dataService.post(
          exportOptions.fetchDatas,
          exportOptions.fetchParams,
          (datas) => {
            res(exportOptions.formatDatasFn(datas.data));
          },
          rej
        );
      }).catch((err) => console.log(err));
      setIsLoading(false);
    }
    var dateFrom = DateTime.local().toFormat("dd-MM-yyyy");
    var fieldsFiltered = fields.filter((f) => !excludedFields.includes(f.name));

    var fieldsFormat = fieldsFiltered.map((f) => f.name);
    var csvData = [fieldsFormat];
    var filename = "EXPORT_" + dateFrom;
    exportDatas.forEach((data) => {
      var entry = [];
      fields.forEach((field) => {
        if (!excludedFields.includes(field.name)) {
          var entryData = "";
          var fieldPath = field.exportPath || field.path;
          if (fieldPath) {
            if (arrayFields[fieldPath]) {
              data[fieldPath].forEach((subEntryData) => {
                arrayFields[fieldPath].dataIndexes.forEach((ind) => {
                  entryData += subEntryData[ind] + " ";
                });
                entryData += "\n";
              });
            } else if (objectFields[fieldPath]) {
              objectFields[fieldPath].dataIndexes.forEach((ind) => {
                if (data[fieldPath]) entryData += data[fieldPath][ind] + " ";
              });
            } else {
              entryData = data[fieldPath];
            }
          }
          entry.push(entryData);
        }
      });
      csvData.push(entry);
    });

    setLaunch({ data: csvData, filename });
  }

  return isLoading ? (
    <ClipLoader size={16} />
  ) : (
    <div className="d-center flex-row">
      <button className="btn btn-link" onClick={formatToCsv}>
        Exporter en CSV
      </button>

      {launch && (
        <CSVLink
          data={launch.data}
          separator={";"}
          enclosingCharacter={`"`}
          filename={launch.filename + ".csv"}
          onClick={() => setLaunch(false)}
          className="btn btn-link"
        >
          Télécharger
        </CSVLink>
      )}
    </div>
  );
};

export default ExportCSV;
