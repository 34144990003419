import React from "react";
import Control from "../../common/Control";
import PostalCode from "../PostalCode";
import DigitControl from "../DigitControl";

const AnswerInterpretor = ({ question, inputTypes, updateAnswer, answers, k, errors }) => {
  var content = <></>;
  var inputTypeRef = inputTypes.find((i) => i.id == question.type);
  var questionType = inputTypeRef?.type || "infos";

  if (questionType == "postalCode") {
    content = (
      <PostalCode
        value={answers.find((a) => a.question == question.id).value}
        updateData={updateAnswer}
        name={question.id}
        error={errors}
      />
    );
  } else if (questionType == "nir") {
    content = (
      <>
        <div className="row m-0">
          <div className="col-12 col-md-6 col-lg-6">
            <img
              style={{ maxHeight: 196 }}
              className="img-fluid mb-3"
              src="/assets/carte-vitale.png"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <p className="text-primary">
              Votre numéro de sécurité sociale figure sur la face recto de votre carte vitale (cf
              image).
            </p>
          </div>
        </div>
        <DigitControl
          params={[
            { size: 1, value: "" },
            { size: 2, value: "" },
            { size: 2, value: "" },
            { size: 2, value: "" },
            { size: 3, value: "" },
            { size: 3, value: "" },
            { size: 2, value: "" },
          ]}
          name={question.id}
          complete={updateAnswer}
          value={answers.find((a) => a.question == question.id).value}
        />
        <span className="text-danger">{errors[question.id]}</span>
      </>
    );
  } else if (questionType == "infos") {
    content = (
      <>
        <div className="row m-0">
          <div className="col-12">
            <p className="alert alert-progress">{question.text}</p>
          </div>
        </div>
      </>
    );
  } else {
    content = (
      <Control
        label=""
        name={question.id}
        id={k}
        type={questionType}
        datas={question.answers}
        dataIndex={"value"}
        dataLabel={"label"}
        inputParams={question.inputParams}
        change={updateAnswer}
        value={answers.find((a) => a.question == question.id).value}
        error={errors}
      />
    );
  }

  return content;
};

export default AnswerInterpretor;
