import React, { useContext } from "react";
import tools from "../../../../../../../../helpers/tools";
import { ObjectiveContext } from "../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import { StepContext } from "../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/StepContext";
import useAmendmentModule from "../../../../hooks/useAmendmentModule";
import AddAmendmentOperatorForm from "./AmendmentStepView/AmendmentOperatorsList/AddAmendmentOperatorForm/AddAmendmentOperatorForm";
import AmendmentOperatorsList from "./AmendmentStepView/AmendmentOperatorsList/AmendmentOperatorsList";
import AmendmentStepView from "./AmendmentStepView/AmendmentStepView";

const AmendmentStepsList = () => {
  const objective = useContext(ObjectiveContext);
  const { isCurrentAmendmentDraft } = useAmendmentModule();

  return (
    <ul className="list-group pl-3" style={{ position: "relative", left: -14 }}>
      {objective.steps.map((step, stk) => {
        const stepKey = `objstep-${objective.pathType}-${objective.goal}-${step.step}`;

        return (
          <li className="list-group-item border-0 p-0 mb-2" key={stepKey}>
            <StepContext.Provider value={step}>
              <div
                className="pr-0  "
                style={{
                  paddingLeft: 1,
                  borderLeft: `28px solid ${tools.getCERElementUpdateTypeColor(step.updateType)}`,
                }}
              >
                <AmendmentStepView />

                <AmendmentOperatorsList />
                {step.updateType != "DELETED" && isCurrentAmendmentDraft && (
                  <div className="pr-2 pt-0">
                    <AddAmendmentOperatorForm />
                  </div>
                )}
              </div>
            </StepContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};

export default AmendmentStepsList;
