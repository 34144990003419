import React from "react";
import Control from "../../../../../../common/Control";
import SaveBtn from "../../../../../../common/SaveBtn";
import useStoreState from "../../../Context/Store/useStoreState";

const PresidentSign = () => {
  const { state, items, gState, items_actions } = useStoreState();
  const { utils, ep } = items;
  const iAmPresident = gState.auth.user.id == ep.president;

  return iAmPresident && !ep.closedAt ? (
    <div className="col-12">
      <div className="d-center flex-column">
        <Control
          label="Le président confirme tous les avis EP"
          type="checkbox"
          name="isSignedByPresident"
          checked={utils.isSignedByPresident}
          change={(e) =>
            items_actions.setItem("utils", {
              ...state.items.util,
              isSignedByPresident: e.target.checked,
            })
          }
        />
        {utils.isSignedByPresident && (
          <SaveBtn
            margin="mt-3"
            type="btn-primary"
            text="Valider les avis EP"
            save={items_actions.saveAvisEp}
            isSaving={state.isLoadingAvisEp}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default PresidentSign;
