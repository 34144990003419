import React from "react";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { modalActions } from "../../../../../context/actions/modalActions";
import useStore from "../../../../../context/useStore";
import dataService from "../../../../../helpers/dataService";
import AddRecipientToEp from "../../AddRecipientToEp";
import {
  currentAmendmentAtom,
  cerAmendmentsAtom,
  isCurrentAmendmentDraftAtom,
  isLoadingAmendmentAtom,
  isCurrentAmendmentRenewAtom,
  amendmentErrorsAtom,
} from "../atoms/AmendmentModuleAtoms";
import { CERModel, currentCERViewAtom, recipientCERAtom } from "../atoms/CERModuleAtoms";
//import useCERModule from "./useCERModule";

const useAmendmentModule = () => {
  const [recipientCER, setRecipientCER] = useAtom(recipientCERAtom);
  const [currentCERView, setCurrentCERView] = useAtom(currentCERViewAtom);
  const [cerAmendments, setCerAmendments] = useAtom(cerAmendmentsAtom);
  const [currentAmendment, setCurrentAmendment] = useAtom(currentAmendmentAtom);
  const [isCurrentAmendmentDraft] = useAtom(isCurrentAmendmentDraftAtom);
  const [isCurrentAmendmentRenew] = useAtom(isCurrentAmendmentRenewAtom);
  const [isLoadingAmendment, setIsLoadingAmendment] = useAtom(isLoadingAmendmentAtom);
  const [amendmentErrors, setAmendmentErrors] = useAtom(amendmentErrorsAtom);
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);

  const getAmendments = (recipientCERId) => {
    dataService.get(`cer-amendments/cer/${recipientCERId}`, setCerAmendments);
  };

  const getRecipientCER = () => {
    dataService.get(`recipients/${recipientCER.userRecipient}/cer`, (data) => {
      setRecipientCER({
        ...CERModel,
        ...data,
      });
    });
  };

  const getAmendmentById = (amendmentId) => {
    setIsLoadingAmendment(true);
    dataService.get(`cer-amendments/${amendmentId}`, setCurrentAmendment, setAmendmentErrors, () =>
      setIsLoadingAmendment(false)
    );
  };
  const createAmendment = () => {
    setIsLoadingAmendment(true);
    dataService.post(
      `cer-amendments`,
      currentAmendment,
      (datas) => {
        setCurrentAmendment(datas);
        toast.success("L'élément a bien été créé");
        getAmendments(datas.cer);
        getRecipientCER();
      },
      setAmendmentErrors,
      () => setIsLoadingAmendment(false)
    );
  };
  const patchAmendment = () => {
    setIsLoadingAmendment(true);
    dataService.patch(
      `cer-amendments/${currentAmendment.id}`,
      currentAmendment,
      (datas) => {
        setCurrentAmendment(datas);
        toast.success("L'élément a bien été mis à jour");
      },
      setAmendmentErrors,
      () => setIsLoadingAmendment(false)
    );
  };
  const patchAmendmentSigned = (signedAt) => {
    setIsLoadingAmendment(true);
    dataService.patch(
      `cer-amendments/${currentAmendment.id}/sign`,
      {
        signedAt,
      },
      (data) => {
        setCurrentAmendment({ ...currentAmendment, status: data.status, signedAt: data.signedAt });
        getAmendments(recipientCER.id);
        getRecipientCER();

        if (currentAmendment.type == "RENEWED") {
          mActions.updateModal({
            isOpen: true,
            content: <AddRecipientToEp recipient={{ id: recipientCER.userRecipient }} />,
          });
        }
      },
      setAmendmentErrors,
      () => {
        setIsLoadingAmendment(false);
      }
    );
  };

  const onOpenAmendment = (amendment) => {
    setCurrentAmendment(amendment);
    setCurrentCERView("AMENDMENT_EDIT");
  };

  const onCreateAmendment = (type) => {
    setCurrentAmendment({
      ...recipientCER,
      cer: recipientCER.id,
      status: "DRAFT",
      id: null,
      type,
      beginAt:
        type == "RENEWED"
          ? DateTime.fromISO(recipientCER.endAt).plus({ days: 1 }).toFormat("yyyy-MM-dd")
          : null,
      endAt: null,
      duration: null,
      signedAt: null,
    });
  };

  const onAddObjective = (goal) => {
    setCurrentAmendment({
      ...currentAmendment,
      goals: [...currentAmendment.goals, { ...goal, updateType: "ADDED" }],
    });
  };

  const onUpdateObjective = (goal, index) => {
    setCurrentAmendment({
      ...currentAmendment,
      goals: currentAmendment.goals.map((g, gk) => {
        return index == gk ? goal : g;
      }),
    });
  };

  const onDeleteObjective = (index) => {
    if (currentAmendment.goals[index].updateType == "ADDED") {
      setCurrentAmendment({
        ...currentAmendment,
        goals: currentAmendment.goals.filter((g, gk) => gk != index),
      });
    } else {
      setCurrentAmendment({
        ...currentAmendment,
        goals: currentAmendment.goals.map((g, gk) =>
          gk === index
            ? {
                ...g,
                steps: [
                  ...g.steps
                    .filter((s) => s.updateType != "ADDED")
                    .map((s) => ({
                      ...s,
                      updateType: "DELETED",
                      operators: [
                        ...s.operators
                          .filter((o) => o.updateType != "ADDED")
                          .map((o) => ({ ...o, updateType: "DELETED" })),
                      ],
                    })),
                ],
                updateType: "DELETED",
              }
            : g
        ),
      });
    }
  };
  const onCancelDeleteObjective = (index) => {
    setCurrentAmendment({
      ...currentAmendment,
      goals: currentAmendment.goals.map((g, gk) =>
        gk === index
          ? {
              ...g,
              steps: [
                ...g.steps.map((s) => ({
                  ...s,
                  updateType: undefined,
                  operators: [...s.operators.map((o) => ({ ...o, updateType: undefined }))],
                })),
              ],
              updateType: undefined,
            }
          : g
      ),
    });
  };

  const updateCurrentAmendment = (newCurrentAmendment) => {
    setCurrentAmendment(newCurrentAmendment);
  };

  return {
    cerAmendments,
    currentAmendment,
    setCurrentAmendment,
    recipientCER,
    isCurrentAmendmentDraft,
    isCurrentAmendmentRenew,
    isLoadingAmendment,
    amendmentErrors,
    actions: {
      getAmendmentById,
      getAmendments,
      createAmendment,
      patchAmendment,
      patchAmendmentSigned,
      updateCurrentAmendment,
    },
    events: {
      onOpenAmendment,
      onCreateAmendment,
      onAddObjective,
      onUpdateObjective,
      onDeleteObjective,
      onCancelDeleteObjective,
    },
  };
};

export default useAmendmentModule;
