import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import SaveBtn from "../../common/SaveBtn";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";
import RecipientTimeline from "../RecipientDetail/RecipientTimeline";
import useAllRecipients from "./useAllRecipients";

const ContactInfo = ({ icon, value }) => {
  return (
    <div>
      <small>
        <i className={`fa ${icon} mr-1`} />
        <span>{value || "-"}</span>
      </small>
    </div>
  );
};

const ProtectedSection = ({ recipient }) => {
  return (
    <>
      <ContactInfo icon="fa-home" value={tools.getFullAddress(recipient.location)} />

      <div className="mt-3">
        <Link
          className="btn btn-primary w-100 d-center"
          to={tools.getRecipientLink(recipient)}
          target="_blank"
        >
          Voir le détail
        </Link>
      </div>
    </>
  );
};

const AllRecipientsItem = ({ recipient }) => {
  const { organizations } = useOrganizations();
  const { isLoadingTimelineId, getRecipientTimeline } = useAllRecipients();
  const [state, dispatch] = useStore();
  const { CONTRACT_TYPES } = state.constants.items;
  const recipientOrga = tools.findIn(
    organizations,
    "slugName",
    recipient.currentOrientation?.orientation
  );

  const recipientType = tools.getRecipientType(recipient.model);

  return (
    <div className="p-3 rounded shadow-sm bg-white mb-2">
      <div className="d-flex align-items-start justify-content-between">
        <strong>{tools.getFullname(recipient)}</strong>
        <span className={`badge badge-${recipientType.color}`}>{recipientType.name}</span>
      </div>
      <div>
        <small>Né(e) le : {tools.formatDate(recipient.birthdate)}</small>
      </div>

      <div>
        <small>Réf. unique : {recipientOrga?.name}</small>
      </div>
      <div>
        <small>Orienté le : {tools.formatDate(recipient.currentOrientation?.createdAt)}</small>
      </div>
      {recipient.lastContract && (
        <div>
          <small>
            Contrat{" "}
            {tools.checkDateInRange(
              DateTime.local().toISO(),
              recipient.lastContract?.beginAt,
              recipient.lastContract?.endAt
            ) ? (
              <span className="badge badge-success">en cours</span>
            ) : (
              <span className="badge badge-danger">expiré</span>
            )}{" "}
            : {tools.findIn(CONTRACT_TYPES, "id", recipient.lastContract.type).name} du{" "}
            {tools.formatDate(recipient.lastContract?.beginAt)}{" "}
            {recipient.lastContract?.endAt && (
              <>au {tools.formatDate(recipient.lastContract?.endAt)}</>
            )}
          </small>
        </div>
      )}

      {recipient.isVisible ? (
        <ProtectedSection recipient={recipient} />
      ) : (
        <div className="alert bg-body border mb-0 mt-2">
          Vous n'avez pas accès aux informations de cet allocataire.
        </div>
      )}
      {recipient.model == "UserRecipient" && !recipient.events?.length && (
        <div className="mt-2">
          <SaveBtn
            className="btn btn-secondary w-100 d-center"
            margin="mt-0"
            save={() => getRecipientTimeline(recipient.id)}
            text="Afficher la timeline"
            isSaving={isLoadingTimelineId == recipient.id}
          />
        </div>
      )}
      {recipient.events?.length > 0 && (
        <div style={{ height: 400, overflowY: "auto", overflowX: "hidden" }} className="mt-2">
          <RecipientTimeline events={recipient.events} nbCol="1-column" />
        </div>
      )}
    </div>
  );
};

export default AllRecipientsItem;
