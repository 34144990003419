import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { DateTime } from "luxon";
import colors from "../../../helpers/colors";

const TimelineEvent = ({ event }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: "#fff",
        color: "#404040",
        padding: 11,
        paddingTop: 4,
        boxShadow: "0 -3px 0 " + colors[event.color] + " ,0 0 15px -3px #afafaf",
      }}
      contentArrowStyle={{ borderRight: "7px solid " + colors[event.color] }}
      date={DateTime.fromISO(event.date).toFormat("dd/MM/yyyy")}
      iconStyle={{
        background: colors[event.color],
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
      }}
      icon={<i className={"fa fa-1x fa-" + event.icon} />}
    >
      <span
        style={{ background: colors[event.color] }}
        className="badge text-white font-weight-normal"
      >
        {event.type}
      </span>
      {event.subType && (
        <span className="badge badge-light font-weight-normal ml-2">{event.subType}</span>
      )}
      <h3
        style={{ fontSize: "1rem", color: colors[event.color] }}
        className="vertical-timeline-element-title"
      >
        {event.title}
      </h3>
      {event.description != "" && (
        <p className="mt-2" style={{ fontSize: "0.8rem", lineHeight: "0.9rem" }}>
          {event.description}
        </p>
      )}
      {event.comment ? (
        <p className="mt-2">
          <i className="fa fa-comment mr-2" />
          <span dangerouslySetInnerHTML={{ __html: event.comment }} />
        </p>
      ) : null}
      {event.dataList &&
        event.dataList.length > 0 &&
        event.dataList.map((list, lk) => (
          <div key={`evdatalist${event.id}${lk}`} style={{ fontSize: "0.8rem" }}>
            <div>
              <strong>{list.title}</strong>
            </div>
            <ul>
              {list.list.map((item, itk) => (
                <li key={`evdatalistitem${event.id}${item}${itk}`}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
    </VerticalTimelineElement>
  );
};

export default TimelineEvent;
