import React, { useState, useEffect } from "react";
import FormPage from "../../common/FormPage/FormPage";
import Axios from "axios";
import useStore from "../../../context/useStore";
import { DateTime } from "luxon";
import { formActions } from "../../../context/actions/formActions";
import formConfigObject from "../../common/FormPage/FormConfigObject/FormConfigObject";
import tools from "../../../helpers/tools";
import { modalActions } from "../../../context/actions/modalActions";
import Page from "../../common/layout/Page";

var searchCompanyTimeout = null;
var previousCompany = "";

const EntretienGiratoire = (props) => {
  const [state, dispatch] = useStore();
  const form_actions = formActions(state, dispatch);
  const modal_actions = modalActions(state, dispatch);
  const [step, setStep] = useState(0);
  const [companyResult, setCompanyResult] = useState([]);
  const [loginOpen, setLoginOpen] = useState(false);
  const [formConfig, setFormConfig] = useState(formConfigObject);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({ email: "exists" });
  const [answers, setAnswers] = useState(false);

  useEffect(() => {}, [answers]);

  const nextStep = () => {
    setIsLoading(true);

    validateStep();
  };
  const previousStep = () => {
    setStep((s) => s - 1);
  };

  const validateStep = () => {
    setStep((s) => s + 1);
    setIsLoading(false);
  };

  const save = () => {};

  const setFormConfigEntry = (entries) => {
    var fc = JSON.parse(JSON.stringify(formConfig));
    fc.form.find((f) => {
      entries.forEach((ent) => {
        if (f.questions.find((q) => q.name == ent.entryName)) {
          f.questions.find((q) => q.name == ent.entryName)[ent.inputName] =
            ent.inputValue;
        }
      });
    });

    setFormConfig(fc);
  };

  return (
    <Page>
      <FormPage
        step={step}
        setStep={setStep}
        formConfig={formConfig}
        isLoading={isLoading}
        nextStep={nextStep}
        previousStep={previousStep}
        answers={answers}
        setAnswers={setAnswers}
        save={save}
        errors={errors}
        loginOpen={loginOpen}
        setLoginOpen={setLoginOpen}
      />
    </Page>
  );
};

export default EntretienGiratoire;
