import React from "react";
import useStore from "../../../context/useStore";
import { DateTime } from "luxon";
import "./recipientcard.scss";
import tools from "../../../helpers/tools";

const RecipientCard = ({ recipient }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const isNew = recipient.isNew;
  return (
    <div className="col-12 col-md-4 col-lg-4  mb-2">
      <div className="card h-100 shadow-lg">
        <div className={"card-header " + (isNew ? "bg-success" : "bg-primary")}>
          <div className="font-weight-bold">{`${tools.getFullname(recipient)}`}</div>

          <span className={"badge badge-secondary"}>
            {isNew ? "allocataire temporaire" : "allocataire existant"}
          </span>
        </div>
        <div className="card-body">
          <div className="rec-row">
            <div className="rec-label">NIR</div>
            <div className="rec-value">{recipient.nir}</div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Date de naissance</div>
            <div className="rec-value">
              {recipient.birthdate
                ? DateTime.fromISO(recipient.birthdate).toFormat("dd/MM/yyyy")
                : "N.D"}
            </div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Email</div>

            <div className="rec-value">{recipient.email}</div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Téléphone</div>
            <div className="rec-value">{recipient.phone}</div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Adresse</div>
            <div className="rec-value">{`${recipient.address} ${recipient.addressAdd} ${recipient.location.postalCode} ${recipient.location.name}`}</div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Situation familiale</div>
            <div className="rec-value">{`${
              constants.MARITAL_STATUS.find((f) => f.id == recipient.maritalStatus)?.name
            }`}</div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Nombre d'enfants à charge au sens des PF</div>
            <div className="rec-value">{recipient.nbDependantChildren}</div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Nombre de personnes à charge</div>
            <div className="rec-value">{recipient.nbChildrenOrDependant}</div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Droits</div>
            <div className="rec-value">
              <div>{constants.RSA_TYPES.find((r) => r.id == recipient.rights.type)?.name}</div>
              {`Du ${
                recipient.rights?.beginAt
                  ? DateTime.fromISO(recipient.rights.beginAt).toFormat("dd/MM/yyyy")
                  : "N.D"
              } au ${
                recipient.rights?.endAt
                  ? DateTime.fromISO(recipient.rights.endAt).toFormat("dd/MM/yyyy")
                  : "N.D"
              }`}
            </div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Droits et devoirs</div>
            <div className="rec-value">
              {`Du ${
                recipient.rightsAndDuties?.beginAt
                  ? DateTime.fromISO(recipient.rightsAndDuties.beginAt).toFormat("dd/MM/yyyy")
                  : "N.D"
              } au ${
                recipient.rightsAndDuties?.endAt
                  ? DateTime.fromISO(recipient.rightsAndDuties.endAt).toFormat("dd/MM/yyyy")
                  : "N.D"
              }`}
            </div>
          </div>
          <div className="rec-row">
            <div className="rec-label">Contrats</div>
            <div className="rec-value">
              {`Du ${
                recipient.contracts?.beginAt
                  ? DateTime.fromISO(recipient.contracts.beginAt).toFormat("dd/MM/yyyy")
                  : "N.D"
              } au ${
                recipient.contracts?.endAt
                  ? DateTime.fromISO(recipient.contracts.endAt).toFormat("dd/MM/yyyy")
                  : "N.D"
              }`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientCard;
