import React, { useEffect } from "react";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import useRecipientStock from "./useRecipientStock";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";

const RecipientStockFilters = () => {
  const {
    recipientStockFilters,
    isLoadingRecipientStock,
    nbResults,
    onFiltersChange,
    onSearchRecipientStock,
  } = useRecipientStock();

  const { onGetOrganizations, organizations } = useOrganizations();

  useEffect(() => {
    onGetOrganizations();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    onSearchRecipientStock();
  };

  return (
    <form className="" onSubmit={onSubmit}>
      <div className="row">
        <div className="col-8">
          <Control
            label="Rechercher"
            placeholder="Nom, Prénom, Téléphone, NIR, Matricule"
            type="text"
            name="terms"
            value={recipientStockFilters.filters.terms}
            change={onFiltersChange}
          />
        </div>
        <div className="col-4 d-center">
          <Control
            label="Ne possède pas d'adresse email"
            type="checkbox"
            name="noEmail"
            checked={recipientStockFilters.filters.noEmail}
            change={onFiltersChange}
          />
        </div>
        <div className="col-4">
          <Control
            label="Orientation"
            type="select"
            name="orientation"
            datas={organizations.filter((og) => ["PE", "CD"].includes(og.slugName))}
            dataIndex="slugName"
            dataLabel="name"
            selectFirstLabel="Toutes"
            value={recipientStockFilters.filters.orientation}
            change={onFiltersChange}
          />
        </div>
        <div className="col-4 d-center">
          <Control
            label="Allocataires avec un EP en cours"
            type="checkbox"
            name="isEpInProgress"
            checked={recipientStockFilters.filters.isEpInProgress}
            change={onFiltersChange}
          />
        </div>
        <div className="col-4 d-center">
          <Control
            label="Allocataires avec une réorientation en cours"
            type="checkbox"
            name="isReorientationInProgress"
            checked={recipientStockFilters.filters.isReorientationInProgress}
            change={onFiltersChange}
          />
        </div>
        <div className="col-12">
          <SaveBtn
            type="btn-primary"
            className="w-100 d-center py-3"
            typeBtn="submit"
            isSaving={isLoadingRecipientStock}
            text="Rechercher"
          />
        </div>
        <div className="col-12 mt-2">
          <div className="text-center">{nbResults} résultats</div>
        </div>
      </div>
    </form>
  );
};

export default RecipientStockFilters;
