import React from "react";
import colors from "../../../../../../helpers/colors";
import useCERData from "../../../../../Hooks/useCERData/useCERData";
import useCERModule from "../../../CERModule/hooks/useCERModule";

const ObjectiveSelect = ({ objective, handleNewObjectiveChange = () => {}, k }) => {
  const { objectiveCategories, cerObjectives } = useCERData();
  const { recipientCER } = useCERModule();

  //on ajoute isDisabled aux objectives déjà sélectionnés ailleurs et on les tri par category
  const orderedCerObjectives = [...cerObjectives]
    .map((o) => (recipientCER.goals.find((ro) => ro.goal == o.id) ? { ...o, isDisabled: true } : o))
    .sort((a, b) => (a.category > b.category ? -1 : 1));

  const getCategoriesOptGroups = () => {
    const optGroups = [];
    objectiveCategories.forEach((cat) => {
      //on retire les objectives qui ne sont pas de la même category
      var filterdOrderedObjectives = orderedCerObjectives.filter((obj) => obj.category == cat.id);

      //on push l'optgroup et ses options si celui-ci n'est pas vide
      if (filterdOrderedObjectives.length) {
        optGroups.push(
          <optgroup key={`catselect${k}${cat.id}`} label={cat.name}>
            {filterdOrderedObjectives.map((obj) => {
              return (
                <option
                  disabled={obj.isDisabled}
                  value={obj.id}
                  key={`catselect${k}${cat.id}${obj.name}`}
                >
                  {obj.name}
                </option>
              );
            })}
          </optgroup>
        );
      }
    });
    return optGroups;
  };

  return (
    <select
      className="form-control border-0"
      style={{ width: "auto", color: colors.info }}
      type="select"
      name="goal"
      onChange={handleNewObjectiveChange}
      value={objective.goal}
    >
      <option value="">+ Ajouter un objectif</option>
      {getCategoriesOptGroups()}
    </select>
  );
};

export default ObjectiveSelect;
