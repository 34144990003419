import { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import useThemes from "../../Hooks/useThemes/useThemes";

const useOperatorList = () => {
  const [state] = useStore();
  const [operators, setOperators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("OperatorList", null);
  if (!searchState?.search?.terms) {
    searchState = null;
  }
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          filters: {
            terms: "",
            isTrustedOnly: false,
          },
          page: 1,
          nbItemsByPage: 25,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const { themes, subThemes, isLoadingThemes, onGetThemes } = useThemes();

  function searchOperators() {
    setErrors(false);
    dataService.post(
      `operators/search`,
      search,
      (data) => {
        setOperators(data.data);
        setNbPages(Math.ceil(parseInt(data.count) / search.nbItemsByPage));
        setNbResults(data.count);
      },
      (err) => {},
      () => setIsLoading(false)
    );
  }
  function updateOperatorSearch(e) {
    var inputType = e.target.type;
    var value = e.target.value;
    var su = { ...search };
    if (inputType == "checkbox") {
      su.filters[e.target.name] = e.target.checked;
    } else {
      if (value == "") {
        delete su.filters[e.target.name];
      } else {
        su.filters[e.target.name] = value;
      }
    }
    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function changeNbItemsByPage(i) {
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
  }

  function removeOperator(id) {
    if (window.confirm("Confirmez-vous la suppression de cet opérateur ?")) {
      dataService.remove(`operators/${id}`, {}, (data) => searchOperators());
    }
  }

  useEffect(() => {
    onGetThemes();
  }, []);

  return {
    state,
    operators,
    isLoading: isLoading || isLoadingThemes,
    setIsLoading,
    themes,
    subThemes,
    search,
    errors,
    nbPages,
    nbResults,
    searchOperators,
    updateOperatorSearch,
    changePage,
    changeNbItemsByPage,
    removeOperator,
  };
};

export default useOperatorList;
