import React, { useEffect, useState } from "react";
import tools from "../../../../../../helpers/tools";
import Control from "../../../../../common/Control";
import SaveBtn from "../../../../../common/SaveBtn";
import useStoreState from "../../Context/Store/useStoreState";
import EpCalling from "../EpCalling";
import AllocCounter from "./AllocCounter";
import DirectorSign from "./DirectorSign";
import EditAlloc from "./EditAlloc/EditAlloc";
import PresidentSign from "./EditAlloc/PresidentSign";
import EpAllocBlock from "./EpAllocBlock";
import EpSessionTables from "./EpSessionTables";
import EPTitle from "./EPTitle";
import SelectModerators from "./SelectModerators";

const EpSession = () => {
  const { items } = useStoreState();
  const [showCalling, setShowCalling] = useState(false);
  const { utils, ep } = items;

  useEffect(() => {
    setShowCalling(false);
  }, [ep.calledAt]);

  return showCalling ? (
    <EpCalling />
  ) : (
    <div>
      {!ep.closedAt && (
        <button
          className="btn btn-link mb-3 pl-0"
          onClick={() => setShowCalling(true)}
        >
          <i className="fa fa-arrow-left mr-2" />
          Ouvrir l'appel
        </button>
      )}
      <div className="row">
        <div className="col-12">
          <EPTitle />
        </div>
      </div>
      {!ep.closedAt && <SelectModerators />}
      {utils.currentAlloc ? (
        <EditAlloc recipient={utils.currentAlloc} />
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <EpSessionTables />
            </div>
          </div>
          <PresidentSign />
          <DirectorSign />
        </div>
      )}
    </div>
  );
};

export default EpSession;
