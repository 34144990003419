import React from "react";
import Control from "../../../../common/Control";
import useCERModule from "../../CERModule/hooks/useCERModule";
import tools from "../../../../../helpers/tools";
import CEREditSection from "../CEREditSection/CEREditSection";
import { DateTime } from "luxon";
import useContractForm from "./useContractForm";

const CERContractFormSigned = ({ isRenew }) => {
  const { currentRecipient, currentEntity, CER_DURATIONS, state } = useContractForm(isRenew);

  return (
    <CEREditSection>
      <div className="row mx-0">
        <div className="col-12 mb-2">
          <strong>Informations du contrat</strong>
        </div>
        <div className="col-12">
          Partenaire : <strong>{currentRecipient.lastOrientation?.partnerName}</strong>
        </div>
        <div className="col-6">
          Date de début :{" "}
          <strong>{DateTime.fromISO(currentEntity.beginAt).toFormat("dd/MM/yyyy")}</strong>
        </div>
        <div className="col-6">
          Date de fin :{" "}
          <strong>{DateTime.fromISO(currentEntity.endAt).toFormat("dd/MM/yyyy")}</strong>
        </div>

        <div className="col-6">
          Date de signature initiale :{" "}
          <strong>{DateTime.fromISO(currentEntity.signedAt).toFormat("dd/MM/yyyy")}</strong>
        </div>
        {currentEntity.signUpdatedAt && (
          <div className="col-6">
            Signature des dernières modifications :{" "}
            <strong>{DateTime.fromISO(currentEntity.signUpdatedAt).toFormat("dd/MM/yyyy")}</strong>
          </div>
        )}
      </div>
    </CEREditSection>
  );
};

export default CERContractFormSigned;
