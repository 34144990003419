const formConfigObject = {
  form: [
    {
      text: "Déterminons la nature de ce RDV",
      questions: [
        {
          name: "rdvStatus",
          type: "btnList",
          description: "",
          label: "Le RDV a t-il eu lieu ?",
          datas: [
            {
              id: 1,
              name: "Oui, c'est un rendez-vous d'orientation",
            },
            {
              id: 2,
              name: "Oui, c'est un entretien giratoire",
            },
            {
              id: 3,
              name: "Non, le rendez-vous est annulé",
            },
          ],
          className: "col-12",
          required: true,
        },
      ],
    },
    {
      text: "(Re) Faisons ensemble la RDSP",
      questions: [
        {
          name: "FormRDSP",
          type: "component",
          required: true,
        },
      ],
    },
    {
      text: "Déterminons la nature de ce RDV",
      questions: [
        {
          name: "rdvStatus",
          type: "btnList",
          description: "",
          label: "Le RDV a t-il eu lieu ?",
          datas: [
            {
              id: 1,
              name: "Oui, c'est un rendez-vous d'orientation",
            },
            {
              id: 2,
              name: "Oui, c'est un entretien giratoire",
            },
            {
              id: 3,
              name: "Non, le rendez-vous est annulé",
            },
          ],
          className: "col-12",
          required: true,
        },
      ],
    },
  ],
};

export default formConfigObject;
