import React from "react";

const InfoBox = ({ name, value }) => {
  return (
    <div className="row border-bottom align-items-center">
      <div className="col-12 col-md-6 col-lg-4">
        <label>{name}</label>
      </div>
      <div className="col-12 col-md-6 col-lg-8">{value}</div>
    </div>
  );
};

export default InfoBox;
