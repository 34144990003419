import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Page from "../../common/layout/Page";
import Pagination from "../../common/Pagination";
import SaveBtn from "../../common/SaveBtn";
import Table from "../../common/Table";
import CERListFilters from "./CERListFilters/CERListFilters";
import useCERList from "./useCERList";

const CERList = () => {
  const [state] = useStore();
  const { CER_DURATIONS, CER_GOAL_PATH_TYPES, CER_GOAL_STATUSES } = state.constants.items;
  const { cerList, cerListFilters, actions, isSearchingCerList } = useCERList();

  const getCERPathTypes = (goals) => {
    var pathTypes = new Set();
    goals.forEach((goal) => {
      pathTypes.add(goal.pathType);
    });

    return [...pathTypes];
  };

  const getCERGoalsProgress = (goals) => {
    const count = [...CER_GOAL_STATUSES.map((s) => ({ ...s, count: 0 }))];
    goals.forEach((goal) => {
      count.find((c) => c.id == goal.detail.status).count++;
    });

    return count;
  };

  const checkIsMinor = (birthdate) => {
    const dur = DateTime.local().diff(DateTime.fromISO(birthdate), "years").toObject();

    return dur.years < 18;
  };

  return (
    <Page container={"container px-5"} title={"Contrats CER"}>
      <div className="row">
        <div className="col-12">
          <CERListFilters />
        </div>
        <div className="col-12">
          <SaveBtn
            type="btn-primary"
            text="Rechercher"
            save={actions.getCerList}
            isSaving={isSearchingCerList}
            className="w-100 d-center font-weight-bold py-3"
          />
        </div>
        <div className="col-12 pt-2 d-flex justify-content-end">{cerList.count} résultat(s)</div>
      </div>
      <div className="row">
        <div className="col-12">
          <Table
            exportOptions={{
              fetchDatas: "cers/search",
              fetchParams: { ...cerListFilters, nbItemsByPage: "*" },
              formatDatasFn: (datas) =>
                datas.map((data) => {
                  return {
                    ...data,
                    userRecipient: tools.getFullname(data.userRecipient),
                    CAFId: data.userRecipient.CAFId,
                    nir: data.userRecipient.nir,
                    beginAt: tools.formatDate(data.beginAt),
                    endAt: tools.formatDate(data.endAt),
                    signedAt: tools.formatDate(data.signedAt),
                    createdAt: tools.formatDate(data.createdAt),
                    userManager: tools.getFullname(data.userRecipient.userManager),
                    postalCode: data.userRecipient.location.postalCode,
                    city: data.userRecipient.location.name,
                    zone: data.zone,
                    isMinor: checkIsMinor(data.userRecipient.birthdate) ? "Oui" : "Non",
                    type: getCERPathTypes(data.goals).includes("SOCIAL_PRO_COURSE")
                      ? getCERPathTypes(data.goals).includes("SOCIAL_COURSE")
                        ? "mixte"
                        : "pro"
                      : "social",
                  };
                }),
              excludedFields: ["Crée le", "Période", "Types", "Progression objectifs"],
              arrayFields: [],
              objectFields: {},
              additionalFields: [
                { path: "CAFId", name: "Matricule CAF" },
                { path: "postalCode", name: "Code postal" },
                { path: "city", name: "Ville" },
                { path: "isMinor", name: "Mineur" },
                { path: "nir", name: "NIR" },
                { path: "beginAt", name: "Début" },
                { path: "endAt", name: "Fin" },
                { path: "signedAt", name: "Signature" },
                { path: "duration", name: "Durée" },
                { path: "type", name: "Type" },
              ],
            }}
            fields={[
              {
                path: "userRecipient",
                name: "Allocataire",
                render: (el) => (
                  <Link to={`/recipients/${el.id}`}>
                    {tools.getFullname(el)}
                    <div>
                      <small>{el.nir}</small>
                    </div>
                    {checkIsMinor(el.birthdate) && <span className="badge badge-info">mineur</span>}
                  </Link>
                ),
              },

              {
                path: "*",
                name: "Créé le",
                exportPath: "createdAt",
                render: (el) => (
                  <>
                    <div>{tools.formatDate(el.createdAt)}</div>
                    <div>
                      <small>màj le {tools.formatDate(el.updatedAt)}</small>
                    </div>
                  </>
                ),
              },
              {
                path: "*",
                name: "Période",
                exportPath: "period",
                render: (el) =>
                  `du ${tools.formatDate(el.beginAt)} au ${tools.formatDate(el.endAt)} - ${
                    tools.findIn(CER_DURATIONS, "id", el.duration).name
                  }`,
              },

              {
                path: "*",
                name: "Statut",
                exportPath: "status",
                render: (el) => {
                  return el.status == "SIGNED" ? (
                    <>
                      <div className="badge badge-success">SIGNÉ</div>
                      <div>
                        <small>1ere sign. : {tools.formatDate(el.signedAt)}</small>
                      </div>
                      <div>
                        <small>dern. sign. : {tools.formatDate(el.signUpdatedAt)}</small>
                      </div>
                    </>
                  ) : (
                    <div className="badge badge-dark">BROUILLON</div>
                  );
                },
              },
              {
                path: "goals",
                name: "Types",
                render: (el) =>
                  getCERPathTypes(el).map((pt, ptk) => {
                    var color = ["primary", "info"][ptk];
                    return (
                      <div className={`badge badge-${color}`} key={`obj${pt}${ptk}`}>
                        {tools.findIn(CER_GOAL_PATH_TYPES, "id", pt).name}
                      </div>
                    );
                  }),
              },
              {
                path: "goals",
                name: "Progression objectifs",
                render: (el) => (
                  <>
                    <div>{el.length} objectifs</div>
                    {getCERGoalsProgress(el).map((t, tk) => (
                      <div style={{ fontSize: 11 }} title={t.name} key={`goa${t.id}${tk}`}>
                        <i className={`fa fa-${t.icon} text-${t.color} mr-2`} />
                        {t.count}
                      </div>
                    ))}
                  </>
                ),
              },
              {
                path: "userRecipient",
                name: "Référent unique",
                exportPath: "userManager",
                render: (el) => tools.getFullname(el.userManager),
              },
              {
                path: "*",
                name: "Localité",
                exportPath: "zone",
                render: (el) => (
                  <>
                    <span className="badge badge-dark">{el.zone}</span>
                    <div>{tools.getFullAddress(el.userRecipient.location)}</div>
                  </>
                ),
              },
            ]}
            datas={cerList.data}
          />
        </div>
      </div>
      <Pagination
        nbPages={Math.ceil(cerList.count / 25)}
        page={cerListFilters.page}
        changePage={actions.changePage}
        nbItemsByPage={cerListFilters.nbItemsByPage}
        changeNbItemsByPage={actions.changeNbItemsByPage}
      />
    </Page>
  );
};

export default CERList;
