import React, { useState } from "react";
import Page from "../../common/layout/Page";
import StatContainer from "./StatContainer";
import StatsFilters from "./StatsFilters";
import EvoAndChart from "./EvoAndChart";
import DelayMoyOrientation from "./DelayMoyOrientation/DelayMoyOrientation";
import ReoByPartner from "./ReoByPartner/ReoByPartner";
import Accordion from "../../common/Accordion/Accordion";
import NbFirstOrientation from "./NbFirstOrientation/NbFirstOrientation";
import NbOrientByPart from "./NbOrientByPart/NbOrientByPart";
import NbOrientByPartNoEG from "./NbOrientByPartNoEG/NbOrientByPartNoEG";
import RDVPies from "./RDVPies/RDVPies";
import AnnualReoByPart from "./AnnualReoByPart/AnnualReoByPart";
import SanctionByPart from "./SanctionByPart/SanctionByPart";
import CERElementsCount from "./CERElementsCount/CERElementsCount";
import CERMeans from "./CERMeans/CERMeans";
import PositiveOutcome from "./PositiveOutcome/PositiveOutcome";
import Stock from "./Stock/Stock";
import ContractsNextEP from "./ContractsNextEP/ContractsNextEP";
import CERTotalCount from "./CERTotalCount/CERTotalCount";
import DelayMoyContractualisation from "./DelayMoyContractualisation/DelayMoyContractualisation";
import NbOrientByTerritory from "./NbOrientByTerritory/NbOrientByTerritory";

import NbCERByTerritory from "./NbCERByTerritory/NbCERByTerritory";
import NbRdvEGCafByTerritory from "./NbRdvEGCafByTerritory/NbRdvEGCafByTerritory";

const Stats = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  return (
    <Page title="Statistiques" container="container px-5" bgTransparent>
      <div className="row">
        <StatsFilters year={year} setYear={setYear} />
        <Accordion
          year={year}
          id="statAccordion"
          className="w-100"
          items={[
            {
              title: "Orientations",
              content: (
                <>
                  {/* <StatContainer
                    title="Délai moyen d'orientation (1ère orientation)"
                    description="Première orientation = orientation automatique algorithme (hors EG) ou orientation à l'issue de l'EG (du premier EG). Délai entre la DTLIQ et la date de l'orientation. La date de l'orientation intervient dans le mois concerné."
                    index={0}
                  >
                    <DelayMoyOrientation
                      url="statistics/orientations/firsts/delay"
                      year={year}
                      showPie={false}
                    />
                  </StatContainer> */}
                  <StatContainer
                    title="Nombre de première orientation par partenaire"
                    description="Répartition des premières orientations par partenaire."
                    index={1}
                  >
                    <NbFirstOrientation
                      url="statistics/orientations/firsts/nb"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Nombre d'orientation via EG"
                    description="Nombre d'allocataires orientés suite à un entretien giratoire, par partenaire d'orientation."
                    index={2}
                  >
                    <EvoAndChart
                      url="statistics/orientations-eg/nb"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de BRSA total orientés par partenaire"
                    description="Tous les allocataires incluant ceux orientés suite à un entretien giratoire"
                    index={3}
                  >
                    <NbOrientByPart
                      url="statistics/orientations/nb"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de BRSA total orientés par partenaire hors EG"
                    description="Tous les allocataires excluant ceux orientés suite à un entretien giratoire"
                    index={4}
                  >
                    <NbOrientByPartNoEG
                      url="statistics/orientations/nb-without-eg"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de BRSA orientés sans contrat/projet deux mois après l'orientation"
                    description="Nombre de NIR ayant une orientation vers le partenaire concerné et un délai entre la date d'orientation et la date du jour contenu dans le mois concerné atteignant 2 mois.
Ce volume pourra varier à postériori d'un mois échu si l'information de contractualisation est reçue est que le délai de contractualisation est finalement inférieur à 2 mois."
                    index={5}
                  >
                    <EvoAndChart
                      url="statistics/orientations-without-contracts"
                      year={year}
                      displayTotal={true}
                      otherPostParams={{
                        minimum: 2,
                      }}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de BRSA orientés sans contrat/projet en stock"
                    description="Il s'agit là du stock observé, un BRSA sort du stock en ayant un contrat à jour mais aussi s'il n'est plus SDD."
                    index={6}
                  >
                    <EvoAndChart
                      url="statistics/orientations-without-contracts"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de BRSA total orientés à la CAF par territoire et par commune"
                    description="Tous les allocataires incluant ceux orientés suite à un entretien giratoire"
                    index={3}
                  >
                    <NbOrientByTerritory
                      url="statistics/orientations/nb-caf"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                </>
              ),
            },
            {
              title: "EP",
              content: (
                <>
                  <StatContainer
                    title="Décisions des EP clôturées par types"
                    description="Décisions des EP clôturées par types"
                    index={0}
                  >
                    <EvoAndChart
                      url="statistics/eps-by-types"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Niveau des sanctions"
                    description="Nombre de décisions de type sanction par type (niveau 1, niveau 2 ou pas de réduction)"
                    index={1}
                  >
                    <SanctionByPart
                      url="statistics/eps/sanctions"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                </>
              ),
            },
            {
              title: "Réorientations",
              content: (
                <>
                  <StatContainer
                    title="Nombre de réorientations avant contractualisation"
                    description="Avec le module EP il faudra distinguer les réorientations intervenant à la demande du référent unique alors que l'allocataire est déjà accompagné par lui de celles qui nous intéressent là intervenant avant qu'un contrat ne soit signé."
                    index={0}
                  >
                    <EvoAndChart
                      url="statistics/orientations-before-contract"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Réorientations par partenaire"
                    description="Nombre de réorientations par organisme partenaire et par statut"
                    index={1}
                  >
                    <ReoByPartner
                      url="statistics/reorientations"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Répartition des réorientations EP clôturées"
                    description="Statistique annuelle représentant les réorientations issues d'EP clôturées entre les partenaires"
                    index={1}
                  >
                    <AnnualReoByPart
                      url="statistics/reorientations/from-to"
                      year={year}
                    />
                  </StatContainer>
                </>
              ),
            },
            {
              title: "Contractualisations",
              content: (
                <>
                  <StatContainer
                    title="Nombre de contractualisations"
                    description="Toutes les contractualisation."
                    index={0}
                  >
                    <EvoAndChart
                      url="statistics/contracts/nb"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Délai moyen de contractualisation"
                    description="Délai entre la date de l'orientation chez un référent et la date de la contractualisation chez le même référent. Cette date de contractualisation intervenant dans le mois concerné."
                    index={1}
                  >
                    <DelayMoyContractualisation
                      url="statistics/contracts/delay"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Contrats signés après une réorientation EP"
                    description="Nombre de contrats signés par les nouveaux RU suite à une réorientation validée en EP"
                    index={2}
                  >
                    <ContractsNextEP
                      url="statistics/contracts-next-ep"
                      year={year}
                    />
                  </StatContainer>
                </>
              ),
            },
            {
              title: "Autres",
              content: (
                <>
                  <StatContainer
                    title="Nombre de BRSA entrant soumis aux droits et devoirs"
                    description="Nombre de NIR distincts par DTLIQ, attention à neutraliser les éventuels doublons sur un même mois"
                    index={0}
                  >
                    <EvoAndChart
                      url="statistics/recipients/new-with-rights"
                      year={year}
                    />
                  </StatContainer>
                  <StatContainer
                    title="RDV EG & RDV CAF"
                    description={
                      <ul>
                        <li>
                          RDV honoré : RDV clôturé et auquel l’allocataire s’est
                          bien présenté.
                        </li>
                        <li>
                          RDV non honoré : RDV auquel l’allocataire ou l’agent
                          ne s’est pas présenté
                        </li>
                        <li>
                          RDV à mener : RDV dans le futur sur lequel un
                          allocataire est positionné
                        </li>
                      </ul>
                    }
                    index={1}
                  >
                    <RDVPies url="statistics/rdvs" year={year} />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de RDV CAF par territoire et par commune"
                    description={
                      <ul>
                        <li>
                          RDV honoré : RDV clôturé et auquel l’allocataire s’est
                          bien présenté.
                        </li>
                        <li>
                          RDV non honoré : RDV auquel l’allocataire ou l’agent
                          ne s’est pas présenté
                        </li>
                        <li>
                          RDV à mener : RDV dans le futur sur lequel un
                          allocataire est positionné
                        </li>
                      </ul>
                    }
                    index={3}
                  >
                    <NbRdvEGCafByTerritory
                      url="statistics/rdv/caf-par-commune"
                      year={year}
                      displayTotal={true}
                    />
                  </StatContainer>
                  <StatContainer
                    title="RDV - Motifs de sortie"
                    description="Nombre de sorties positives par catégorie"
                    index={2}
                  >
                    <PositiveOutcome
                      url="statistics/rdvs/exit-reasons-ok"
                      year={year}
                    />
                  </StatContainer>
                </>
              ),
            },
            {
              title: "CER",
              content: (
                <>
                  <StatContainer
                    title="Nombre de CER total"
                    description="Nombre de contrats CER cumulé"
                    index={0}
                  >
                    <CERTotalCount
                      url="statistics/cers/nb"
                      year={year}
                      displayTotal
                    />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de CER par territoire, par commune et par type"
                    description="Répartition des contrats CER par territoire, par commune et par type"
                    index={0}
                  >
                    <NbCERByTerritory
                      url="statistics/cers/par-commune"
                      year={year}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Délai de contractualisation"
                    description="Nombre de CER signés <1 mois, entre 1 et 2 mois et >2 mois."
                    index={0}
                  >
                    <EvoAndChart url="statistics/cers/delays" year={year} />
                  </StatContainer>

                  <StatContainer
                    title="Durée des CER"
                    description="Répartition des contrats CER par durée"
                    index={0}
                  >
                    <EvoAndChart
                      url="statistics/cers/by-duration"
                      year={year}
                    />
                  </StatContainer>
                  <StatContainer
                    title="Type de parcours des CER"
                    description="Répartition des contrats CER par type de parcours"
                    index={0}
                  >
                    <EvoAndChart url="statistics/cers/by-type" year={year} />
                  </StatContainer>
                  <StatContainer
                    title="Nombre de CER par composant"
                    description="Nombre de contrats CER par thèmes/objectifs/démarches/freins"
                    index={0}
                  >
                    <CERElementsCount
                      url="statistics/cers/by-goals"
                      year={year}
                    />
                  </StatContainer>

                  <StatContainer
                    title="Moyens mobilisés"
                    description="Nombre de fois ou les moyens mobilisés dans des contrats CER"
                    index={0}
                  >
                    <CERMeans url="statistics/cers/means" year={year} />
                  </StatContainer>
                </>
              ),
            },
            {
              title: "STOCK",
              content: (
                <StatContainer
                  title="Stock restant & orientations par partenaire"
                  description="Orientation des allocataires stock et nombre restant. Cette statistique est globale et n'est pas affectée par l'année sélectionnée !"
                  index={3}
                >
                  <Stock url="statistics/stocks" year={year} />
                </StatContainer>
              ),
            },
          ]}
        />
      </div>
    </Page>
  );
};

export default Stats;
