import React from "react";
import useStore from "../../../../context/useStore";
import colors from "../../../../helpers/colors";
import SaveBtn from "../../../common/SaveBtn";
import useCERMeans from "./useCERMeans";

const CERMeans = ({ url, year }) => {
  const [state] = useStore();
  const { CER_MEANS } = state.constants.items;
  const { datas, isLoading, getDatas } = useCERMeans({ url, year });

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={datas ? "btn-default d-center" : "btn-primary d-center"}
          color={datas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            datas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>

      {!isLoading && datas && (
        <div className="col-12 mt-3">
          <ul className="list-group">
            {Object.keys(datas).map((dkey) => {
              var entry = datas[dkey];
              var meanRef = CER_MEANS.find((m) => m.id == dkey);
              return (
                <li key={`mean${dkey}`} className="list-group-item">
                  <div className="row">
                    <div className="col-4">{meanRef?.name}</div>
                    <div className="col-4 font-weight-bold text-primary">{entry}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CERMeans;
