import React, { useEffect, useState } from "react";
import ItemStore from "./Context/Store/ItemStore";
import itemReducer from "./Context/reducers/itemsReducer";
import EpFormPage from "./EpFormPage/EpFormPage";
import { Link } from "react-router-dom";

const EpForm = (props) => {
  var epId = props.match.params.id;

  const items = [
    {
      name: "ep",
      paths: {
        get: `eps/${epId}`,
      },
      initialState: {
        beginAt: "",
        beginHourAt: "",
        beginMinuteAt: "",
        closedAt: "",
        calledAt: "",
        createdAt: "",
        endedAt: "",
        id: "",
        isBackupSession: false,
        isFull: false,
        jury: [],
        sector: {},
        updatedAt: "",
      },
      fetchOnLoad: true,
    },
    {
      name: "epInitial",
      initialState: {
        beginAt: "",
        beginHourAt: "",
        beginMinuteAt: "",
        closedAt: "",
        calledAt: "",
        createdAt: "",
        endedAt: "",
        id: "",
        isBackupSession: false,
        isFull: false,
        jury: [],
        sector: {},
        updatedAt: "",
      },
    },
    {
      name: "organizations",
      paths: {
        get: `organizations`,
      },
      initialState: [],
      fetchOnLoad: true,
    },
    {
      name: "recipients",
      paths: {
        get: `eps/${epId}/recipients`,
      },
      initialState: [],
      fetchOnLoad: true,
    },
    {
      name: "recipientsInitial",
      initialState: [],
    },
    {
      name: "utils",
      initialState: {
        prevCurrentAlloc: null,
        currentAlloc: null,
        currentAvis: {},
        isSavingWriterAndPresident: false,
        isSignedByPresident: false,
      },
    },
  ];

  return (
    <ItemStore rootReducer={itemReducer}>
      <div className="container" style={{ maxWidth: "95%" }}>
        <Link to="/ep" className="btn btn-link">
          <i className="fa fa-arrow-left mr-2" />
          Revenir au calendrier des EP
        </Link>
      </div>
      <EpFormPage pageTitle="" items={items} />
    </ItemStore>
  );
};

export default EpForm;
