import React, { useEffect } from "react";
import useStoreState from "../Context/Store/useStoreState";
import Page from "../../../../common/layout/Page";
import StartEpPage from "./StartEpPage";
import EpCalling from "./EpCalling";
import EpSession from "./EpSession/EpSession";

const EpFormPage = (props) => {
  const { state, gState, items, items_actions } = useStoreState();

  useEffect(() => {
    items_actions.initItems(props.items);
  }, []);

  var isLoading = false;

  return (
    <Page
      bgTransparent
      title={props.pageTitle}
      isLoading={isLoading}
      back={props.back}
      style={{ maxWidth: "95%" }}
    >
      {state.isInit ? (
        !items.ep.startedAt ? (
          <StartEpPage />
        ) : !items.ep.calledAt ? (
          <EpCalling state={state} gState={gState} items={items} items_actions={items_actions} />
        ) : (
          <EpSession />
        )
      ) : null}
    </Page>
  );
};

export default EpFormPage;
