import React from "react";
import Control from "./Control";

const Pagination = ({
  page,
  nbPages,
  changePage,
  nbItemsByPage,
  changeNbItemsByPage,
}) => {
  var pages = [];
  var pageMin = page - 2 < 1 ? 1 : page - 2;
  var pageMax = page + 2 > nbPages ? nbPages : page + 2;
  for (let i = pageMin; i <= pageMax; i++) {
    pages.push(
      <li
        key={"page" + i}
        className={"page-item " + (page == i ? "active" : "")}
      >
        <button className="page-link" onClick={() => changePage(i)}>
          {i}
        </button>
      </li>
    );
  }

  return (
    <div className="row">
      <div className="text-center d-center text-muted mb-2 col-12 col-md-4">
        {nbPages} page{nbPages > 1 ? "s" : ""}
      </div>

      <ul className="pagination justify-content-center  col-12 col-md-4">
        {page > 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(1)}>
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
        )}
        {page > 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(page - 1)}>
              <span aria-hidden="true">&lsaquo;</span>
            </button>
          </li>
        )}
        {pages}
        {page < nbPages && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(page + 1)}>
              <span aria-hidden="true">&rsaquo;</span>
            </button>
          </li>
        )}
        {page < nbPages && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(nbPages)}>
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        )}
      </ul>
      <div className="text-center text-muted  col-12 col-md-4">
        <label
          className="d-center flex-row mx-auto text-muted font-weight-normal"
          style={{ width: 224 }}
        >
          Afficher{" "}
          <Control
            containerStyle={{ width: 100 }}
            className="mx-auto"
            type="select"
            value={nbItemsByPage}
            change={(e) => changeNbItemsByPage(e.target.value || 25)}
            datas={[
              { id: 25, name: "25" },
              { id: 50, name: "50" },
              { id: 100, name: "100" },
            ]}
          />{" "}
          élements
        </label>
      </div>
    </div>
  );
};

export default Pagination;
