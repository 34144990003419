import React, { useContext, useState } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERModule from "../../../../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "../../../ObjectiveContext";
import OperatorSelect from "../AddOperatorForm/OperatorSelect";
import { StepContext } from "../StepContext";
import { OperatorContext } from "./OperatorContext";

const OperatorEdit = ({ goToViewMode }) => {
  const step = useContext(StepContext);
  const objective = useContext(ObjectiveContext);
  const operator = useContext(OperatorContext);

  const { recipientCER, events } = useCERModule();
  const [newOperator, setNewOperator] = useState({ ...operator });

  const handleNewOperatorChange = (e) => {
    const { name, value } = e.target;
    setNewOperator({ ...newOperator, [name]: value });
  };

  const onValidateOperator = () => {
    var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
    var stepIndex = tools.getStepIndex(objective.steps, step);
    var operatorIndex = tools.getOperatorIndex(step.operators, operator);

    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex
            ? {
                ...st,
                operators: st.operators.map((o, ok) => {
                  return operatorIndex == ok ? newOperator : o;
                }),
              }
            : st;
        }),
      ],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
    goToViewMode();
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <OperatorSelect
        objective={objective}
        step={step}
        operator={newOperator}
        handleNewOperatorChange={handleNewOperatorChange}
        k="modifyOperator"
      />

      <button
        className="btn border ml-2"
        disabled={!newOperator.operator}
        onClick={onValidateOperator}
        style={{ color: "#dee2e6" }}
      >
        <i className="fa fa-check" />
      </button>
    </div>
  );
};

export default OperatorEdit;
