import React, { useEffect, useState } from "react";
import BubbleIcon from "./BubbleIcon";
import { SyncLoader } from "react-spinners";

const FormInfo = ({
  text,
  isLoading,
  loadingText = false,
  style = {},
  answers = {},
  actions,
  specialActions,
}) => {
  const [outputText, setOutputText] = useState("");

  useEffect(() => {
    if (text) {
      let newText = "";
      var regExp = /\[(.*?)\]/g;

      var result = "";
      newText = text.replace(regExp, "===");
      let results = [];
      while ((result = regExp.exec(text)) !== null) {
        //newText = newText.replace(regExp, result[1]);
        results.push(result[1]);
      }
      if (results.length > 0) {
        results.forEach((res) => {
          newText = newText.replace("===", eval(res));
        });
      } else {
        newText = text;
      }

      setOutputText(newText);
    }
  }, [text, answers]);

  return (
    <>
      <div
        className="d-flex flex-column flex-md-row form-info align-items-start justify-content-start animated fadeInUp faster mx-2"
        style={{ ...style, minHeight: 74 }}
      >
        <div className="form-info-text">
          <p className="mb-0">
            <b>Entretien giratoire</b>
          </p>
          {isLoading ? (
            <div className="">
              <SyncLoader size={8} color="#002789" />
              {loadingText && <p className="mb-0">{loadingText}</p>}
            </div>
          ) : (
            <p>{outputText}</p>
          )}
          {actions && (
            <div style={{ marginTop: 40 }}>
              {actions.map((ac, ack) => {
                return (
                  <button
                    key={"action" + ack}
                    className="btn btn-default mr-2"
                    onClick={() => {
                      specialActions[ac.action]();
                    }}
                  >
                    {ac.text}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FormInfo;
