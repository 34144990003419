import React, { useContext, useEffect, useState } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERModule from "../../../../../../CERModule/hooks/useCERModule";
import AddEntityForm from "../../../../common/AddEntityForm";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { StepContext } from "../StepContext";
import OperatorSelect from "./OperatorSelect";

const AddOperatorForm = () => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const { recipientCER, events } = useCERModule();

  var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);

  const [newOperator, setNewOperator] = useState({
    operator: "",
  });

  const handleNewOperatorChange = (e) => {
    const { name, value } = e.target;
    setNewOperator({ ...newOperator, [name]: value });
  };

  const handleAddOperator = () => {
    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex ? { ...st, operators: [...st.operators, newOperator] } : st;
        }),
      ],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
    setNewOperator({ operator: "" });
  };

  useEffect(() => {
    if (newOperator.operator) {
      handleAddOperator();
    }
  }, [newOperator]);

  return (
    <AddEntityForm onSubmit={handleAddOperator} pbClass="pb-0">
      <OperatorSelect
        handleNewOperatorChange={handleNewOperatorChange}
        objective={objective}
        step={step}
        operator={newOperator}
        k={`addOperator`}
      />
    </AddEntityForm>
  );
};

export default AddOperatorForm;
