import React from "react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { modalActions } from "../../../../../context/actions/modalActions";
import useStore from "../../../../../context/useStore";
import dataService from "../../../../../helpers/dataService";

const eventInitialState = {
  type: 3,
  subType: "",
  title: "RDV",
  start: "",
  hourStart: "",
  minuteStart: "",
  end: "",
  box: "",
  site: "",
  duration: "15",
  isPhoneCall: false,
};

const useCreateRdvOnTheFly = ({
  recipient,
  onSaved,
  hasExternalSubmitHandler, //true si le composant est incorporé ailleurs et ne crée pas lui même le RDV directement au submit
}) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const [event, setEvent] = useState({
    ...eventInitialState,
    userRecipient: recipient.id,
  });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [sites, setSites] = useState([]);
  const hasErrors = Object.values(errors).length;
  const { SLOT_TYPES, SLOT_SUBTYPES } = state.constants.items;

  const loadSitesOfOrgaSlugName = (organizationSlugName) => {
    dataService.get(`organizations`, (datas) => {
      const orgaId = datas.find((o) => o.slugName == organizationSlugName).id;
      dataService.get(`organizations/${orgaId}/sites`, setSites);
    });
  };

  const loadSiteById = (siteId) => {
    dataService.get(`sites/${siteId}`, (data) => {
      setSites([data]);
      setEvent({ ...event, site: siteId });
    });
  };

  const handleChangeEvent = (e) => {
    const { name, value: inputValue, type } = e.target;
    let value = "";
    if (type == "checkbox") value = e.target.checked;
    else value = inputValue;

    var other = {};
    if (name == "duration" && !value) return;

    if (["start", "duration", "hourStart", "minuteStart"].includes(name)) {
      var hourValue = name == "hourStart" ? value : event.hourStart;
      var minuteValue = name == "minuteStart" ? value : event.minuteStart;
      var startValue = name == "start" ? value : event.start;
      var durationValue = name == "duration" ? value : event.duration;
      other.end = DateTime.fromISO(new Date(startValue).toISOString())
        .startOf("day")
        .startOf("hour")
        .set({ hour: hourValue || 0 })
        .set({ minute: minuteValue || 0 })
        .plus({ minutes: durationValue })
        .toFormat("HH:mm");
    }
    if (name == "type") {
      other.subType = "";
    }
    if (name == "site") {
      other.box = "";
    }
    if (name == "box") {
      other.title = sites
        .find((s) => s.id == event.site)
        .boxes.find((b) => b._id == value).name;
    }

    setEvent({
      ...event,
      [name]: value,
      ...other,
    });
  };

  const getFormattedEvent = () => {
    let newEvent = null;
    if (event.start && event.duration) {
      const startISO = new Date(event.start).toISOString();
      newEvent = {
        ...event,
        start: DateTime.fromISO(startISO)
          .set({ hour: event.hourStart || 0 })
          .set({ minute: event.minuteStart || 0 })
          .toISO(),
        end: DateTime.fromISO(startISO)
          .set({ hour: event.hourStart || 0 })
          .set({ minute: event.minuteStart || 0 })
          .plus({ minutes: event.duration })
          .toISO(),
      };
    }

    return newEvent;
  };

  const validateRdv = () => {
    const eventToValidate = getFormattedEvent();
    let validationErrors = {};
    if (eventToValidate) {
      if (
        DateTime.fromISO(eventToValidate.start) >
        DateTime.fromISO(eventToValidate.end)
      ) {
        validationErrors = {
          end: "L'heure de fin est inférieure à l'heure de début",
        };
      }
    }
    if (!event.start) {
      validationErrors.start = "Une date de début est requise";
    }
    if (!event.hourStart) {
      validationErrors.hourStart = "Une heure de début est requise";
    }
    if (!event.minuteStart) {
      validationErrors.minuteStart = "Une minute de début est requise";
    }
    if (!event.end) {
      validationErrors.end = "Une date de début est requise";
    }
    if (!event.site) {
      validationErrors.site = "Un site est requis";
    }
    if (!event.type) {
      validationErrors.type = "Une type de RDV est requis";
    }
    setErrors(validationErrors);
  };

  useEffect(() => {
    if (getFormattedEvent()) {
      validateRdv();
    }
  }, [event]);

  const submit = () => {
    validateRdv();
    const eventToSubmit = getFormattedEvent();
    if (!eventToSubmit || hasErrors) return;
    setIsSaving(true);
    if (!hasExternalSubmitHandler) {
      dataService.post(
        `rdv`,
        eventToSubmit,
        (data) => {
          onSaved({ rdvOnTheFly: data });
          mActions.updateModal({
            isOpen: false,
            content: null,
            noClose: false,
          });
        },
        setErrors,
        () => setIsSaving(false)
      );
    } else {
      onSaved({ rdvOnTheFly: eventToSubmit });
      setTimeout(() => {
        try {
          setIsSaving(false);
        } catch (err) {}
      }, 2500);
    }
  };

  return {
    sites,
    event,
    errors,
    hasErrors,
    isSaving,
    SLOT_TYPES,
    SLOT_SUBTYPES,
    handleChangeEvent,
    submit,
    loadSitesOfOrgaSlugName,
    loadSiteById,
  };
};

export default useCreateRdvOnTheFly;
