import React from "react";
import ReactApexChart from "react-apexcharts";
import SaveBtn from "../../../common/SaveBtn";
import colors from "../../../../helpers/colors";
import useAnnualReoByPart from "./useAnnualReoByPart";
import Control from "../../../common/Control";

const AnnualReoByPart = ({
  url,
  year,
  displayTotal = true,
  showLine = true,
  showPie = true,
  otherPostParams = {},
}) => {
  const { isLoading, chartState, getDatas, hasDatas, total, isZeroShow, setIsZeroShow, orgas } =
    useAnnualReoByPart(url, year, otherPostParams);

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          color={hasDatas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {/* <div className="col-12">
        <Control
          label="Afficher les valeurs à 0"
          type="checkbox"
          name="isZeroShow"
          checked={isZeroShow}
          change={(e) => setIsZeroShow(e.target.checked)}
        />
      </div> */}
      {!isLoading && hasDatas ? (
        <>
          <div className="col-12 ">
            {showLine && (
              <ReactApexChart
                options={chartState.line.options}
                series={chartState.line.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className="col-12 ">
            {showPie && (
              <ReactApexChart
                options={chartState.pie.options}
                series={chartState.pie.series}
                type="pie"
                height={350}
              />
            )}
          </div>
          {displayTotal ? (
            <div className="col-12 text-center font-weight-bold mt-3">TOTAL : {total}</div>
          ) : null}

          <div className="col-12 col-md-6  mt-3">
            <h5>Répartition des saisines validées en réorientation depuis un partenaire</h5>
            <ul>
              {Object.keys(chartState.fromTotal).map((org) => {
                var val = chartState.fromTotal[org];
                var orgRef = orgas.find((o) => o.slugName == org);
                return (
                  <li>
                    {orgRef?.name || org} : {val}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-12 col-md-6  mt-3">
            <h5>Répartition des saisines validées en réorientation vers un nouveau partenaire</h5>
            <ul>
              {Object.keys(chartState.toTotal).map((org) => {
                var val = chartState.toTotal[org];
                var orgRef = orgas.find((o) => o.slugName == org);
                return (
                  <li>
                    {orgRef?.name || org} : {val}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-12 col-md-6  mt-3">
            <h5>Réorientation conservée par partenaire suite EP</h5>
            <ul>
              {Object.keys(chartState.keepInitialOrientation).map((org) => {
                var val = chartState.keepInitialOrientation[org];
                var orgRef = orgas.find((o) => o.slugName == org);
                return (
                  <li>
                    {orgRef?.name || org} : {val}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AnnualReoByPart;
