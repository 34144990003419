import React, { useEffect } from "react";
import useStore from "../../../context/useStore";
import Page from "../../common/layout/Page";
import useCERData from "../../Hooks/useCERData/useCERData";
import AddObjectiveItem from "./AddObjectiveItem";
import ObjectiveList from "./ObjectiveList/ObjectiveList";
import ObstacleList from "./ObstacleList/ObstacleList";

const CERData = () => {
  const [state] = useStore();
  const {
    objectiveCategories,
    setObjectiveCategories,
    getCerObjectives,
    getCerSteps,
    getCerObstacles,
  } = useCERData();

  useEffect(() => {
    getCerObjectives();
    getCerSteps();
    getCerObstacles();
    setObjectiveCategories(state.constants.items.CER_GOAL_CATEGORIES);
  }, []);

  return (
    <>
      <Page title="Objectifs/Démarches CER" container={"container-fluid px-5"}>
        {objectiveCategories.map((category) => {
          return (
            <div key={`cat${category.id}`}>
              <h4 className="text-info">#{category.name}</h4>
              <div className="mt-3 mb-4">
                <ObjectiveList category={category} />

                <AddObjectiveItem category={category.id} />
              </div>
            </div>
          );
        })}
      </Page>
      <Page title="Freins à l'accomplissement des objectifs" container="container-fluid px-5">
        <ObstacleList />
      </Page>
    </>
  );
};

export default CERData;
