import { DateTime } from "luxon";
import React from "react";
import useStoreState from "../../Context/Store/useStoreState";
import EPTimer from "./EPTimer";

const EPTitle = () => {
  const { items } = useStoreState();
  const { ep } = items;

  const getHourFormatted = (hour) => {
    return (hour + "").length == 1 ? `0${hour}` : hour;
  };
  const getMinuteFormatted = (minute) => {
    return (minute + "").length == 1 ? `0${minute}` : minute;
  };

  return (
    <div className="">
      <h2>
        EP du {DateTime.fromISO(ep.beginAt).setLocale("FR").toFormat("EEEE dd LLLL yyyy")} -{" "}
        {getHourFormatted(ep.beginHourAt)}H{getHourFormatted(ep.beginMinuteAt)}
      </h2>
      <div className="text-black-50 my-3 font-italic ">
        <div className="">
          Démarré à {ep.startedAt ? DateTime.fromISO(ep.startedAt).toFormat("HH'H'mm") : "..."}
        </div>
        {!ep.closedAt && (
          <div className="d-inline-flex flex-row">
            <span className="mr-1">
              <i className="fa fa-stopwatch" />
            </span>
            <EPTimer beginAt={ep.startedAt} />
          </div>
        )}

        {ep.calledAt && (
          <div>Appel effectué à {DateTime.fromISO(ep.calledAt).toFormat("HH'H'mm")}</div>
        )}
        {ep.closedAt && <div>Clôturé à {DateTime.fromISO(ep.closedAt).toFormat("HH'H'mm")}</div>}
        {ep.endedAt && (
          <div>Finalisé le {DateTime.fromISO(ep.endedAt).toFormat("dd/MM/yyyy 'à' HH'H'mm")}</div>
        )}
      </div>
    </div>
  );
};

export default EPTitle;
