import React from "react";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import useAllRecipients from "./useAllRecipients";

const AllRecipientsFilters = () => {
  const {
    allRecipientsFilters,
    isAllRecipientsLoading,
    allRecipientsNbResults,
    onUpdateFilters,
    searchAllRecipients,
  } = useAllRecipients();

  const onSubmit = (e) => {
    e.preventDefault();
    searchAllRecipients();
  };

  const handleChangeFilters = (e) => {
    const { name, value } = e.target;
    onUpdateFilters({
      ...allRecipientsFilters,
      filters: {
        ...allRecipientsFilters.filters,
        [name]: value,
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12">
          <Control
            label="Rechercher un allocataire par son Nom"
            name="lastname"
            type="text"
            value={allRecipientsFilters.filters.lastname}
            change={handleChangeFilters}
          />
          <Control
            label="par son Prénom"
            name="firstname"
            type="text"
            value={allRecipientsFilters.filters.firstname}
            change={handleChangeFilters}
          />
          <Control
            label="par son NIR"
            name="nir"
            type="text"
            value={allRecipientsFilters.filters.nir}
            change={handleChangeFilters}
          />
          <Control
            label="par son Matricule"
            name="CAFId"
            type="text"
            value={allRecipientsFilters.filters.CAFId}
            change={handleChangeFilters}
          />
          <small>
            <i>
              Cette recherche s'effectue sur l'ensemble des collections
              d'allocataires.
            </i>
          </small>
        </div>
        {Object.keys(allRecipientsFilters.filters).length > 0 && (
          <div className="col-12">
            <SaveBtn
              typeBtn="submit"
              type="btn-primary"
              className="w-100 py-3 d-center"
              text="Rechercher"
              isSaving={isAllRecipientsLoading}
            />
          </div>
        )}
        <div className="col-12 text-center pt-2">
          <strong>{allRecipientsNbResults} résultat(s)</strong>
        </div>
      </div>
    </form>
  );
};

export default AllRecipientsFilters;
