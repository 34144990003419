import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import logo from "./logo.svg";
import PrivateRoute from "./components/common/PrivateRoute";
import tools from "./helpers/tools";
import jwt_decode from "jwt-decode";
// Pages
import useStore from "./context/useStore";
import { authActions } from "./context/actions/authActions";
import { AUTH_TYPES } from "./context/reducers/authReducers";
import Sidenav from "./components/common/layout/Sidenav";
import Login from "./components/pages/Login/Login";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { constantActions } from "./context/actions/constantActions";
import UserList from "./components/pages/UserList/UserList";
import UserDetail from "./components/pages/UserDetail/UserDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import OrganizationList from "./components/pages/OrganizationList/OrganizationList";
import OrganizationDetail from "./components/pages/OrganizationDetail/OrganizationDetail";
import SiteList from "./components/pages/SiteList/SiteList";
import SiteDetail from "./components/pages/SiteDetail/SiteDetail";
import CalendarDetail from "./components/pages/CalendarDetail/CalendarDetail";
import RoleStyle from "./components/common/layout/RoleStyle";
import TopHeader from "./components/common/layout/TopHeader";
import RdvComponent from "./components/pages/RdvComponent/RdvComponent";
import RecipientList from "./components/pages/RecipientList/RecipientList";
import RecipientDetail from "./components/pages/RecipientDetail/RecipientDetail";
import AnomalyList from "./components/pages/AnomalyList/AnimalyList";
import MyAnomalyList from "./components/pages/MyAnomalyList/MyAnomalyList";
import RecipientTempList from "./components/pages/RecipientTempList/RecipientTempList";
import RecipientTempDetail from "./components/pages/RecipientTempDetail/RecipientTempDetail";
import ConstantDynamic from "./components/pages/ConstantDynamic/ConstantDynamic";
import { api_url } from "./config";
import Axios from "axios";
import ThemeList from "./components/pages/ThemeList/ThemeList";
import ThemeDetail from "./components/pages/ThemeDetail/ThemeDetail";
import EntretienGiratoire from "./components/pages/EntretienGiratoire/EntretienGiratoire";
import OperatorList from "./components/pages/OperatorList/OperatorList";
import OperatorDetail from "./components/pages/OperatorDetail/OperatorDetail";
import Loader from "./components/common/Loader";
import Simulator from "./components/pages/Simulator/Simulator";
import CustomModal from "./components/common/CustomModal/CustomModal";
import CustomModal2 from "./components/common/CustomModal/CustomModal2";
import FileExchange from "./components/pages/FileExchange/FileExchange";
import PassOrientation from "./components/pages/PassOrientation/PassOrientation";
import Profile from "./components/pages/Profile/Profile";
import NotFound from "./components/pages/NotFound/NotFound";
import sidenavItems from "./sidenavItems";
import RecipientCreate from "./components/pages/RecipientCreate/RecipientCreate";
import Stats from "./components/pages/Stats/Stats";
import SmsCron from "./components/pages/SmsCron/SmsCron";
import EP from "./components/pages/EP/EP";
import SidePanel from "./components/common/SidePanel/SidePanel";
import Sectors from "./components/pages/EP/Sectors/Sectors";
import LeftPanel from "./components/common/LeftPanel/LeftPanel";
import RecipientCreateEP from "./components/pages/RecipientCreateEP/RecipientCreateEP";
import MyEps from "./components/pages/EP/MyEps/MyEps";
import AllInvitations from "./components/pages/EP/AllInvitations/AllInvitations";
import EpForm from "./components/pages/EP/EpForm/EpForm";
import EpPv from "./components/pages/EP/EpPv/EpPv";
import Reorientations from "./components/pages/Reorientations/Reorientations";
import RecipientCreateEpList from "./components/pages/RecipientCreateEpList/RecipientCreateEpList";
import RecipientCreateEpDetail from "./components/pages/RecipientCreateEpDetail/RecipientCreateEpDetail";
import CERData from "./components/pages/CERData/CERData";
import CERPrint from "./components/pages/CERPrint/CERPrint";
import AmendmentPrint from "./components/pages/CERPrint/AmendmentPrint";

import { DateTime, Settings } from "luxon";
import CERList from "./components/pages/CERList/CERList";
import Test from "./components/pages/Test/Test";
import RecipientStockList from "./components/pages/RecipientStockList/RecipientStockList";
import RecipientStockDetail from "./components/pages/RecipientStockDetail/RecipientStockDetail";
import AllRecipients from "./components/pages/AllRecipients/AllRecipients";
import RecipientEpOutcomeList from "./components/pages/RecipientEpOutcomeList/RecipientEpOutcomeList";
import SuiviAnomalyList from "./components/pages/SuiviAnomalyList/SuiviAnomalyList";
Settings.defaultZoneName = "Indian/Reunion";

var firstLoad = true;
const App = () => {
  const [state, dispatch] = useStore();
  console.log("state global", state);
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  Axios.defaults.withCredentials = true;
  Axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== api_url + "auth/logout") {
          actions.logoutUser();
        }
      }
      if (error.response.status == 400) {
        if (error.response.data == "Bad Request") {
          actions.logoutUser();
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    if (localStorage.jwtToken && firstLoad) {
      firstLoad = false;
      // Set auth token header auth
      //tools.setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);

      // Set user and isAuthenticated
      dispatch({
        type: AUTH_TYPES.SET_CURRENT_USER,
        payload: decoded,
      });

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout user

        actions.logoutUser();
        // Clear current Profile
        // store.dispatch(clearCurrentProfile())
        // Redirect to login
      }
    }

    if (!localStorage.jwtToken) {
      Axios.get(api_url + "auth/me")
        .then((res) => {
          localStorage.setItem("jwtToken", res.data);

          const decoded = jwt_decode(res.data);
          dispatch({
            type: AUTH_TYPES.SET_CURRENT_USER,
            payload: decoded,
          });
        })
        .catch((err) => {
          //actions.logoutUser();
        });
    }
    c_actions.getConstants();
  }, []);

  return (
    <Router>
      <div className="wrapper">
        <RoleStyle />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Sidenav sidenavItems={sidenavItems} />

        <div id="content" className="position-relative">
          <TopHeader />
          {Object.keys(state.constants.items).length ? (
            <Switch>
              <Route path="/login" exact component={Dashboard} />
              <Route
                path="/renew-password/:token/:email"
                exact
                component={RenewPassword}
              />
              <Route
                path="/activate-account/:token/:email"
                exact
                component={ActivateAccount}
              />
              <PrivateRoute path="/" exact component={Dashboard} />
              <PrivateRoute path="/users" exact component={UserList} />
              <PrivateRoute path="/ep" exact component={EP} />
              <PrivateRoute path="/ep/:id" exact component={EpForm} />
              <PrivateRoute path="/myeps" exact component={MyEps} />
              <PrivateRoute
                path="/allepinvitations"
                exact
                component={AllInvitations}
              />
              <PrivateRoute
                path="/createrecipientep"
                exact
                component={RecipientCreateEP}
              />
              <PrivateRoute path="/sectors" exact component={Sectors} />
              <PrivateRoute path="/users/:id" exact component={UserDetail} />
              <PrivateRoute
                path="/organizations"
                exact
                component={OrganizationList}
              />
              <PrivateRoute
                path="/organizations/:id"
                exact
                component={OrganizationDetail}
              />
              <PrivateRoute path="/sites" exact component={SiteList} />
              <PrivateRoute path="/sites/:id" exact component={SiteDetail} />
              <PrivateRoute
                path="/sites/:id/calendar"
                exact
                component={CalendarDetail}
              />
              <PrivateRoute path="/rdvs" exact component={RdvComponent} />
              <PrivateRoute
                path="/recipients"
                exact
                component={RecipientList}
              />
              <PrivateRoute
                path="/recipients/:id"
                exact
                component={RecipientDetail}
              />
              <PrivateRoute
                path="/recipients-create"
                exact
                component={RecipientCreate}
              />
              <PrivateRoute path="/anomalies" exact component={AnomalyList} />
              <PrivateRoute
                path="/recipients-ep"
                exact
                component={RecipientCreateEpList}
              />
              <PrivateRoute
                path="/recipients-ep/:id"
                exact
                component={RecipientCreateEpDetail}
              />
              <PrivateRoute
                path="/recipients-temp"
                exact
                component={RecipientTempList}
              />
              <PrivateRoute
                path="/recipients-temp/:id"
                exact
                component={RecipientTempDetail}
              />
              <PrivateRoute
                path="/my-anomalies"
                exact
                component={MyAnomalyList}
              />
              <PrivateRoute
                path="/suivi-anomalies"
                exact
                component={SuiviAnomalyList}
              />
              <PrivateRoute
                path="/dynamic-constants"
                exact
                component={ConstantDynamic}
              />
              <PrivateRoute path="/themes" exact component={ThemeList} />
              <PrivateRoute path="/themes/:id" exact component={ThemeDetail} />
              <PrivateRoute path="/operators" exact component={OperatorList} />
              <PrivateRoute
                path="/operators/:id"
                exact
                component={OperatorDetail}
              />
              <PrivateRoute path="/eg" exact component={EntretienGiratoire} />
              <PrivateRoute path="/simulator" exact component={Simulator} />
              <PrivateRoute
                path="/file-exchange"
                exact
                component={FileExchange}
              />
              <PrivateRoute
                path="/passorientation/:id"
                exact
                component={PassOrientation}
              />
              <PrivateRoute path="/me" exact component={Profile} />
              <PrivateRoute path="/stats" exact component={Stats} />
              <PrivateRoute
                path="/passorientation"
                exact
                component={PassOrientation}
              />
              <PrivateRoute path="/smscron" exact component={SmsCron} />
              <PrivateRoute path="/ep-pv/:id" exact component={EpPv} />
              <PrivateRoute
                path="/reorientations"
                exact
                component={Reorientations}
              />
              <PrivateRoute
                path="/reorientations/:id"
                exact
                component={Reorientations}
              />
              <PrivateRoute path="/cer-data" exact component={CERData} />
              <PrivateRoute
                path="/cercontract/:id"
                exact
                component={CERPrint}
              />
              <PrivateRoute
                path="/ceramendment/:recipientId/:amendmentId"
                exact
                component={AmendmentPrint}
              />
              <PrivateRoute path="/cer-list" exact component={CERList} />
              <PrivateRoute path="/test" exact component={Test} />
              <PrivateRoute
                path="/recipients-stock"
                exact
                component={RecipientStockList}
              />
              <PrivateRoute
                path="/recipients-stock/:id"
                exact
                component={RecipientStockDetail}
              />
              <PrivateRoute
                path="/all-recipients"
                exact
                component={AllRecipients}
              />
              {/* <PrivateRoute
                path="/recipients-ep-results"
                exact
                component={RecipientEpOutcomeList}
              /> */}
              <Route component={NotFound} />
            </Switch>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <CustomModal />
      <CustomModal2 />
      <SidePanel />
      <LeftPanel />
    </Router>
  );
};

export default App;
