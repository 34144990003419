import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import dataService from "../../../../helpers/dataService";
import Loader from "../../../common/Loader";
import PVDoc from "./PVDoc";
import PVAnnexes from "./PVAnnexes";
import PVEmargement from "./PVEmargement";
import useStore from "../../../../context/useStore";
import colors from "../../../../helpers/colors";

const MyDoc = ({ datas, recipients, orgas, constants, isPreview }) => {
  var styles = StyleSheet.create({
    title: {
      fontSize: 18,
      fontWeight: "bold",
      paddingTop: 20,
      paddingBottom: 10,
      textAlign: "center",
      color: colors.primary,
    },
    info: {
      fontSize: 11,
      paddingBottom: 5,
    },
    tableRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
    },
    tableRowCustom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
    },
    tableHead: {
      textAlign: "center",
      color: "white",
      backgroundColor: "black",
    },
    tableCell2: {
      display: "flex",
      width: "49.99%",
      borderBottomWidth: 1,
      borderColor: "black",
      borderBottomStyle: "solid",
      padding: 3,
      fontSize: 11,
      height: "100%",
    },
    tableCell3: {
      display: "flex",
      width: "33.33%",
      borderBottomWidth: 1,
      borderColor: "black",
      borderBottomStyle: "solid",
      padding: 3,
      fontSize: 11,
      height: "100%",
    },
    tableCell4: {
      display: "flex",
      width: "24.99%",
      borderBottomWidth: 1,
      borderColor: "black",
      borderBottomStyle: "solid",
      padding: 3,
      fontSize: 11,
      height: "100%",
    },
    tableCell17: {
      display: "flex",
      width: "8.33%",
      borderBottomWidth: 1,
      borderColor: "black",
      borderBottomStyle: "solid",
      padding: 3,
      fontSize: 8,
      height: "100%",
    },
    textCenter: {
      textAlign: "center",
    },
    tableColumn: {
      display: "flex",
      flexDirection: "column",
    },
  });

  const title = (
    <>
      <View>
        <Text>PROCES VERBAL DE L'EQUIPE PLURIDISCIPLINAIRE</Text>
      </View>

      <View>
        <Text style={{ color: "black", fontSize: 13 }}>
          SECTEUR {datas.sector.name} -{" "}
          {DateTime.fromISO(datas.beginAt).setLocale("FR").toFormat("dd LLLL yyyy")}
          {" - "}
        </Text>
      </View>
      <View>
        <Text style={{ color: "black", fontSize: 13 }}>
          {datas.address1} {datas.address2} {datas.postalCode} {datas.city}
        </Text>
      </View>
      {isPreview && (
        <View
          style={{
            display: "block",
            width: "100%",
            backgroundColor: "orange",
            color: "white",
          }}
        >
          <Text
            style={{
              fontSize: 14,
              display: "block",
              width: "100%",
              backgroundColor: "orange",
            }}
          >
            PRÉVISUALISATION D'UN PV NON FINALISÉ
          </Text>
        </View>
      )}
    </>
  );

  return (
    <Document>
      <PVDoc
        styles={styles}
        datas={datas}
        recipients={recipients}
        orgas={orgas}
        title={title}
        constants={constants}
      />
      {/* <PVEmargement
        styles={styles}
        datas={datas}
        recipients={recipients}
        orgas={orgas}
        constants={constants}
        title={title}
      /> */}
      <PVAnnexes
        styles={styles}
        datas={datas}
        recipients={recipients}
        orgas={orgas}
        constants={constants}
        title={title}
      />
    </Document>
  );
};

const EpPv = (props) => {
  const [isInit, setIsInit] = useState(false);
  const [datas, setDatas] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [orgas, setOrgas] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [docReady, setDocReady] = useState(false);
  const [displayPreview, setDisplayPreview] = useState(false);
  const [pdfKey, setPdfKey] = useState(1);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  useEffect(() => {
    setIsLoading(true);

    if (props.previewData) {
      setDatas(props.previewData);
      fetchRecipients(props.previewData.id);
    } else {
      const epId = props.match.params.id;

      dataService.get(
        `eps/${epId}/pv`,
        (datas) => {
          setDatas(datas);
          fetchRecipients(epId);
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  }, []);

  const fetchRecipients = (epId) => {
    dataService.get(
      `eps/${epId}/recipients`,
      (datas) => {
        setRecipients(datas);

        dataService.get(
          `organizations`,
          (datas) => {
            setOrgas(datas);

            setIsInit(true);
          },
          setErrors,
          () => setIsLoading(false)
        );
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  return (
    <div key={pdfKey}>
      {isLoading || !isInit ? (
        <Loader />
      ) : errors.other ? (
        <div className="alert alert-danger">{errors.other}</div>
      ) : !datas ? null : (
        <div
          className="text-center pt-3 bg-white pb-3 px-3 mt-2 d-flex justify-content-center align-items-center flex-column animated fadeIn  faster"
          style={{ gap: "1rem" }}
        >
          <h4>
            PROCES VERBAL DE L'EQUIPE PLURIDISCIPLINAIRE DU SECTEUR {datas.sector.name}{" "}
            {DateTime.fromISO(datas.beginAt).setLocale("FR").toFormat("dd LLLL yyyy")} À{" "}
            {datas.beginHourAt}H{datas.beginMinuteAt || ""}
          </h4>
          <PDFDownloadLink
            className="btn btn-primary dl-po-btn mb-2 animated zoomIn  faster"
            document={
              <MyDoc
                datas={datas}
                recipients={recipients}
                orgas={orgas}
                constants={constants}
                isPreview={props.previewData}
              />
            }
            fileName={`PV_EP_${DateTime.fromISO(datas.startedAt)
              .setLocale("FR")
              .toFormat("dd-LLLL-yyyy")}_${datas.sector.name}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              var text = loading ? (
                "Chargement..."
              ) : (
                <>
                  <i className="fa fa-download mr-2" />
                  Télécharger le PV
                </>
              );
              if (error) {
                setPdfKey(pdfKey + 1);
              }
              if (!loading) {
                setDocReady(true);
              }
              return <>{text}</>;
            }}
          </PDFDownloadLink>
          {docReady && (
            <button
              className="btn btn-info mb-2 animated zoomIn faster"
              onClick={() => setDisplayPreview(!displayPreview)}
            >
              <i className={`fa mr-2 ${displayPreview ? "fa-eye-slash" : "fa-eye"}`} />
              {displayPreview ? "Fermer" : "Voir dans"} la visionneuse
            </button>
          )}
          {displayPreview && (
            <div
              className="animated fadeIn fast"
              style={{
                width: "100%",
                height: 600,
              }}
            >
              <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
                <MyDoc
                  datas={datas}
                  recipients={recipients}
                  orgas={orgas}
                  isPreview={props.previewData}
                  constants={constants}
                />
              </PDFViewer>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EpPv;
