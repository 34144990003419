import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { api_url } from "../../../config";
import PostalCode from "../../common/PostalCode";
import SaveBtn from "../../common/SaveBtn";

import DynamicList from "../../common/DynamicList";
import { toast } from "react-toastify";

const SiteDetail = (props) => {
  const [state, dispatch] = useStore();

  const [site, setSite] = useState({
    organization: "",
    name: "",
    address: "",
    cityId: "",
    location: { postalCode: "", name: "" },
    boxes: [],
    locations: [],
    closingPeriods: [],
    closingHolidays: [],
    contacts: [],
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedTown, setSelectedTown] = useState("");
  const holidays = state.constants.items ? state.constants.items.HOLIDAYS : [];

  useEffect(() => {
    if (holidays && holidays.length > 0 && props.match.params.id == "create") {
      setSite({ ...site, closingHolidays: holidays.map((h) => h.id) });
    }
  }, [holidays]);

  useEffect(() => {
    Axios.get(api_url + "organizations")
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((err) => {});
    Axios.get(api_url + "cities/reunion")
      .then((res) => {
        setLocations(filterTowns(res.data));
      })
      .catch((err) => {});
    if (props.match.params.id != "create") {
      Axios.get(api_url + "sites/" + props.match.params.id)
        .then((res) => {
          res.data.cityId = res.data.location ? res.data.location.cityId : "";
          setSite(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        });
    }
  }, []);

  function filterTowns(locations) {
    var loc = [];
    locations.forEach((l) => {
      if (!loc.find((lc) => lc.townName == l.townName)) {
        loc.push({
          townName: l.townName,
          postalCodes: [],
        });
      }
      loc.find((t) => t.townName == l.townName).postalCodes.push(l);
    });
    return loc;
  }

  function updateData(e) {
    setIsSaved(false);
    var value = e.target.value;
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setSite({ ...site, [e.target.name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);

    setErrors({});
    if (props.match.params.id == "create") {
      //create
      Axios.post(api_url + "sites/", site)
        .then((res) => {
          setSite(res.data);
          setIsSaving(false);
          setIsSaved(true);
          toast.success("Le site a bien été créé");
          window.location.href = "/sites/" + res.data.id;
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          setIsSaving(false);
        });
    } else {
      //update
      Axios.patch(api_url + "sites/" + props.match.params.id, site)
        .then((res) => {
          res.data.cityId = res.data.location.cityId;
          setSite(res.data);
          setIsSaving(false);
          setIsSaved(true);
          toast.success("Le site a bien été modifié");
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }

          setIsSaving(false);
        });
    }
  }

  function changeLocation(e) {
    var locs = JSON.parse(JSON.stringify(site.locations));
    var { name, value } = e.target;
    if (locs.includes(value)) {
      locs = locs.filter((l) => l != value);
    } else {
      locs.push(value);
    }
    setSite({ ...site, locations: locs });
  }
  function changeClosingHolidays(e) {
    var locs = JSON.parse(JSON.stringify(site.closingHolidays));
    var { name, value } = e.target;
    if (locs.includes(value)) {
      locs = locs.filter((l) => l != value);
    } else {
      locs.push(value);
    }
    setSite({ ...site, closingHolidays: locs });
  }

  function addAll() {
    var locs = JSON.parse(JSON.stringify(site.locations));
    var allLocs = locations.find((loc, lock) => loc.townName == selectedTown).postalCodes;

    allLocs.forEach((loc, lock) => {
      if (!locs.find((l) => l == loc.id)) {
        locs.push(loc.id);
      }
    });
    setSite({ ...site, locations: locs });
  }
  function removeAll() {
    var locs = JSON.parse(JSON.stringify(site.locations));
    var allLocs = locations.find((loc, lock) => loc.townName == selectedTown).postalCodes;

    locs = locs.filter((l) => {
      if (allLocs.find((al) => al.id == l)) {
        return false;
      }
      return true;
    });
    setSite({ ...site, locations: locs });
  }

  return state.constants.items.length == 0 || isLoading || organizations.length == 0 ? (
    <Loader />
  ) : (
    <Page container={"container-fluid px-5"} title="Détail site" errors={errors} back={"/sites"}>
      <Control
        type="select"
        label="Organisation"
        name="organization"
        value={site.organization}
        datas={organizations}
        change={updateData}
        dataLabel="name"
        dataIndex="id"
        error={errors}
      />

      <Control label="Nom" name="name" value={site.name} change={updateData} error={errors} />
      <Control
        label="Adresse"
        name="address"
        value={site.address}
        change={updateData}
        error={errors}
      />
      <PostalCode
        value={site.cityId}
        name="cityId"
        updateData={updateData}
        inputLabel="Code postal"
        error={errors}
        location={site.location}
      />

      <DynamicList
        listLabel={"Boxs de réception des personnes"}
        name="boxes"
        fields={[{ label: "Nom du box", type: "text", name: "name" }]}
        items={site.boxes}
        change={updateData}
        btnLabel={"Ajouter une box"}
        minItems={1}
        error={errors}
      />

      <div className="row mb-2">
        <div className="col-12 mb-2">
          <label>Sélectionnez les communes puis les codes postaux fédérés par ce site :</label>
        </div>
        <div className="col-12">
          <ul className="list-group d-flex flex-row flex-wrap justify-content-start align-items-center">
            {locations.map((loc, lock) => (
              <li key={"loc" + lock} className="list-group-item p-0 border-0 m-1">
                <button
                  className={
                    "btn w-100 d-flex align-items-center justify-content-between btn-sm " +
                    (loc.townName == selectedTown ? "btn-primary" : "btn-default")
                  }
                  onClick={() => setSelectedTown(loc.townName)}
                >
                  {loc.townName}

                  {loc.postalCodes.filter((l) => site.locations.includes(l.id)).length > 0 && (
                    <span className="badge badge-primary ml-2">
                      {loc.postalCodes.filter((l) => site.locations.includes(l.id)).length}
                    </span>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-12 mt-3">
          {selectedTown != "" && (
            <>
              <button className="btn btn-dark btn-sm mr-2" onClick={addAll}>
                Tout ajouter
              </button>
              <button className="btn btn-dark btn-sm" onClick={removeAll}>
                Tout retirer
              </button>
              <Control
                label="Sélectionnez les zones"
                type="checkBtnList"
                btnSm
                datas={locations.find((loc, lock) => loc.townName == selectedTown).postalCodes}
                change={changeLocation}
                value={site.locations}
                name="locations"
                dataLabel="locationName"
                dataLabel2="postalCode"
                dataLabelDefault="name"
                error={errors}
              />
            </>
          )}
        </div>
      </div>
      <DynamicList
        listLabel="Périodes de fermeture"
        btnLabel="Ajouter une période"
        uniqueKey="pf-1"
        name="closingPeriods"
        fields={[
          {
            label: "Nom",
            name: "name",
            type: "text",
            placeholder: "Vacances d'été",
          },
          {
            label: "Entre le",
            name: "beginAt",
            type: "date",
          },
          {
            label: "et le",
            name: "endAt",
            type: "date",
          },
        ]}
        items={
          site.closingPeriods
            ? site.closingPeriods.map((s) => {
                s.beginAt = DateTime.fromISO(s.beginAt).toFormat("yyyy-MM-dd");
                s.endAt = DateTime.fromISO(s.endAt).toFormat("yyyy-MM-dd");
                return s;
              })
            : []
        }
        change={updateData}
        error={errors}
      />
      <Control
        label="L'établissement est fermé les jours fériés suivants (cochés)"
        type="checkBtnList"
        btnSm
        datas={holidays}
        change={changeClosingHolidays}
        value={site.closingHolidays}
        name="closingHolidays"
        error={errors}
      />

      <DynamicList
        listLabel="Contacts"
        btnLabel="Ajouter un contact"
        uniqueKey="pf-1"
        name="contacts"
        fields={[
          {
            label: "Nom",
            name: "lastname",
          },
          {
            label: "Prénom",
            name: "firstname",
          },
          {
            label: "Email",
            name: "email",
          },
          {
            label: "Téléphone",
            name: "phone",
          },
        ]}
        items={site.contacts ? site.contacts : []}
        change={updateData}
        error={errors}
      />
      <div className="d-flex justify-content-end">
        <SaveBtn save={save} isSaving={isSaving} isSaved={isSaved} />
      </div>
    </Page>
  );
};

export default SiteDetail;
