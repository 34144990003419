import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import colors from "../../../../../../helpers/colors";
import tools from "../../../../../../helpers/tools";
import useCERModule from "../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "./ObjectiveContext";
import AddStepForm from "./ObjectiveItem/AddStepForm/AddStepForm";
import ObjectiveEdit from "./ObjectiveItem/ObjectiveEdit";
import ObjectiveView from "./ObjectiveItem/ObjectiveView";
import StepsList from "./ObjectiveItem/StepsList/StepsList";

const objectiveDragHandleWidth = 30;

const ObjectiveHandle = ({ dragHandleProps }) => {
  return (
    <div
      className="d-flex align-items-start justify-content-center pt-2 text-white"
      style={{
        background: colors.info,
        width: objectiveDragHandleWidth,
        position: "absolute",
        left: 0,
        top: 0,
        height: "calc(100% - 36px)",
        borderTopRightRadius: 10,
      }}
      {...dragHandleProps}
    >
      <i className="fa fa-grip-vertical" />
    </div>
  );
};

const ObjectivesList = ({ pathType }) => {
  const { recipientCER, events } = useCERModule();
  const [goalEditingId, setGoalEditingId] = useState(null);

  const objectivesOfPath = recipientCER.goals.filter((g) => g.pathType == pathType.id);

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return false;
    }

    //SI ON A DROP UN OBJECTIF
    if (result.type == "OBJECTIVES") {
      const items = Array.from(recipientCER.goals);

      //on remap l'index du tableau d'objectifs car result.source.index correspond à l'index dans l'array d'objectifs filtré par pathType
      var realSourceIndex = tools.getObjectiveIndex(
        recipientCER.goals,
        objectivesOfPath[result.source.index]
      );
      var realDestinationIndex = tools.getObjectiveIndex(
        recipientCER.goals,
        objectivesOfPath[result.destination.index]
      );
      const reorderedItems = tools.reorderItems(items, realSourceIndex, realDestinationIndex);

      events.onUpdateObjectives(reorderedItems);
    } else {
      //SI ON A DROP UN STEP
      const objectiveData = result.type.split("-"); //[0] contient pathType, [1] contient l'id de l'objectif
      const objectiveObj = recipientCER.goals.find(
        (g) => g.goal == objectiveData[1] && g.pathType == objectiveData[0]
      );
      const items = Array.from(objectiveObj.steps);
      const reorderedItems = tools.reorderItems(
        items,
        result.source.index,
        result.destination.index
      );

      const objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objectiveObj);
      events.onUpdateObjective({ ...objectiveObj, steps: reorderedItems }, objectiveIndex);
    }
  };

  const goToViewMode = () => {
    setGoalEditingId(null);
  };

  const goToEditMode = (goalId) => {
    setGoalEditingId(goalId);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="objectives" type="OBJECTIVES">
        {(provided) => (
          <ul
            className="list-group objectives"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {objectivesOfPath.map((objective, obk) => {
              const objectiveKey = `obj${pathType.id}${objective.goal}`;
              const isBeingEdited = goalEditingId == objective.goal;
              return (
                <Draggable key={objectiveKey} draggableId={objectiveKey} index={obk}>
                  {(provided) => (
                    <li
                      className="list-group-item border-0 p-0"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <ObjectiveContext.Provider value={objective}>
                        <div
                          className="pt-2 px-3"
                          style={{
                            borderLeft: `${objectiveDragHandleWidth}px solid transparent`,
                          }}
                        >
                          <ObjectiveHandle dragHandleProps={provided.dragHandleProps} />
                          {isBeingEdited ? (
                            <ObjectiveEdit goToViewMode={goToViewMode} />
                          ) : (
                            <ObjectiveView goToEditMode={() => goToEditMode(objective.goal)} />
                          )}

                          <StepsList />
                        </div>
                        <div
                          className="pr-2 pt-0 pl-5"
                          style={{
                            borderLeft: `1px dashed ${colors.info}`,
                          }}
                        >
                          <AddStepForm />
                        </div>
                      </ObjectiveContext.Provider>
                    </li>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ObjectivesList;
