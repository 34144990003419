import React from "react";

const StatContainer = ({ title, description, index, ...props }) => {
  return (
    <div
      className="custom-card w-100 mb-2 animated fadeInUp faster"
      style={{
        animationDelay: `${index * 100}ms`,
      }}
    >
      <h5 className="text-left">{title}</h5>
      <div className="font-weight-light py-2">{description}</div>
      {props.children}
    </div>
  );
};

export default StatContainer;
