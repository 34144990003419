import React from "react";
import useStore from "../../../../../../context/useStore";
import StatusIcon from "./StatusIcon";

const SelectStatus = ({ onSelectStatus, type = "GOAL" }) => {
  const [state] = useStore();

  const { CER_GOAL_STATUSES, CER_STEP_STATUSES, CER_OBSTACLE_STATUSES } = state.constants.items;

  const statuses =
    type == "GOAL" ? CER_GOAL_STATUSES : type == "STEP" ? CER_STEP_STATUSES : CER_OBSTACLE_STATUSES;

  return (
    <div
      style={{
        position: "absolute",
        bottom: 40,
        left: 0,
        zIndex: "12",
        width: 170,
      }}
    >
      <div
        className="bg-white shadow rounded d-flex flex-column align-items-start p-2"
        style={{ gap: 5 }}
      >
        {statuses.map((status) => {
          return (
            <StatusIcon
              status={status.id}
              onClick={() => onSelectStatus(status.id)}
              key={`goalstatus${status.name}`}
              text={status.name}
              size={28}
              type={type}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SelectStatus;
