import React, { useEffect, useState } from "react";
import Control from "../../../../../../common/Control";
import dataService from "../../../../../../../helpers/dataService";
import useStoreState from "../../../Context/Store/useStoreState";
import OldEpDetailsReo from "./OldEpDetailsReo";

const DecisionReorientationForm = ({ avisEp, setAvisEp, recipient, isClosed, orgas }) => {
  const { state, gState, items } = useStoreState();

  const [lockPartners, setLockPartners] = useState(false);

  const { ORIENTATION_COURSE_TYPES, REORIENTATION_TYPES } = gState.constants.items;

  const isDirector = gState.auth.user.role == "CAF_DIRECTOR" || gState.auth.user.isDirector;
  var isLocked = (!isDirector && isClosed) || items.ep.endedAt;

  useEffect(() => {
    let dec = avisEp.reorientation.reorientationType;
    switch (dec) {
      case "ORIENTATION_KEPT":
        setLockPartners(true);

        setAvisEp({
          ...avisEp,
          reorientation: {
            ...avisEp.reorientation,
            partner: avisEp.reorientation?.orientation?.fromPartner,
          },
        });
        break;
      case "REORIENTATION_ACCEPTED":
        setLockPartners(true);
        setAvisEp({
          ...avisEp,
          reorientation: {
            ...avisEp.reorientation,
            partner: avisEp.reorientation?.orientation?.toPartner,
          },
        });
        break;

      case "NEW_REORIENTATION":
        setLockPartners(false);
        setAvisEp({
          ...avisEp,
          reorientation: {
            ...avisEp.reorientation,
            partner: null,
          },
        });
        break;
    }
  }, [avisEp.reorientation.reorientationType]);

  return (
    <div className="position-relative">
      {isLocked ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0,0,0,0)",
            width: "100%",
            height: "100%",
            zIndex: "999",
          }}
        ></div>
      ) : null}
      <Control
        disabled={isLocked ? true : false}
        label="Avis concernant l'orientation"
        type="btnList"
        btnInline
        name="reorientationType"
        datas={REORIENTATION_TYPES}
        dataIndex="id"
        dataLabel="name"
        value={avisEp.reorientation?.reorientationType}
        change={(e) =>
          setAvisEp({
            ...avisEp,
            reorientation: {
              ...avisEp.reorientation,
              reorientationType: e.target.value,
            },
          })
        }
      />

      <OldEpDetailsReo oldEpDetails={recipient.oldEpDetails} />

      <Control
        disabled={isLocked || lockPartners ? true : false}
        label="Partenaire vers lequel orienter l'allocataire"
        type="btnList"
        btnInline
        name="partner"
        datas={orgas.filter((og) => !["CAF_EG", "ML"].includes(og.slugName))}
        dataIndex="id"
        dataLabel="name"
        value={avisEp.reorientation?.partner}
        change={(e) =>
          setAvisEp({
            ...avisEp,
            reorientation: { ...avisEp.reorientation, partner: e.target.value },
          })
        }
      />
      <Control
        disabled={isLocked ? true : false}
        label="Choix concernant le type de parcours"
        type="btnList"
        btnInline
        name="courseType"
        datas={ORIENTATION_COURSE_TYPES}
        dataIndex="id"
        dataLabel="name"
        value={avisEp.reorientation?.courseType}
        change={(e) =>
          setAvisEp({
            ...avisEp,
            reorientation: {
              ...avisEp.reorientation,
              courseType: e.target.value,
            },
          })
        }
      />
      <Control
        disabled={isClosed ? true : false}
        label="Note interne"
        type="textarea"
        name="note"
        value={avisEp.note}
        change={(e) => {
          setAvisEp({
            ...avisEp,

            note: e.target.value,
          });
        }}
      />
    </div>
  );
};

export default DecisionReorientationForm;
