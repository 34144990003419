import React, { useState } from "react";
import Control from "../common/Control";

const dayData = [
  { id: "MO", name: "Lun" },
  { id: "TU", name: "Mar" },
  { id: "WE", name: "Mer" },
  { id: "TH", name: "Jeu" },
  { id: "FR", name: "Ven" },
];
const monthData = [];
for (var i = 1; i <= 31; i++) {
  monthData.push({ id: i, name: i });
}

const DaySelector = ({ slotCalendarConfig, updateRec, errors }) => {
  const frequencyLabels = {
    DAILY: "jours ouvrés",
    WEEKLY: "semaines",
    MONTHLY: "mois",
  };

  function updateCustomBy(e) {
    var { name, value } = e.target;
    var ev = JSON.parse(JSON.stringify(slotCalendarConfig));
    if (name == "byMinutes") {
      ev[name] = value;
    } else {
      if (ev[name].includes(value)) {
        ev[name] = ev[name].filter((v) => v != value);
      } else {
        ev[name].push(value);
      }
    }
    updateRec({ target: { name: name, value: ev[name] } });
  }

  return (
    <div>
      {slotCalendarConfig.frequency == "MINUTELY" ? (
        <Control
          label={"Répéter toutes les combien de minutes ?"}
          type="number"
          margin={"my-2 mt-3"}
          name="byMinutes"
          value={slotCalendarConfig.byMinutes}
          change={updateCustomBy}
          error={errors}
        />
      ) : (
        <Control
          label="Tous(tes) les"
          type="number"
          name="interval"
          margin={"my-2 mt-3"}
          value={slotCalendarConfig.interval}
          change={updateRec}
          suffix={
            <span className="d-flex align-items-center pl-2">
              {frequencyLabels[slotCalendarConfig.frequency]}
            </span>
          }
          error={errors}
        />
      )}
      {slotCalendarConfig.frequency == "WEEKLY" && (
        <Control
          label={"Jours de répétition"}
          type="checkBtnList"
          margin={"my-2 mt-3"}
          name="byDayOfWeek"
          btnInline={true}
          value={slotCalendarConfig.byDayOfWeek}
          datas={dayData}
          change={updateCustomBy}
          error={errors}
        />
      )}
      {slotCalendarConfig.frequency == "MONTHLY" && (
        <Control
          label={"Jours de répétition"}
          type="checkBtnList"
          margin={"my-2 mt-3"}
          name="byDayOfMonth"
          btnInline={true}
          value={slotCalendarConfig.byDayOfMonth}
          datas={monthData}
          change={updateCustomBy}
          inputStyle={{
            flex: "14%",
            maxWidth: "14%",
          }}
          error={errors}
        />
      )}
    </div>
  );
};

export default DaySelector;
