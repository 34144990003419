import React, { useEffect, useState } from "react";
import tools from "../../../../../../../helpers/tools";
import Control from "../../../../../../common/Control";
import DynamicList from "../../../../../../common/DynamicList";
import SaveBtn from "../../../../../../common/SaveBtn";
import useStoreState from "../../../Context/Store/useStoreState";
import DecisionSanctionForm from "./DecisionSanctionForm";
import OldEpDetails from "./OldEpDetails";

const DecisionSanction = () => {
  const { state, gState, items, items_actions } = useStoreState();

  const recipient = items.utils.currentAlloc;
  const avisEp = recipient.EPNotice;

  const setAvisEp = (updatedAvis) => {
    items_actions.updateCurrentAlloc({ ...recipient, EPNotice: updatedAvis });
  };

  const getSanctionSentence = () => {
    return tools.getSanctionSentence(avisEp, gState.constants.items);
  };

  const isDirector = gState.auth.user.role == "CAF_DIRECTOR" || gState.auth.user.isDirector;

  return (
    <div>
      <DecisionSanctionForm avisEp={avisEp} setAvisEp={setAvisEp} recipient={recipient} isClosed={items.ep.closedAt} />
      {items.ep.closedAt && !isDirector ? (
        <div className="alert alert-success">
          <div>Avis de l'EP</div>
          <p>{getSanctionSentence()}</p>
        </div>
      ) : (
        <SaveBtn
          className="w-100 d-center"
          text={
            <div className="d-center flex-column">
              <div>{getSanctionSentence()}</div>
              <strong style={{ fontSize: 18 }}>Valider la décision</strong>
            </div>
          }
          save={items_actions.saveDecision}
          isSaving={state.isLoadingDecision}
        />
      )}
    </div>
  );
};

export default DecisionSanction;
