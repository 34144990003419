import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap";
import "./variables.scss";
import "./animate.css";
import "react-vertical-timeline-component/style.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Store from "./context/Store";
import rootReducer from "./context/rootReducer";
import ErrorBoundary from "./helpers/ErrorBoundary";

ReactDOM.render(
  <Store rootReducer={rootReducer}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Store>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
