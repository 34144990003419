import React, { useContext } from "react";
import tools from "../../../../../../../../helpers/tools";
import useCERData from "../../../../../../../Hooks/useCERData/useCERData";
import useCERModule from "../../../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "../../ObjectiveContext";
import { StepContext } from "./StepContext";
import StepStatus from "./StepStatus";

const StepView = ({ goToEditMode }) => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const { cerSteps } = useCERData();
  const { recipientCER, events, isRecipientCERDraft } = useCERModule();
  const stepRef = cerSteps.find((o) => o.id == step.step);
  var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);

  const deleteStep = () => {
    const newObjective = {
      ...objective,
      steps: objective.steps.filter((s, sk) => sk != stepIndex),
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-between ${
        isRecipientCERDraft ? "item-list-view" : "pb-2"
      }`}
    >
      <div>
        {!isRecipientCERDraft && <StepStatus />}
        <strong className="text-primary">{stepRef?.name}</strong>
      </div>

      {isRecipientCERDraft && (
        <div>
          <button className="btn btn-sm" onClick={goToEditMode}>
            <i className="fa fa-edit text-primary" />
          </button>
          <button className="btn btn-sm" onClick={deleteStep}>
            <i className="fa fa-times text-primary" />
          </button>
        </div>
      )}
    </div>
  );
};

export default StepView;
