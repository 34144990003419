import React from "react";
import Page from "../../common/layout/Page";
import EPCalendar from "./EPCalendar";

const EP = () => {
  return (
    <Page bgTransparent container={"container-fluid "} title="Liste des EP">
      <div className="mx-auto">
        <EPCalendar />
      </div>
    </Page>
  );
};

export default EP;
