import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import SaveBtn from "../../../common/SaveBtn";

const Jury = ({ ep, myjury, allMembers, sectors, fetchJuryDatas }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [jury, setJury] = useState(myjury);
  const [orgas, setOrgas] = useState([]);

  useEffect(() => {
    getOrgas();
  }, []);

  const addMember = (member) => {
    setJury((jury) => [...jury, { userManager: member }]);
  };
  const removeMember = (member) => {
    setJury((jury) => jury.filter((j) => j.userManager.id != member.id));
  };

  const getOrgas = () => {
    dataService.get(`organizations`, setOrgas);
  };

  const save = () => {
    var saveObj = jury.map((j) => ({ userManager: j.userManager.id }));

    setIsSaving(true);
    dataService.patch(
      `eps/${ep.id}/jury`,
      saveObj,
      (datas) => {
        toast.success("Jury enregistré avec succès");
        fetchJuryDatas();
      },
      setErrors,
      () => setIsSaving(false)
    );
    //PATCH eps/${ep.id}/jury
    //[...membersIds]
  };

  var sectorName = sectors.length ? sectors.find((s) => s.id == ep.sector).name : "";

  return (
    <div className="row">
      {/* <div className="col-12 col-md-6 border-right">
        <h4
          className=" d-flex w-100 justify-content-between align-items-center"
          style={{ fontSize: 16 }}
        >
          Tous les utilisateurs secteur {sectorName}
          <div
            className="d-center rounded-circle bg-dark text-white"
            style={{
              width: 40,
              height: 40,
            }}
          >
            {allMembers.userManagers?.length || ".."}
          </div>
        </h4>
        <ul className="list-group">
          {allMembers.userManagers
            ?.filter((m) => !jury.find((me) => me.userManager.id == m.id))
            .map((m) => {
              var user = m;
              return (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center p-2 border-top-0 border-left-0 border-right-0 border-bottom mb-1 rounded-0"
                  key={`memberun${user.id}`}
                >
                  <div>{tools.getFullname(user)}</div>
                  <div>
                    <button
                      className="btn btn-default"
                      onClick={() => addMember(user)}
                    >
                      <i className="fa fa-angle-double-right" />
                    </button>
                  </div>
                </li>
              );
            }) || null}
        </ul>
      </div> */}
      <div className="col-12">
        <h4
          style={{ fontSize: 16 }}
          className="text-success d-flex w-100 justify-content-between align-items-center"
        >
          Utilisateurs inscrits à cet EP
          <div
            className="d-center rounded-circle bg-success text-white"
            style={{
              width: 40,
              height: 40,
            }}
          >
            {jury.length}
          </div>
        </h4>
        <div className="my-2 border-bottom pb-2 font-weight-bold">
          {constants.INVITATION_STATUS.map((inv) => (
            <div className={` mr-2 text-${inv.color}`}>
              {jury.filter((j) => j.invitationStatus == inv.id).length} {inv.name}
            </div>
          ))}
        </div>
        <ul className="list-group">
          {jury.map((j) => {
            var user = j.userManager;
            return (
              <li
                className="list-group-item d-flex flex-row  justify-content-between align-items-center p-2 border-top-0 border-left-0 border-right-0 border-bottom mb-1 rounded-0"
                key={`members${user.id}`}
              >
                <div className="d-flex justify-content-between align-items-center flex-row w-100">
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => removeMember(user)}
                  >
                    <i className="fa fa-angle-double-left" />
                  </button> */}
                  <div>
                    {!allMembers.userManagers?.find((u) => u == user.id) && (
                      <i
                        className="fa fa-exclamation-circle text-danger cursor-pointer"
                        title="L'utilisateur ne fait plus partie de ce secteur"
                        onClick={() => alert("L'utilisateur ne fait plus partie de ce secteur.")}
                      />
                    )}{" "}
                    {tools.getFullname(user)}
                    <div>
                      <small>{user.phone}</small>
                    </div>
                    <div>
                      {orgas.length
                        ? user.organizations.map((or) => (
                            <small className="badge badge-light">
                              {orgas.find((org) => org.id == or).name}
                            </small>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="">
                    {user.invitationStatus && (
                      <i
                        className={`fa ml-2 fa-${
                          constants.INVITATION_STATUS.find((inv) => inv.id == user.invitationStatus)
                            .icon
                        } text-${
                          constants.INVITATION_STATUS.find((inv) => inv.id == user.invitationStatus)
                            .color
                        }`}
                        onClick={() =>
                          alert(
                            constants.INVITATION_STATUS.find(
                              (inv) => inv.id == user.invitationStatus
                            ).name
                          )
                        }
                      />
                    )}
                    {j.invitationSentAt ? (
                      <div className="d-center flex-row align-items-center" style={{ fontSize: 9 }}>
                        <i className={`fa fa-paper-plane text-success mr-1`} />
                        invité•e le {DateTime.fromISO(j.invitationSentAt).toFormat("dd/MM/yyyy")}
                        <span
                          style={{
                            fontSize: 12,
                          }}
                          className={`ml-2 badge badge-${
                            constants.INVITATION_STATUS.find((inv) => inv.id == j.invitationStatus)
                              .color
                          }`}
                        >
                          {
                            constants.INVITATION_STATUS.find((inv) => inv.id == j.invitationStatus)
                              .name
                          }
                        </span>
                      </div>
                    ) : (
                      <div className="d-center flex-row align-items-center" style={{ fontSize: 9 }}>
                        <i className={`fa fa-bell-slash text-default  mr-1`} />
                        pas encore invité•e
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {/* <div className="col-12 d-flex w-100 justify-content-center">
        <SaveBtn save={save} isSaving={isSaving} />
      </div> */}
    </div>
  );
};

export default Jury;
