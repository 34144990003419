import { atom } from "jotai";

export const CERModel = {
  userRecipient: "",
  beginAt: "",
  endAt: "",
  duration: "TWELVE_MONTHS",
  status: "DRAFT",
  goals: [],
  means: [],
};
export const recipientCERAtom = atom(JSON.parse(JSON.stringify(CERModel)));
export const currentRecipientAtom = atom(null);
export const isRecipientCERDraftAtom = atom((get) => {
  return get(recipientCERAtom).status == "DRAFT" ? true : false;
});
export const currentCERViewAtom = atom("CER_LIST"); //CER_EDIT
export const isLoadingCERAtom = atom(false);
export const isLoadingCERGoalAtom = atom(false);
export const cerErrorsAtom = atom({});
export const isSavingCERMeansAtom = atom(false);
