import React from "react";
import useStore from "../../../context/useStore";

const CalendarStats = ({ events }) => {
  const [state, dispatch] = useStore();

  var slotTypes =
    state.constants.items && state.constants.items.SLOT_TYPES
      ? state.constants.items.SLOT_TYPES
      : [];

  return (
    <div className="row mb-2">
      <div className="col-12">
        <ul className="list-group">
          {slotTypes.map((st) => (
            <li className="list-group-item d-flex flex-row justify-content-between">
              <label className="mb-0">{st.name}</label>
              <div className="d-flex flex-row justify-content-around">
                <div className="mr-2">
                  TOUS : {events.filter((ev) => ev.type == st.id).length}
                </div>
                <div className="mr-2">
                  LIBRES :{" "}
                  {
                    events.filter((ev) => ev.type == st.id && !ev.userRecipient)
                      .length
                  }
                </div>
                <div className="mr-2">
                  RÉSERVÉS :{" "}
                  {
                    events.filter((ev) => ev.type == st.id && ev.userRecipient)
                      .length
                  }
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CalendarStats;
