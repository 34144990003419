import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";

const MutationModal = ({
  recipient,
  onSaved = () => {},
  organizationMutationAllowed = {},
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const [errors, setErrors] = useState({});
  const [avis, setAvis] = useState({
    organismePrenantId: "",
  });

  useEffect(() => {
    onGetOrganizations();
  }, []);

  const { organizations, onGetOrganizations } = useOrganizations();

  const save = () => {
    setIsSaving(true);
    dataService.patch(
      `recipients/${recipient.id}/orientation/mutate`,
      {
        organismePrenantId: avis.organismePrenantId,
      },
      (datas) => {
        toast.success("La mutation de l'allocataire a bien été effectuée");
        onSaved();
        mActions.updateModal({
          isOpen: false,
          content: null,
          noClose: false,
        });
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  let filteredOrga = organizations.filter(
    (o) => o.slugName == organizationMutationAllowed.code
  );

  return (
    <div className="custom-card">
      <div className="d-flex justify-content-start w-100 flex-column">
        <h4 style={{ fontSize: 16 }}>
          Demander la mutation de l'allocataire {tools.getFullname(recipient)}
        </h4>

        <Control
          label="Vers quel organisme souhaitez-vous muter ?"
          type="btnList"
          name="organization"
          datas={filteredOrga}
          change={(e) =>
            setAvis({ ...avis, organismePrenantId: e.target.value })
          }
          value={avis.organismePrenantId}
        />

        <div className="d-flex justify-content-end">
          {Object.values(errors).map((err) => (
            <span
              className="badge badge-danger d-center mt-5 mr-2 rounded"
              key={`error${err}`}
            >
              {err}
            </span>
          ))}
          <SaveBtn
            save={save}
            isSaving={isSaving}
            text={`Demander la mutation de ${tools.getFullname(recipient)}`}
          />
        </div>
      </div>
    </div>
  );
};

export default MutationModal;
