import { DateTime } from "luxon";
import React, { useState } from "react";
import Control from "../../../../common/Control";
import SaveBtn from "../../../../common/SaveBtn";
import useAmendmentModule from "../hooks/useAmendmentModule";

const AmendmentSignButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [signedAt, setSignedAt] = useState(DateTime.local().toFormat("yyyy-MM-dd"));
  const { actions, isLoadingAmendment, isCurrentAmendmentRenew } = useAmendmentModule();

  const toggleIsOpen = () => {
    setIsOpen((o) => !o);
  };

  const handleSignedAt = (e) => {
    setSignedAt(e.target.value);
  };

  const save = () => {
    actions.patchAmendmentSigned(signedAt);
  };

  return (
    <div className="d-flex flex-column w-50 align-items-start">
      <button className="btn btn-default border bg-white" onClick={toggleIsOpen}>
        <span className="mr-2">Passer en état signé</span>
        <i className={`fa fa-chevron-${isOpen ? "up" : "down"}`} />
      </button>
      {isOpen && (
        <div className="alert alert-info">
          <div className="d-flex align-items-start justify-content-start w-100">
            <i className="fa fa-exclamation-circle mt-1 mr-2" />
            <p>
              Si {isCurrentAmendmentRenew ? "ce renouvellement" : "cet avenant"} a été imprimé et
              signé par l'allocataire et son référent unique, vous pouvez le passer en état signé.
              Un {isCurrentAmendmentRenew ? "renouvellement" : "avenant"} en état signé ne sera plus
              modifiable.
            </p>
          </div>
          <Control label="Date de signature" type="date" value={signedAt} change={handleSignedAt} />
          <div className="d-flex justify-content-end">
            <SaveBtn
              save={save}
              isSaving={isLoadingAmendment}
              type="btn-info"
              text="Passer en état signé"
              margin=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AmendmentSignButton;
