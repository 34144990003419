import React, { useEffect, useState } from "react";
import Control from "../../../common/Control";
import SaveBtn from "../../../common/SaveBtn";
import useCERData from "../../../Hooks/useCERData/useCERData";

const ObstacleItem = ({ obstacle }) => {
  const { modifyCerObstacle } = useCERData();
  const [newObstacle, setNewObstacle] = useState({ ...obstacle });
  const [isModified, setIsModified] = useState(false);

  const handleChangeObstacle = (e) => {
    const { name, value } = e.target;
    setNewObstacle({ ...newObstacle, [name]: value });
    setIsModified(true);
  };

  const save = () => {
    setIsModified(false);
    modifyCerObstacle(newObstacle);
  };

  return (
    <li className="list-group-item d-flex align-items-center justify-content-start">
      <Control
        className="w-100"
        label=""
        type="text"
        name="name"
        change={handleChangeObstacle}
        value={newObstacle.name}
      />
      {isModified && <SaveBtn save={save} isSaving={false} margin="" />}
    </li>
  );
};

export default ObstacleItem;
