import React from "react";
import useCERData from "../../../Hooks/useCERData/useCERData";
import AddStepItem from "../AddStepItem";
import StepItem from "./StepItem/StepItem";

const StepList = ({ objective }) => {
  const { cerSteps } = useCERData();

  const stepsOfObjective = cerSteps.filter((step) => step.cerGoal == objective.id);

  return (
    <ul className="list-group mt-2">
      {stepsOfObjective.map((step) => (
        <StepItem key={`step${step.id}`} objective={objective} step={step} />
      ))}

      <AddStepItem objective={objective} />
    </ul>
  );
};

export default StepList;
