import React, { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useReoByPartner = (url, year) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(null);

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year },
      setChartState,
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
  };
};

export default useReoByPartner;
