import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import colors from "../../../../../../../../helpers/colors";
import tools from "../../../../../../../../helpers/tools";
import Loader from "../../../../../../../common/Loader";
import useCERModule from "../../../../../CERModule/hooks/useCERModule";
import SelectStatus from "../../../common/SelectStatus";
import StatusIcon from "../../../common/StatusIcon";
import { ObjectiveContext } from "../../ObjectiveContext";
import { StepContext } from "./StepContext";

const StepStatus = ({ disabled = false }) => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const { recipientCER, events, actions, isLoadingCERGoal } = useCERModule();
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const onChangeStepStatus = (status) => {
    if (disabled) return false;
    setIsLoading(true);
    const newStep = { ...step, detail: { status, date: DateTime.local().toISO() } };
    var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
    var stepIndex = tools.getStepIndex(objective.steps, step);

    let newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex ? newStep : st;
        }),
      ],
    };
    //si tous les steps sont done, on passe l'objectif à done
    let newDetail = { ...newObjective.detail };
    const isObjectiveInProgress = actions.checkStepInProgress(newObjective);
    if (isObjectiveInProgress) {
      newDetail = { status: "IN_PROGRESS", date: DateTime.local().toISO() };
    }
    const isObjectiveDone = actions.checkAllStepsDone(newObjective);
    if (isObjectiveDone) {
      newDetail = { status: "DONE", date: DateTime.local().toISO() };
    }
    newObjective = {
      ...newObjective,
      detail: newDetail,
    };

    events.onUpdateObjectiveAndSave(newObjective, objectiveIndex);
    toggleStatuses();
  };

  const toggleStatuses = () => {
    if (disabled) return false;
    setIsStatusOpen(!isStatusOpen);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [step.isDone]);

  return (
    <div
      style={{
        position: "absolute",
        top: 5,
        left: 0,
        zIndex: "9",
      }}
    >
      <StatusIcon
        onClick={toggleStatuses}
        isLoading={isLoadingCERGoal}
        status={step.detail.status}
        type="STEP"
        size={28}
        title={`Depuis le ${tools.formatDate(step.detail.date)}`}
        disabled={disabled}
      />

      {isStatusOpen && <SelectStatus type="STEP" onSelectStatus={onChangeStepStatus} />}
    </div>
  );
};

export default StepStatus;
