import React, { useEffect, useState } from "react";
import Control from "../../common/Control";
import PostalCode from "../../common/PostalCode";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import DynamicList from "../../common/DynamicList";
import PassOrientation from "../PassOrientation/PassOrientation";
import PasswordControl from "../../common/PasswordControl";
import dataService from "../../../helpers/dataService";

const RecipientInfo = ({
  recipient,
  genders,
  maritalStatuses,
  allDisabled,
  errors,
  updateData,
  setCanSave,
  setErrors,
  organizations,
}) => {
  const [state] = useStore();
  const constants = state.constants.items;
  return (
    <div className="row mx-auto" style={{ maxWidth: 1000 }}>
      <div className="col-12"></div>

      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="NIR"
          name="nir"
          value={recipient.nir}
          change={(e) => {
            if (e.target.value.length <= 15) {
              updateData(e);
            }
          }}
          error={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Nom"
          name="lastname"
          value={recipient.lastname}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Prénom"
          name="firstname"
          value={recipient.firstname}
          change={updateData}
          error={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Date de naissance"
          name="birthdate"
          type="date"
          value={DateTime.fromISO(recipient.birthdate).toFormat("yyyy-MM-dd")}
          change={updateData}
          error={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <PostalCode
          disabled={allDisabled}
          value={recipient.cityId}
          name="cityId"
          updateData={updateData}
          inputLabel="Code postal"
          error={errors}
          location={recipient.location}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          label={
            <>
              Email{" "}
              <small>
                <i>optionnel</i>
              </small>
            </>
          }
          name="email"
          disabled={allDisabled}
          value={recipient.email}
          type="email"
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          label={
            <>
              N° allocataire{" "}
              <small>
                <i>optionnel</i>
              </small>
            </>
          }
          name="CAFId"
          disabled={allDisabled}
          value={recipient.CAFId}
          type="CAFId"
          change={updateData}
          error={errors}
        />
      </div>
      {/* <div className="col-12 col-lg-6">
        <Control
          label="Orientation initiale"
          type="select"
          name="initialOrientation"
          disabled={allDisabled}
          value={recipient.initialOrientation}
          change={updateData}
          error={errors}
          datas={organizations}
        />
      </div> */}
      <div className="col-12 col-lg-6">
        <Control
          label="Situation professionnelle"
          type="select"
          name="professionalSituation"
          disabled={allDisabled}
          value={recipient.professionalSituation}
          change={updateData}
          error={errors}
          datas={constants.PROFESSIONAL_SITUATIONS}
        />
      </div>
    </div>
  );
};

export default RecipientInfo;
