import React from "react";

const AllocCounter = ({ nbRecs, nbRecsDone }) => {
  var className =
    nbRecs > 0
      ? nbRecs == nbRecsDone
        ? "bg-success text-white"
        : "bg-primary text-white"
      : "bg-default text-dark";

  return (
    <div
      className={`d-center rounded px-2 ${className} `}
      style={{
        height: 40,
      }}
    >
      {nbRecsDone}/{nbRecs}
      {nbRecs == nbRecsDone && nbRecs > 0 && <i className="fa fa-check ml-2" />}
    </div>
  );
};

export default AllocCounter;
