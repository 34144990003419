import React, { useEffect, useRef } from "react";
import Control from "../Control";
import FormUtils from "./FormUtils";
import FormInfo from "./FormInfo";
import useStore from "../../../context/useStore";
import FormRDSP from "../../pages/EntretienGiratoire/FormRDSP/FormRDSP";

var oldStep = 0;

const Questions = ({
  form,
  answers,
  updateAnswer,
  step,
  nextStep,
  previousStep,
  errors,
  specialActions,
  setStep,
}) => {
  const [state, dispatch] = useStore();
  const prevCountRef = useRef();

  const Components = {
    FormRDSP: FormRDSP,
  };

  useEffect(() => {
    prevCountRef.current = step;
  }, [step]);

  const prevCount = prevCountRef.current;

  function skipQuestion() {
    var prevStep = prevCount;
    if (prevStep > step) {
      previousStep();
    } else if (prevStep <= step) {
      nextStep();
    }
    return "";
  }

  useEffect(() => {
    form.forEach((q, qk) => {
      if (
        qk == step &&
        !FormUtils.shouldDisplayBlock(answers, q.questions, q.condition, state)
      ) {
        skipQuestion();
      }
    });
  }, [answers, form, step]);

  var content = form.map((q, qk) => {
    if (qk == step) {
      if (
        FormUtils.shouldDisplayBlock(answers, q.questions, q.condition, state)
      ) {
        return (
          <div key={"part" + qk} className="row m-0">
            {q.questions.map((part, partk) => {
              if (part.type == "component") {
                var MyComponent = Components[part.name];
              }
              return FormUtils.shouldDisplayQuestion(
                answers,
                part.condition,
                errors,
                state
              ) ? (
                part.type == "info" ? (
                  <FormInfo
                    key={"part" + qk + partk}
                    style={{ marginTop: 40 }}
                    text={part.text}
                    answers={answers}
                    actions={part.actions}
                    specialActions={specialActions}
                  />
                ) : part.type == "component" ? (
                  <MyComponent
                    {...part}
                    answers={answers}
                    updateAnswer={updateAnswer}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    setStep={setStep}
                  />
                ) : (
                  <Control
                    key={"part" + qk + partk}
                    {...part}
                    containerStyle={{ overflow: "hidden" }}
                    change={updateAnswer}
                    value={answers[part.name]}
                    checked={answers[part.name]}
                    error={errors}
                    isValid={
                      part.datas
                        ? part.datas.find(
                            (l) => l[part.dataIndex] == answers[part.name]
                          )
                        : answers[part.name] != ""
                        ? true
                        : false
                    }
                  />
                )
              ) : (
                ""
              );
            })}
          </div>
        );
      } else {
        //skipQuestion();
        return "";
      }
    } else {
      return "";
    }
  });

  return content;
};

export default Questions;
