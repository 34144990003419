import React, { useState } from "react";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Page from "../../common/layout/Page";
import sidenavItems from "../../../sidenavItems";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";

const Dashboard = () => {
  const [state, dispatch] = useStore();
  const user = state.auth.user;

  const cantViewEP = ["MANAGER_PARTNER", "AGENT_PARTNER"].includes(user.role) && !user.isEP;

  return (
    <Page title={`Bienvenue ${tools.getFullname(user)}`} container={"container-fluid px-5"}>
      <div className="row px-3">
        {sidenavItems.map((sd, sdk) => {
          if (sd.isEP) return false;
          if (sd.path == "#") {
            return sd.items.map((ssd, ssdk) => {
              if (ssd.isEP && cantViewEP) return false;
              return (
                <div className="p-1 col-2 " key={`linkcol${ssdk}`}>
                  <Link
                    to={ssd.path}
                    className="d-center p-3 flex-column  btn btn-outline-primary h-100"
                    data-priv={ssd.dataPriv}
                  >
                    <i className={`mb-2 fa fa-2x fa-${ssd.icon}`} />
                    <strong>{ssd.name}</strong>
                  </Link>
                </div>
              );
            });
          }
          return (
            <div className="p-1 col-2 " key={`inlinkcol${sdk}`}>
              <Link
                to={sd.path}
                className="d-center p-3 flex-column  btn btn-outline-primary h-100"
                data-priv={sd.dataPriv}
              >
                <i className={`mb-2 fa fa-2x fa-${sd.icon}`} />
                <strong>{sd.name}</strong>
              </Link>
            </div>
          );
        })}
      </div>
    </Page>
  );
};

export default Dashboard;
