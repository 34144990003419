import React, { useEffect, useState } from "react";
import Control from "../../common/Control";
import PostalCode from "../../common/PostalCode";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import DynamicList from "../../common/DynamicList";
import PassOrientation from "../PassOrientation/PassOrientation";
import PasswordControl from "../../common/PasswordControl";
import dataService from "../../../helpers/dataService";

const RecipientInfo = ({
  recipient,
  genders,
  maritalStatuses,
  allDisabled,
  errors,
  updateData,
  setCanSave,
  setErrors,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [showDl, setShowDl] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [agents, setAgents] = useState([]);
  const copyText = (inputName) => {
    var copyText = document.querySelector("input[name='" + inputName + "']");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    toast.success("La valeur a été copiée dans votre presse-papier");
  };

  useEffect(() => {
    dataService.get(`managers/agents/mine`, setAgents);
  }, []);

  useEffect(() => {
    setShowDl(false);
  }, [recipient]);

  return (
    <div className="row mx-auto" style={{ maxWidth: 1000 }}>
      <div className="col-12">
        <Control
          disabled={allDisabled}
          label="N° d'allocataire"
          name="CAFId"
          value={recipient.CAFId}
          change={(e) => {
            if (e.target.value.length <= 7) {
              updateData(e);
            }
          }}
          error={errors}
          suffix={
            <i
              className="fa fa-copy px-2"
              title="Copier dans le presse-papier"
              style={{ padding: "10px 0", cursor: "pointer" }}
              onClick={() => copyText("CAFId")}
            />
          }
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Civilité"
          type="btnList"
          btnInline={true}
          datas={genders}
          name="gender"
          value={recipient.gender}
          change={updateData}
          error={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="NIR"
          name="nir"
          value={recipient.nir}
          change={(e) => {
            if (e.target.value.length <= 15) {
              updateData(e);
            }
          }}
          error={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Nom"
          name="lastname"
          value={recipient.lastname}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Prénom"
          name="firstname"
          value={recipient.firstname}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label={<>Email </>}
          name="email"
          type="email"
          value={recipient.email}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Date de naissance"
          name="birthdate"
          type="date"
          value={DateTime.fromISO(recipient.birthdate).toFormat("yyyy-MM-dd")}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Téléphone"
          type="tel"
          name="phone"
          value={recipient.phone}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Téléphone 2"
          type="tel"
          name="phone2"
          value={recipient.phone2}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Adresse"
          name="address"
          value={recipient.address}
          change={updateData}
          error={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Complément d'adresse"
          name="addressAdd"
          value={recipient.addressAdd}
          change={updateData}
          error={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Nombre d'enfants à charge au sens des PF"
          type="numberInt"
          name="nbDependantChildren"
          value={recipient.nbDependantChildren}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Nombre de personnes à charge"
          type="numberInt"
          name="nbChildrenOrDependant"
          value={recipient.nbChildrenOrDependant}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          disabled={allDisabled}
          label="Situation familiale"
          type="select"
          datas={maritalStatuses}
          name="maritalStatus"
          value={recipient.maritalStatus}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <PostalCode
          disabled={allDisabled}
          value={recipient.cityId}
          name="cityId"
          updateData={updateData}
          inputLabel="Code postal"
          error={errors}
          location={recipient.location}
        />
      </div>

      <div className="col-12">
        <DynamicList
          listLabel={
            <>
              Droits{" "}
              <span className="badge badge-primary ml-2">
                {recipient.rights.length}
              </span>
            </>
          }
          btnLabel="Ajouter un droit"
          uniqueKey="recrights-1"
          name="rights"
          fields={[
            {
              label: "Type",
              name: "type",
              type: "select",
              datas: constants.RSA_TYPES,
              dataIndex: "id",
              dataLabel: "name",
            },
            {
              label: "Début",
              name: "beginAt",
              type: "date",
            },
            {
              label: "Fin",
              name: "endAt",
              type: "date",
            },
          ]}
          items={recipient.rights ? recipient.rights : []}
          change={updateData}
          error={errors}
        />
      </div>

      <div className="col-12">
        <DynamicList
          listLabel={
            <>
              Droits et devoirs{" "}
              <span className="badge badge-primary ml-2">
                {recipient.rightsAndDuties.length}
              </span>
            </>
          }
          btnLabel="Ajouter un droit et devoir"
          uniqueKey="recrightsandduties-1"
          name="rightsAndDuties"
          fields={[
            {
              label: "Début",
              name: "beginAt",
              type: "date",
            },
            {
              label: "Fin",
              name: "endAt",
              type: "date",
            },
          ]}
          items={recipient.rightsAndDuties ? recipient.rightsAndDuties : []}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 ">
        <DynamicList
          listLabel={
            <>
              Contrats{" "}
              <span className="badge badge-primary ml-2">
                {recipient.contracts.length}
              </span>
            </>
          }
          btnLabel="Ajouter un contrat"
          uniqueKey="contracts-1"
          name="contracts"
          fields={[
            {
              label: "Type",
              name: "type",
              type: "select",
              datas: constants.CONTRACT_TYPES,
              dataIndex: "id",
              dataLabel: "name",
            },
            {
              label: "Début",
              name: "beginAt",
              type: "date",
            },
            {
              label: "Fin",
              name: "endAt",
              type: "date",
            },
          ]}
          items={recipient.contracts ? recipient.contracts : []}
          change={updateData}
          error={errors}
        />
      </div>

      <div className="col-12 ">
        <Control
          label="Situation professionnelle"
          type="select"
          name="professionalSituation"
          disabled={allDisabled}
          value={recipient.professionalSituation}
          change={updateData}
          error={errors}
          datas={constants.PROFESSIONAL_SITUATIONS}
        />

        <Control
          label="Projet professionnel"
          type="textarea"
          name="professionalProject"
          disabled={allDisabled}
          value={recipient.professionalProject}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          label="Permis de conduire"
          type="checkBtnList"
          name="driveLicenses"
          disabled={allDisabled}
          value={recipient.driveLicenses || []}
          datas={constants.DRIVE_LICENSES}
          btnInline
          btnSm
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          label="Moyens de transport"
          type="checkBtnList"
          name="transports"
          disabled={allDisabled}
          value={recipient.transports || []}
          datas={constants.TRANSPORTS}
          btnInline
          btnSm
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Control
          label="N° d'inscription à France Travail"
          type="text"
          name="numSubscribePE"
          disabled={allDisabled}
          value={recipient.numSubscribePE}
          change={updateData}
          error={errors}
        />
      </div>
      {agents.length ? (
        <div className="col-12 col-lg-6">
          <Control
            label="Nom de l'agent CAF en charge"
            type="select"
            name="userManager"
            disabled={allDisabled}
            datas={agents}
            dataIndex={"id"}
            dataLabel={"lastname"}
            dataLabel2={"firstname"}
            selectFirstLabel={recipient.userManagerDisplayed?.fullName}
            value={recipient.userManager}
            change={updateData}
            error={errors}
          />
        </div>
      ) : null}
      <div className="col-6">
        <Control
          label="Emploi recherché en priorité"
          type="text"
          name="jobSearch"
          disabled={allDisabled}
          value={recipient.jobSearch}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-6">
        <Control
          label="Durée expérience"
          type="text"
          name="jobSearchExp"
          disabled={allDisabled}
          value={recipient.jobSearchExp}
          change={updateData}
          error={errors}
          suffix={"an(s)"}
        />
      </div>
      {/* <div className="col-6">
        <Control
          label="Autre emploi recherché"
          type="text"
          name="job2Search"
          disabled={allDisabled}
          value={recipient.job2Search}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-6">
        <Control
          label="Durée expérience"
          type="text"
          name="job2SearchExp"
          disabled={allDisabled}
          value={recipient.job2SearchExp}
          change={updateData}
          error={errors}
          suffix={"an(s)"}
        />
      </div> */}
      <div className="col-12">
        <Control
          label="Mot de passe (laissez vide pour inchangé)"
          type="password"
          name="password"
          value={recipient.password}
          change={updateData}
          error={errors}
          setError={setErrors}
          autocomplete="new-password"
          required={true}
          containerStyle={{ padding: 0 }}
        />
        <PasswordControl
          password={recipient.password}
          minLength={9}
          minUppercase={1}
          minLowercase={3}
          minNumber={1}
          setCanSave={setCanSave}
        />
        <Control
          label="Retapez le mot de passe"
          type="password"
          name="passwordConfirm"
          value={passwordConfirm}
          change={(e) => setPasswordConfirm(e.target.value)}
          error={
            passwordConfirm != recipient.password
              ? { passwordConfirm: "Doit être identique à celui du dessus" }
              : {}
          }
          setError={setErrors}
          isValid={
            passwordConfirm == recipient.password &&
            recipient.password &&
            recipient.password != ""
          }
          required={true}
          containerStyle={{ padding: 0 }}
        />
      </div>
      <div className="col-12">
        <Control
          label="Activer le compte immédiatement ? (aucun mail d'activation ne sera envoyé)"
          type="checkbox"
          name="isActivated"
          disabled={allDisabled}
          checked={recipient.isActivated}
          change={updateData}
          error={errors}
        />
      </div>
    </div>
  );
};

export default RecipientInfo;
