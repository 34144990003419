import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { modalActions } from "../../../../../../context/actions/modalActions";
import tools from "../../../../../../helpers/tools";
import SaveBtn from "../../../../../common/SaveBtn";
import EpPv from "../../../EpPv/EpPv";
import useStoreState from "../../Context/Store/useStoreState";

const DirectorSign = () => {
  const { state, items, gState, items_actions, gDispatch } = useStoreState();
  const { utils, ep, recipients } = items;
  const modal_actions = modalActions(gState, gDispatch);
  const isDirector = gState.auth.user.role == "CAF_DIRECTOR" || gState.auth.user.isDirector; //ajouter le check !!!

  const saveAvisDirector = () => {
    items_actions.saveAvisDirector();
  };

  const directorFinalizeEp = () => {
    var emptyDecisions = [];
    recipients.forEach((rec) => {
      if (!rec.directorNotice.decision) {
        emptyDecisions.push(rec);
      }
    });
    if (emptyDecisions.length) {
      toast.error(
        `Vous n'avez pas renseigné de décision concernant les allocataires suivants : ${emptyDecisions
          .map((rec) => tools.getFullname(rec.userRecipient))
          .join(", ")}`
      );
      var emptyBlock = document.querySelector(
        `span[data-key='alloc${emptyDecisions[0].userRecipient.nir}']`
      );
      if (emptyBlock) {
        document
          .querySelector(`span[data-key='alloc${emptyDecisions[0].userRecipient.nir}']`)
          .scrollIntoView();
      }
    } else {
      items_actions.directorFinalizeEp();
    }
  };

  const openPVPreview = () => {
    modal_actions.updateModal({
      isOpen: true,
      content: <EpPv previewData={ep} />,
    });
  };

  return ep.endedAt ? (
    <div className="col-12">
      <div className="d-center">
        <Link to={`/ep-pv/${ep.id}`} className="btn btn-link">
          Accéder au Procès Verbal
        </Link>
      </div>
    </div>
  ) : ep.closedAt && isDirector ? (
    <div className="col-12">
      <div className="d-center">
        <button className="btn btn-link" onClick={openPVPreview}>
          Prévisualiser le PV
        </button>
      </div>
      <div className="d-center flex-column">
        <SaveBtn
          type="btn-primary"
          save={saveAvisDirector}
          isSaving={state.isLoadingClose}
          text={"Sauvegarder mes décisions"}
        />
        <div className="alert alert-info my-2">
          Pensez à sauvegarder vos décisions avant de finaliser l'EP
        </div>
      </div>
      <div className="d-center flex-column">
        <SaveBtn
          disabled={state.isLoadingClose || state.isLoadingEnd}
          type="btn-success"
          save={directorFinalizeEp}
          isSaving={state.isLoadingEnd}
          text={"Finaliser et générer le Procès Verbal"}
        />
      </div>
    </div>
  ) : null;
};

export default DirectorSign;
