import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../../../../context/useStore";

const MotifDisplay = ({ recipient, hideTitle = false }) => {
  const [state] = useStore();
  const { EP_SANCTION_REASONS_1, EP_SANCTION_REASONS_2, EP_REASONS_1, EP_REASONS_2, EP_ADDITIONAL_REASONS } =
    state.constants.items;

  var sanctionsMotifs1 = recipient.type == "SANCTION" ? EP_SANCTION_REASONS_1 : EP_REASONS_1;
  var sanctionsMotifs2 = recipient.type == "SANCTION" ? EP_SANCTION_REASONS_2 : EP_REASONS_2;

  return (
    <div>
      {!hideTitle && (
        <strong className="text-primary mb-3 d-block">
          Motifs d'inscription de l'allocataire à l'EP du{" "}
          {DateTime.fromISO(recipient.updatedAt).setLocale("FR").toFormat("dd LLLL yyyy")}
        </strong>
      )}
      {recipient.reasons.map((reason) => (
        <div>
          <strong>Motif 1</strong>
          <div>{sanctionsMotifs1.find((rea) => rea.id == reason.reason1).name}</div>

          <strong>Motif 2</strong>
          <ul className="mb-0 pl-3">
            {reason.reasons2.map((reason2) => (
              <li>{sanctionsMotifs2.find((rea) => rea.id == reason2)?.name || ""}</li>
            ))}
          </ul>
        </div>
      ))}
      {recipient.additionalReasons?.length ? (
        <div>
          <strong>Informations complémentaires</strong>
          <div>
            <ul className="mb-0 pl-3">
              {recipient.additionalReasons.map((ad) => {
                let reas = EP_ADDITIONAL_REASONS.find((a) => a.id == ad) || {};
                return <li>{reas.name || <i className="text-danger">motif invalide</i>}</li>;
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MotifDisplay;
