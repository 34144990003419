export const FORM_TYPES = {
  LOAD_FORM: "LOAD_FORM",
  GET_TARIF: "GET_TARIF",
  DISPLAY_BASKET: "DISPLAY_BASKET",
};

const initialState = {
  isLoading: false,
  displayBasket: false,
  tarifs: {},
  total: {
    monthlyPrice: "",
    annualPrice: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FORM_TYPES.LOAD_FORM:
      return {
        ...state,
        isLoading: action.payload,
      };

    case FORM_TYPES.GET_TARIF:
      return {
        ...state,
        tarifs: action.payload.tarifs,
        total: action.payload.total,
        isLoading: false,
      };
    case FORM_TYPES.DISPLAY_BASKET:
      return {
        ...state,
        displayBasket: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
