import React, { useContext } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERData from "../../../../../../../../Hooks/useCERData/useCERData";
import { ObjectiveContext } from "../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import { StepContext } from "../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/StepContext";
import useAmendmentModule from "../../../../../hooks/useAmendmentModule";

const AmendmentStepView = ({ goToEditMode }) => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const { cerSteps } = useCERData();
  const { currentAmendment, events, isCurrentAmendmentDraft } = useAmendmentModule();
  const stepRef = cerSteps.find((o) => o.id == step.step);
  var objectiveIndex = tools.getObjectiveIndex(currentAmendment.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);

  const deleteStep = () => {
    let newSteps = [...objective.steps];
    if (newSteps[stepIndex].updateType == "ADDED") {
      newSteps = newSteps.filter((s, sk) => sk != stepIndex);
    } else {
      newSteps = newSteps.map((s, sk) =>
        sk == stepIndex
          ? {
              ...s,
              operators: [
                ...s.operators
                  .filter((o) => o.updateType != "ADDED")
                  .map((o) => ({ ...o, updateType: "DELETED" })),
              ],
              updateType: "DELETED",
            }
          : s
      );
    }

    const newObjective = {
      ...objective,
      steps: newSteps,
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
  };

  const cancelDelete = () => {
    let newSteps = [...objective.steps];

    newSteps = newSteps.map((s, sk) =>
      sk == stepIndex
        ? {
            ...s,
            operators: [...s.operators.map((o) => ({ ...o, updateType: "" }))],
            updateType: "",
          }
        : s
    );

    const newObjective = {
      ...objective,
      steps: newSteps,
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
  };

  const itemNameStyle = tools.getCERElementUpdateTypeStyle(step.updateType);

  return (
    <div
      className={`d-flex align-items-center justify-content-between ${
        isCurrentAmendmentDraft ? "item-list-view" : "pb-2"
      }`}
    >
      <div className="py-2 pl-2">
        <strong className="" style={{ ...itemNameStyle }}>
          {stepRef?.name}
        </strong>
      </div>

      {isCurrentAmendmentDraft && (
        <div>
          {step.updateType == "DELETED" && objective.updateType != "DELETED" && (
            <button className="btn btn-sm btn-link text-danger " onClick={cancelDelete}>
              Restaurer la démarche
            </button>
          )}
          {objective.updateType != "DELETED" && step.updateType != "DELETED" && (
            <button className="btn btn-sm btn-outline-dark" onClick={deleteStep}>
              <i className="fa fa-times " />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AmendmentStepView;
