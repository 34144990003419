import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useContext } from "react";
import colors from "../../../helpers/colors";
import useCERData from "../../Hooks/useCERData/useCERData";
import useCERModule from "../RecipientDetail/CERModule/hooks/useCERModule";
import { GoalContext } from "./GoalContext";
import { PathTypeContext } from "./PathTypeContext";
import StepsList from "./StepsList";

const GoalsList = ({ constants }) => {
  const { cerObjectives } = useCERData();
  const { recipientCER } = useCERModule();
  const pathType = useContext(PathTypeContext);

  const filteredObjectives = recipientCER.goals.filter((g) => {
    return g.pathType == pathType.id;
  });

  var styles = StyleSheet.create({
    container: {
      paddingLeft: 10,
      paddingBottom: 4,
    },
    title: {
      fontSize: 11,
      color: colors.info,
      paddingBottom: 3,
    },
  });

  return filteredObjectives.map((objective, ok) => {
    var objectiveRef = cerObjectives.find((o) => o.id == objective.goal);
    var categoryRef = constants.CER_GOAL_CATEGORIES.find((cat) => cat.id == objectiveRef.category);
    var objectifNum = parseInt(ok) + 1;

    return (
      <View style={{ ...styles.container }}>
        <Text style={{ ...styles.title }}>
          <Text style={{ fontSize: 9 }}>{categoryRef?.name}/</Text>
          {objectiveRef?.name}
        </Text>
        <GoalContext.Provider value={objective}>
          <StepsList />
        </GoalContext.Provider>
      </View>
    );
  });
};

export default GoalsList;
