import React from "react";
import CreateRdvOnTheFly from "../RecipientDetail/common/CreateRdvOnTheFly/CreateRdvOnTheFly";

const Test = () => {
  return (
    <CreateRdvOnTheFly
      organizationSlugName="CAF"
      recipient={{
        isActivated: true,
        firstname: "test",
        lastname: "test",
        gender: 2,
        CAFId: 123456,
        model: "UserRecipient",
        fullName: "test test",
        id: "5fd3414f50d1e148cfc3a2f0",
      }}
    />
  );
};

export default Test;
