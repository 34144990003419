import React, { useState } from "react";
import SaveBtn from "../../common/SaveBtn";
import Table from "../../common/Table";
import tools from "../../../helpers/tools";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import dataService from "../../../helpers/dataService";
import { toast } from "react-toastify";

const SmsTest = ({ id, name }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(true);
  const [displayError, setDisplayError] = useState(true);
  const [isSendingSms, setIsSendingSms] = useState(false);

  const [datas, setDatas] = useState({
    userRecipients: [],
    errors: [],
  });

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `notifications/sms`,
      { smsType: id },
      (datas) => {
        setDatas(datas);
        setIsFetched(true);
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  const sendSmsToRecipient = (recipientId) => {
    if (!window.confirm("Confirmez cette action")) return false;
    setIsSendingSms(true);
    dataService.post(
      "notifications/sms/send",
      {
        smsType: id,
        userRecipientId: recipientId,
      },
      () => toast.success("Sms envoyé"),
      (err) => {},
      () => setIsSendingSms(false)
    );
  };

  return (
    <div className=" bg-white shadow-sm p-3 mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <SaveBtn
          save={getDatas}
          isSaving={isLoading}
          text={`${name}`}
          type="btn-primary"
          margin="m-0 mb-2"
        />
        {isFetched && (
          <span>
            Total : {datas.userRecipients.length + datas.errors.length}
          </span>
        )}
      </div>
      {isFetched ? (
        <>
          <div
            className="alert alert-success d-flex flex-row align-items-center justify-content-between cursor-pointer"
            onClick={() => setDisplaySuccess(!displaySuccess)}
          >
            <div className="">
              <i
                className={`fa fa-chevron-${
                  displaySuccess ? "up" : "down"
                } mr-2`}
              />
              Allocataires qui recevrons le SMS
            </div>
            <div>{datas.userRecipients.length}</div>
          </div>
          {displaySuccess ? (
            <Table
              datas={datas.userRecipients}
              fields={[
                {
                  name: "Allocataire",
                  path: "*",
                  render: (el) => tools.getFullname(el),
                },
                {
                  name: "Email",
                  path: "email",
                },
                {
                  name: "Téléphone",
                  path: "phone",
                },
                {
                  name: "NIR",
                  path: "nir",
                },
                {
                  name: "Voir",
                  path: "id",
                  render: (el) => (
                    <div className="d-center flex-row">
                      <Link
                        className="btn btn-default btn-sm d-center mr-1"
                        to={`/recipients/${el}`}
                      >
                        <i className="fa fa-eye" />
                      </Link>
                      {isSendingSms ? (
                        <Loader />
                      ) : (
                        <button
                          onClick={() => sendSmsToRecipient(el)}
                          className="btn btn-info"
                        >
                          Envoyer le sms
                        </button>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          ) : null}
          <div
            className="alert alert-danger  d-flex flex-row align-items-center justify-content-between cursor-pointer"
            onClick={() => setDisplayError(!displayError)}
          >
            <div>
              <i
                className={`fa fa-chevron-${displayError ? "up" : "down"} mr-2`}
              />
              Allocataires qui présentent une erreur sur le numéro de téléphone
            </div>
            <div>{datas.errors.length}</div>
          </div>
          {displayError ? (
            <Table
              datas={datas.errors}
              fields={[
                {
                  name: "Allocataire",
                  path: "*",
                  render: (el) => tools.getFullname(el),
                },
                {
                  name: "Email",
                  path: "email",
                },
                {
                  name: "Téléphone",
                  path: "phone",
                  render: (el) => (
                    <span className="badge badge-danger">{el}</span>
                  ),
                },
                {
                  name: "NIR",
                  path: "nir",
                },
                {
                  name: "Voir",
                  path: "id",
                  render: (el) => (
                    <Link
                      className="btn btn-default btn-sm"
                      to={`/recipients/${el}`}
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  ),
                },
              ]}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default SmsTest;
