const useReorientationTools = ({ organizations }) => {
  const getOrganizationFromId = (id) => {
    var org = organizations.find((o) => o.id == id);
    if (org) {
      return org;
    }
    return {};
  };

  return { actions: { getOrganizationFromId } };
};

export default useReorientationTools;
