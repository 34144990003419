import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";

const useSanctionByPart = (url, year, otherPostParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [total, setTotal] = useState(0);
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const years = [year];
  const [orgas, setOrgas] = useState([]);

  useEffect(() => {
    //getOrgas();
  }, []);

  const getOrgas = () => {
    dataService.get(`organizations`, setOrgas);
  };

  const line1Options = {
    chart: {
      height: 350,
      type: "bar",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: months,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const line2Options = {
    chart: {
      height: 350,
      type: "bar",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: years,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year, ...otherPostParams },
      (datas) => {
        var allTotals = datas.global.map((o) => o.months.reduce((a, b) => a + b));

        setTotal(allTotals.reduce((a, b) => a + b));
        setChartState({
          line1: {
            series: datas.global.map((o) => ({
              name: o.name,
              data: o.months,
            })),
            options: line1Options,
          },
          pie1: {
            series: datas.global.map((o) => o.months.reduce((a, b) => a + b)),
            options: {
              labels: datas.global.map((o) => o.name),
              legend: { position: "bottom" },
              chart: {
                toolbar: {
                  show: true,
                },
              },
            },
          },
          line2: {
            series: datas.details?.map((o) => ({
              name: `${o.name} (${o.year[0]})`,
              data: o.year,
            })),
            options: line2Options,
          },
          pie2: {
            series: datas.details?.map((o) => o.year.reduce((a, b) => a + b)),
            options: {
              labels: datas.details?.map((o) => o.name),
              legend: { position: "bottom" },
              chart: {
                toolbar: {
                  show: true,
                },
              },
            },
          },
          ...datas,
        });
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
    orgas,
  };
};

export default useSanctionByPart;
