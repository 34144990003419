import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { modalActions } from "../../../../../../context/actions/modalActions";
import { sidePanelActions } from "../../../../../../context/actions/sidePanelActions";
import tools from "../../../../../../helpers/tools";
import Control from "../../../../../common/Control";
import Table from "../../../../../common/Table";
import ReorientationIcon from "../../../../Reorientations/ReorientationIcon";
import useReorientationTools from "../../../../Reorientations/useReorientationTools";
import useStoreState from "../../Context/Store/useStoreState";
import EditAlloc from "./EditAlloc/EditAlloc";
import MotifDisplay from "./EditAlloc/MotifDisplay";

const EpAllocBlock = ({ recipients, epType, isClosed, allRecipients }) => {
  const { state, gState, gDispatch, items, items_actions } = useStoreState();
  const { ORIENTATION_STATUS } = gState.constants.items;

  const { ep, utils, organizations } = items;
  const mActions = modalActions(gState, gDispatch);
  const spActions = sidePanelActions(gState, gDispatch);
  const isWriterOrPresident = [ep.writer, ep.president].includes(
    gState.auth.user.id
  );
  const isDirector =
    ["SUPER_ADMIN", "ADMIN", "CAF_DIRECTOR"].includes(gState.auth.user.role) ||
    gState.auth.user.isDirector;
  const openAlloc = (alloc) => {
    console.log("open alloc", alloc);
    console.log("isWriterOrPresident", isWriterOrPresident);
    console.log("isDirector", isDirector);
    if (isWriterOrPresident || isDirector) {
      items_actions.setCurrentAlloc(alloc);
    }

    // items_actions.setCurrentAlloc(alloc);
  };

  useEffect(() => {
    if (
      utils.prevCurrentAlloc &&
      recipients.find((r) => r.id == utils.prevCurrentAlloc.id)
    ) {
      setTimeout(() => {
        document
          .querySelector(`#currentAlloc${utils.prevCurrentAlloc.id}`)
          .scrollIntoView();
      }, 500);
    }
  }, [utils.prevCurrentAlloc]);

  const updateDirectorNotice = (recipient, e) => {
    const { name, value } = e.target;
    const newRecipients = allRecipients.map((rec) =>
      rec.id == recipient.id
        ? { ...rec, directorNotice: { ...rec.directorNotice, [name]: value } }
        : rec
    );
    items_actions.updateRecipients(newRecipients);
  };

  console.log("organizations", organizations);

  const { actions } = useReorientationTools({ organizations });

  const openHistoric = (historic) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <ul className="list-group">
          {historic.map((hist) => {
            var histName = tools.findIn(
              ORIENTATION_STATUS,
              "id",
              hist.status
            ).name;
            return (
              <li key={`listhisto${hist.date}`} className="list-group-item">
                <strong className="mr-2 d-block">
                  <i className="fa fa-chevron-right mr-2" />
                  {DateTime.fromISO(hist.date).toFormat("dd/MM/yyyy HH:mm")}
                </strong>{" "}
                {histName}
              </li>
            );
          })}
        </ul>
      ),
    });
  };

  const getAction = () => {
    switch (epType.id) {
      case "REORIENTATION_KO":
        var cells = [
          {
            name: "Inscrit par",
            path: "*",
            render: (el) =>
              actions.getOrganizationFromId(
                el.EPNotice.reorientation.orientation?.fromPartner || ""
              ).name,
          },
          {
            name: "Orientation",
            path: "*",
            render: (el) => (
              <>
                <ReorientationIcon
                  fromPartner={
                    actions.getOrganizationFromId(
                      el.EPNotice.reorientation.orientation?.fromPartner || ""
                    ).name
                  }
                  toPartner={
                    actions.getOrganizationFromId(
                      el.EPNotice.reorientation.orientation?.toPartner || ""
                    ).name
                  }
                  fromAgent={tools.getFullname(
                    el.EPNotice.reorientation.orientation?.createdBy || ""
                  )}
                  toAgent={tools.getFullname(
                    el.EPNotice.reorientation.orientation?.receivedBy || ""
                  )}
                />
                <div>
                  {
                    tools.findIn(
                      ORIENTATION_STATUS,
                      "id",
                      el.EPNotice.reorientation.orientation.status
                    ).name
                  }
                  <button
                    className="btn btn-link btn-sm"
                    onClick={() =>
                      openHistoric(
                        el.EPNotice.reorientation.orientation.statusHistorics
                      )
                    }
                  >
                    {
                      el.EPNotice.reorientation.orientation.statusHistorics
                        .length
                    }{" "}
                    échange(s)
                  </button>
                </div>
              </>
            ),
          },
          {
            name: "Motifs",
            path: "*",
            render: (el) => <MotifDisplay recipient={el} hideTitle />,
          },
          {
            name: isClosed ? "Avis de l'EP" : "Traiter",
            path: "*",
            render: (el) =>
              tools.checkEpRecipientDone("REORIENTATION_KO", el) ? (
                <>
                  <button
                    className="btn btn-success"
                    onClick={() => openAlloc(el)}
                  >
                    {tools.getReorientationSentence(
                      el.EPNotice,
                      gState.constants.items,
                      organizations
                    )}
                  </button>
                  {el.EPNotice.note && (
                    <details className="mt-3">
                      <summary>Note interne</summary>
                      <p>{el.EPNotice.note}</p>
                    </details>
                  )}
                </>
              ) : isClosed ? null : (
                <button
                  className={`btn ${
                    utils.currentAlloc == el.userRecipient.id
                      ? "btn-success animated pulse infinite"
                      : "btn-primary"
                  }`}
                  onClick={() => openAlloc(el)}
                >
                  Traiter
                </button>
              ),
          },
        ];

        if (isClosed) {
          cells.push({
            name: "Décision du directeur",
            path: "*",
            render: (el) => (
              <>
                <Control
                  disabled={ep.endedAt || !isDirector ? true : false}
                  type="btnList"
                  datas={[
                    { id: 1, name: "Accord" },
                    { id: 2, name: "À surseoir" },
                  ]}
                  btnInline
                  name="decision"
                  value={el.directorNotice.decision}
                  change={(e) => updateDirectorNotice(el, e)}
                />
                <Control
                  disabled={ep.endedAt || !isDirector ? true : false}
                  label="Commentaire"
                  type="textarea"
                  name="comment"
                  value={el.directorNotice.comment}
                  change={(e) => updateDirectorNotice(el, e)}
                />
              </>
            ),
          });
        }
        return cells;
      case "SANCTION":
        var cells = [
          {
            name: "Inscrit par",
            path: "*",
            render: (el) =>
              el.organizationOrigin
                ? actions.getOrganizationFromId(el.organizationOrigin).name
                : "-",
          },
          {
            name: "Motifs",
            path: "*",
            render: (el) => <MotifDisplay recipient={el} hideTitle />,
          },
          {
            name: isClosed ? "Avis de l'EP" : "Traiter",
            path: "*",
            render: (el) =>
              tools.checkEpRecipientDone("SANCTION", el) ? (
                <>
                  <button
                    className="btn btn-success"
                    onClick={() => openAlloc(el)}
                  >
                    {tools.getSanctionSentence(
                      el.EPNotice,
                      gState.constants.items
                    )}
                  </button>
                  {el.EPNotice.note && (
                    <details className="mt-3">
                      <summary>Note interne</summary>
                      <p>{el.EPNotice.note}</p>
                    </details>
                  )}
                </>
              ) : isClosed ? null : (
                <button
                  className={`btn ${
                    utils.currentAlloc == el.userRecipient.id
                      ? "btn-success animated pulse infinite"
                      : "btn-primary"
                  }`}
                  onClick={() => openAlloc(el)}
                >
                  Traiter
                </button>
              ),
          },
        ];
        if (isClosed) {
          cells.push({
            name: "Décision du directeur",
            path: "*",
            render: (el) => (
              <>
                <Control
                  type="btnList"
                  datas={[
                    { id: 1, name: "Accord" },
                    { id: 2, name: "À surseoir" },
                  ]}
                  disabled={!isDirector}
                  btnInline
                  dataIndex="id"
                  dataLabel2="name"
                  name="decision"
                  value={el.directorNotice.decision}
                  change={(e) => updateDirectorNotice(el, e)}
                />
                <Control
                  disabled={!isDirector}
                  label="Commentaire"
                  type="textarea"
                  name="comment"
                  value={el.directorNotice.comment}
                  change={(e) => updateDirectorNotice(el, e)}
                />
              </>
            ),
          });
        }
        return cells;
      case "REORIENTATION_OK":
        var cells = [
          {
            name: "Inscrit par",
            path: "*",
            render: (el) =>
              actions.getOrganizationFromId(
                el.EPNotice.reorientation.orientation.fromPartner
              ).name,
          },
          {
            name: "Orientation",
            path: "*",
            render: (el) => (
              <>
                <ReorientationIcon
                  fromPartner={
                    actions.getOrganizationFromId(
                      el.EPNotice.reorientation.orientation.fromPartner
                    ).name
                  }
                  toPartner={
                    actions.getOrganizationFromId(
                      el.EPNotice.reorientation.orientation.toPartner
                    ).name
                  }
                  fromAgent={tools.getFullname(
                    el.EPNotice.reorientation.orientation.createdBy
                  )}
                  toAgent={tools.getFullname(
                    el.EPNotice.reorientation.orientation.receivedBy
                  )}
                />
                <div>
                  {
                    tools.findIn(
                      ORIENTATION_STATUS,
                      "id",
                      el.EPNotice.reorientation.orientation.status
                    ).name
                  }
                  <button
                    className="btn btn-link btn-sm"
                    onClick={() =>
                      openHistoric(
                        el.EPNotice.reorientation.orientation.statusHistorics
                      )
                    }
                  >
                    {
                      el.EPNotice.reorientation.orientation.statusHistorics
                        .length
                    }{" "}
                    échange(s)
                  </button>
                </div>
              </>
            ),
          },
          {
            name: "Motifs",
            path: "*",
            render: (el) => <MotifDisplay recipient={el} hideTitle />,
          },
        ];

        if (isClosed) {
          cells.push({
            name: "Décision du directeur",
            path: "*",
            render: (el) => (
              <>
                <Control
                  type="btnList"
                  datas={[
                    { id: 1, name: "Accord" },
                    { id: 2, name: "À surseoir" },
                  ]}
                  btnInline
                  name="decision"
                  value={el.directorNotice.decision}
                  change={(e) => updateDirectorNotice(el, e)}
                  disabled={!isDirector}
                />
                <Control
                  label="Commentaire"
                  type="textarea"
                  name="comment"
                  value={el.directorNotice.comment}
                  change={(e) => updateDirectorNotice(el, e)}
                  disabled={!isDirector}
                />
              </>
            ),
          });
        }
        return cells;
      case "SOCIAL_PATH_MAINTAIN":
        var cells = [
          {
            name: "Inscrit par",
            path: "*",
            render: (el) =>
              el.organizationOrigin
                ? actions.getOrganizationFromId(el.organizationOrigin).name
                : "-",
          },
          {
            name: "Motifs",
            path: "*",
            render: (el) => <MotifDisplay recipient={el} hideTitle />,
          },
        ];
        if (isClosed) {
          cells.push({
            name: "Décision du directeur",
            path: "*",
            render: (el) => (
              <>
                <Control
                  type="btnList"
                  datas={[
                    { id: 1, name: "Accord" },
                    { id: 2, name: "À surseoir" },
                  ]}
                  disabled={!isDirector}
                  btnInline
                  name="decision"
                  value={el.directorNotice.decision}
                  change={(e) => updateDirectorNotice(el, e)}
                />
                <Control
                  disabled={!isDirector}
                  label="Commentaire"
                  type="textarea"
                  name="comment"
                  value={el.directorNotice.comment}
                  change={(e) => updateDirectorNotice(el, e)}
                />
              </>
            ),
          });
        }
        return cells;
      default:
        return [];
    }
  };

  return (
    <Table
      style={{ fontSize: 12 }}
      fullWidth
      fields={[
        {
          name: "Allocataire",
          path: "*",
          render: (el) => {
            var route = tools.getRecipientLinkFromParams(
              el.userRecipientRef,
              el.userRecipient.id
            );
            return (
              <a
                className="btn btn-link"
                target="_BLANK"
                href={route}
                data-key={`alloc${el.userRecipient.nir}`}
              >
                <i className="fa fa-link mr-2" />
                <div id={`currentAlloc${el.id}`}>
                  {tools.getFullname(el.userRecipient)}
                </div>
                <span>{el.userRecipient.nir}</span>
              </a>
            );
          },
        },

        ...getAction(),
      ]}
      datas={recipients}
    />
  );
};

export default EpAllocBlock;
