import { useAtom } from "jotai";
import { toast } from "react-toastify";
import dataService from "../../../helpers/dataService";
import {
  cerObjectivesAtom,
  cerStepsAtom,
  modifiedObjectiveIdAtom,
  modifiedStepIdAtom,
  objectiveCategoriesAtom,
  cerObstaclesAtom,
  operatorsAtom,
  pathTypesAtom,
} from "./CERAtoms";

const useCERData = () => {
  const [cerObjectives, setCerObjectives] = useAtom(cerObjectivesAtom);
  const [cerSteps, setCerSteps] = useAtom(cerStepsAtom);
  const [modifiedObjectiveId, setModifiedObjectiveId] = useAtom(modifiedObjectiveIdAtom);
  const [modifiedStepId, setModifiedStepId] = useAtom(modifiedStepIdAtom);
  const [objectiveCategories, setObjectiveCategories] = useAtom(objectiveCategoriesAtom);
  const [pathTypes, setPathTypes] = useAtom(pathTypesAtom);
  const [operators, setOperators] = useAtom(operatorsAtom);
  const [cerObstacles, setCerObstacles] = useAtom(cerObstaclesAtom);

  const getCerObjectives = () => {
    dataService.get(`cer-goals`, (data) => {
      setCerObjectives(data);
    });
  };

  const addCerObjective = (objective) => {
    dataService.post(`cer-goals`, objective, (data) => {
      setCerObjectives([
        ...cerObjectives,
        {
          ...data,
        },
      ]);
    });
  };

  const modifyCerObjective = (objective) => {
    dataService.patch(`cer-goals/${objective.id}`, objective, (data) => {
      setCerObjectives(cerObjectives.map((o) => (o.id == data.id ? data : o)));
    });
  };

  const removeCerObjective = (objectiveId) => {
    const stepsOfObjective = cerSteps.find((c) => c.objectiveId == objectiveId);
    if (stepsOfObjective) {
      toast.error(
        "Veuillez supprimer toutes les démarches dans cet objectif avant de le supprimer."
      );
      return false;
    }
    setCerObjectives(cerObjectives.filter((o) => o.id != objectiveId));
  };

  const getCerSteps = () => {
    dataService.get(`cer-steps`, (data) => {
      setCerSteps(data);
    });
  };

  const addCerStep = (step) => {
    dataService.post(`cer-steps`, step, (data) => {
      setCerSteps([
        ...cerSteps,
        {
          ...data,
        },
      ]);
    });
  };

  const modifyCerStep = (step) => {
    dataService.patch(`cer-steps/${step.id}`, step, (data) => {
      setCerSteps(cerSteps.map((o) => (o.id == data.id ? data : o)));
    });
  };

  const removeCerStep = (stepId) => {
    setCerSteps(cerSteps.filter((o) => o.id != stepId));
  };

  const getCerOperators = () => {
    dataService.get(`themes`, (themes) => {
      let subThemes = [];
      themes.forEach((t) => subThemes.push(...t.subThemes));
      dataService.get(`operators`, (data) => {
        setOperators(
          data.map((op) => ({
            ...op,
            subThemesMatch: op.subThemes
              .map((sub) => {
                const stRef = subThemes.find((s) => s._id == sub);
                return stRef?.name;
              })
              .join("/"),
          }))
        );
      });
    });
  };

  const populateOperatorsSubThemes = (operatorsArray, subThemes) => {};

  const getCerObstacles = () => {
    dataService.get(`cer-obstacles`, (data) => {
      setCerObstacles(data);
    });
  };

  const addCerObstacle = (obstacle) => {
    dataService.post(`cer-obstacles`, obstacle, (data) => {
      setCerObstacles([
        ...cerObstacles,
        {
          ...data,
        },
      ]);
      toast.success("Frein ajouté");
    });
  };

  const modifyCerObstacle = (obstacle) => {
    dataService.patch(`cer-obstacles/${obstacle.id}`, obstacle, (data) => {
      setCerObstacles(cerObstacles.map((o) => (o.id == data.id ? data : o)));
      toast.success("Frein enregistré");
    });
  };

  const removeCerObstacle = (obstacleId) => {
    setCerObstacles(cerObstacles.filter((o) => o.id != obstacleId));
  };

  return {
    cerObjectives,
    cerSteps,
    cerObstacles,
    operators,
    pathTypes,
    setPathTypes,
    addCerObjective,
    addCerStep,
    modifyCerObjective,
    modifyCerStep,
    removeCerObjective,
    removeCerStep,
    modifiedObjectiveId,
    setModifiedObjectiveId,
    modifiedStepId,
    setModifiedStepId,
    objectiveCategories,
    setObjectiveCategories,
    getCerObjectives,
    getCerSteps,
    getCerOperators,
    addCerObstacle,
    getCerObstacles,
    modifyCerObstacle,
    removeCerObstacle,
  };
};

export default useCERData;
