import React, { useEffect, useState } from "react";
import Loader from "../../../common/Loader";
import RecipientRdvListItem from "./RecipientRdvListItem";
import dataService from "../../../../helpers/dataService";
import useStore from "../../../../context/useStore";
import { modalActions } from "../../../../context/actions/modalActions";
import CreateRdvOnTheFly from "../common/CreateRdvOnTheFly/CreateRdvOnTheFly";

const initialDays = [
  { id: 1, name: "Lundi" },
  { id: 2, name: "Mardi" },
  { id: 3, name: "Mercredi" },
  { id: 4, name: "Jeudi" },
  { id: 5, name: "Vendredi" },
];
const RecipientRdv = ({
  rdvs,
  errors,
  setErrors,
  recipientId,
  getRdvs,
  isLoading,
  rdspId,
  recipient,
}) => {
  const [state, dispatch] = useStore();
  const modActions = modalActions(state, dispatch);
  const [rdvSlots, setRdvSlots] = useState([]);
  const [isLoadingSlots, setIsLoadingSlots] = useState(false);
  const [rdvSelected, setRdvSelected] = useState(null);
  const [currentRdv, setCurrentRdv] = useState(null);
  const [originSelected, setOriginSelected] = useState(null);
  const [reasonSelected, setReasonSelected] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [reportRdvOpen, setReportRdvOpen] = useState(false);
  const [isReportSiteOpen, setIsReportSiteOpen] = useState(false);

  useEffect(() => {
    setReasonSelected(false);
  }, [originSelected]);

  function getReportDates(rdv, siteId) {
    setReportRdvOpen(rdv.id);
    setRdvSlots([]);
    setIsSaved(false);
    setRdvSelected(null);
    setCurrentRdv(rdv);
    if (rdv.slotCalendar.type == 3) {
      //si c'est un rdv CER, on sélectionne pas de slot, on crée à la volée (CreateRdvOnTheFly)
      setRdvSlots([]);
    } else {
      setIsLoadingSlots(true);
      dataService.post(
        `slotcalendars/sites/${siteId}`,
        {
          userRecipientId: recipientId,
          slotCalendarId: rdv.slotCalendarId,
          isReport: true,
        },
        setRdvSlots,
        setErrors,
        () => setIsLoadingSlots(false)
      );
    }
  }

  function saveReportRdv(slobObjOverride = null) {
    var slotObj = {};
    var postParams = {};
    if (slobObjOverride) {
      //si c'est un rdv non issue d'un slot calendar sélectionné (onTheFly)
      slotObj = slobObjOverride;
      postParams = {
        slotsId: null,
        start: slotObj.start,
        end: slotObj.end,
        slotCalendarId: currentRdv.slotCalendarId,
        reportType: reasonSelected,
        cancelOrigin: originSelected,
        box: slotObj.box,
        site: slotObj.site,
        type: slotObj.type,
        subType: slotObj.subType,
        title: slotObj.title,
        isPhoneCall: slotObj.isPhoneCall,
      };
    } else {
      slotObj = rdvSlots.find((s) => s.id == rdvSelected);
      postParams = {
        slotsId: slotObj.slotsId,
        start: slotObj.start,
        end: slotObj.end,
        slotCalendarId: currentRdv.slotCalendarId,
        reportType: reasonSelected,
        cancelOrigin: originSelected,
        isPhoneCall: slotObj.isPhoneCall,
      };
    }

    setIsSaving(true);

    dataService.post(
      `slotcalendars/recipients/${recipientId}`,
      postParams,
      (data) => {
        setIsReportSiteOpen(false);
        setReportRdvOpen(false);
        getRdvs();
        setIsSaved(true);
      },
      setErrors,
      () => setIsSaving(false)
    );
  }

  const openRdvOnTheFlyModal = () => {
    modActions.updateModal({
      isOpen: true,
      content: (
        <CreateRdvOnTheFly
          organizationSlugName="CAF"
          recipient={recipient}
          onSaved={getRdvs}
        />
      ),
    });
  };

  const orientationRdvs = rdvs.filter((rdv) =>
    [1, 2].includes(rdv.slotCalendar.type)
  );
  const cerRdvs = rdvs.filter((rdv) => rdv.slotCalendar.type == 3);

  return (
    <>
      <ul className="list-group my-4">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-12 col-md-6">
              <h5 className="mb-3">
                <div style={{ lineHeight: "36px" }}>
                  Rendez-vous d'orientation
                </div>
              </h5>

              {orientationRdvs.map((rdv, rdvk) => {
                return (
                  <RecipientRdvListItem
                    key={"rdvorientation" + rdvk}
                    rdv={rdv}
                    rdvIndex={rdvk}
                    getReportDates={getReportDates}
                    errors={errors}
                    rdvSlots={rdvSlots}
                    setOriginSelected={setOriginSelected}
                    originSelected={originSelected}
                    reasonSelected={reasonSelected}
                    saveReportRdv={saveReportRdv}
                    isSaved={isSaved}
                    isSaving={isSaving}
                    rdvSelected={rdvSelected}
                    rdspId={rdspId}
                    reportRdvOpen={reportRdvOpen}
                    isLoadingSlots={isLoadingSlots}
                    setReasonSelected={setReasonSelected}
                    setRdvSelected={setRdvSelected}
                    isReportSiteOpen={isReportSiteOpen}
                    setIsReportSiteOpen={setIsReportSiteOpen}
                  />
                );
              })}
            </div>
            <div className="col-12 col-md-6">
              <h5 className="mb-3 d-flex align-items-center justify-content-between">
                <div style={{ lineHeight: "36px" }}>Rendez-vous CER</div>
                <button
                  className="btn btn-secondary btn-sm border"
                  onClick={openRdvOnTheFlyModal}
                >
                  Créer un rendez-vous CER à la volée
                </button>
              </h5>

              {cerRdvs.map((rdv, rdvk) => {
                return (
                  <RecipientRdvListItem
                    key={"rdvcer" + rdvk}
                    rdv={rdv}
                    rdvIndex={rdvk}
                    getReportDates={getReportDates}
                    errors={errors}
                    rdvSlots={rdvSlots}
                    setOriginSelected={setOriginSelected}
                    originSelected={originSelected}
                    reasonSelected={reasonSelected}
                    saveReportRdv={saveReportRdv}
                    isSaved={isSaved}
                    isSaving={isSaving}
                    rdvSelected={rdvSelected}
                    rdspId={rdspId}
                    reportRdvOpen={reportRdvOpen}
                    isLoadingSlots={isLoadingSlots}
                    setReasonSelected={setReasonSelected}
                    setRdvSelected={setRdvSelected}
                    isReportSiteOpen={isReportSiteOpen}
                    setIsReportSiteOpen={setIsReportSiteOpen}
                  />
                );
              })}
            </div>
          </div>
        )}
      </ul>
    </>
  );
};

export default RecipientRdv;
