import React from "react";
import useCERData from "../../../Hooks/useCERData/useCERData";
import AddObstacleForm from "./AddObstacleForm";
import ObstacleItem from "./ObstacleItem";

const ObstacleList = () => {
  const { cerObstacles } = useCERData();

  return (
    <>
      <ul className="list-group">
        {cerObstacles.map((obstacle) => (
          <ObstacleItem obstacle={obstacle} key={`obstacle${obstacle.id}`} />
        ))}
        <AddObstacleForm />
      </ul>
    </>
  );
};

export default ObstacleList;
