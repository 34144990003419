import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import DynamicList from "../../common/DynamicList";
import PostalCode from "../../common/PostalCode";
import useManagers from "../../Hooks/useManagers/useManagers";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";
import useRecipientStock from "../RecipientStockList/useRecipientStock";

const RecipientStockInfo = () => {
  const [state] = useStore();
  const { currentRecipientStock } = useRecipientStock();
  const [recipient, setRecipient] = useState(currentRecipientStock);
  const [errors, setErrors] = useState({});
  const { getManagers, managers } = useManagers();
  const { organizations, onGetOrganizations } = useOrganizations();
  const constants = state.constants.items;

  const updateData = (e) => {
    const { name, value } = e.target;
    setRecipient({ ...recipient, [name]: value });
  };
  const allDisabled = true;

  useEffect(() => {
    if (managers.length == 0) {
      getManagers();
    }
  }, [managers]);
  useEffect(() => {
    if (organizations.length == 0) {
      onGetOrganizations();
    }
  }, [organizations]);

  useEffect(() => {
    setRecipient(currentRecipientStock);
  }, [currentRecipientStock]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="custom-card">
          <Control
            disabled={allDisabled}
            label="N° d'allocataire"
            name="CAFId"
            value={recipient.CAFId}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="NIR"
            name="nir"
            value={recipient.nir}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={true}
            label="Orientation"
            name="lastOrientation"
            type="select"
            value={recipient.lastOrientation.orientation}
            change={updateData}
            dataIndex="slugName"
            dataLabel="name"
            datas={organizations}
            error={errors}
          />
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="custom-card">
          <Control
            label="Origine"
            value={
              tools.findIn(constants.RECIPIENT_ORIGINS, "id", recipient.origin)
                .name
            }
            error={errors}
            disabled={true}
          />
          <Control
            label="Mise à jour SID"
            name="updatedFromSIDAt"
            value={tools.formatDate(recipient.updatedFromSIDAt)}
            change={() => {}}
            disabled={true}
          />
          <Control
            label="Import SID"
            name="importedFromSIDAt"
            value={tools.formatDate(recipient.importedFromSIDAt)}
            change={() => {}}
            disabled={true}
          />
        </div>
      </div>
      <div className="col-12 col-md-6 mt-3">
        <div className="custom-card">
          <Control
            disabled={allDisabled}
            label="Civilité"
            type="btnList"
            btnInline={true}
            datas={constants.GENDERS}
            name="gender"
            value={recipient.gender}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Nom"
            name="lastname"
            value={recipient.lastname}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Prénom"
            name="firstname"
            value={recipient.firstname}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Date de naissance"
            name="birthdate"
            type="date"
            value={DateTime.fromISO(recipient.birthdate).toFormat("yyyy-MM-dd")}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Nombre d'enfants à charge au sens des PF"
            type="numberInt"
            name="nbDependantChildren"
            value={recipient.nbDependantChildren || 0}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Nombre de personnes à charge"
            type="numberInt"
            name="nbChildrenOrDependant"
            value={recipient.nbChildrenOrDependant}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Situation familiale"
            type="select"
            datas={constants.MARITAL_STATUS}
            name="maritalStatus"
            value={recipient.maritalStatus}
            change={updateData}
            error={errors}
          />
        </div>
      </div>

      <div className="col-12 col-md-6 mt-3">
        <div className="custom-card">
          <Control
            disabled={allDisabled}
            label="Email"
            name="email"
            type="text"
            value={recipient.email}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Téléphone"
            type="tel"
            name="phone"
            value={recipient.phone}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Téléphone 2"
            type="tel"
            name="phone2"
            value={recipient.phone2}
            change={updateData}
            error={errors}
          />
          <PostalCode
            disabled={allDisabled}
            value={recipient.cityId}
            name="cityId"
            updateData={updateData}
            inputLabel="Code postal"
            error={errors}
            location={recipient.location}
          />
          <Control
            disabled={allDisabled}
            label="Adresse"
            name="address"
            value={recipient.address}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Complément d'adresse"
            name="addressAdd"
            value={recipient.addressAdd}
            change={updateData}
            error={errors}
          />
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="custom-card">
          <DynamicList
            listLabel={
              <>
                Droits{" "}
                <span className="badge badge-primary ml-2">
                  {recipient.rights.length}
                </span>
              </>
            }
            disabled={allDisabled}
            uniqueKey="recrights-1"
            name="rights"
            fields={[
              {
                label: "Type",
                name: "type",
                type: "select",
                datas: constants.RSA_TYPES,
                dataIndex: "id",
                dataLabel: "name",
                disabled: allDisabled,
              },
              {
                label: "Depuis",
                name: "beginAt",
                type: "date",
                disabled: allDisabled,
              },
              {
                label: "Fin",
                name: "endAt",
                type: "date",
                disabled: allDisabled,
              },
            ]}
            items={recipient.rights ? recipient.rights : []}
            change={updateData}
            error={errors}
          />
          <DynamicList
            listLabel={
              <>
                Droits et devoirs{" "}
                <span className="badge badge-primary ml-2">
                  {recipient.rightsAndDuties.length}
                </span>
              </>
            }
            disabled={allDisabled}
            uniqueKey="recrightsandduties-1"
            name="rightsAndDuties"
            fields={[
              {
                label: "Depuis",
                name: "beginAt",
                type: "date",
                disabled: allDisabled,
                inputStyle: { maxWidth: 148 },
              },
              {
                label: "Fin",
                name: "endAt",
                type: "date",
                disabled: allDisabled,
                inputStyle: { maxWidth: 148 },
              },
              {
                label: "État SDD",
                name: "stateSdd",
                type: "text",
                disabled: true,
                inputStyle: { maxWidth: 34 },
              },
              {
                label: "Date m.à.j",
                name: "updatedFromSIDAt",
                type: "date",
                disabled: true,
                inputStyle: { maxWidth: 148 },
              },
            ]}
            items={
              recipient.rightsAndDuties
                ? recipient.rightsAndDuties.map((rr) => {
                    var rrAug = { ...rr };
                    var isValid = new Date() >= new Date(rrAug.beginAt);
                    if (rrAug.endAt) {
                      isValid = isValid && new Date(rrAug.endAt) >= new Date();
                    }
                    rrAug.stateSdd = isValid ? "S" : "N";

                    return rrAug;
                  })
                : []
            }
            change={updateData}
            error={errors}
          />
          <DynamicList
            listLabel={
              <>
                Contrats{" "}
                <span className="badge badge-primary ml-2">
                  {recipient.contracts.length}
                </span>
              </>
            }
            disabled={allDisabled}
            uniqueKey="contracts-1"
            name="contracts"
            fields={[
              {
                label: "Type",
                name: "type",
                type: "select",
                datas: constants.CONTRACT_TYPES,
                dataIndex: "id",
                dataLabel: "name",
              },
              {
                label: "Depuis",
                name: "beginAt",
                type: "date",
              },
              {
                label: "Fin",
                name: "endAt",
                type: "date",
              },
            ]}
            items={recipient.contracts ? recipient.contracts : []}
            change={updateData}
            error={errors}
          />
        </div>
      </div>

      <div className="col-12  mt-3">
        <div className="custom-card">
          <Control
            label="Situation professionnelle"
            type="select"
            disabled={allDisabled}
            name="professionalSituation"
            value={recipient.professionalSituation}
            change={updateData}
            error={errors}
            datas={constants.PROFESSIONAL_SITUATIONS}
          />
          <Control
            label="Durée expérience"
            type="text"
            disabled={allDisabled}
            name="jobSearchExp"
            value={recipient.jobSearchExp}
            change={updateData}
            error={errors}
            suffix={<span className="d-center px-2">an(s)</span>}
          />
          <Control
            label="Projet professionnel"
            type="textarea"
            disabled={allDisabled}
            name="professionalProject"
            value={recipient.professionalProject}
            change={updateData}
            error={errors}
          />
          <Control
            label="Permis de conduire"
            type="checkBtnList"
            disabled={allDisabled}
            name="driveLicenses"
            value={recipient.driveLicenses || []}
            datas={constants.DRIVE_LICENSES}
            btnInline
            btnSm
            change={updateData}
            error={errors}
          />
          <Control
            label="Moyens de transport"
            type="checkBtnList"
            disabled={allDisabled}
            name="transports"
            value={recipient.transports || []}
            datas={constants.TRANSPORTS}
            btnInline
            btnSm
            change={updateData}
            error={errors}
          />
        </div>
      </div>

      <div className="col-12 col-lg-6 mt-3">
        <div className="custom-card">
          <Control
            label="N° d'inscription à France Travail"
            disabled={allDisabled}
            type="text"
            name="numSubscribePE"
            value={recipient.numSubscribePE}
            change={updateData}
            error={errors}
          />
          <Control
            label="Emploi recherché en priorité"
            disabled={allDisabled}
            type="text"
            name="jobSearch"
            value={recipient.jobSearch}
            change={updateData}
            error={errors}
          />
        </div>
      </div>
    </div>
  );
};

export default RecipientStockInfo;
