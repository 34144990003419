import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./main.scss";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import EventModal from "./EventModal";
import OptionModal from "./OptionModal";
import frLocale from "@fullcalendar/core/locales/fr";
import $ from "jquery";
import useStore from "../../context/useStore";
import colors from "../../helpers/colors";

const eventInitialState = {
  title: "RDV",
  start: new Date().setMinutes(0),
  end: new Date().setMinutes(0),
  slotCalendarConfig: {
    start: "",
    end: "",
    frequency: "",
    byDayOfWeek: [],
    byDayOfMonth: [],
    interval: 1,
  },
};

const calendarParams = {
  hourSlots: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  minuteSlots: [15, 30, 45],
  minTime: "06:00:00",
  maxTime: "18:00:00",
};
var isInit = false;

const Calendar = ({
  events,
  type = 1,
  setSearch,
  search,
  site,
  postEvent,
  patchEvent,
  deleteEvent,
  errors,
  canModify,
}) => {
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [changeOption, setChangeOption] = useState(1);
  const [optionModalOpen, setOptionModalOpen] = useState(false);
  const [dropInfo, setDropInfo] = useState(false);
  const [eventInfo, setEventInfo] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [state, dispatch] = useStore();
  const slotTypes = state.constants.items.SLOT_TYPES;

  useEffect(() => {
    $("#eventModal1").on("hide.bs.modal", function () {
      setModalOpen(false);
    });
    $("#optionModal1").on("hide.bs.modal", function () {
      setOptionModalOpen(false);
    });
  }, []);

  useEffect(() => {
    setModalOpen(false);
    setOptionModalOpen(false);
  }, [events]);

  function addEvent(event) {
    var evs = JSON.parse(JSON.stringify(events));
    var ev = {
      type: type,
      id: evs.length + 1,
      title: event.title,
      start: event.start,
      end: event.end,
      slotCalendarConfig: event.slotCalendarConfig,
      box: event.box,
      site: site.id,
    };
    ev.slotCalendarConfig.start = ev.start;

    postEvent(ev);
  }
  function updateEvent(event) {
    event.slotCalendarConfig.start = event.start;

    patchEvent(event);
  }

  function dateClick(date) {
    if (!canModify) return false;
    setSelectedDate(date.date);
    var ev = JSON.parse(JSON.stringify(eventInitialState));
    ev.start = new Date(JSON.parse(JSON.stringify(date.date)));
    ev.end = new Date(date.date.setMinutes(date.date.getMinutes() + 30));
    ev.slotCalendarConfig.start = ev.start;
    ev.slotCalendarConfig.end = ev.end;
    setSelectedEvent(ev);

    setModalOpen(true);
  }
  function eventClick(ev) {
    if (!canModify) return false;
    var eventSel = events.find((e) => e.id == ev.event.id);

    setSelectedEvent(eventSel);
    setSelectedDate(eventSel.start);
    setModalOpen(true);
  }
  function eventDropResize(info) {
    if (!canModify) {
      cancelDrop(info, true);
      return false;
    }
    var ev = JSON.parse(
      JSON.stringify(events.find((e) => e.id == info.event.id))
    );
    if (info.event.slotCalendarConfig) {
      ev.slotCalendarConfig = info.event.slotCalendarConfig;
    }
    ev.start = info.event.start;
    ev.end = info.event.end;
    ev.start = new Date(ev.start).toISOString();
    ev.end = new Date(ev.end).toISOString();

    ev.slotCalendarConfig.start = ev.start;

    if (ev.slotCalendarConfig.frequency != "") {
      setEventInfo(ev);
      setDropInfo(info);
      setOptionModalOpen(true);
    } else {
      updateEvent(ev);
    }
  }
  function confirmDrop() {
    if (isDelete) {
      deleteEvent(dropInfo.id, changeOption == 1 ? "0" : "1");
      setIsDelete(false);
    } else {
      var ev = JSON.parse(JSON.stringify(eventInfo));
      if (changeOption == 1) {
        ev.isRecursive = false;
      } else {
        ev.isRecursive = true;
      }
      updateEvent(ev);
    }
  }
  function cancelDrop(info = false, force = false) {
    var di = info && force ? info : dropInfo;
    if (!isDelete) {
      try {
        di.revert();
      } catch (err) {}
    }
  }
  function removeEvent(event) {
    if (event.slotCalendarConfig.frequency != "") {
      setDropInfo(event);
      setIsDelete(true);
      setOptionModalOpen(true);
    } else {
      deleteEvent(event.id, changeOption == 1 ? "0" : "1");
    }
  }
  return (
    <>
      <FullCalendar
        locale={frLocale}
        slotMinTime={calendarParams.minTime}
        slotMaxTime={calendarParams.maxTime}
        initialView="timeGridWeek"
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        dateClick={dateClick}
        eventClick={eventClick}
        eventDrop={eventDropResize}
        eventResize={eventDropResize}
        editable={true}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        events={events.map((e) => ({ ...e, title: `(${e.title})` }))}
        eventDidMount={(info) => {
          if (info.event.extendedProps.userRecipient) {
            var ur = info.event.extendedProps.userRecipient;

            $(info.el).prepend(
              `<i class="fa fa-user" style="font-size: 10px;
              float: left;
              color: yellow;
              margin-right: 2px;
              padding: 1px 2px;" />` +
                (info.event.extendedProps.isClosed
                  ? `<i class="fa fa-check" style="font-size: 10px;
              float: left;
              color: yellow;
              margin-right: 2px;
              padding: 1px 2px;" />`
                  : "")
            );

            $(info.el).attr("data-toggle", "tooltip");
            $(info.el).attr("data-placement", "top");
            $(info.el).attr(
              "title",
              (ur.gender == 1 ? "Mr " : "Mme ") +
                ur.lastname +
                " " +
                ur.firstname
            );
          }
          let type = info.event.extendedProps.type;
          let color = colors[slotTypes.find((s) => s.id == type).color];
          $(info.el).css({
            background: color,
            fontWeight: "bold",
            borderColor: color,
          });
        }}
        showNonCurrentDates={false}
        allDaySlot={false}
        nowIndicator={true}
        slotDuration={"00:15:00"}
        datesSet={(view) => {
          var sh = JSON.parse(JSON.stringify(search));
          sh.start = view.view.currentStart;
          sh.end = view.view.currentEnd;
          if (isInit) {
            setSearch(sh);
          } else {
            isInit = true;
          }
        }}
      />

      <EventModal
        modalOpen={modalOpen}
        selectedEvent={selectedEvent}
        selectedDate={selectedDate}
        setSelectedEvent={setSelectedEvent}
        title={"Evénement"}
        k={1}
        addEvent={addEvent}
        eventDropResize={eventDropResize}
        removeEvent={removeEvent}
        hourSlots={calendarParams.hourSlots}
        minuteSlots={calendarParams.minuteSlots}
        site={site}
        errors={errors}
      />
      <OptionModal
        k={1}
        optionModalOpen={optionModalOpen}
        setChangeOption={setChangeOption}
        changeOption={changeOption}
        isDelete={isDelete}
        confirmDrop={confirmDrop}
        cancelDrop={cancelDrop}
      />
    </>
  );
};

export default Calendar;
