import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import tools from "../../../helpers/tools";
import useCERModule from "../RecipientDetail/CERModule/hooks/useCERModule";
import { AmendmentContext } from "./AmendmentContext";

const AmendmentContractInfo = ({ CER_DURATIONS }) => {
  const currentAmendment = useContext(AmendmentContext);
  const { recipientCER } = useCERModule();
  var styles = StyleSheet.create({
    container: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    text: {
      fontSize: 9,
    },
  });

  const getDateFormatted = (dateISO) =>
    DateTime.fromISO(dateISO).setLocale("FR").toFormat("dd LLLL yyyy");

  const signDate =
    currentAmendment.status == "DRAFT"
      ? ""
      : currentAmendment.signedAt
      ? DateTime.fromISO(currentAmendment.signedAt).setLocale("FR").toFormat("dd LLLL yyyy")
      : null;

  const isRenew = currentAmendment.type == "RENEWED";
  const timeRangeEntity = isRenew ? currentAmendment : recipientCER;

  return (
    <View style={{ ...styles.container }}>
      <Text style={{ ...styles.text }}>
        Durée du contrat : {getDateFormatted(timeRangeEntity.beginAt)} au{" "}
        {getDateFormatted(timeRangeEntity.endAt)}
      </Text>
      <Text style={{ ...styles.text }}>
        {isRenew ? "Renouvellement" : "Avenant"} du : {getDateFormatted(currentAmendment.createdAt)}
      </Text>
      <Text style={{ ...styles.text }}>
        Date de signature {isRenew ? "du renouvellement" : "de l'avenant"} : {signDate}
      </Text>
    </View>
  );
};

export default AmendmentContractInfo;
