import React, { useState } from "react";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Control";
import Loader from "../../common/Loader";

const CreateRdv = ({ site, recipientId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState(false);
  const createRdv = () => {
    setIsLoading(true);

    dataService.post(
      "slotcalendars/createtoeg",
      {
        slotCalendarDatas: {
          site: site.id,
          box: selectedBox,
          type: 2,
          title: site.boxes.find((b) => b._id == selectedBox).name,
        },
        recipientId: recipientId,
      },
      (datas) => {
        if (window.confirm("Souhaitez-vous être redirigé vers la page du rendez-vous ?")) {
          window.location.href = `/rdvs?organization=${site.organization}&site=${site.id}&box=${selectedBox}`;
        }
      },
      (errors) => {},
      () => {}
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="col-12 bg-light p-3">
      <h5>Positionner un RDV immédiatement</h5>
      <Control
        label="Choisissez un box"
        type="select"
        name="selectedBox"
        value={selectedBox}
        datas={site.boxes}
        dataIndex="_id"
        change={(e) => setSelectedBox(e.target.value)}
      />
      <button
        className="btn btn-warning mx-2"
        onClick={createRdv}
        disabled={selectedBox ? false : true}
      >
        Créer un RDV et le prendre en charge immédiatement
      </button>
    </div>
  );
};

export default CreateRdv;
