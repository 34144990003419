import React from "react";
import { Link } from "react-router-dom";

const Page = ({
  container = "container",
  containerClassname = "",
  title = false,
  subTitle = "",
  children,
  errors = null,
  back = null,
  action = null,
  style = { maxWidth: "100%" },
  noMargin = false,
  bgTransparent = false,
  noPadding = false,
}) => {
  return (
    <div
      className={container + (noMargin ? " " : " my-5 ") + containerClassname}
      style={style}
    >
      <div
        className={
          " d-flex align-items-center animated fadeInDown faster " +
          (title ? "mb-3" : "")
        }
      >
        {back && (
          <Link
            to={back}
            className="btn mr-3 shadow-none "
            style={{ padding: "15px 20px", borderRadius: "100%" }}
          >
            <i className="fa fa-arrow-left"></i>
          </Link>
        )}

        <h1
          className="align-items-center d-flex justify-content-between mb-0 mx-0 text-center w-100"
          style={{ fontSize: "1.5rem" }}
        >
          {title}
          {action && (
            <div data-priv={action.dataPriv ? action.dataPriv : ""}>
              <Link to={action.to} className="btn btn-primary float-right ml-3">
                <i className="fa fa-plus mr-2"></i>
                {action.text}
              </Link>
            </div>
          )}
        </h1>
      </div>
      {subTitle && (
        <div className="mb-3">
          <small>{subTitle}</small>
        </div>
      )}
      {errors && errors.other && (
        <div className="alert alert-danger">{errors.other}</div>
      )}
      <div
        className={
          bgTransparent
            ? noPadding
              ? ""
              : "p-4"
            : "p-4 bg-white rounded shadow-sm"
        }
      >
        {children}
      </div>
    </div>
  );
};

export default Page;
