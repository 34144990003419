import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useContext } from "react";
import useCERData from "../../Hooks/useCERData/useCERData";
import { AmendmentContext } from "./AmendmentContext";
import AmendmentGoalsList from "./AmendmentGoalsList";
import { PathTypeContext } from "./PathTypeContext";

const AmendmentPathTypesList = ({ constants }) => {
  const currentAmendment = useContext(AmendmentContext);
  const { pathTypes } = useCERData();

  const filteredPathTypes = pathTypes.filter((pt) => {
    return currentAmendment.goals.find((g) => g.pathType == pt.id);
  });

  var styles = StyleSheet.create({
    container: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "grey",
      padding: 10,
      marginTop: 10,
    },
    title: {
      fontSize: 13,
      paddingBottom: 4,
    },
  });

  return filteredPathTypes.map((pathType) => {
    return (
      <View style={{ ...styles.container }} key={`pathtype${pathType.id}`} wrap={false}>
        <Text style={{ ...styles.title }}>{pathType.name}</Text>
        <PathTypeContext.Provider value={pathType}>
          <AmendmentGoalsList constants={constants} />
        </PathTypeContext.Provider>
      </View>
    );
  });
};

export default AmendmentPathTypesList;
