import { DateTime } from "luxon";
import React from "react";
import { toast } from "react-toastify";
import tools from "../../../../../helpers/tools";
import SaveBtn from "../../../../common/SaveBtn";
import useStoreState from "../Context/Store/useStoreState";

const EpCalling = ({ setShowCalling = false }) => {
  const { state, gState, items, items_actions } = useStoreState();

  var { ep } = items;
  var { jury } = ep;
  const myId = gState.auth.user.id;

  return myId != ep.writer ? (
    <div>
      <p className="text-center mb-0">
        Vous ne pouvez pas modifier la liste d'appel car vous n'êtes pas
        rédacteur sur cet EP.
      </p>
      <button className="btn btn-link" onClick={() => window.location.reload()}>
        Actualiser la page
      </button>
    </div>
  ) : (
    <div className="row animated fadeInRight faster">
      <div className="col-12 col-md-6 border-right">
        <h4
          className=" d-flex w-100 justify-content-between align-items-center"
          style={{ fontSize: 16 }}
        >
          Utilisateurs inscrits
          <div
            className="d-center rounded-circle bg-dark text-white"
            style={{
              width: 40,
              height: 40,
            }}
          >
            {jury.filter((j) => !j.isPresent).length}
          </div>
        </h4>
        <ul className="list-group">
          {jury
            .filter((j) => !j.isPresent)
            .map((j) => {
              var user = j.userManager;
              return (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center p-2 border-top-0 border-left-0 border-right-0 border-bottom mb-1 rounded-0 animated fadeInUp faster"
                  key={`memberun${user.id}`}
                >
                  <div>{tools.getFullname(user)}</div>
                  <div>
                    <button
                      className="btn btn-success"
                      onClick={() => items_actions.callMember(user.id, true)}
                    >
                      <i className="fa fa-angle-double-right" />
                    </button>
                  </div>
                </li>
              );
            }) || null}
        </ul>
      </div>
      <div className="col-12 col-md-6">
        <h4
          style={{ fontSize: 16 }}
          className="text-success d-flex w-100 justify-content-between align-items-center"
        >
          <div
            className="d-center rounded-circle bg-success text-white"
            style={{
              width: 40,
              height: 40,
            }}
          >
            {jury.filter((j) => j.isPresent).length}
          </div>
          Utilisateurs présents
        </h4>
        <ul className="list-group">
          {jury
            .filter((j) => j.isPresent)
            .map((j) => {
              var user = j.userManager;
              return (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center p-2 border-top-0 border-left-0 border-right-0 border-bottom mb-1 rounded-0 animated fadeInUp faster"
                  key={`members${user.id}`}
                >
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-dark"
                      onClick={() => items_actions.callMember(user.id, false)}
                    >
                      <i className="fa fa-angle-double-left" />
                    </button>
                  </div>
                  <div>{tools.getFullname(user)}</div>
                </li>
              );
            })}
        </ul>
      </div>

      <div className="col-6 mt-3">
        <div className="d-flex justify-content-end w-100 text-danger font-weight-bold">
          {jury.filter((j) => !j.isPresent).length} absent(s)
        </div>
      </div>
      <div className="col-6 mt-3">
        <div className="d-flex justify-content-start w-100 text-success font-weight-bold">
          {jury.filter((j) => j.isPresent).length} présent(s)
        </div>
      </div>
      <div className="col-12 d-flex w-100 justify-content-center">
        <SaveBtn
          save={items_actions.saveCalling}
          isSaving={state.isLoadingCalling}
          text={
            <div className="d-center">
              APPEL TERMINÉ
              <i className="fa fa-chevron-right ml-2" />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default EpCalling;
