import React, { useEffect } from "react";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";
import AllRecipientsItem from "./AllRecipientsItem";
import useAllRecipients from "./useAllRecipients";

const AllRecipientsList = () => {
  const { allRecipients } = useAllRecipients();
  const { organizations, onGetOrganizations } = useOrganizations();

  useEffect(() => {
    if (organizations.length == 0) onGetOrganizations();
  }, []);

  return (
    <div className="row mb-5">
      {allRecipients.map((recipient) => {
        return (
          <div className="col-12 col-md-4">
            <AllRecipientsItem recipient={recipient} key={`rec${recipient.id}`} />
          </div>
        );
      })}
    </div>
  );
};

export default AllRecipientsList;
