import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { api_url } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import RecipientTempInfo from "./RecipientTempInfo";
import { toast } from "react-toastify";
import RecipientInfo from "../RecipientCreateEP/RecipientInfo";
import RecipientInfoPopulated from "./RecipientInfoPopulated";
import dataService from "../../../helpers/dataService";

const RecipientCreateEpDetail = (props) => {
  const [state, dispatch] = useStore();

  const [recipient, setRecipient] = useState({
    nir: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    cityId: "",
    email: "",
    CAFId: "",
    location: {
      cityId: "",
      postalCode: "",
      name: "",
      locationName: "",
    },
  });
  const [events, setEvents] = useState(false);
  const [rdsps, setRdsps] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [allDisabled, setAllDisabled] = useState(true);
  const [formConfig, setFormConfig] = useState(false);

  var idToGet = props.recipientId ? props.recipientId : props.match.params.id;
  useEffect(() => {
    Axios.get(api_url + "recipients_ep/" + idToGet)
      .then((res) => {
        setRecipient(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }, []);

  const saveEPRecipient = (rec) => {
    setIsSaving(true);
    dataService.patch(
      `recipients_ep/${rec.id}`,
      rec,
      (datas) => {
        toast.success("L'allocataire EP a bien été mis à jour");
      },
      (err) => {
        setErrors(err);
      },
      () => setIsSaving(false)
    );
  };

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page
      noMargin={props.recipientId}
      bgTransparent
      container={`container-fluid ${props.recipientId ? "" : "px-5"}`}
      title={props.recipientId ? false : "Détail allocataire EP"}
      errors={errors}
      back={props.recipientId ? false : "/recipients-ep"}
    >
      <RecipientInfoPopulated
        recipient={recipient}
        allDisabled={true}
        isEP={true}
        saveRecipient={saveEPRecipient}
        isSaving={isSaving}
      />
      {/* <RecipientInfo
            recipient={recipient}
            genders={state.constants.items.GENDERS}
            maritalStatuses={state.constants.items.MARITAL_STATUS}
            errors={errors}
            updateData={() => {}}
          /> */}
    </Page>
  );
};

export default RecipientCreateEpDetail;
