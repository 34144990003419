import React, { useContext } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERData from "../../../../../../../../Hooks/useCERData/useCERData";
import useCERModule from "../../../../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { StepContext } from "../StepContext";
import { ObstacleContext } from "./ObstacleContext";
import ObstacleStatus from "./ObstacleStatus";

const IconBlock = ({ icon, children }) => {
  return (
    <div className="text-black-50">
      <i className={`fa fa-${icon} pr-1`} />
      {children}
    </div>
  );
};

const ObstacleView = ({ goToEditMode, disabled = false }) => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const obstacle = useContext(ObstacleContext);
  const { cerObstacles } = useCERData();
  const { recipientCER, events, isRecipientCERDraft } = useCERModule();
  const obstacleRef = cerObstacles.find((o) => o.id == obstacle.obstacle);
  var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);
  var obstacleIndex = tools.getStepIndex(step.obstacles, obstacle);

  const deleteObstacle = () => {
    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((s, sk) => {
          return sk == stepIndex
            ? { ...s, obstacles: s.obstacles.filter((o, ok) => ok != obstacleIndex) }
            : s;
        }),
      ],
    };
    events.onUpdateObjectiveAndSave(newObjective, objectiveIndex);
  };

  return (
    <div
      className="d-flex align-items-center justify-content-between item-list-view"
      style={{ fontSize: 12 }}
    >
      <div className="py-2">
        {!isRecipientCERDraft && <ObstacleStatus disabled={disabled} />}
        <strong className="text-black-50">{obstacleRef?.name}</strong>
      </div>

      {!disabled && (
        <div>
          <button className="btn btn-sm" onClick={() => goToEditMode(obstacle.obstacle)}>
            <i className="fa fa-edit text-black-50" />
          </button>
          <button className="btn btn-sm" onClick={deleteObstacle}>
            <i className="fa fa-times text-black-50" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ObstacleView;
