import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import Axios from "axios";
import { api_url } from "../../config";

var cpto = null;

const PostalCode = ({
  value,
  updateData,
  location,
  name = "cityId",
  inputLabel = "",
  error,
  disabled = false,
  margin = "my-0",
}) => {
  const [cpInp, setCpInp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedText, setSelectedText] = useState(
    "entrez un cp puis choisissez dans la liste"
  );

  useEffect(() => {
    if (location && location.postalCode && location.name) {
      setSelectedText(location.postalCode + " - " + location.name);
    }
  }, [location]);

  useEffect(() => {
    clearTimeout(cpto);
    if (cpInp.length == 5) {
      cpto = setTimeout(() => {
        getLocation();
      }, 800);
    }
  }, [cpInp]);

  function getLocation() {
    setIsLoading(true);
    setResults([]);
    Axios.get(api_url + "cities?postalCode=" + cpInp)
      .then((res) => {
        setResults(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function updateSearch(value) {
    if ((value.length <= 5 && /^\d+$/.test(value)) || value.length == 0) {
      setCpInp(value);
    }
  }

  function changeData(res) {
    updateData({ target: { name: name, value: res.id, other: res } });
    setSelectedText(
      res.postalCode +
        " - " +
        res.townName +
        (res.locationName ? " - " + res.locationName : "")
    );
  }

  return (
    <>
      <div className={"form-group " + margin}>
        <label className="d-block">{inputLabel}</label>
        <p>
          <i>{selectedText}</i>
        </p>
        <div className="input-group">
          <input
            disabled={disabled ? "disabled" : false}
            type="text"
            className="form-control"
            id="postalCodeSearch"
            placeholder="Code postal"
            value={cpInp}
            onChange={(e) => updateSearch(e.target.value)}
          />
          <div className="input-group-append d-flex align-items-center border">
            <i className="fa fa-search mx-2" />
          </div>
        </div>
        <span
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </span>

        {isLoading && <Loader />}
        <ul className="list-group mt-3" id="cityList">
          {results.map((res, resk) => (
            <li
              key={"pc" + resk}
              style={{ cursor: "pointer" }}
              className={"list-group-item " + (res.id == value ? "active" : "")}
              onClick={() => changeData(res)}
            >
              {res.postalCode} - {res.townName}{" "}
              {res.locationName ? res.locationName : ""}
              {res.id == value && (
                <i
                  style={{ lineHeight: "19px" }}
                  className="fa fa-check animated fadeInRight float-right faster"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default PostalCode;
