import React from "react";
import useStoreState from "../../../Context/Store/useStoreState";
import DecisionReorientation from "./DecisionReorientation";
import DecisionSanction from "./DecisionSanction";

const AllocDecision = () => {
  const { items } = useStoreState();
  const recipient = items.utils.currentAlloc;
  return (
    <div className="custom-card">
      <strong className="text-primary">Avis de l'EP</strong>
      {recipient.type == "SANCTION" ? <DecisionSanction /> : null}
      {recipient.type == "REORIENTATION_KO" ? <DecisionReorientation /> : null}
    </div>
  );
};

export default AllocDecision;
