import { DateTime } from "luxon";
import React, { useState } from "react";
import Control from "../../../../common/Control";
import SaveBtn from "../../../../common/SaveBtn";
import useCERModule from "../hooks/useCERModule";

const SignButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [signedAt, setSignedAt] = useState(DateTime.local().toFormat("yyyy-MM-dd"));
  const { actions, isLoadingCER } = useCERModule();

  const toggleIsOpen = () => {
    setIsOpen((o) => !o);
  };

  const handleSignedAt = (e) => {
    setSignedAt(e.target.value);
  };

  const save = () => {
    actions.patchRecipientCERSigned(signedAt);
  };

  return (
    <div className="d-flex flex-column w-50 align-items-start">
      <button className="btn btn-default border bg-white" onClick={toggleIsOpen}>
        <span className="mr-2">Passer en état signé</span>
        <i className={`fa fa-chevron-${isOpen ? "up" : "down"}`} />
      </button>
      {isOpen && (
        <div className="alert alert-info">
          <div className="d-flex align-items-start justify-content-start w-100">
            <i className="fa fa-exclamation-circle mt-1 mr-2" />
            <p>
              Si ce contrat CER a été imprimé et signé par l'allocataire et son référent unique,
              vous pouvez le passer en état signé. Un contrat CER en état signé ne sera plus
              modifiable. Vous pourrez uniquement lui ajouter des avenants ou le renouveler à
              échéance.
            </p>
          </div>
          <Control label="Date de signature" type="date" value={signedAt} change={handleSignedAt} />
          <div className="d-flex justify-content-end">
            <SaveBtn
              save={save}
              isSaving={isLoadingCER}
              type="btn-info"
              text="Passer le contrat CER en état signé"
              margin=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SignButton;
