import React from "react";
import SaveBtn from "../../../../common/SaveBtn";
import BackToListButton from "../../common/BackToListButton/BackToListButton";
import CERContractForm from "../../common/CERContractForm/CERContractForm";
import CERContractFormSigned from "../../common/CERContractForm/CERContractFormSigned";
import RecipientInfoRecap from "../../common/RecipientInfoRecap/RecipientInfoRecap";
import SignButton from "../CEREditModule/SignButton";
import useAmendmentModule from "../hooks/useAmendmentModule";
import AmendmentGoalsForm from "./AmendmentGoalsForm.js/AmendmentGoalsForm";
import AmendmentSignButton from "./AmendmentSignButton";

const AmendmentEditModule = () => {
  const {
    currentAmendment,
    isCurrentAmendmentDraft,
    actions,
    isLoadingAmendment,
    isCurrentAmendmentRenew,
  } = useAmendmentModule();

  const getTitleText = () => {
    const entityName = isCurrentAmendmentRenew ? "Renouvellement" : "Avenant";
    const pronoun = isCurrentAmendmentRenew ? "du " : "de l'";
    const determ = `${pronoun}${entityName}`;
    if (isCurrentAmendmentDraft) {
      if (currentAmendment.id) {
        return `Modification ${determ} (brouillon)`;
      } else {
        return `Création ${determ} (brouillon)`;
      }
    } else {
      return `${entityName} (signé)`;
    }
  };

  const getSaveButtonText = () => {
    if (currentAmendment.id) {
      return `Enregistrer le brouillon`;
    } else {
      return "Créer le brouillon";
    }
  };

  const saveAmendment = () => {
    if (currentAmendment.id) {
      actions.patchAmendment();
    } else {
      actions.createAmendment();
    }
  };

  return (
    <div className="mb-3">
      <div>
        <BackToListButton />
      </div>
      <div>
        <h4 className="text-center">{getTitleText()}</h4>
      </div>
      {currentAmendment.type == "RENEWED" && (
        <>
          <div>
            <RecipientInfoRecap />
          </div>
          <div>
            {isCurrentAmendmentDraft ? (
              <CERContractForm isRenew />
            ) : (
              <CERContractFormSigned isRenew />
            )}
          </div>
        </>
      )}
      <div>
        <AmendmentGoalsForm />
      </div>
      {isCurrentAmendmentDraft && (
        <div className="d-flex justify-content-around">
          {currentAmendment.id && <AmendmentSignButton />}
          <div>
            <SaveBtn
              isSaving={isLoadingAmendment}
              text={getSaveButtonText()}
              save={saveAmendment}
              margin=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AmendmentEditModule;
