import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("ERROR BOUNDARIES CATCHED", error, errorInfo);
    this.setState({ error: error.stack });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="alert alert-warning">
          Quelque chose s'est mal passé... {this.state.error}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
