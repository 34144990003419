import React from "react";
import useCERData from "../../../../../Hooks/useCERData/useCERData";
import AddObjectiveForm from "../../../common/CERGoalsForm/AddObjectiveForm/AddObjectiveForm";
import ObjectivesListSigned from "../../../common/CERGoalsForm/ObjectivesList/ObjectivesListSigned";
import PathTypeBlock from "../../../common/CERGoalsForm/PathTypeBlock/PathTypeBlock";
import useAmendmentModule from "../../hooks/useAmendmentModule";
import AddAmendmentObjectiveForm from "./AddAmendmentObjectiveForm/AddAmendmentObjectiveForm";
import AmendmentObjectiveList from "./AmendmentObjectiveList/AmendmentObjectiveList";

const AmendmentGoalsForm = () => {
  const { pathTypes } = useCERData();
  const { isCurrentAmendmentDraft } = useAmendmentModule();

  return (
    <div className="row mx-0 my-3">
      <div className="col-12 mb-2">
        <div>
          <strong>Objectifs</strong>
        </div>
      </div>
      <div className="col-12">
        <div>
          {pathTypes.map((pathType) => (
            <PathTypeBlock pathType={pathType} key={`pathtype${pathType.id}`}>
              <AmendmentObjectiveList pathType={pathType} />

              {isCurrentAmendmentDraft && <AddAmendmentObjectiveForm pathType={pathType} />}
            </PathTypeBlock>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AmendmentGoalsForm;
