import axios from "axios";

import { FORM_TYPES } from "../reducers/formReducers";
import { DateTime } from "luxon";

export const formActions = (state, dispatch, props) => {
  function getTarif(datas) {
    dispatch({ type: FORM_TYPES.LOAD_FORM, payload: true });

    var formattedDatas = [];
    var spCp = ["973", "976"];

    datas.products.forEach((prod) => {
      if (!datas[prod]) return false;
      var cp = datas.postalCode.substring(0, 3);
      var postalCode = spCp.includes(cp) ? "973" : "974";

      formattedDatas.push({
        ...datas[prod],
        productType: prod,
        postalCode: postalCode,
        ape: datas.ape,
        turnover: datas.turnover,
        endAt: DateTime.fromISO(datas[prod].beginAt).plus({ year: 1 }).startOf("day").toISO(),
      });
    });

    axios
      .post(process.env.API_URL + "tarificators", formattedDatas)
      .then((res) => {
        dispatch({ type: FORM_TYPES.GET_TARIF, payload: res.data });
      })
      .catch((err) => {});
  }

  function displayBasket(isVisible) {
    dispatch({
      type: FORM_TYPES.DISPLAY_BASKET,
      payload: isVisible ? true : false,
    });
  }

  return {
    getTarif,
    displayBasket,
  };
};
