import React from "react";
import useCERModule from "../../../hooks/useCERModule";

const CERActionButtons = () => {
  const { recipientCER, isRecipientCERDraft, setCurrentCERView, currentRecipient } = useCERModule();

  const getCEREditActionText = () => {
    if (isRecipientCERDraft) {
      return "Modifier";
    } else {
      return "Consulter";
    }
  };

  const printCER = () => {
    window.open(`/cercontract/${currentRecipient.id}`, "Contrat CER", "height=400,width=400");
  };

  return (
    <div className="d-flex align-items-center justify-content-around w-100">
      <button className="btn btn-primary" onClick={() => setCurrentCERView("CER_EDIT")}>
        {getCEREditActionText()}
      </button>
      <button className="btn btn-link" onClick={printCER}>
        Imprimer
      </button>
    </div>
  );
};

export default CERActionButtons;
