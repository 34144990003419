import React, { useState } from "react";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Control";
import SaveBtn from "../../../common/SaveBtn";
import SimpleDynamicList from "../../../common/SimpleDynamicList";

const SectorDetail = ({ sector, refresh }) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const [mySector, setMySector] = useState(sector);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMySector({ ...mySector, [name]: value });
  };

  const saveSector = () => {
    setIsSaving(true);
    dataService.patch(
      `sectors/${sector.id}`,
      mySector,
      (datas) => {
        refresh();
        mActions.updateModal({ isOpen: false, content: null });
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  return (
    <div>
      <h4>{sector.name}</h4>
      <div className="row">
        <div className="col-12 col-md-6">
          <Control
            label="Adresse 1"
            type="text"
            name="address1"
            value={mySector.address1}
            change={handleChange}
            error={errors}
          />
          <Control
            label="Adresse 2"
            type="text"
            name="address2"
            value={mySector.address2}
            change={handleChange}
            error={errors}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Code postal"
            type="text"
            name="postalCode"
            value={mySector.postalCode}
            change={handleChange}
            error={errors}
          />
          <Control
            label="Ville"
            type="text"
            name="city"
            value={mySector.city}
            change={handleChange}
            error={errors}
          />
        </div>

        <div className="col-12 col-md-6">
          <SimpleDynamicList
            listLabel="Code postaux concernés"
            element="postalCodeTowns"
            items={mySector.postalCodeTowns}
            fields={[
              {
                name: "postalCodeTown",
                type: "text",
                disabled: true,
              },
            ]}
            change={handleChange}
            btnLabel="Ajouter un code postal"
            disabled={true}
          />
        </div>
        {/* <div className="col-12 col-md-6">
          <SimpleDynamicList
            listLabel="Adresses email"
            element="emails"
            items={mySector.emails}
            fields={[
              {
                name: "email",
                type: "text",
              },
            ]}
            change={handleChange}
            btnLabel="Ajouter un email"
          />
        </div> */}
        <div className="col-12 d-flex justify-content-end">
          <SaveBtn save={saveSector} isSaving={isSaving} />
        </div>
      </div>
    </div>
  );
};

export default SectorDetail;
