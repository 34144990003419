import React, { useEffect, useState } from "react";
import tools from "../../../helpers/tools";
import FormGenerator from "../../common/FormGenerator/FormGenerator";

import TimeBadge from "./TimeBadge";
import ModalBtn from "../../common/ModalBtn";
import useStore from "../../../context/useStore";
import { modalActions } from "../../../context/actions/modalActions";
import dataService from "../../../helpers/dataService";
import AddRecipientToCrossInterview from "../RecipientDetail/AddRecipientToCrossInterview";
import CreateRdvOnTheFly from "../RecipientDetail/common/CreateRdvOnTheFly/CreateRdvOnTheFly";

const questionLabels = {
  rdvOccured: "Le RDV a t-il eu lieu ?",
  decisionTaken: "Quelle décision a été prise ?",
  newPartner: "Sélectionnez le nouveau partenaire",
  rdvLocation: "Sélectionnez le lieu de RDV",
  newRdvReason: "Sélectionnez un motif pour le prochain RDV",
  dateRdvSamePartner: "Sélectionnez une date pour le prochain RDV",
  dateRdvNewPartner: "Sélectionnez une date pour le prochain RDV sur le site choisi",
  cancelReason: "Quel est le motif de l'annulation ?",
  reportReason: "Quel est le motif du report ?",
  reportDate: "A quelle date souhaitez-vous reporter le RDV ?",
  cancelOrigin: "Qui est à l'origine de l'annulation du RDV ?",
  accompany: "Par qui se fera l'accompagnement ?",
  operator: "Choisissez l'opérateur en charge de l'accompagnement",
  comment: "Rajoutez un commentaire",
};

const RdvDetail = ({
  rdv,
  setRdv,
  getRdvs,
  rdvsExpired,
  preventBack,
  setPreventBack,
  openModal,
  organizations,
  filters,
  errors,
  setErrors,
}) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const [rdvProcess, setRdvProcess] = useState(false);
  const [step, setStep] = useState(0);
  const [orientationPartners, setOrientationPartners] = useState([]);
  const [rdvFormConfig, setRdvFormConfig] = useState({
    sections: ["Déroulement de l'entretien"],
    questions: [
      {
        id: 1,
        question: questionLabels.rdvOccured,
        name: "rdvOccured",
        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "Le RDV a eu lieu comme prévu" },
          { value: 2, label: "Le RDV est annulé OU l'allocataire n'est plus SDD" },
        ],
        condition: "",
      },
      {
        id: 2,
        question: questionLabels.decisionTaken,
        name: "decisionTaken",

        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "Parcours d'insertion en cours confirmé" },
          { value: 2, label: "Prise d'un autre RDV" },
          { value: 3, label: "Orientation vers un partenaire" },
        ],
        condition: "q1==1",
      },
      {
        id: 3,
        question: questionLabels.newPartner,
        name: "newPartner",
        type: 4,
        section: 0,
        answers: [],
        condition: "q1==1&&q2==3",
      },
      {
        id: 4,
        question: questionLabels.rdvLocation,
        name: "rdvLocation",
        type: 4,
        section: 0,
        answers: [],
        condition: "q1==1&&q2==3",
      },
      {
        id: 5,
        question: questionLabels.newRdvReason,
        name: "newRdvReason",
        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "Motif1" },
          { value: 2, label: "Motif2" },
          { value: 3, label: "Motif3" },
        ],
        condition: "q1==1&&q2==20",
      },
      {
        id: 6,
        question: questionLabels.dateRdvSamePartner,
        name: "dateRdvSamePartner",
        type: 4,
        section: 0,
        answers: [],
        condition: "q1==1&&q2==2",
      },
      {
        id: 7,
        question: questionLabels.dateRdvNewPartner,
        name: "dateRdvNewPartner",
        type: 4,
        section: 0,
        answers: [],
        condition: "q1==1&&q2==3",
      },
      {
        id: 8,
        question: questionLabels.cancelReason,
        name: "cancelReason",
        type: 4,
        section: 0,
        answers: [
          {
            value: 1,
            label:
              "Aucun report possible actuellement : L'allocataire ne s'est pas présenté ou l'agent n'a pas pu prendre en charge le rendez-vous",
          },
          { value: 2, label: "Le RDV doit être reporté" },
          { value: 3, label: "L'allocataire n'est plus soumis au RSA" },
          { value: 4, label: "L'allocataire ne réside plus dans le département" },
        ],
        condition: "q1==2",
      },
      {
        id: 9,
        question: questionLabels.cancelOrigin,
        name: "cancelOrigin",
        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "L'allocataire" },
          { value: 2, label: "L'agent en charge" },
        ],
        condition: "q1==2&&q8!=3&&q8!=4",
      },
      {
        id: 10,
        question: questionLabels.reportReason,
        name: "reportReason",
        type: 4,
        section: 0,
        answers: state.constants.items.RDV_REPORT_TYPES.map((r) => {
          return { value: r.id, label: r.name };
        }),
        condition: "q1==2&&q9==1",
      },
      {
        id: 11,
        question: questionLabels.reportDate,
        name: "reportDate",
        type: 4,
        section: 0,
        answers: [],
        condition: "q1==2&&q8==2",
      },
      {
        id: 12,
        question: questionLabels.accompany,
        name: "accompany",
        type: 4,
        section: 0,
        answers: [
          { value: 1, label: "Accompagnement CAF" },
          { value: 2, label: "Accompagnement CAF / Opérateur de confiance" },
        ],
        condition: "q2==1&&q1==1",
      },
      {
        id: 13,
        question: questionLabels.operator,
        name: "operator",
        type: 4,
        section: 0,
        answers: [],
        condition: "q1==1&&q12==2&&q2==1",
      },
      {
        id: 14,
        question: questionLabels.comment,
        name: "comment",
        type: 1,
        section: 0,
        answers: [],
        condition: "",
      },
    ],
  });
  const [rdvClosed, setRdvClosed] = useState(false);
  const [fixedNextRdv, setFixedNextRdv] = useState(null);
  const [isSavingRdv, setIsSavingRdv] = useState(false);
  const [isFromCafEg, setIsFromCafEg] = useState(false);

  useEffect(() => {
    handleCafEGSelected();
  }, [rdv]);

  function handleCafEGSelected() {
    var selectedOrga = organizations.find((o) => o.id == filters.organization);
    let isCafEg = selectedOrga?.slugName == "CAF_EG";
    setIsFromCafEg(true);
    let rdvFormConfigCopy = { ...rdvFormConfig };
    rdvFormConfigCopy.questions.find(
      (q) => q.question == questionLabels.decisionTaken
    ).answers[2].label = isCafEg
      ? "Orientation vers un partenaire"
      : "Ré-orientation vers un autre partenaire";
    setRdvFormConfig(rdvFormConfigCopy);
    setIsFromCafEg(isCafEg);
  }

  useEffect(() => {
    var frm = JSON.parse(JSON.stringify(rdvFormConfig));
    // frm.questions.find((f) => f.name == "reportReason").answers =
    //   state.constants.items.RDV_REPORT_TYPES.map((r) => {
    //     return { value: r.id, label: r.name };
    //   });

    dataService.get(
      "operators",
      (datas) => {
        frm.questions.find((f) => f.name == "operator").answers = datas
          .filter((o) => o.isTrusted)
          .map((d) => ({
            value: d.id,
            label: d.name,
          }));
        setRdvFormConfig(frm);
      },
      (errors) => {}
    );
  }, [state.constants.items]);

  //pour réserver les slots de RDV
  useEffect(() => {
    if (rdv && step > 0) {
      var prevStep = step - 1;

      if (
        (rdvProcess[prevStep].label == questionLabels.dateRdvSamePartner ||
          rdvProcess[prevStep].label == questionLabels.dateRdvNewPartner ||
          rdvProcess[prevStep].label == questionLabels.reportDate) &&
        rdvProcess[prevStep].value != ""
      ) {
        var dateLock = rdvFormConfig.questions[prevStep].answers.find(
          (a) => a.value == rdvProcess[prevStep].value
        );

        dateLock.isSkipCheckRdv =
          rdvProcess.find((r) => r.name == "cancelOrigin").value == 1 ? false : true;

        setIsSavingRdv(true);
        dataService.patch(
          `slotcalendars/recipients/${rdv.userRecipient.id}`,
          dateLock,
          (data) => {
            setFixedNextRdv(dateLock);
            setPreventBack(true);
          },
          (err) => {},
          () => setIsSavingRdv(false)
        );
      }
    }
  }, [step]);

  useEffect(() => {
    if (rdvClosed) {
      save();
    }
  }, [rdvClosed]);

  //on récupère les données des organ/sites/rdvs dispo aux étapes spécifiques du formulaire
  function updateFormAnswers(ans) {
    var conf = JSON.parse(JSON.stringify(rdvFormConfig));
    var rdvOccured = ans.find((a) => a.label == questionLabels.rdvOccured).value;
    var decisionTaken = ans.find((a) => a.label == questionLabels.decisionTaken).value;
    var newPartner = ans.find((a) => a.label == questionLabels.newPartner).value;
    var rdvLocation = ans.find((a) => a.label == questionLabels.rdvLocation).value;

    var cancelReason = ans.find((a) => a.label == questionLabels.cancelReason).value;
    var cancelOrigin = ans.find((a) => a.label == questionLabels.cancelOrigin).value;

    //récupérer les autres organismes si réorientation
    if (rdvOccured == 1 && decisionTaken == 3 && step == 1) {
      //récupération des partenaires orientation sauf moi
      dataService.get(
        `organizations/orientation_partners`,
        (data) => {
          setOrientationPartners(data);
          data = data
            .filter((r) => r.id != filters.organization)
            .map((o) => {
              return { value: o.id, label: o.name };
            });

          conf.questions.find((q) => q.question == questionLabels.newPartner).answers = data;

          setRdvFormConfig(conf);
        },
        setErrors
      );
    }

    //question 4, récupérer les sites de l'orga de la q3
    if (rdvOccured == 1 && decisionTaken == 3 && step == 2) {
      dataService.get(
        `organizations/${newPartner}/sites`,
        (data) => {
          data = data.map((o) => {
            return { value: o.id, label: o.name };
          });
          conf.questions.find((q) => q.question == questionLabels.rdvLocation).answers = data;
          setRdvFormConfig(conf);
        },
        setErrors
      );
    }

    //question 5, récupérer les RDV chez le partenaire actuel (nouveau rdv)
    if (rdvOccured == 1 && decisionTaken == 2 && step == 1) {
      dataService.post(
        `slotcalendars/sites/${filters.site}`,
        {
          userRecipientId: rdv.userRecipient.id,
          slotCalendarId: rdv.id,
          isReport: true,
          isSkipCheckRdv: true,
        },
        (data) => {
          data = data.map((o) => {
            return {
              value: o.id,
              label: o.label + " - " + o.boxes.map((b) => b.name).join(", "),
              start: o.start,
              end: o.end,
              comment: rdv.comment,
              slotsId: o.slotsId,
            };
          });
          conf.questions.find((q) => q.question == questionLabels.dateRdvSamePartner).answers =
            data;
          setRdvFormConfig(conf);
        },
        setErrors
      );
    }

    //question 6, récupérer les RDV chez le nouveau partenaire choisi selon le site choisi en q4 (réorientation)

    if (rdvOccured == 1 && decisionTaken == 3 && step == 3) {
      //SI le partner est CAF ou CAF EG, on propose une prise de RDV
      var shouldTakeRdv = ["CAF", "CAF_EG"].includes(
        orientationPartners.find((a) => a.id == newPartner).slugName
      );
      if (shouldTakeRdv) {
        dataService.post(
          `slotcalendars/sites/${rdvLocation}`,
          {
            userRecipientId: rdv.userRecipient.id,
            slotCalendarId: rdv.id,
            isReport: true,
            isSkipCheckRdv: true,
          },
          (data) => {
            data = data.map((o) => {
              return {
                value: o.id,
                label: o.label + " - " + o.boxes.map((b) => b.name).join(", "),
                start: o.start,
                end: o.end,
                comment: rdv.comment,
                slotsId: o.slotsId,
              };
            });
            conf.questions.find((q) => q.question == questionLabels.dateRdvNewPartner).answers =
              data;
            setRdvFormConfig(conf);
          },
          setErrors
        );
      } else {
        setTimeout(() => {
          document.querySelector("#continue-hbtn-step3").click();
        }, 500);
      }
    }

    //question 9, récupérer les RDV chez le partenaire actuel (report)
    if (rdvOccured == 2 && cancelReason == 2 && step == 8) {
      conf.questions.find((q) => q.question == questionLabels.reportDate).answers = [];
      setRdvFormConfig(conf);

      dataService.post(
        `slotcalendars/sites/${filters.site}`,
        {
          userRecipientId: rdv.userRecipient.id,
          isReport: true,
          slotCalendarId: rdv.id,
          isSkipCheckRdv: cancelOrigin == "2" ? true : false,
        },
        (data) => {
          data = data.map((o) => {
            return {
              value: o.id,
              label: o.label + " - " + o.boxes.map((b) => b.name).join(", "),
              start: o.start,
              end: o.end,
              comment: rdv.comment,
              slotsId: o.slotsId,
            };
          });
          conf.questions.find((q) => q.question == questionLabels.reportDate).answers = data;
          setRdvFormConfig(conf);
        },
        setErrors
      );
    }
    setRdvProcess(ans);
  }

  function save() {
    var newOrientationRef = rdvProcess.find((r) => r.name == "newPartner");
    var decisionTakenRef = rdvProcess.find((r) => r.name == "decisionTaken");
    if (newOrientationRef?.value && !isFromCafEg) {
      mActions.updateModal({
        isOpen: true,
        noClose: true,
        content: (
          <AddRecipientToCrossInterview
            recipient={rdv.userRecipient}
            organization={newOrientationRef.value}
            onSaved={finalSave}
          />
        ),
      });
    } else if (decisionTakenRef?.value == "1") {
      mActions.updateModal({
        isOpen: true,
        noClose: true,
        content: (
          <CreateRdvOnTheFly
            organizationSlugName="CAF"
            recipient={rdv.userRecipient}
            onSaved={finalSave}
          />
        ),
      });
    } else {
      finalSave();
    }
  }

  function finalSave() {
    var newOrientation = rdvProcess.find((r) => r.name == "newPartner").value;
    var reportType = rdvProcess.find((r) => r.name == "cancelReason").value;
    var cancelOrigin = rdvProcess.find((r) => r.name == "cancelOrigin").value;
    var accompany = rdvProcess.find((r) => r.name == "accompany").value;
    var operator = rdvProcess.find((r) => r.name == "operator").value;
    var comment = rdvProcess.find((r) => r.name == "comment").value;

    var closeType = 0;
    if (rdvProcess.find((r) => r.name == "rdvOccured").value == 1) closeType = 1; //RDV effectué - Parcours d'insertion en cours
    if (rdvProcess.find((r) => r.name == "decisionTaken").value == 2) closeType = 2; //RDV effectué - Nécessite un nouveau RDV
    if (rdvProcess.find((r) => r.name == "decisionTaken").value == 3) closeType = 3; //RDV effectué - Nécessite une nouvelle orientation
    if (isFromCafEg && closeType == 3) {
      closeType = 6; //Orientation immédiate
    }
    if (reportType == 2) closeType = 4; //Report
    if (reportType == 1) closeType = 5; //Allocataire non présent
    if (reportType == 3) closeType = 7; //Allocataire plus soumis RSA
    if (reportType == 4) closeType = 9; //Allocataire plus dans le dept.
    if (closeType == 7 || closeType == 9) {
      cancelOrigin = 2;
    }

    let rdvPatchData = {
      closeType: closeType,
      reportType: closeType == 2 || closeType == 4 ? reportType : null,
      dateLock: fixedNextRdv,
      newOrientation: newOrientation || null,
      cancelOrigin,
      accompany,
      operator,
      comment,
      isFromCafEg,
    };

    dataService.patch(
      `slotcalendars/${rdv.id}/close`,
      rdvPatchData,
      (data) => {
        setRdv(false);
        setRdvProcess(false);
        setStep(0);
        getRdvs();
      },
      setErrors
    );
  }

  return (
    <>
      <button
        className="btn btn-default mb-3"
        onClick={() => {
          setRdv(false);
          setRdvProcess(false);
          setStep(0);
        }}
      >
        <i className="fa fa-arrow-left mr-2" />
        Revenir à la liste
      </button>
      <div className="row">
        <div className="col-12">
          <div className="card animated fadeInUp faster">
            <div className="card-header d-flex align-items-center">
              <h2 className="mb-0 text-white d-flex justify-content-between align-items-center w-100">
                <span>{tools.getFullname(rdv.userRecipient)}</span>
                <ModalBtn
                  label="Voir son parcours"
                  btnClass="btn btn-default btn-sm bg-white"
                  id="recipientDetailModal"
                  onClick={() => {
                    openModal(rdv.userRecipient.id);
                  }}
                />
                {rdv.userRecipient.spouse && (
                  <ModalBtn
                    label="Voir le conjoint"
                    btnClass="btn btn-default btn-sm bg-white"
                    id="recipientDetailModal"
                    onClick={() => {
                      openModal(rdv.userRecipient.spouse.id);
                    }}
                  />
                )}
                <TimeBadge rdvsExpired={rdvsExpired} rdv={rdv} />
                <span>{tools.getPeriodFormated(rdv.start, rdv.end)}</span>
              </h2>
            </div>
            <div className="card-body px-5">
              <span className="d-none">{step}</span>
              <FormGenerator
                isDspFinished={false}
                setIsDspFinished={setRdvClosed}
                setFormData={() => {}}
                formConfig={rdvFormConfig}
                updateFormAnswers={updateFormAnswers}
                step={step}
                setStep={setStep}
                isVertical={true}
                preventBack={preventBack}
                isLoading={isSavingRdv}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RdvDetail;
