import React from "react";
import SaveBtn from "../../../../common/SaveBtn";
import BackToListButton from "../../common/BackToListButton/BackToListButton";
import CERContractForm from "../../common/CERContractForm/CERContractForm";
import CERContractFormSigned from "../../common/CERContractForm/CERContractFormSigned";
import CERGoalsForm from "../../common/CERGoalsForm/CERGoalsForm";
import RecipientInfoRecap from "../../common/RecipientInfoRecap/RecipientInfoRecap";
import useCERModule from "../hooks/useCERModule";
import MeansInPlace from "../MeansInPlace/MeansInPlace";
import SignButton from "./SignButton";

const CEREditModule = () => {
  const { actions, recipientCER, isLoadingCER, isRecipientCERDraft } = useCERModule();

  const getTitleText = () => {
    if (isRecipientCERDraft) {
      if (recipientCER.id) {
        return "Modification du contrat CER (brouillon)";
      } else {
        return "Création du contrat CER (brouillon)";
      }
    } else {
      return "Contrat CER (signé)";
    }
  };

  const getSaveButtonText = () => {
    if (recipientCER.id) {
      return `Enregistrer le brouillon`;
    } else {
      return "Créer le brouillon";
    }
  };

  const saveCER = () => {
    if (recipientCER.id) {
      actions.patchRecipientCER();
    } else {
      actions.createRecipientCER();
    }
  };

  return (
    <div className="mb-3">
      <div>
        <BackToListButton />
      </div>
      <div>
        <h4 className="text-center">{getTitleText()}</h4>
      </div>
      <div>
        <RecipientInfoRecap />
      </div>
      <div>{isRecipientCERDraft ? <CERContractForm /> : <CERContractFormSigned />}</div>
      <div>
        <CERGoalsForm />
      </div>
      {isRecipientCERDraft ? (
        <div className="d-flex justify-content-around">
          {recipientCER.id && <SignButton />}
          <div>
            <SaveBtn isSaving={isLoadingCER} text={getSaveButtonText()} save={saveCER} margin="" />
          </div>
        </div>
      ) : (
        <MeansInPlace />
      )}
    </div>
  );
};

export default CEREditModule;
