import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERModule from "../../../../../../CERModule/hooks/useCERModule";
import AddEntityForm from "../../../../common/AddEntityForm";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { StepContext } from "../StepContext";
import ObstacleSelect from "./ObstacleSelect";

const AddObstacleForm = () => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const { recipientCER, events } = useCERModule();

  var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);

  const [newObstacle, setNewObstacle] = useState({
    obstacle: "",
    detail: { status: "UNFINISH", date: DateTime.local().toISO() },
  });

  const handleNewObstacleChange = (e) => {
    const { name, value } = e.target;
    setNewObstacle({ ...newObstacle, [name]: value });
  };

  const handleAddObstacle = () => {
    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex ? { ...st, obstacles: [...st.obstacles, newObstacle] } : st;
        }),
      ],
    };
    events.onUpdateObjectiveAndSave(newObjective, objectiveIndex);
    setNewObstacle({
      obstacle: "",
      detail: { status: "UNFINISH", date: DateTime.local().toISO() },
    });
  };

  useEffect(() => {
    if (newObstacle.obstacle) {
      handleAddObstacle();
    }
  }, [newObstacle]);

  return (
    <AddEntityForm onSubmit={handleAddObstacle} pbClass="pb-0">
      <ObstacleSelect
        handleNewObstacleChange={handleNewObstacleChange}
        objective={objective}
        step={step}
        obstacle={newObstacle}
        k={`addObstacle`}
      />
    </AddEntityForm>
  );
};

export default AddObstacleForm;
