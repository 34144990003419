import React, { useEffect } from "react";
import Page from "../../common/layout/Page";
import Pagination from "../../common/Pagination";
import RecipientStockFilters from "./RecipientStockFilters";
import RecipientStockTable from "./RecipientStockTable";
import useRecipientStock from "./useRecipientStock";

const RecipientStockList = () => {
  const {
    recipientStock,
    isLoadingRecipientStock,
    onSearchRecipientStock,
    recipientStockFilters,
    nbPages,
    changePage,
    changeNbItemsByPage,
  } = useRecipientStock();

  return (
    <>
      <Page container="container px-5" title="Allocataires du stock">
        <RecipientStockFilters />
      </Page>
      <RecipientStockTable />
      <Pagination
        nbPages={nbPages}
        page={recipientStockFilters.page}
        changePage={changePage}
        nbItemsByPage={recipientStockFilters.nbItemsByPage}
        changeNbItemsByPage={changeNbItemsByPage}
      />
    </>
  );
};

export default RecipientStockList;
