import React from "react";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";

const FastStockBlock = ({
  actions,
  isLoadingAction,
  fastStockFileName,
  setFastStockFileName,
  errors,
}) => {
  return (
    <>
      <h5 className="mt-4 mb-2">Allocataires STOCK depuis FAST</h5>
      <ul className="list-group">
        <li className="list-group-item d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-start flex-column">
            Récupère le fichier spécifié depuis FAST et le traite
            <Control
              className="w-100 px-0"
              type="text"
              placeholder="nom du fichier (sans le .csv)"
              value={fastStockFileName}
              name="fastFileName"
              change={(e) => setFastStockFileName(e.target.value)}
              error={errors}
            />
            <p className="text-muter">ex : allocSTOCK-YMD</p>
          </div>
          <div className="d-center flex-row">
            {fastStockFileName && fastStockFileName.length && (
              <SaveBtn
                type="btn-success"
                margin=""
                save={() => actions.import.stock()}
                isSaving={isLoadingAction.includes("stock")}
                text={
                  <div className="d-center">
                    <i className="fa fa-file-import mr-2" />
                    Importer
                  </div>
                }
              />
            )}
          </div>
        </li>
      </ul>
    </>
  );
};

export default FastStockBlock;
