import React, { useState } from "react";
import Control from "../../../common/Control";
import { useAtom } from "jotai";
import {
  differedSearchAtom,
  paginationAtom,
  searchAtom,
} from "../useSuiviAnomalyList";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
let differedTimeout = null;

function SuiviAnomalyListFilters({
  constants,
  nbResults,
  nbResultsFiltered,
  actions,
}) {
  const [search, setSearch] = useAtom(searchAtom);
  const [, setDifferedSearch] = useAtom(differedSearchAtom);
  const [, setPagination] = useAtom(paginationAtom);
  const [isProcessing, setIsProcessing] = useState(false);

  const updateSearch = (e) => {
    clearTimeout(differedTimeout);
    var { value, name, checked, type, formArray } = e.target;
    let su = { ...search };

    if (type == "checkbox") {
      if (checked) {
        su[name] = true;
      } else {
        delete su[name];
      }
    } else if (formArray) {
      if (formArray.target.checked) {
        su[name].push(value);
      } else {
        su[name] = su[name].filter((f) => f != value);
      }
    } else {
      if (value == "") {
        delete su[name];
      } else {
        su[name] = value;
      }
    }

    setSearch(su);
  };

  const applyFilters = (overrideFilters = null) => {
    setPagination((p) => ({ ...p, page: 1 }));
    setDifferedSearch(overrideFilters || search);
  };

  const resetSearch = () => {
    const newSearch = {
      recipientName: "",
      recipientEmail: "",
      recipientNir: "",
      recipientCAFId: "",
      onlyWithoutRDSP: false,
      onlyWithoutRdvEG: false,
      debutDateEnvoiNotif1: "",
      finDateEnvoiNotif1: "",
      rsaType: "",
      recipientType: "",
    };
    setSearch(newSearch);
    applyFilters(newSearch);
  };
  return (
    <div className="row ">
      <div className="col-12 col-md-6  justify-content-center">
        <Control
          margin={"my-0"}
          label="Nom de l'allocataire"
          name="recipientName"
          value={search.recipientName}
          change={updateSearch}
          placeholder="Nom, prénom"
          type="text"
        />
        <Control
          margin={"my-0"}
          label="Email de l'allocataire"
          name="recipientEmail"
          value={search.recipientEmail}
          change={updateSearch}
          placeholder="Email"
          type="text"
        />
        <Control
          margin={"my-0"}
          label="NIR de l'allocataire"
          name="recipientNir"
          value={search.recipientNir}
          change={updateSearch}
          placeholder="NIR"
          type="text"
        />
        <Control
          margin={"my-0"}
          label="Matricule de l'allocataire"
          name="recipientCAFId"
          value={search.recipientCAFId}
          change={updateSearch}
          placeholder="Matricule"
          type="text"
        />
      </div>
      <div className="col-12 col-md-6 ">
        <Control
          margin={"my-0"}
          label="Type d'allocataire"
          type="btnList"
          name="recipientType"
          value={search.recipientType}
          selectFirstLabel="Tous"
          datas={[
            { id: "", name: "Tous" },
            { id: "UserRecipient", name: "Flux" },
            { id: "UserRecipientDSP", name: "Temporaire" },
          ]}
          change={updateSearch}
          btnInline
        />
        <Control
          margin={"my-0"}
          label="Type de droit"
          type="select"
          name="rsaType"
          value={search.rsaType}
          selectFirstLabel="Tous"
          datas={constants.RSA_TYPES}
          change={updateSearch}
        />

        <Control
          margin={"my-0"}
          label="Date envoi 1ère sollicitation entre le"
          type="date"
          name="debutDateEnvoiNotif1"
          value={search.debutDateEnvoiNotif1}
          change={updateSearch}
        />
        <Control
          margin={"my-0"}
          label="Et le"
          type="date"
          name="finDateEnvoiNotif1"
          value={search.finDateEnvoiNotif1}
          change={updateSearch}
        />

        <Control
          margin={"mb-0 mt-4"}
          label={"Uniquement sans RDSP"}
          name="onlyWithoutRDSP"
          type="checkbox"
          checked={search.onlyWithoutRDSP}
          change={updateSearch}
        />
        <Control
          margin={"my-0"}
          label={"Uniquement sans RDV EG"}
          name="onlyWithoutRdvEG"
          type="checkbox"
          checked={search.onlyWithoutRdvEG}
          change={updateSearch}
        />
      </div>

      <div className="col-12 d-flex align-items-center justify-content-between mt-2">
        {isProcessing ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className="font-weight-bold mr-2">
            {nbResultsFiltered} anomalie(s) trouvée(s) / {nbResults} anomalies
            au total
          </div>
        )}
        <div className="d-flex">
          <button className="btn btn-default mr-2" onClick={resetSearch}>
            Vider les filtres
          </button>
          <SaveBtn
            onClick={() => applyFilters()}
            text="Rechercher"
            type="btn-primary"
            margin="mt-0"
          />
        </div>
      </div>
    </div>
  );
}

export default SuiviAnomalyListFilters;
