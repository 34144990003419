import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { api_url } from "../../../config";
import SimpleDynamicList from "../../common/SimpleDynamicList";
import PostalCode from "../../common/PostalCode";
import DynamicList from "../../common/DynamicList";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";
import { toast } from "react-toastify";

const OperatorDetail = (props) => {
  const [state, dispatch] = useStore();

  const [operator, setOperator] = useState({
    name: "",
    subThemes: [],
    address: "",
    phone: "",
    email: "",
    cityId: "",
    link: "",
    isTrusted: false,
    location: {
      postalCode: "",
      name: "",
    },
    closingPeriods: [],
    closingHolidays: [],
    contacts: [],
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(false);
  const [themes, setThemes] = useState([]);
  const holidays = state.constants.items ? state.constants.items.HOLIDAYS : [];

  useEffect(() => {
    if (holidays && holidays.length > 0 && props.match.params.id == "create") {
      setOperator({ ...operator, closingHolidays: holidays.map((h) => h.id) });
    }
  }, [holidays]);

  useEffect(() => {
    getDatas();
    if (props.match.params.id != "create") {
      Axios.get(api_url + "operators/" + props.match.params.id)
        .then((res) => {
          setOperator({ ...res.data, cityId: res.data.location?.cityId });
        })
        .catch((err) => {
          setErrors(err.response.data);
        });
    }
  }, []);

  function getDatas() {
    setIsLoading(true);
    Axios.get(api_url + "themes")
      .then((res) => {
        setThemes(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  function updateData(e) {
    var usr = JSON.parse(JSON.stringify(operator));
    var { name, value, type } = e.target;
    console.log(name, value);
    if (type == "checkbox") {
      value = e.target.checked ? true : false;
    }
    if (name == "cityId") {
      usr.location = {
        postalCode: e.target.other.postalCode,
        name: e.target.other.townName,
      };
    }
    if (e.target.formArray) {
      console.log("in");
      var elem = usr[name];
      if (e.target.formArray.target.checked) {
        elem.push(value);
      } else {
        elem = elem.filter((v) => v != value);
      }
    } else {
      elem = value;
    }
    usr[name] = elem;
    setOperator(usr);
  }

  function save() {
    setIsLoading(true);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      dataService.post(
        "operators",
        operator,
        (datas) => {
          window.location.href = "/operators/" + datas.id;
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      //update
      dataService.patch(
        `operators/${props.match.params.id}`,
        operator,
        (data) => {
          setOperator(data);
          toast.success("Enregistrement réussi");
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  }

  function changeClosingHolidays(e) {
    var locs = JSON.parse(JSON.stringify(operator.closingHolidays));
    var { name, value } = e.target;
    if (locs.includes(value)) {
      locs = locs.filter((l) => l != value);
    } else {
      locs.push(value);
    }
    setOperator({ ...operator, closingHolidays: locs });
  }

  function addAll() {
    var locs = JSON.parse(JSON.stringify(operator.subThemes));
    var allLocs = themes.find(
      (loc, lock) => loc.name == selectedTheme
    ).subThemes;

    allLocs.forEach((loc, lock) => {
      if (!locs.find((l) => l == loc._id)) {
        locs.push(loc._id);
      }
    });
    setOperator({ ...operator, subThemes: locs });
  }
  function removeAll() {
    var locs = JSON.parse(JSON.stringify(operator.subThemes));
    var allLocs = themes.find(
      (loc, lock) => loc.name == selectedTheme
    ).subThemes;

    locs = locs.filter((l) => {
      if (allLocs.find((al) => al._id == l)) {
        return false;
      }
      return true;
    });
    setOperator({ ...operator, subThemes: locs });
  }

  function changeTheme(e) {
    var locs = JSON.parse(JSON.stringify(operator.subThemes));
    var { name, value } = e.target;
    if (locs.includes(value)) {
      locs = locs.filter((l) => l != value);
    } else {
      locs.push(value);
    }
    setOperator({ ...operator, subThemes: locs });
  }

  console.log("Operator", operator);

  return state.constants.items.length == 0 ? (
    <Loader />
  ) : (
    <>
      {isLoading && <Loader />}
      <Page
        container={"container-fluid px-5"}
        title="Détail de l'opérateur"
        errors={errors}
        back={"/operators"}
      >
        <div className="row">
          <div className="col-12">
            <Control
              label="Est un opérateur de confiance"
              type="checkbox"
              name="isTrusted"
              checked={operator.isTrusted}
              change={updateData}
              error={errors}
              className="mb-4"
            />
            <Control
              label="Nom"
              name="name"
              value={operator.name}
              change={updateData}
              error={errors}
              className="px-0"
            />
            <Control
              label="Adresse"
              name="address"
              value={operator.address}
              change={updateData}
              error={errors}
              className="px-0"
            />

            <Control
              label="Email"
              type="email"
              name="email"
              value={operator.email}
              change={updateData}
              error={errors}
              className="px-0"
            />
            <Control
              label="Téléphone"
              type="tel"
              name="phone"
              value={operator.phone}
              change={updateData}
              error={errors}
              className="px-0"
            />
            <Control
              label="Lien du site internet"
              type="text"
              name="link"
              placeholder="https://"
              value={operator.link}
              change={updateData}
              error={errors}
              className="px-0"
            />
            <PostalCode
              inputLabel="Code postal"
              value={operator.postalCode}
              updateData={updateData}
              name="cityId"
              error={errors}
              margin="px-0"
              location={operator.location}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>
              Sélectionnez les thèmes puis les sous-thèmes qui concernent cet
              opérateur :
            </label>

            <ul className="list-group d-flex flex-row flex-wrap justify-content-start align-items-center">
              {themes.map((loc, lock) => (
                <li key={"loc" + lock} className="list-group-item p-0">
                  <button
                    className={
                      "btn w-100 d-flex align-items-center justify-content-between btn-sm " +
                      (loc.name == selectedTheme
                        ? "btn-primary"
                        : "btn-default")
                    }
                    onClick={() => setSelectedTheme(loc.name)}
                  >
                    {loc.name}

                    {loc.subThemes.filter((l) =>
                      operator.subThemes.includes(l._id)
                    ).length > 0 && (
                      <span className="badge badge-info ml-2">
                        {
                          loc.subThemes.filter((l) =>
                            operator.subThemes.includes(l._id)
                          ).length
                        }
                      </span>
                    )}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12">
            {selectedTheme != "" && (
              <>
                <Control
                  labelStyle={{ display: "flex", justifyContent: "between" }}
                  label={
                    <>
                      Sélectionnez les sous-thèmes{" "}
                      <button
                        className="btn btn-dark btn-sm mx-2"
                        onClick={addAll}
                      >
                        Tout ajouter
                      </button>
                      <button
                        className="btn btn-dark btn-sm"
                        onClick={removeAll}
                      >
                        Tout retirer
                      </button>
                    </>
                  }
                  type="checkBtnList"
                  btnSm
                  datas={
                    themes.find((loc, lock) => loc.name == selectedTheme)
                      .subThemes
                  }
                  change={changeTheme}
                  value={operator.subThemes}
                  name="themes"
                  dataIndex="_id"
                  dataLabel="name"
                  dataLabelDefault="name"
                  className="px-0"
                  error={errors}
                />
              </>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <DynamicList
              listLabel="Périodes de fermeture"
              btnLabel="Ajouter une période"
              uniqueKey="pf-1"
              name="closingPeriods"
              fields={[
                {
                  label: "Nom",
                  name: "name",
                  type: "text",
                  placeholder: "Vacances d'été",
                },
                {
                  label: "Entre le",
                  name: "beginAt",
                  type: "date",
                },
                {
                  label: "et le",
                  name: "endAt",
                  type: "date",
                },
              ]}
              items={
                operator.closingPeriods
                  ? operator.closingPeriods.map((s) => {
                      s.beginAt = DateTime.fromISO(s.beginAt).toFormat(
                        "yyyy-MM-dd"
                      );
                      s.endAt = DateTime.fromISO(s.endAt).toFormat(
                        "yyyy-MM-dd"
                      );
                      return s;
                    })
                  : []
              }
              change={updateData}
              error={errors}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Control
              label="L'établissement est fermé les jours fériés suivants (cochés)"
              type="checkBtnList"
              btnSm
              datas={holidays}
              change={changeClosingHolidays}
              value={operator.closingHolidays}
              name="closingHolidays"
              error={errors}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <DynamicList
              listLabel={
                <>
                  Contacts{" "}
                  <span className="badge badge-primary ml-2">
                    {operator.contacts.length}
                  </span>
                </>
              }
              btnLabel="Ajouter un contact"
              uniqueKey="pf-1"
              name="contacts"
              fields={[
                {
                  label: "Nom",
                  name: "lastname",
                },
                {
                  label: "Prénom",
                  name: "firstname",
                },
                {
                  label: "Email",
                  name: "email",
                },
                {
                  label: "Téléphone",
                  name: "phone",
                },
              ]}
              items={operator.contacts ? operator.contacts : []}
              change={updateData}
              error={errors}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-success btn-save mt-3" onClick={save}>
            Enregistrer
          </button>
        </div>
      </Page>
    </>
  );
};

export default OperatorDetail;
