import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import ExportCsv from "../../common/ExportCsv";
import dataService from "../../../helpers/dataService";
import { toast } from "react-toastify";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";

var strTimeout = null;

const UserList = () => {
  const [state] = useStore();
  const constants = state.constants.items;
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("UserList", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          user: {
            searchString: "",
            role: "",
          },
          page: 1,
          nbItemsByPage: 25,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const { organizations, onGetOrganizations } = useOrganizations();

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);

    onGetOrganizations();

    strTimeout = setTimeout(() => {
      tools.saveState("UserList", "search", JSON.stringify(search));
      searchUsers();
    }, 1500);
  }, [search]);

  function searchUsers() {
    setErrors(false);
    dataService.post(
      `managers/search`,
      {
        ...search,
        nbItemsByPage: parseInt(search.nbItemsByPage),
      },
      (data) => {
        setUsers(data.data);
        setNbPages(Math.ceil(parseInt(data.count) / search.nbItemsByPage));
        setIsLoading(false);
        setNbResults(data.count);
      },
      setErrors,
      () => setIsLoading(false)
    );
  }
  function updateUserSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (value == "") {
      delete su.user[e.target.name];
    } else {
      su.user[e.target.name] = value;
    }

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function changeNbItemsByPage(i) {
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
  }

  const removeUser = (id) => {
    dataService.remove(`managers/${id}`, {}, (datas) => {
      searchUsers();
      toast.success("L'utilisateur a bien été supprimé");
    });
  };

  /**
   * Parse manager's organizations to get only list of name
   * Return "Toutes" if manager has all organizations
   * @author Romain
   *
   * @param {ObjectId[]} userOrganizationIds
   * @returns {string} organizationNames
   */
  function getUserOrganizationNames(userOrganizationIds) {
    if (userOrganizationIds.length === organizations.length) {
      return "Toutes";
    }

    const result = [];
    userOrganizationIds.forEach((o) => {
      const organizationFound = organizations.find((x) => x.id === o);
      if (!organizationFound) return;
      result.push(organizationFound.name);
    });

    return result.join(", ");
  }

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        action={{ to: "/users/create", text: "Créer un utilisateur" }}
        title={"Liste des utilisateurs"}
      >
        <div className="row mb-4">
          {state.constants.items.length == 0 ? (
            <Loader />
          ) : (
            <div className="col-5">
              <Control
                label="Rôle"
                type="btnList"
                value={search.user.role}
                datas={[{ id: "", name: "Tous" }, ...constants.ROLES_MANAGER]}
                change={(e) =>
                  updateUserSearch({
                    target: { name: "role", value: e.target.value },
                  })
                }
                btnInline
              />
            </div>
          )}
          <div className="col">
            <Control
              margin={"my-0"}
              label="Recherche"
              name="searchString"
              value={search.user.searchString}
              change={updateUserSearch}
              suffix={
                <i
                  className="fa fa-search ml-2"
                  style={{ lineHeight: "34px" }}
                ></i>
              }
            />
          </div>
          <div className="col align-items-center justify-content-end d-flex">
            {nbResults} utilisateurs trouvés
          </div>
        </div>
      </Page>
      <div className="col-12 p-4">
        {errors && errors.other ? (
          <div className="text-danger m-auto text-center">{errors.other}</div>
        ) : isLoading ? (
          <Loader />
        ) : (
          <>
            <Table
              bgTransparent
              datas={users}
              exportOptions={{
                fetchDatas: "managers/search",
                fetchParams: {
                  ...search,
                  nbItemsByPage: "*",
                },
                formatDatasFn: (datas) =>
                  datas.map((r) => ({
                    ...r,
                    isActivated: r.isActivated ? "Oui" : "Non",
                    role: constants.ROLES_MANAGER.find((x) => x.id === r.role)
                      ?.name,
                    organizations: getUserOrganizationNames(r.organizations),
                    isEP: r.isEP ? "Oui" : "Non",
                    isDirector: r.isDirector ? "Oui" : "Non",
                  })),
                excludedFields: ["Modifier", "Supprimer", "organizations"],
                additionalFields: [
                  { name: "Peut participer aux EP", path: "isEP" },
                  { name: "Est délégataire du DG", path: "isDirector" },
                ],
              }}
              fields={[
                {
                  name: "Organisation(s)",
                  path: "organizations",
                  render: (elem) => getUserOrganizationNames(elem),
                },
                {
                  name: "Rôle",
                  path: "role",
                  render: (elem) =>
                    state.constants.items.ROLES_MANAGER.find(
                      (r) => r.id == elem
                    )
                      ? state.constants.items.ROLES_MANAGER.find(
                          (r) => r.id == elem
                        ).name
                      : elem,
                },
                {
                  name: "Prénom",
                  path: "firstname",
                },
                {
                  name: "Nom",
                  path: "lastname",
                },
                {
                  name: "Email",
                  path: "email",
                },
                {
                  name: "Dernière connexion",
                  path: "lastConnectionAt",
                  render: (el) => tools.formatDate(el),
                },
                {
                  name: "Fin de contrat",
                  path: "contractEndAt",
                  render: (el) => tools.formatDate(el),
                },
                {
                  name: "Modifier",
                  path: "id",
                  render: (elem) => (
                    <>
                      <Link
                        className="btn btn-primary btn-sm align-items-center justify-content-center d-flex"
                        to={"/users/" + elem}
                      >
                        <i className="fa fa-edit"></i>
                      </Link>
                    </>
                  ),
                },
                {
                  name: "Supprimer",
                  path: "id",
                  render: (elem) => (
                    <button
                      className="btn btn-danger"
                      onClick={() => removeUser(elem)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  ),
                },
              ]}
            />
          </>
        )}
        <Pagination
          nbPages={nbPages}
          page={search.page}
          changePage={changePage}
          nbItemsByPage={search.nbItemsByPage}
          changeNbItemsByPage={changeNbItemsByPage}
        />
      </div>
    </>
  );
};

export default UserList;
