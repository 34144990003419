import React from "react";
import useCERData from "../../../Hooks/useCERData/useCERData";
import ObjectiveItem from "./ObjectiveItem/ObjectiveItem";
import StepList from "../StepList/StepList";

const ObjectiveList = ({ category }) => {
  const { cerObjectives } = useCERData();

  return (
    <ul className="list-group">
      {cerObjectives
        .filter((objective) => objective.category == category.id)
        .map((objective) => {
          return (
            <ObjectiveItem objective={objective} key={`objective${objective.id}`}>
              <StepList objective={objective} />
            </ObjectiveItem>
          );
        })}
    </ul>
  );
};

export default ObjectiveList;
