import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import Table from "../../common/Table";
import tools from "../../../helpers/tools";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";

var strTimeout = null;

const RecipientCreateEpList = (props) => {
  const [recipients, setRecipients] = useState([]);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("RecipientCreateEpList", null);
  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          user: {
            searchString: "",
            noEmail: false,
          },
          page: 1,
          nbItemsByPage: 25,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);

    strTimeout = setTimeout(() => {
      tools.saveState("RecipientCreateEpList", "search", JSON.stringify(search));
      searchRecipients();
    }, 1500);
  }, [search]);

  function searchRecipients() {
    setErrors({});
    Axios.post(api_url + "recipients_ep/search", search)
      .then((res) => {
        setRecipients(res.data.data);
        setNbPages(Math.ceil(parseInt(res.data.count) / search.nbItemsByPage));
        setIsLoading(false);
        setNbResults(res.data.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        setRecipients([]);
      });
  }
  function updateSearch(e) {
    var value = e.target.value;
    var su = { ...search };

    su.user[e.target.name] = value;

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function changeNbItemsByPage(i) {
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
  }

  const removeRecipient = (recipientId) => {
    dataService.remove(
      `recipients_ep/${recipientId}`,
      {},
      (datas) => {
        searchRecipients();
      },
      setErrors
    );
  };

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        title={"Allocataires EP"}
        bgTransparent
        noPadding
        action={{
          to: "/createrecipientep",
          text: "Inscrire un allocataire EP",
        }}
      >
        {state.constants.items.length == 0 || isLoading ? (
          <Loader />
        ) : (
          <>
            {errors && errors.other ? (
              <div className="text-danger m-auto text-center">{errors.other}</div>
            ) : null}
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Table
                  fullWidth
                  datas={recipients}
                  fields={[
                    {
                      name: "Nom",
                      exportPath: "name",
                      path: "*",
                      render: (el) => (
                        <>
                          <Link
                            to={`recipients-ep/${el.id}`}
                            className="btn btn-link text-left px-0"
                          >
                            {tools.getFullname(el)}
                          </Link>
                          {!el.updatedFromSIDAt &&
                          Math.abs(
                            DateTime.fromISO(el.updatedAt).diff(DateTime.local(), "days").toObject()
                              .days
                          ) >= 1 ? (
                            <div className="badge badge-danger d-block">absent du SID</div>
                          ) : null}
                          <small className="text-muted">
                            Créé par {tools.getFullname(el.createdBy)}
                          </small>
                        </>
                      ),
                    },
                    {
                      name: "NIR",
                      path: "nir",
                    },
                    {
                      name: "Matr.",
                      path: "CAFId",
                    },

                    {
                      name: "Tél",
                      path: "phone",
                    },
                    {
                      name: "Lieu",
                      path: "location",
                      render: (elem) =>
                        elem && elem.postalCode ? elem.postalCode + " " + elem.name : "",
                    },
                    {
                      name: "Né le",
                      path: "birthdate",
                      render: (el) => DateTime.fromISO(el).toFormat("dd/MM/yyyy"),
                    },
                    {
                      name: "Motifs",
                      path: "*",
                      render: (el) => {
                        const motifs = tools.getMotifs(el.currentEP || {}, constants);
                        return (
                          el.currentEP && (
                            <>
                              <span className="badge badge-primary d-block">
                                EP du{" "}
                                {DateTime.fromISO(el.currentEP.EP.beginAt).toFormat("dd/MM/yyyy")}
                              </span>
                              {motifs.map((motif) => (
                                <div style={{ fontSize: 12 }}>
                                  <strong className="d-block">{motif.reason1}</strong>
                                  <ul>
                                    {motif.reason2?.map((rea) => (
                                      <li>{rea}</li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </>
                          )
                        );
                      },
                    },
                    {
                      name: "Voir",
                      path: "id",
                      render: (elem) => (
                        <Link
                          to={"/recipients-ep/" + elem}
                          className="btn btn-primary w-100 btn-sm d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      ),
                    },
                    {
                      name: "Supprimer",
                      path: "*",
                      render: (elem) =>
                        !elem.updatedFromSIDAt ? (
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => removeRecipient(elem.id)}
                          >
                            <i className="fa fa-times" />
                          </button>
                        ) : (
                          <>
                            déjà m.a.j depuis SID{" "}
                            {DateTime.fromISO(elem.updatedFromSIDAt).toFormat("dd/MM/yyyy")}
                          </>
                        ),
                    },
                  ]}
                />
              </>
            )}
            <Pagination
              nbPages={nbPages}
              page={search.page}
              changePage={changePage}
              nbItemsByPage={search.nbItemsByPage}
              changeNbItemsByPage={changeNbItemsByPage}
            />
          </>
        )}
      </Page>
    </>
  );
};

export default RecipientCreateEpList;
