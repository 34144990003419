import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useContext } from "react";
import colors from "../../../helpers/colors";
import tools from "../../../helpers/tools";
import useCERData from "../../Hooks/useCERData/useCERData";
import { StepContext } from "./StepContext";

const InfoLabel = ({ children }) => {
  var styles = StyleSheet.create({
    info: {
      fontSize: 8,
      color: "#797979",
      paddingLeft: 10,
    },
  });

  return <Text style={{ ...styles.info }}>{children}</Text>;
};

const AmendmentOperatorsList = () => {
  const { operators } = useCERData();
  const step = useContext(StepContext);

  var styles = StyleSheet.create({
    container: {
      paddingLeft: 10,
      paddingBottom: 4,
    },
  });

  const getStatusStyles = (operator) => {
    const titleStyles = {
      fontSize: 9,
      fontWeight: "bold",
    };
    if (operator.updateType) {
      switch (operator.updateType) {
        case "DELETED":
          return {
            ...titleStyles,
            color: colors.danger,
            textDecoration: "line-through",
          };
        case "ADDED":
          return {
            ...titleStyles,
            color: colors.success,
          };
      }
    }

    return {
      ...titleStyles,
      color: "#7f7f7f",
    };
  };

  return step.operators.map((operator) => {
    var operatorRef = operators.find((o) => o.id == operator.operator);
    const titleStyles = getStatusStyles(operator);
    const isDeleted = operator.updateType == "DELETED";

    return (
      <View style={{ ...styles.container }}>
        <Text style={{ ...titleStyles }}>{operatorRef?.name}</Text>
        {operatorRef?.address && !isDeleted ? (
          <InfoLabel>
            {operatorRef.address} {operatorRef.location?.postalCode} {operatorRef.location?.name}
          </InfoLabel>
        ) : null}

        {operatorRef?.email && !isDeleted ? (
          <InfoLabel>
            {operatorRef?.phone ? tools.formatPhone(operatorRef.phone) : null} - {operatorRef.email}
          </InfoLabel>
        ) : null}
        {operatorRef?.link && !isDeleted ? <InfoLabel>{operatorRef.link}</InfoLabel> : null}
      </View>
    );
  });
};

export default AmendmentOperatorsList;
