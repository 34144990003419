import { PDFViewer, Page, Text, Image, View, Document, StyleSheet, PDFDownloadLink, Font } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import React from "react";
import tools from "../../../../helpers/tools";

const PVDoc = ({ styles, datas, recipients, orgas, title, constants }) => {
  const nbSanctionsNoReduction = recipients.filter(
    (rec) => rec.type == "SANCTION" && rec.EPNotice.sanction.level == 3
  ).length;
  const nbSanctionsSurseoir = recipients.filter(
    (rec) => rec.type == "SANCTION" && rec.directorNotice.decision == 2
  ).length;
  const nbSanctions = recipients.filter((rec) => rec.type == "SANCTION").length;

  const nbReoOkSurseoir = recipients.filter(
    (rec) => rec.type == "REORIENTATION_OK" && rec.directorNotice.decision == 2
  ).length;
  const nbReoOk = recipients.filter((rec) => rec.type == "REORIENTATION_OK").length;

  const nbReoKoSurseoir = recipients.filter(
    (rec) => rec.type == "REORIENTATION_KO" && rec.directorNotice.decision == 2
  ).length;
  const nbReoKo = recipients.filter((rec) => rec.type == "REORIENTATION_KO").length;

  const nbMaintienSurseoir = recipients.filter(
    (rec) => rec.type == "SOCIAL_PATH_MAINTAIN" && rec.directorNotice.decision == 2
  ).length;
  const nbMaintien = recipients.filter((rec) => rec.type == "SOCIAL_PATH_MAINTAIN").length;
  const nbSurseoir = recipients.filter((rec) => rec.directorNotice.decision == 2).length;

  const { INVITATION_MANDATES } = constants;

  const getMandate = (jur) => {
    const mandate = INVITATION_MANDATES.find((inv) => inv.id == jur.userMandate);
    return mandate ? mandate.name : "";
  };

  return (
    <Page wrap size="A4" orientation="portrait">
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
        }}
      >
        <View style={{ width: "10%" }}>
          <Image style={{ height: 80, width: 55 }} src="/assets/logo-espoar.png" />
        </View>
        <View style={{ width: "87%" }}>
          <View
            style={{
              ...styles.title,
              textAlign: "left",
              paddingLeft: "2%",
            }}
          >
            <Text>{title}</Text>
          </View>
        </View>
      </View>
      <View style={{ textAlign: "center", paddingLeft: 20, paddingRight: 20 }}>
        <Text
          style={{
            ...styles.title,
          }}
        >
          QUORUM
        </Text>
        <Text
          style={{
            ...styles.info,
          }}
        >
          Le quorum est atteint et la commission peut valablement délibérer.
        </Text>
      </View>
      <View style={{ paddingLeft: 20, paddingRight: 20 }}>
        <View style={{ ...styles.tableRow }}>
          <Text style={{ ...styles.tableCell4, ...styles.tableHead }}>MEMBRE</Text>
          <Text style={{ ...styles.tableCell4, ...styles.tableHead }}>ORGANISME</Text>
          <Text style={{ ...styles.tableCell4, ...styles.tableHead }}>FONCTION</Text>
          <Text
            style={{
              ...styles.tableCell4,
              ...styles.tableHead,
            }}
          >
            EMARGEMENT
          </Text>
        </View>
        {datas.jury
          .filter((jur) => jur.isPresent)
          .map((jur, jurk) => {
            let orgaSlugs = jur.userManager.organizations.map((org) => orgas.find((or) => or.id == org));
            orgaSlugs = orgaSlugs.find((org) => org.slugName == "CAF")
              ? orgaSlugs.filter((org) => org.slugName == "CAF")
              : orgaSlugs;
            let organizations = orgaSlugs.map((org) => orgas.find((or) => or.id == org.id).name).join(", ");

            const fonction = `${jur.userManager.id == datas.president ? "Président" : "Membre"} ${getMandate(jur)}`;

            const { isPresent } = jur;

            return (
              <View style={{ ...styles.tableRow }} key={`mbmjur${jurk}`}>
                <Text style={{ ...styles.tableCell4 }}>{tools.getFullname(jur.userManager)}</Text>
                <Text style={{ ...styles.tableCell4 }}>{organizations}</Text>
                <Text style={{ ...styles.tableCell4 }}>{fonction}</Text>
                <Text
                  style={{
                    ...styles.tableCell4,
                    color: isPresent ? "green" : "red",
                  }}
                >
                  {isPresent ? "Présent" : "Absent"}
                </Text>
              </View>
            );
          })}
      </View>

      <View style={{ textAlign: "center", paddingLeft: 20, paddingRight: 20 }}>
        <Text
          style={{
            ...styles.title,
          }}
        >
          AVIS DE L'ÉQUIPE PLURIDISCIPLINAIRE
        </Text>
        <Text style={{ ...styles.info }}>
          La liste des dossiers présentés et les avis de la commission est en annexe du présent procès-verbal.
        </Text>
      </View>

      <View style={{ paddingLeft: 20, paddingRight: 20 }}>
        <View style={{ ...styles.tableRow, fontWeight: "700" }}>
          <Text style={{ ...styles.tableCell3, ...styles.tableHead }}>OBJET</Text>

          <Text style={{ ...styles.tableCell3, ...styles.tableHead }}>AVIS EP</Text>
          <Text style={{ ...styles.tableCell3, ...styles.tableHead }}>DÉCISIONS DIRECTEUR</Text>
        </View>
      </View>
      <View style={{ ...styles.tableRow, paddingLeft: 20, paddingRight: 20 }}>
        <Text style={{ ...styles.tableCell3 }}>Sanctions</Text>
        <View style={{ ...styles.tableCell3, textAlign: "center" }}>
          <Text>{nbSanctions}</Text>
          <Text style={{ fontSize: 8, display: "block", width: "100%" }}>
            (dont {nbSanctionsNoReduction} sans réduction)
          </Text>
        </View>
        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>
          {nbSanctions - nbSanctionsSurseoir} accord / {nbSanctionsSurseoir} à surseoir
        </Text>
      </View>
      <View style={{ ...styles.tableRow, paddingLeft: 20, paddingRight: 20 }}>
        <Text style={{ ...styles.tableCell3 }}>Réorientations validées</Text>

        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>{nbReoOk}</Text>
        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>
          {nbReoOk - nbReoOkSurseoir} accord / {nbReoOkSurseoir} à surseoir
        </Text>
      </View>
      <View style={{ ...styles.tableRow, paddingLeft: 20, paddingRight: 20 }}>
        <Text style={{ ...styles.tableCell3 }}>Réorientations à résoudre</Text>

        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>{nbReoKo}</Text>
        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>
          {nbReoKo - nbReoKoSurseoir} accord / {nbReoKoSurseoir} à surseoir
        </Text>
      </View>
      <View style={{ ...styles.tableRow, paddingLeft: 20, paddingRight: 20 }}>
        <Text style={{ ...styles.tableCell3 }}>Maintien de parcours social</Text>

        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>{nbMaintien}</Text>
        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>
          {nbMaintien - nbMaintienSurseoir} accord / {nbMaintienSurseoir} à surseoir
        </Text>
      </View>
      <View style={{ ...styles.tableRow, paddingLeft: 20, paddingRight: 20 }}>
        <Text style={{ ...styles.tableCell3 }}>Total</Text>

        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>
          {tools.round(nbSanctions + nbReoKo + nbReoOk + nbMaintien)}
        </Text>
        <Text style={{ ...styles.tableCell3, textAlign: "center" }}>
          {tools.round(nbSanctions + nbReoKo + nbReoOk + nbMaintien - nbSurseoir)} accord / {nbSurseoir} à surseoir
        </Text>
      </View>

      <View style={{ paddingLeft: 20, paddingRight: 20 }}>
        <View style={{ ...styles.tableRow, height: 80 }}>
          <View style={{ ...styles.tableCell3, ...styles.textCenter }}>
            <Text>Fait à{"     "}</Text>
            <Text>le {DateTime.local().setLocale("FR").toFormat("dd LLLL yyyy")}</Text>
          </View>
          <View style={{ ...styles.tableCell3, ...styles.textCenter }}>
            <Text>Le(la) Président(e) de l'Équipe Pluridisciplinaire</Text>
            <Text>
              Clôturé par {tools.getFullname(datas.closedBy)}{" "}
              {DateTime.fromISO(datas.closedAt).toFormat("dd/MM/yyyy 'à' HH'h'mm")}
            </Text>
          </View>
          <View style={{ ...styles.tableCell3, ...styles.textCenter }}>
            <Text>Le directeur</Text>
            <Text>
              Finalisé par {tools.getFullname(datas.endedBy)} {datas.endedBy?.isDirector && "par délégation "}
              {DateTime.fromISO(datas.endedAt).toFormat("dd/MM/yyyy 'à' HH'h'mm")}
            </Text>
          </View>
        </View>
      </View>

      {/* <View style={{ textAlign: "center" }}>
        <Text
          style={{
            ...styles.title,
          }}
        >
          DÉCISIONS
        </Text>
        <Text style={{ ...styles.info }}>
          La liste des dossiers présentés et les avis de la commission est en
          annexe du présent procès-verbal.
        </Text>
      </View>

      <View>
        <View style={{ ...styles.tableRow, height: 80 }}>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Le(la) président(e) du Conseil Départemental
          </Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Date :
          </Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Le(la) Directeur(trice) de la Caisse d'Allocations Familiales
          </Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Date :
          </Text>
        </View>
        <View style={{ ...styles.tableRow }}>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Maintien et réorientation de parcours
          </Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}></Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Sanctions
          </Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}></Text>
        </View>
        <View style={{ ...styles.tableRow }}>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Ajournement ou sans suites
          </Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}></Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}>
            Ajournement ou sans suites
          </Text>
          <Text style={{ ...styles.tableCell4, ...styles.textCenter }}></Text>
        </View> */}

      {/* <View
            style={{
              ...styles.textCenter,
              fontSize: 10,
              borderTopWidth: 1,
              borderTopColor: "black",
              borderTopStyle: "solid",
            }}
          >
            <Text>HOTEL DU DÉPARTEMENT</Text>
            <View style={{ fontSize: 8 }}>
              <Text>DIRECTION DE L'INSERTION</Text>
              <Text>Service départemental polyvalence insertion</Text>
              <Text>SECRETARIAT DE L'ÉQUIPE PLURIDISCIPLINAIRE</Text>
              <Text>2 Rue de la Source - 97488 Saint-Denis Cedex</Text>
              <Text>Tél : 0262 94 29 29 - Télécopie</Text>
              <Text>Site internet : http://www.cg974.fr</Text>
            </View>
          </View> 
      </View>*/}
    </Page>
  );
};

export default PVDoc;
