const initialState = {
  isLoading: false,
  isLoadingCalling: false,
  isLoadingClose: false,
  isLoadingEnd: false,
  items: {},
  actionUrls: {},
  errors: {},
  isInit: false,
};

const initItems = (payload, state) => {
  const { items, actionUrls } = payload;
  return {
    ...state,
    items: {
      ...items,
      epInitial: items.ep,
      recipientsInitial: items.recipients,
    },
    actionUrls,
    isLoading: false,
    isInit: true,
  };
};

const updateItem = ({ item, value }, state) => {
  return { ...state, items: { ...state.items, [item]: value } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "IS_LOADING_CALLING":
      return { ...state, isLoadingCalling: action.payload };
    case "IS_LOADING_WRITERS":
      return { ...state, isLoadingWriters: action.payload };
    case "IS_LOADING_CLOSE":
      return { ...state, isLoadingClose: action.payload };
    case "IS_LOADING_END":
      return { ...state, isLoadingEnd: action.payload };
    case "IS_LOADING_RECIPIENTS":
      return { ...state, isLoadingRecipients: action.payload };
    case "SET_RECIPIENTS":
      return {
        ...state,
        isLoadingRecipients: false,
        items: { ...state.items, recipients: action.payload },
      };
    case "INIT_ITEMS":
      return initItems(action.payload, state);
    case "IS_INIT":
      return { ...state, isInit: action.payload };

    case "UPDATE_ITEM":
      return updateItem(action.payload, state);

    case "SET_ERRORS":
      return { ...state, errors: action.payload };
    case "SET_CURRENT_ALLOC":
      return {
        ...state,
        items: {
          ...state.items,
          utils: {
            ...state.items.utils,
            prevCurrentAlloc: state.items.utils.currentAlloc,
            currentAlloc: action.payload,
          },
        },
      };

    default:
      return state;
  }
};
