import React, { useState, useEffect } from "react";
import Control from "../common/Control";
import DaySelector from "./DaySelector";
import { DateTime } from "luxon";

const RecurringBox = ({ updateReccuring, slotCalendarConfig, errors, selectedEvent }) => {
  const [custom, setCustom] = useState(false);

  useEffect(() => {
    if (custom) {
      var rec = JSON.parse(JSON.stringify(slotCalendarConfig));
      rec.byMinutes = [];
      rec.byDayOfWeek = [];
      rec.byDayOfMonth = [];
      rec.hourEnd = "";
      rec.minuteEnd = "0";
      rec.interval = 1;
      updateReccuring(rec);
    }
  }, [custom]);

  function updateRec(e) {
    var { name, value } = e.target;

    var rec = JSON.parse(JSON.stringify(slotCalendarConfig));

    if (name == "frequency") {
      if (value == "WEEKLY") {
        rec.byDayOfMonth = [];
      }
      if (value == "MONTHLY") {
        rec.byDayOfWeek = [];
      }
    }
    if (name == "end" && value != "") {
      value = new Date(value).toISOString();
    }
    rec[name] = value;

    updateReccuring(rec);
  }

  return (
    <div>
      <Control
        label="Répéter l'évènement"
        margin="my-2 mt-3"
        name="frequency"
        type="select"
        datas={[
          { id: "MINUTELY", name: "Aujourd'hui" },
          { id: "DAILY", name: "Tous les jours" },
          { id: "WEEKLY", name: "Toute les semaines" },
          { id: "MONTHLY", name: "Tous les mois" },
        ]}
        value={slotCalendarConfig.frequency}
        change={updateRec}
        selectFirstLabel={"Aucune répétition"}
        error={errors}
      />
      {slotCalendarConfig.frequency && (
        <Control
          margin={"my-3"}
          label="Personnaliser la répétition"
          type="checkbox"
          checked={custom}
          change={(e) => setCustom(e.target.checked)}
          error={errors}
        />
      )}
      {(custom || slotCalendarConfig.frequency == "MINUTELY") && (
        <DaySelector
          slotCalendarConfig={slotCalendarConfig}
          updateRec={updateRec}
          errors={errors}
        />
      )}
      {slotCalendarConfig.frequency && slotCalendarConfig.frequency != "MINUTELY" ? (
        <Control
          label="Jusqu'au"
          margin="my-2 mt-3"
          type="date"
          name="end"
          value={DateTime.fromISO(slotCalendarConfig.end).toFormat("yyyy-MM-dd")}
          change={updateRec}
          error={errors}
        />
      ) : slotCalendarConfig.frequency == "MINUTELY" ? (
        <>
          <Control
            label="Jusqu'à (heure)"
            margin="my-2 mt-3"
            type="range"
            name="hourEnd"
            value={slotCalendarConfig.hourEnd}
            change={updateRec}
            error={errors}
            inputParams={{
              min: parseInt(DateTime.fromISO(selectedEvent.start).hour),
              max: 18,
              step: 1,
            }}
          />
          <Control
            label="Jusqu'à (minute)"
            margin="my-2 mt-3"
            type="range"
            name="minuteEnd"
            value={slotCalendarConfig.minuteEnd}
            change={updateRec}
            error={errors}
            inputParams={{
              min: 0,
              max: 59,
              step: 1,
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default RecurringBox;
