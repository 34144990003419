import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import dataService from "../../../helpers/dataService";

const themesAtom = atom([]);
const subThemesAtom = atom((get) => {
  const themes = get(themesAtom);
  let subThemes = [];
  themes.forEach((theme) =>
    theme.subThemes.forEach((sub) => subThemes.push({ ...sub, id: sub._id }))
  );
  return subThemes;
});

const useThemes = () => {
  const [isLoadingThemes, setIsLoadingThemes] = useState(false);

  const [themes, setThemes] = useAtom(themesAtom);
  const [subThemes, setSubThemes] = useAtom(subThemesAtom);

  const onGetThemes = () => {
    setIsLoadingThemes(true);
    dataService.get(
      `themes`,
      setThemes,
      (err) => {},
      () => setIsLoadingThemes(false)
    );
  };

  return {
    themes,
    subThemes,
    isLoadingThemes,
    onGetThemes,
  };
};

export default useThemes;
