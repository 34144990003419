import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { api_url } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import dataService from "../../../helpers/dataService";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";
import tools from "../../../helpers/tools";
import { DateTime } from "luxon";

const UserDetail = (props) => {
  const [state, dispatch] = useStore();

  const [user, setUser] = useState({
    organizations: [],
    sites: [],
    email: "",
    password: "",
    role: "",
    gender: 1,
    firstname: "",
    lastname: "",
    phone: "",
    isEP: false,
    isDirector: false,
    contractEndAt: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [isResending, setIsResending] = useState(false);
  const [isDisabling, setIsDisabling] = useState(false);
  const [loadedOrgs, setLoadedOrgs] = useState([]);
  const { organizations, onGetOrganizations } = useOrganizations();

  useEffect(() => {
    onGetOrganizations();
    if (props.match.params.id != "create") {
      dataService.get(`managers/${props.match.params.id}`, setUser, setErrors);
    }
  }, []);

  useEffect(() => {
    if (user.organizations && user.organizations.length) {
      getSitesFromOrga();
    }
  }, [user.organizations]);

  const getSitesFromOrga = () => {
    var allPromises = [];
    var orgs = [...loadedOrgs];
    user.organizations.forEach((org) => {
      if (!orgs.includes(org)) {
        orgs.push(org);
        allPromises.push(
          new Promise((resolve, reject) => {
            dataService.get(`organizations/${org}/sites`, resolve, reject, () => {});
          })
        );
      }
    });
    Promise.all(allPromises).then((values) => {
      var sts = [];
      values.forEach((v) => {
        sts = [...sts, ...v];
      });
      var allSites = [...sites, ...sts];

      setSites(allSites);
      setLoadedOrgs(orgs);
    });
  };

  function updateData(e) {
    var usr = JSON.parse(JSON.stringify(user));
    var { name, value, type, checked } = e.target;

    if (e.target.formArray) {
      var elem = usr[name];
      if (e.target.formArray.target.checked) {
        elem.push(value);
      } else {
        elem = elem.filter((v) => v != value);
      }
    } else {
      elem = value;
    }
    usr[name] = elem;
    var hasErrors = false;
    if (name == "organizations") {
      usr.sites.forEach((s) => {
        var sio = sites.find((o) => o.id == s);
        if (sio && !usr.organizations.includes(sio.organization)) {
          alert(
            `Veuillez d'abord décocher le site ${sio.name} rattaché à cette organisation avant de la décocher`
          );
          hasErrors = true;
        }
      });
    }
    if (!hasErrors) {
      setUser(usr);
    }
  }

  function save() {
    setIsLoading(true);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      dataService.post(
        "managers/",
        user,
        (datas) => {
          setUser(datas);
          toast.success("Utilisateur enregistré");
          window.location.href = "/users/" + datas.id;
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      //update
      dataService.patch(
        `managers/${props.match.params.id}`,
        user,
        (datas) => {
          setUser(datas);
          setIsLoading(false);
          toast.success("Utilisateur enregistré");
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  }

  const resendActivation = () => {
    if (!window.confirm("Confirmez-vous le renvoi de l'email d'activation ?")) return false;
    setIsResending(true);
    dataService.get(
      "managers/" + user.id + "/renew-activation",
      () => {
        toast.success("L'email d'activation a bien été envoyé");
      },
      setErrors,
      () => setIsResending(false)
    );
  };
  const disableAccount = (status) => {
    if (
      !window.confirm(
        `Confirmez-vous ${status ? "l'activation" : "la désactivation"} de ce compte ?`
      )
    )
      return false;
    setIsDisabling(true);
    dataService.patch(
      "managers/" + user.id + "/activation",
      { isActivated: status },
      (datas) => {
        toast.success("Le compte a bien été désactivé");
        setUser(datas);
      },
      setErrors,
      () => setIsDisabling(false)
    );
  };

  const unlockAccount = () => {
    dataService.get(`managers/${user.id}/authorization/reset`, (datas) => {
      toast.success(
        "Le compte a bien été débloqué, l'utilisateur devra recharger la page de connexion de son coté."
      );
    });
  };

  return state.constants.items.length == 0 || !organizations ? (
    <Loader />
  ) : (
    <>
      <Page
        container={"container-fluid px-5"}
        title="Détail utilisateur"
        errors={errors}
        back={"/users"}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            Dernière connexion : {tools.formatDate(user.lastConnectionAt)}
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Organisations"
              name="organizations"
              type="checkboxList"
              value={user.organizations}
              datas={organizations}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            {sites && (
              <Control
                label="Sites"
                name="sites"
                type="checkboxList"
                value={user.sites}
                datas={sites.filter((s) => user.organizations.includes(s.organization))}
                change={updateData}
                error={errors}
              />
            )}
          </div>
        </div>
        <Control
          label="Rôle"
          type="btnList"
          btnInline={true}
          name="role"
          value={user.role}
          datas={state.constants.items.ROLES_MANAGER}
          dataIndex="id"
          dataLabel="name"
          change={updateData}
          error={errors}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Peut participer aux EP"
              type="checkbox"
              name="isEP"
              checked={user.isEP}
              change={(e) => setUser({ ...user, isEP: e.target.checked })}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Est délégataire du DG"
              type="checkbox"
              name="isDirector"
              checked={user.isDirector}
              change={(e) => setUser({ ...user, isDirector: e.target.checked })}
              error={errors}
            />
          </div>
        </div>
        <Control
          label="Civilité"
          type="select"
          name="gender"
          value={user.gender}
          datas={state.constants.items.GENDERS}
          dataIndex="id"
          dataLabel="name"
          change={updateData}
          error={errors}
        />
        <Control
          label="Prénom"
          name="firstname"
          value={user.firstname}
          change={updateData}
          error={errors}
        />
        <Control
          label="Nom"
          name="lastname"
          value={user.lastname}
          change={updateData}
          error={errors}
        />
        <Control label="Email" name="email" value={user.email} change={updateData} error={errors} />
        {user.id && (
          <div className="d-flex align-items-center flex-row">
            <SaveBtn
              margin="m-2"
              className=" d-center"
              type="btn-primary"
              save={resendActivation}
              isSaving={isResending}
              text={
                <>
                  <i className="fa fa-envelope-open-text mr-2" />
                  Renvoyer l'email d'activation
                </>
              }
            />
            <SaveBtn
              margin="m-2"
              className=" d-center"
              type={user.isActivated ? "btn-danger" : "btn-success"}
              save={() => disableAccount(!user.isActivated)}
              isSaving={isDisabling}
              text={
                <>
                  <i className={`fa fa-power-off mr-2`} />
                  {user.isActivated ? "Désactiver" : "Activer"} le compte
                </>
              }
            />
            {user.isActivated && (
              <button className="btn btn-dark" onClick={unlockAccount}>
                <i className="fa fa-lock-open mr-2" />
                Débloquer le compte
              </button>
            )}
          </div>
        )}
        {props.match.params.id == "create" && (
          <Control
            label="Mot de passe"
            name="password"
            type="password"
            value={user.password}
            change={updateData}
            error={errors}
          />
        )}
        <Control
          label="Téléphone"
          name="phone"
          value={user.phone}
          change={updateData}
          error={errors}
        />
        <Control
          label="Date de fin de contrat"
          name="contractEndAt"
          type="date"
          value={DateTime.fromISO(user.contractEndAt).toFormat("yyyy-MM-dd")}
          change={updateData}
          error={errors}
        />
        <div className="d-flex align-items-center justify-content-end">
          <SaveBtn save={save} isSaving={isLoading} text={"Enregistrer"} />
        </div>{" "}
      </Page>
    </>
  );
};

export default UserDetail;
