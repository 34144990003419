import React, { useEffect, useState } from "react";
import ModalBtn from "../ModalBtn";
import Modal from "../Modal";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import FormBasket from "./FormBasket";
import UserBox from "./UserBox";
import { formActions } from "../../../context/actions/formActions";
import Progress from "./Progress";

const FormHeader = ({
  title,
  step,
  lastStep,
  previousStep,
  nextStep,
  loginOpen,
  setLoginOpen,
  answers,
  setAnswers,
  formConfig,
}) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const form_actions = formActions(state, dispatch);
  var blockStyle = { minWidth: "33%" };

  return (
    <>
      <div
        className="d-flex align-items-center position-fixed justify-content-bewteen flex-wrap w-100 py-2 px-4 bg-white"
        style={{
          zIndex: "99",
          left: 0,
          top: 0,
        }}
      >
        <div
          className="d-flex justify-content-start flex-fill"
          style={blockStyle}
        >
          <button
            className="btn btn-link"
            onClick={previousStep}
            style={{
              opacity: step > 0 ? 1 : 0,
              pointerEvents: step > 0 ? "" : "none",
            }}
          >
            <i className="fa fa-arrow-left mr-2" />
            Retour
          </button>
        </div>
        <h3
          className="justify-content-center flex-fill d-none d-md-flex mb-0"
          style={blockStyle}
        >
          {title}
        </h3>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: "9999",
          }}
        >
          <Progress step={step} maxStep={lastStep} color={"#002789"} />
        </div>
      </div>
    </>
  );
};

export default FormHeader;
