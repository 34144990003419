import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useContext } from "react";
import tools from "../../../helpers/tools";
import useCERData from "../../Hooks/useCERData/useCERData";
import { StepContext } from "./StepContext";

const InfoLabel = ({ children }) => {
  var styles = StyleSheet.create({
    info: {
      fontSize: 8,
      color: "#797979",
      paddingLeft: 10,
    },
  });

  return <Text style={{ ...styles.info }}>{children}</Text>;
};

const OperatorsList = () => {
  const { operators } = useCERData();
  const step = useContext(StepContext);

  var styles = StyleSheet.create({
    container: {
      paddingLeft: 10,
      paddingBottom: 4,
    },
    title: {
      fontSize: 9,
      fontWeight: "bold",
      color: "#000000",
    },
  });

  return step.operators.map((operator) => {
    var operatorRef = operators.find((o) => o.id == operator.operator);

    return (
      <View style={{ ...styles.container }}>
        <Text style={{ ...styles.title }}>{operatorRef?.name}</Text>
        {operatorRef?.address ? (
          <InfoLabel>
            {operatorRef.address} {operatorRef.location?.postalCode} {operatorRef.location?.name}
          </InfoLabel>
        ) : null}

        {operatorRef?.email ? (
          <InfoLabel>
            {operatorRef?.phone ? tools.formatPhone(operatorRef.phone) : null} - {operatorRef.email}
          </InfoLabel>
        ) : null}
        {operatorRef?.link ? <InfoLabel>{operatorRef.link}</InfoLabel> : null}
      </View>
    );
  });
};

export default OperatorsList;
