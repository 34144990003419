import React, { useState, useEffect } from "react";
import Axios from "axios";
import { api_url } from "../../../config";
import { DateTime } from "luxon";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import montserrat from "../../pages/PassOrientation/montserrat.ttf";
import colors from "../../../helpers/colors";

const FormViewerPdf = ({ form, answers, recipient }) => {
  const [formatedAnswers, setFormatedAnswers] = useState([]);

  Font.register({
    family: "Montserrat",
    format: "truetype",
    fonts: [{ src: montserrat }],
  });

  useEffect(() => {
    getLocationDatas();
  }, []);

  function getLocationByCityId(id) {
    return new Promise((resolve, reject) => {
      Axios.get(api_url + "cities?_id=" + id)
        .then((res) => {
          resolve(res.data[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function getLocationDatas() {
    var fAns = JSON.parse(JSON.stringify(answers));

    form.questions.forEach(async (q) => {
      var ans = answers.find((a) => a.question == q.id).value;

      if (q.type == 11) {
        var city = await getLocationByCityId(ans);

        fAns.find((a) => a.question == q.id).value =
          (city.locationName ? city.locationName + " " : "") + city.postalCode + " " + city.name;
      }
    });
    setFormatedAnswers(fAns);
  }

  useEffect(() => {
    if (formatedAnswers.length) {
      setTimeout(() => {
        document.querySelector(".dl-rdsp-btn").click();
      }, 1500);
    }
  }, [formatedAnswers]);

  const myDoc = (formatedAnswers, form) => {
    var styles = StyleSheet.create({
      title: {
        marginTop: 10,
        fontSize: 12,
        borderBottom: "1pt solid grey",
      },
      question: {
        fontSize: 8,
        marginTop: 5,
      },
      answers: {
        fontSize: 9,
        color: colors.primary,
      },
      disabled: {
        color: "grey",
      },
      info: {
        fontSize: 10,
        color: "grey",
      },
    });

    return (
      <Document>
        <Page
          wrap
          size="A4"
          orientation="portrait"
          style={{ padding: "20px", fontFamily: "Montserrat" }}
        >
          <View>
            <Image
              src="/assets/logo-espoar.png"
              style={{
                width: 40,
                position: "absolute",
                right: 0,
                top: 0,
              }}
            />
            <Text style={{ textAlign: "center", marginBottom: 10, fontSize: 14 }}>
              Votre Recueil des Données Socio-Professionnelles
            </Text>
            <Text style={{ ...styles.info }}>
              Dénomination :{" "}
              <Text style={{ color: colors.primary }}>
                {recipient.lastname} {recipient.firstname}
              </Text>
            </Text>
            <Text style={{ ...styles.info }}>
              NIR : <Text style={{ color: colors.primary }}>{recipient.nir}</Text>
            </Text>
            <Text style={{ ...styles.info }}>
              Email : <Text style={{ color: colors.primary }}>{recipient.email}</Text>
            </Text>
            <Text style={{ ...styles.info }}>
              Téléphone : <Text style={{ color: colors.primary }}>{recipient.phone}</Text>
            </Text>
            <Text style={{ ...styles.info }}>
              Code postal :{" "}
              <Text style={{ color: colors.primary }}>
                {recipient.location.postalCode} - {recipient.location.name}
              </Text>
            </Text>
            <Text style={{ ...styles.info }}>
              Date de naissance :{" "}
              <Text style={{ color: colors.primary }}>
                {DateTime.fromISO(recipient.birthdate).toFormat("dd/MM/yyyy")}
              </Text>
            </Text>
            <Text style={{ ...styles.info }}>
              N° allocataire: <Text style={{ color: colors.primary }}>{recipient.CAFId}</Text>
            </Text>
          </View>
          {formatedAnswers.length > 0 &&
            form.sections.map((s, sk) => (
              <View>
                <Text style={{ ...styles.title }}>{s}</Text>
                {form.questions
                  .filter((q) => q.section == sk)
                  .map((q, qk) => {
                    var ans = formatedAnswers.find((a) => a.question == q.id).value;
                    var ret = "";
                    var objvalues = q.answers;
                    if (ans != "") {
                      if ([1, 5, 6, 9, 10, 11, 12].includes(q.type)) {
                        ret = formatedAnswers.find((as) => as.question == q.id).value;
                      } else if ([7].includes(q.type)) {
                        ret = ans
                          .map((a) => {
                            return objvalues.find((ov) => ov.value == a).label;
                          })
                          .join(",");
                      } else if ([11].includes(q.type)) {
                        ret = getLocationByCityId(ans);
                      } else if ([8].includes(q.type)) {
                        ret = DateTime.fromISO(ans).toFormat("dd/MM/yyyy HH:mm");
                      } else {
                        ret = objvalues.find((ov) => ov.value == ans).label;
                      }

                      return (
                        <View>
                          <Text style={{ ...styles.question }}>{q.question}</Text>
                          <Text style={{ ...styles.answers }}>{ret}</Text>
                        </View>
                      );
                    } else {
                      return (
                        <View>
                          <Text style={{ ...styles.question, ...styles.disabled }}>
                            {q.question}
                          </Text>
                          <Text style={{ ...styles.answers, ...styles.disabled }}>
                            non applicable
                          </Text>
                        </View>
                      );
                    }
                  })}
              </View>
            ))}
        </Page>
      </Document>
    );
  };

  return !formatedAnswers.length ? null : (
    <div className="text-center pt-3 bg-white pb-3 px-3 mt-2">
      <p className="my-2">
        Si votre téléchargement ne démarre pas automatiquement, cliquez sur le bouton ci-dessous
      </p>
      <PDFDownloadLink
        className="btn btn-primary mx-auto dl-rdsp-btn"
        document={myDoc(formatedAnswers, form)}
        fileName="Formulaire-RDSP.pdf"
      >
        {({ blob, url, loading, error }) => (loading ? "Chargement..." : "Télécharger")}
      </PDFDownloadLink>
      {/* <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
        <MyDoc datas={datas} />
      </PDFViewer> */}
    </div>
  );
};

export default FormViewerPdf;
