import { atom, useAtom } from "jotai";
import dataService from "../../../helpers/dataService";

export const recipientStockAtom = atom([]);
export const isLoadingRecipientStockAtom = atom(false);
export const recipientStockFiltersAtom = atom({
  filters: {
    terms: "",
    directorDecision: "",
  },
  page: "1",
  nbItemsByPage: 25,
});
export const nbResultsAtom = atom(0);
export const currentRecipientStockAtom = atom(null);
export const nbPagesAtom = atom(0);
export const isLoadingCurrentRecipientStockAtom = atom(false);

const useRecipientEpOutcome = () => {
  const [recipientStock, setRecipientStock] = useAtom(recipientStockAtom);
  const [isLoadingRecipientStock, setIsLoadingRecipientStock] = useAtom(
    isLoadingRecipientStockAtom
  );
  const [recipientStockFilters, setRecipientStockFilters] = useAtom(recipientStockFiltersAtom);
  const [nbResults, setNbResults] = useAtom(nbResultsAtom);
  const [currentRecipientStock, setCurrentRecipientStock] = useAtom(currentRecipientStockAtom);
  const [isLoadingCurrentRecipientStock, setIsLoadingCurrentRecipientStock] = useAtom(
    isLoadingCurrentRecipientStockAtom
  );
  const [nbPages, setNbPages] = useAtom(nbPagesAtom);

  const onSearchRecipientStock = (overrideFilter = null) => {
    setIsLoadingRecipientStock(true);
    const appliedFilters = overrideFilter || recipientStockFilters;
    dataService.post(
      `recipient-stocks/search`,
      appliedFilters,
      (data) => {
        setRecipientStock(data.data.map((d) => ({ ...d, id: d.id || d._id })));
        setNbResults(data.count);
        setNbPages(Math.ceil(parseInt(data.count) / appliedFilters.nbItemsByPage));
      },
      (err) => {},
      () => setIsLoadingRecipientStock(false)
    );
  };

  const onGetRecipientStockById = (id) => {
    setIsLoadingCurrentRecipientStock(true);
    dataService.get(
      `recipient-stocks/${id}`,
      setCurrentRecipientStock,
      (err) => {},
      setIsLoadingCurrentRecipientStock(false)
    );
  };

  const onFiltersChange = (e) => {
    const { name, value, type, checked } = e.target;
    var realValue = value;
    if (type == "checkbox") {
      realValue = checked ? true : false;
    }
    setRecipientStockFilters({
      ...recipientStockFilters,
      filters: {
        ...recipientStockFilters.filters,
        [name]: realValue,
      },
    });
  };

  const changePage = (page) => {
    const newFilters = {
      ...recipientStockFilters,
      page,
    };
    setRecipientStockFilters(newFilters);
    onSearchRecipientStock(newFilters);
  };
  const changeNbItemsByPage = (nbItemsByPage) => {
    const newFilters = {
      ...recipientStockFilters,
      nbItemsByPage,
    };
    setRecipientStockFilters(newFilters);
    onSearchRecipientStock(newFilters);
  };

  return {
    recipientStock,
    isLoadingRecipientStock,
    recipientStockFilters,
    nbResults,
    nbPages,
    isLoadingCurrentRecipientStock,
    currentRecipientStock,
    onSearchRecipientStock,
    onFiltersChange,
    onGetRecipientStockById,
    changePage,
    changeNbItemsByPage,
  };
};

export default useRecipientEpOutcome;
