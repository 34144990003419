import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";

var strTimeout = null;

const SiteList = () => {
  const [sites, setSites] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("SiteList", null);

  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          site: {},
          page: 1,
          nbItemsByPage: 25,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [organizations, setOrganizations] = useState([]);
  const [nbResults, setNbResults] = useState(0);

  useEffect(() => {
    Axios.get(api_url + "organizations/")
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((err) => {
        tools.filterError(err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      tools.saveState("SiteList", "search", JSON.stringify(search));

      searchData();
    }, 1500);
  }, [search]);

  function searchData() {
    setErrors(false);
    setIsLoading(true);

    Axios.post(api_url + "sites/search", search)
      .then((res) => {
        setSites(res.data.data);
        setNbPages(Math.ceil(parseInt(res.data.count) / search.nbItemsByPage));
        setIsLoading(false);
        setNbResults(res.data.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        tools.filterError(err);
        setSites([]);
      });
  }
  function updateSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        su.site[e.target.name] = true;
      } else {
        delete su.site[e.target.name];
      }
    } else {
      if (value == "") {
        delete su.site[e.target.name];
      } else {
        su.site[e.target.name] = value;
      }
    }

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function changeNbItemsByPage(i) {
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
  }

  function deleteSite(id) {
    if (window.confirm("Confirmez-vous la suppression de ce site ?")) {
      setIsLoading(true);
      Axios.delete(api_url + "sites/" + id)
        .then((res) => {
          searchData();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          tools.filterError(err);
        });
    }
  }

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        action={{
          to: "/sites/create",
          text: "Créer un site",
          dataPriv: "crud_sites",
        }}
        title={"Liste des sites"}
      >
        <div className="row mb-4">
          {organizations.length == 0 ? (
            <Loader />
          ) : (
            <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center">
              <Control
                margin={"my-0"}
                label="Organisation"
                type="select"
                name="organization"
                datas={organizations}
                dataLabel="name"
                dataIndex="id"
                value={search.site.organization}
                change={updateSearch}
              />
            </div>
          )}
          <div className="col-12 col-md-6 col-lg-4 justify-content-center">
            <Control
              margin={"my-0"}
              label="Recherche"
              name="searchString"
              value={search.site.searchString}
              change={updateSearch}
              suffix={<i className="fa fa-search ml-2" style={{ lineHeight: "34px" }}></i>}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 align-items-center justify-content-center d-flex">
            {nbResults} sites trouvés
          </div>
        </div>
      </Page>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading || organizations.length == 0 ? (
            <Loader />
          ) : (
            <>
              <Table
                datas={sites}
                fields={[
                  {
                    name: "Organisation",
                    path: "organization",
                    render: (elem) => organizations.find((f) => f.id == elem).name,
                  },
                  {
                    name: "Nom",
                    path: "name",
                  },
                  {
                    name: "Adresse",
                    path: "address",
                  },
                  {
                    name: "Ville",
                    path: "location",
                    render: (elem) => (elem ? elem.postalCode + ", " + elem.name : ""),
                  },
                  {
                    name: "Calendrier",
                    path: "id",
                    render: (elem) => (
                      <div data-priv="view_site_calendar">
                        <Link
                          to={"/sites/" + elem + "/calendar"}
                          className="btn btn-default btn-sm align-items-center justify-content-center d-flex"
                        >
                          <i className="fa fa-calendar-alt"></i>
                        </Link>
                      </div>
                    ),
                  },
                  {
                    name: "Modifier",
                    path: "id",
                    render: (elem) => (
                      <div data-priv="crud_sites">
                        <Link
                          to={"/sites/" + elem}
                          className="btn btn-primary btn-sm align-items-center justify-content-center d-flex"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </div>
                    ),
                  },
                  {
                    name: "Supprimer",
                    path: "id",
                    render: (elem) => (
                      <button
                        data-priv="crud_sites"
                        className="btn btn-danger btn-sm"
                        onClick={() => deleteSite(elem)}
                      >
                        <i className="fa fa-times" />
                      </button>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
            nbItemsByPage={search.nbItemsByPage}
            changeNbItemsByPage={changeNbItemsByPage}
          />
        </div>
      </div>
    </>
  );
};

export default SiteList;
