import React, { useEffect, useState } from "react";
import Control from "../../common/Control";
import PostalCode from "../../common/PostalCode";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import DynamicList from "../../common/DynamicList";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import AddRecipientToEp from "./AddRecipientToEp";
import { modalActions } from "../../../context/actions/modalActions";
import AddRecipientToCrossInterview from "./AddRecipientToCrossInterview";
import MutationModal from "./MutationModal";

const RecipientInfo = ({
  recipient,
  genders,
  maritalStatuses,
  allDisabled,
  errors,
  updateData,
  resendActivationMail,
  unlockAccount,
  rdsps = [],
  refresh = () => {},
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  const [showDl, setShowDl] = useState(false);
  const [agents, setAgents] = useState([]);
  const mActions = modalActions(state, dispatch);

  const partnersMutationAllowed = ["ML_NORD", "ML_SUD", "ML_EST", "ML_OUEST"];

  const copyText = (inputName) => {
    var copyText = document.querySelector("input[name='" + inputName + "']");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    toast.success("La valeur a été copiée dans votre presse-papier");
  };

  useEffect(() => {
    dataService.get(`managers/agents/mine`, setAgents);
  }, []);

  useEffect(() => {
    setShowDl(false);
  }, [recipient]);

  const addRecipientToNextEp = () => {
    mActions.updateModal({
      isOpen: true,
      content: <AddRecipientToEp recipient={recipient} refresh={refresh} />,
    });
  };
  const addRecipientToCrossInterview = () => {
    mActions.updateModal({
      isOpen: true,
      content: (
        <AddRecipientToCrossInterview
          recipient={recipient}
          onSaved={() => refresh()}
        />
      ),
    });
  };

  const organizationAfterMutationAllowed =
    state.constants.items.PARTNERS.filter((o) =>
      partnersMutationAllowed.includes(o.code)
    )?.find((z) => {
      const listOfPostalCodeStr = z.postalCodeTowns?.map((e) => e.toString());
      return listOfPostalCodeStr?.includes(recipient.location.postalCodeTown);
    });

  const openMutationModal = () => {
    mActions.updateModal({
      isOpen: true,
      content: (
        <MutationModal
          recipient={recipient}
          onSaved={() => refresh()}
          organizationMutationAllowed={organizationAfterMutationAllowed}
        />
      ),
    });
  };

  var datesDisabled = [
    "AGENT_CAF_TS",
    "AGENT_CAF_EG",
    "AGENT_CSU_CDD",
    "AGENT_PARTNER",
  ].includes(state.auth.user.role);
  var allDatesDisabled = ["AGENT_PARTNER"].includes(state.auth.user.role);

  const hasCurrentEp = () => {
    return recipient.currentEP;
  };

  const hasCurrentReo = () => {
    return recipient.currentOrientation;
  };

  const canSeeActionButtons = () => {
    return !recipient.currentEP && !recipient.currentOrientation;
  };

  const canMutate = [
    "SUPER_ADMIN",
    "ADMIN",
    "MANAGER_PARTNER",
    "AGENT_PARTNER",
  ].includes(state.auth.user.role);

  return (
    <div className="row mx-auto" style={{ maxWidth: 1000 }}>
      <div className="col-12 d-flex align-items-center justify-content-end">
        {hasCurrentReo() && (
          <span className="badge badge-primary mr-2">
            Réorientation en cours depuis le{" "}
            {DateTime.fromISO(recipient.currentOrientation.createdAt).toFormat(
              "dd/MM/yyyy"
            )}
          </span>
        )}
        {hasCurrentEp() && (
          <span className="badge badge-info mr-1">
            Inscrit à l'EP du{" "}
            {DateTime.fromISO(recipient.currentEP.EP.beginAt).toFormat(
              "dd/MM/yyyy"
            )}
          </span>
        )}
        {recipient.lastOrientation?.orientation && canSeeActionButtons() ? (
          <button
            className="btn btn-warning text-white btn-sm my-2 mr-1"
            onClick={addRecipientToCrossInterview}
          >
            <i className="fa fa-user-tag mr-2" />
            Demander une réorientation
          </button>
        ) : null}

        {canSeeActionButtons() && (
          <button
            className="btn btn-info btn-sm my-2 mr-1"
            onClick={addRecipientToNextEp}
          >
            <i className="fa fa-user-tag mr-2" />
            Inscrire à la prochaine EP
          </button>
        )}
        {!canSeeActionButtons() && (
          <p className="text-danger">
            Inscription en EP et réorientation non disponibles car une de ces
            procédures est déjà en cours.
          </p>
        )}

        {partnersMutationAllowed.includes(
          recipient.lastOrientation?.orientation
        ) &&
        recipient.lastOrientation?.orientation !=
          organizationAfterMutationAllowed.code &&
        canSeeActionButtons() &&
        canMutate ? (
          <button
            className="btn btn-danger text-white btn-sm my-2 mr-1"
            onClick={openMutationModal}
          >
            <i className="fa fa-user-tag mr-2" />
            Muter vers une autre Mission Locale
          </button>
        ) : null}

        <button
          data-priv="unlock_recipient"
          className="btn btn-dark btn-sm my-2 mr-1"
          onClick={unlockAccount}
        >
          <i className="fa fa-lock-open mr-2" />
          Débloquer le compte
        </button>
        <button
          onClick={() =>
            window.open(
              `/passorientation/${recipient.id}`,
              "PassOrientation",
              "height=400,width=400"
            )
          }
          target="_BLANK"
          className="btn btn-primary btn-sm my-2"
          data-priv="view_passorientation"
        >
          <i className="fa fa-download mr-2" />
          Consulter le PASS ORIENTATION
        </button>
      </div>

      <div className="col-12">
        <div className="custom-card">
          <Control
            label="Origine"
            value={
              constants.RECIPIENT_ORIGINS.find((r) => r.id == recipient.origin)
                ?.name
            }
            error={errors}
            disabled={true}
          />
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                disabled={allDisabled}
                label="N° d'allocataire"
                name="CAFId"
                value={recipient.CAFId}
                change={updateData}
                error={errors}
                suffix={
                  <i
                    className="fa fa-copy px-2"
                    title="Copier dans le presse-papier"
                    style={{ padding: "10px 0", cursor: "pointer" }}
                    onClick={() => copyText("CAFId")}
                  />
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                disabled={allDisabled}
                label="NIR"
                name="nir"
                value={recipient.nir}
                change={updateData}
                error={errors}
                suffix={
                  <i
                    className="fa fa-copy px-2"
                    title="Copier dans le presse-papier"
                    style={{ padding: "10px 0", cursor: "pointer" }}
                    onClick={() => copyText("nir")}
                  />
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Mise à jour SID"
                name="updatedFromSIDAt"
                value={
                  recipient.updatedFromSIDAt
                    ? DateTime.fromISO(recipient.updatedFromSIDAt).toFormat(
                        "dd/MM/yyyy"
                      )
                    : ""
                }
                change={() => {}}
                disabled={true}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Import SID"
                name="importedFromSIDAt"
                value={
                  recipient.importedFromSIDAt
                    ? DateTime.fromISO(recipient.importedFromSIDAt).toFormat(
                        "dd/MM/yyyy"
                      )
                    : ""
                }
                change={() => {}}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6 my-3">
        <div className="custom-card">
          <Control
            disabled={allDisabled}
            label="Civilité"
            type="btnList"
            btnInline={true}
            datas={genders}
            name="gender"
            value={recipient.gender}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Nom"
            name="lastname"
            value={recipient.lastname}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Prénom"
            name="firstname"
            value={recipient.firstname}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Date de naissance"
            name="birthdate"
            type="date"
            value={DateTime.fromISO(recipient.birthdate).toFormat("yyyy-MM-dd")}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Nombre d'enfants à charge au sens des PF"
            type="numberInt"
            name="nbDependantChildren"
            value={recipient.nbDependantChildren || 0}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Nombre de personnes à charge"
            type="numberInt"
            name="nbChildrenOrDependant"
            value={recipient.nbChildrenOrDependant}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Situation familiale"
            type="select"
            datas={maritalStatuses}
            name="maritalStatus"
            value={recipient.maritalStatus}
            change={updateData}
            error={errors}
          />
        </div>
      </div>

      <div className="col-12 col-lg-6 my-3">
        <div className="custom-card">
          <Control
            disabled={allDisabled}
            label={
              <>
                Email{" "}
                {recipient.isActivated ? (
                  <span className="text-success">
                    <i className="fa fa-check mr-1 ml-3" />
                    Activé
                  </span>
                ) : (
                  <>
                    <span className="text-danger mr-2">
                      <i className="fa fa-times mr-1 ml-3" />
                      Non activé
                    </span>
                    <button
                      data-priv="resend_recipient_activation"
                      onClick={resendActivationMail}
                      className="btn btn-primary btn-sm"
                    >
                      <i className="fa fa-envelope mr-2" />
                      Renvoyer l'email d'activation
                    </button>
                  </>
                )}
              </>
            }
            name="email"
            type="text"
            value={recipient.email}
            change={updateData}
            error={errors}
            suffix={
              <i
                className="fa fa-copy px-2"
                title="Copier dans le presse-papier"
                style={{ padding: "10px 0", cursor: "pointer" }}
                onClick={() => copyText("email")}
              />
            }
          />
          {recipient.emailOrigin && (
            <span
              className="badge badge-info position-relative"
              style={{
                marginLeft: 10,
                top: -10,
              }}
            >
              email sid : {recipient.emailOrigin}
            </span>
          )}
          <Control
            disabled={allDisabled}
            label="Téléphone"
            type="tel"
            name="phone"
            value={recipient.phone}
            change={updateData}
            error={errors}
            suffix={
              <i
                className="fa fa-copy px-2"
                title="Copier dans le presse-papier"
                style={{ padding: "10px 0", cursor: "pointer" }}
                onClick={() => copyText("phone")}
              />
            }
          />
          <Control
            disabled={allDisabled}
            label="Téléphone 2"
            type="tel"
            name="phone2"
            value={recipient.phone2}
            change={updateData}
            error={errors}
          />
          <PostalCode
            disabled={allDisabled}
            value={recipient.cityId}
            name="cityId"
            updateData={updateData}
            inputLabel="Code postal"
            error={errors}
            location={recipient.location}
          />
          <Control
            disabled={allDisabled}
            label="Adresse"
            name="address"
            value={recipient.address}
            change={updateData}
            error={errors}
          />
          <Control
            disabled={allDisabled}
            label="Complément d'adresse"
            name="addressAdd"
            value={recipient.addressAdd}
            change={updateData}
            error={errors}
          />
        </div>
      </div>

      <div className="col-12 my-3">
        <div className="custom-card">
          <DynamicList
            listLabel={
              <>
                Droits{" "}
                <span className="badge badge-primary ml-2">
                  {recipient.rights.length}
                </span>
              </>
            }
            btnLabel="Ajouter un droit"
            uniqueKey="recrights-1"
            name="rights"
            fields={[
              {
                label: "Type",
                name: "type",
                type: "select",
                datas: constants.RSA_TYPES,
                dataIndex: "id",
                dataLabel: "name",
                disabled: allDatesDisabled,
              },
              {
                label: "Depuis",
                name: "beginAt",
                type: "date",
                disabled: allDatesDisabled,
              },
              {
                label: "Fin",
                name: "endAt",
                type: "date",
                disabled: datesDisabled,
              },
            ]}
            items={recipient.rights ? recipient.rights : []}
            change={updateData}
            error={errors}
          />
          <DynamicList
            listLabel={
              <>
                Droits et devoirs{" "}
                <span className="badge badge-primary ml-2">
                  {recipient.rightsAndDuties.length}
                </span>
              </>
            }
            btnLabel="Ajouter un droit et devoir"
            uniqueKey="recrightsandduties-1"
            name="rightsAndDuties"
            fields={[
              {
                label: "Depuis",
                name: "beginAt",
                type: "date",
                disabled: allDatesDisabled,
                inputStyle: { maxWidth: 148 },
              },
              {
                label: "Fin",
                name: "endAt",
                type: "date",
                disabled: datesDisabled,
                inputStyle: { maxWidth: 148 },
              },
              {
                label: "État SDD",
                name: "stateSdd",
                type: "text",
                disabled: true,
                inputStyle: { maxWidth: 34 },
              },
              {
                label: "Date m.à.j",
                name: "updatedFromSIDAt",
                type: "date",
                disabled: true,
                inputStyle: { maxWidth: 148 },
              },
            ]}
            items={
              recipient.rightsAndDuties
                ? recipient.rightsAndDuties.map((rr) => {
                    var rrAug = { ...rr };

                    rrAug.stateSdd = tools.getEtatSDD(rr);

                    return rrAug;
                  })
                : []
            }
            change={updateData}
            error={errors}
          />
          <DynamicList
            listLabel={
              <>
                Contrats{" "}
                <span className="badge badge-primary ml-2">
                  {recipient.contracts.length}
                </span>
              </>
            }
            btnLabel="Ajouter un contrat"
            uniqueKey="contracts-1"
            name="contracts"
            fields={[
              {
                label: "Type",
                name: "type",
                type: "select",
                datas: constants.CONTRACT_TYPES,
                dataIndex: "id",
                dataLabel: "name",
              },
              {
                label: "Depuis",
                name: "beginAt",
                type: "date",
              },
              {
                label: "Fin",
                name: "endAt",
                type: "date",
              },
            ]}
            items={recipient.contracts ? recipient.contracts : []}
            change={updateData}
            error={errors}
          />
        </div>
      </div>

      <div className="col-12  my-3">
        <div className="custom-card">
          <Control
            label="Situation professionnelle"
            type="select"
            name="professionalSituation"
            value={recipient.professionalSituation}
            change={updateData}
            error={errors}
            datas={constants.PROFESSIONAL_SITUATIONS}
          />
          <Control
            label="Durée expérience"
            type="text"
            name="jobSearchExp"
            value={recipient.jobSearchExp}
            change={updateData}
            error={errors}
            suffix={<span className="d-center px-2">an(s)</span>}
          />
          <Control
            label="Projet professionnel"
            type="textarea"
            name="professionalProject"
            value={recipient.professionalProject}
            change={updateData}
            error={errors}
          />
          <Control
            label="Permis de conduire"
            type="checkBtnList"
            name="driveLicenses"
            value={recipient.driveLicenses || []}
            datas={constants.DRIVE_LICENSES}
            btnInline
            btnSm
            change={updateData}
            error={errors}
          />
          <Control
            label="Moyens de transport"
            type="checkBtnList"
            name="transports"
            value={recipient.transports || []}
            datas={constants.TRANSPORTS}
            btnInline
            btnSm
            change={updateData}
            error={errors}
          />
        </div>
      </div>

      <div className="col-12 col-lg-6 my-3">
        <div className="custom-card">
          <Control
            label="N° d'inscription à France Travail"
            type="text"
            name="numSubscribePE"
            value={recipient.numSubscribePE}
            change={updateData}
            error={errors}
          />
          <Control
            label="Emploi recherché en priorité"
            type="text"
            name="jobSearch"
            value={recipient.jobSearch}
            change={updateData}
            error={errors}
          />
        </div>
      </div>
      <div className="col-12 col-lg-6 my-3">
        <div className="custom-card">
          {agents.length ? (
            <Control
              label="Nom de l'agent CAF en charge"
              type="select"
              name="userManager"
              disabled={allDisabled}
              datas={agents.map((a) => ({
                ...a,
                fullName: tools.getFullname(a),
              }))}
              dataIndex={"id"}
              dataLabel={"fullName"}
              selectFirstLabel={recipient.userManagerDisplayed?.fullName}
              value={recipient.userManager}
              change={updateData}
              error={errors}
            />
          ) : null}
        </div>
      </div>

      {/* <div className="col-6">
        <Control
          label="Autre emploi recherché"
          type="text"
          name="job2Search"
          disabled={allDisabled}
          value={recipient.job2Search}
          change={updateData}
          error={errors}
        />
      </div>
      <div className="col-6">
        <Control
          label="Durée expérience"
          type="text"
          name="job2SearchExp"
          disabled={allDisabled}
          value={recipient.job2SearchExp}
          change={updateData}
          error={errors}
          suffix={"an(s)"}
        />
      </div> */}
    </div>
  );
};

export default RecipientInfo;
