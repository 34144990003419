import React from "react";
import colors from "../../../../helpers/colors";
import Accordion from "../../../common/Accordion/Accordion";
import SaveBtn from "../../../common/SaveBtn";
import useCERElementsCount from "./useCERElementsCount";

const AccordionTitle = ({ entity, className }) => {
  return (
    <div
      className={`d-flex w-100 align-items-center justify-content-between p-2 rounded ${className} `}
    >
      {entity.name} <span className="badge badge-success">{entity.count}</span>
    </div>
  );
};

const CERElementsCount = ({ url, year }) => {
  const { datas, isLoading, getDatas } = useCERElementsCount({ url, year });

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={datas ? "btn-default d-center" : "btn-primary d-center"}
          color={datas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            datas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>

      {!isLoading && datas && (
        <div className="col-12 mt-3">
          <Accordion
            id="statAccordionTheme"
            k={1}
            className="w-100"
            items={datas.map((theme, tk) => {
              return {
                title: <AccordionTitle entity={theme} />,
                content: (
                  <Accordion
                    k={`acc${tk}`}
                    id={`statAccordionGoals${tk}`}
                    className="w-100"
                    items={theme.goals.map((goal, gk) => {
                      return {
                        title: <AccordionTitle entity={goal} className="bg-info text-white" />,
                        content: (
                          <Accordion
                            k={`acc${gk}`}
                            id={`statAccordionSteps${gk}`}
                            className="w-100"
                            items={goal.steps.map((step) => {
                              return {
                                title: (
                                  <AccordionTitle entity={step} className="bg-primary text-white" />
                                ),
                                content: step.obstacles.length
                                  ? step.obstacles.map((obstacle) => (
                                      <AccordionTitle
                                        entity={obstacle}
                                        className="bg-light text-dark"
                                      />
                                    ))
                                  : null,
                              };
                            })}
                          />
                        ),
                      };
                    })}
                  />
                ),
              };
            })}
          />
        </div>
      )}
    </div>
  );
};

export default CERElementsCount;
