import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import dataService from "../../../helpers/dataService";

const recipientAtom = atom(null);

const useRecipientData = () => {
  const [recipient, setRecipient] = useAtom(recipientAtom);

  const getRecipient = (recipientId) => {
    dataService.get(`recipients/${recipientId}`, setRecipient);
  };

  return {
    recipient,
    getRecipient,
  };
};

export default useRecipientData;
