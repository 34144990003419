import { atom } from "jotai";

export const cerListFiltersModel = Object.freeze({
  page: 1,
  nbItemsByPage: 25,
  filters: Object.freeze({
    status: Object.freeze([]),
    userManager: "",
    createdAt: "",
    beginAt: "",
    endAt: "",
    zone: "",
    isUnderage: false,
  }),
});

export const cerListAtom = atom({
  data: [],
  count: 0,
});
export const cerListFiltersAtom = atom({
  page: 1,
  nbItemsByPage: 25,
  filters: {
    status: [],
    userManager: "",
    createdAt: "",
    beginAt: "",
    endAt: "",
    zone: "",
    isUnderage: false,
  },
});
export const isSearchingCerListAtom = atom(false);
