import React from "react";

const CancelErrors = ({ errors }) => {
  return (
    <div className="alert alert-warning">
      <p>L'EP a bien été reportée mais certaines erreur se sont produites :</p>
      <ul>
        {errors.map((err) => (
          <li>
            {err.message}
            <div>
              <small>{JSON.stringify(err.error)}</small>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CancelErrors;
