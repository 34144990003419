import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Control";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import PasswordControl from "../../common/PasswordControl";
import SaveBtn from "../../common/SaveBtn";
import { authActions } from "../../../context/actions/authActions";

const Profile = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordsEqual, setPasswordsEqual] = useState(true);
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const auth_actions = authActions(state, dispatch);

  useEffect(() => {
    setPasswordsEqual(password === passwordConfirm);
  }, [password, passwordConfirm]);

  const savePassword = () => {
    setIsLoading(true);
    dataService.patch(
      "managers/me/password",
      { password },
      () => {
        toast.success(
          "Votre mot de passe a bien été modifié, utilisez le pour vour reconnecter"
        );
        auth_actions.logoutUser();
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  return (
    <Page container={"container-fluid px-5"} title="Profil" errors={errors}>
      <div className="row">
        <div className="col-12 col-md-6">
          <h4 className="mb-3">Modifier mon mot de passe</h4>

          <Control
            label="Nouveau mot de passe"
            type="password"
            name="password"
            value={password}
            change={(e) => setPassword(e.target.value)}
            error={errors}
            setError={setErrors}
            autocomplete="new-password"
            required={true}
            containerStyle={{ padding: 0 }}
          />
          <PasswordControl
            password={password}
            minLength={9}
            minUppercase={1}
            minLowercase={3}
            minNumber={1}
            setCanSave={setCanSave}
          />
          <Control
            label="Retapez le mot de passe"
            type="password"
            name="passwordConfirm"
            value={passwordConfirm}
            change={(e) => setPasswordConfirm(e.target.value)}
            error={
              passwordConfirm != password
                ? { passwordConfirm: "Doit être identique à celui du dessus" }
                : {}
            }
            setError={setErrors}
            isValid={passwordConfirm == password && password && password != ""}
            required={true}
            containerStyle={{ padding: 0 }}
          />
          <SaveBtn
            save={savePassword}
            disabled={
              passwordConfirm != password ||
              !password ||
              password == "" ||
              !canSave
            }
            isSaving={isLoading}
            type="btn-primary"
            margin="mt-3"
          />
        </div>
      </div>
    </Page>
  );
};

export default Profile;
