import React from "react";
import colors from "../../../../helpers/colors";

const RdvItemContainer = ({ rdvIndex, headerContent, children }) => {
  return (
    <li
      className="list-group-item p-0 mb-3 bg-white animated fadeInUp faster shadow-sm border"
      style={{
        borderRadius: 8,
        overflow: "hidden",
        animationDelay: rdvIndex * 50 + "ms",
      }}
    >
      <div
        className="text-center w-100 py-1 font-weight-bold d-flex flex-row align-items-start justify-content-between px-2"
        style={{
          color: colors.primary,
        }}
      >
        {headerContent}
      </div>
      {children}
    </li>
  );
};

export default RdvItemContainer;
