import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { api_url } from "../../../config";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import $ from "jquery";
import CreateRdv from "./CreateRdv";

const initialDays = [
  { id: 1, name: "Lundi", morning: true, evening: false },
  { id: 2, name: "Mardi", morning: true, evening: false },
  { id: 3, name: "Mercredi", morning: true, evening: false },
  { id: 4, name: "Jeudi", morning: true, evening: false },
  { id: 5, name: "Vendredi", morning: true, evening: false },
  { id: 6, name: "Samedi", morning: true, evening: false },
];
const initialMoments = [
  { id: 1, name: "Matin" },
  { id: 2, name: "Après-midi" },
];

const FormRdv = ({ rdspId, userData, formData }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [organism, setOrganism] = useState(false);
  const [sites, setSites] = useState(false);
  const [rdvs, setRdvs] = useState(false);
  const [site, setSite] = useState(false);
  const [rdv, setRdv] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [formRdv, setFormRdv] = useState(false);
  const [countSel, setCountSel] = useState(0);
  const [displayAllRdv, setDisplayAllRdv] = useState(false);
  const [timeSelected, setTimeSelected] = useState({
    days: [],
  });
  const [days, setDays] = useState(initialDays);
  const [rdvsLoading, setRdvsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasReturn, setHasReturn] = useState(false);

  useEffect(() => {
    Axios.get(api_url + "rdsp/" + rdspId + "/orientation")
      .then((res) => {
        setOrganism(res.data.organization);
        setSites(res.data.sites);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
        setHasReturn(true);
      });
  }, []);
  useEffect(() => {
    if (site) {
      setRdv(false);
      setDisplayAllRdv(false);
      setRdvsLoading(true);
      Axios.post(api_url + "slotcalendars/sites/" + site, {
        userRecipientId: userData.id,
      })
        .then((res) => {
          setRdvs(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setRdvsLoading(false);
        });
    }
  }, [site]);

  useEffect(() => {
    if (rdvs && rdvs.length > 0) {
      reCalcDays();
      setRdv(rdvs[0].id);
    }
  }, [rdvs]);

  useEffect(() => {
    if (countSel >= 3) {
      setRdv(false);
      setDisplayAllRdv(true);
    }
  }, [countSel]);

  useEffect(() => {
    setRdv(false);
  }, [timeSelected]);

  function reCalcDays() {
    var dys = JSON.parse(JSON.stringify(days));
    dys = dys.filter((d) => {
      if (
        rdvs.find((r) => {
          var daystart = DateTime.fromISO(r.start).weekday;
          if (daystart == d.id) {
            return true;
          }
        })
      ) {
        return d;
      }
    });
    setDays(dys);
  }

  function save() {
    if (Object.keys(errors).length === 0 && errors.constructor === Object) {
      var selRdv = rdvs.find((r) => r.id == rdv);
      selRdv.comment = message;
      setIsLoading(true);
      Axios.patch(api_url + "slotcalendars/recipients/" + userData.id, selRdv)
        .then((res) => {
          setFormRdv({
            rdv: selRdv,
            site: site,
          });
          toast.success("Votre RDV a bien été enregistré");
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
      //save
      // setFormRdv({
      //   site: 1,
      //   rdv: 2,
      // });
    }
  }
  function setNextRdv() {
    var currentIndex = null;
    rdvs.forEach((r, rk) => {
      if (r.id == rdv) {
        currentIndex = rk;
      }
    });
    if (currentIndex + Math.floor((rdvs.length - 1) / 2) > rdvs.length - 1) {
      currentIndex = 0;
    } else {
      currentIndex += Math.floor((rdvs.length - 1) / 2);
    }
    $("#rdv").removeClass("fadeInRight").addClass("fadeOutLeft");
    setTimeout(() => {
      $("#rdv").removeClass("fadeOutLeft").addClass("fadeInRight");

      setRdv(rdvs[currentIndex].id);
      setCountSel(countSel + 1);
    }, 200);
  }

  function updateTimeSelected(e) {
    var { name, value } = e.target;
    var ev = JSON.parse(JSON.stringify(timeSelected));

    if (ev[name].includes(value)) {
      ev[name] = ev[name].filter((v) => v != value);
    } else {
      ev[name].push(value);
    }

    setTimeSelected(ev);
  }

  return (!organism || isLoading) && !hasReturn ? (
    <Loader />
  ) : (
    <div className="row">
      <div className="col-12">
        {!formRdv ? (
          <div className="card overflow-hidden">
            <div className="card-header">
              <h3 className="mb-0">Prendre un rendez-vous</h3>
            </div>
            <div className="card-body p-4 p-lg-5 p-md-4">
              <div className="d-center mb-2 justify-content-between">
                <p className="m-0">
                  Accompagnement par : <b>{organism.name}</b>
                </p>
              </div>
              {sites && (
                <Control
                  label="Choix du site du Rdv"
                  type="select"
                  name="site"
                  datas={sites}
                  dataIndex={"id"}
                  dataLabel={"name"}
                  value={site}
                  change={(e) => setSite(e.target.value)}
                  error={errors}
                />
              )}
              {rdvsLoading && <Loader />}
              {site &&
                rdvs &&
                (displayAllRdv ? (
                  <>
                    <Control
                      label="Quels jours de la semaine êtes-vous disponible ?"
                      type="checkBtnList"
                      btnInline={true}
                      name="days"
                      datas={days}
                      itemClassName={"btn-sm"}
                      value={timeSelected.days}
                      change={updateTimeSelected}
                    />

                    {timeSelected.days.length > 0 && (
                      <Control
                        label="Choix de la date de RDV"
                        type="btnList"
                        datas={rdvs.filter((r) => {
                          var timestart = DateTime.fromISO(r.start).weekday;
                          if (timeSelected.days.includes(timestart)) return true;
                          return false;
                        })}
                        dataIndex="id"
                        dataLabel="label"
                        name="rdv"
                        change={(e) => setRdv(e.target.value)}
                        value={rdv}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {site && organism.slugName == "CAF_EG" && (
                      <>
                        <CreateRdv
                          site={sites.find((s) => s.id == site)}
                          recipientId={userData.id}
                        />
                        <div className="my-3 font-weight-bold text-center" style={{ fontSize: 22 }}>
                          - OU -
                        </div>
                      </>
                    )}
                    {rdv && (
                      <div className="bg-light p-3">
                        <h5>Programmer un RDV</h5>
                        <div className="form-group animated fadeInUp faster">
                          <label className="d-block">Choix de la date de RDV</label>
                          <div className="btn-group">
                            <button
                              className="btn btn-default active animated faster fadeInRight"
                              style={{ animationDuration: "200ms" }}
                              id="rdv"
                            >
                              <p style={{ width: "93%", margin: 0 }}>
                                {rdvs.find((r) => r.id == rdv).label}
                              </p>
                              <i
                                className="fa fa-check ml-2 animated fadeInRight faster "
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>

                        <button
                          className="btn btn-link  animated fadeInUp faster mx-2"
                          style={{ marginTop: -40 }}
                          onClick={setNextRdv}
                        >
                          Choisir une autre date de RDV
                        </button>
                      </div>
                    )}
                  </>
                ))}
              {site && !rdvs.length && (
                <div className="alert alert-warning">
                  Aucun créneau de RDV disponible trouvé pour ce site.
                </div>
              )}
              {rdv && (
                <div className="bg-light px-3 pb-3 pt-0">
                  <Control
                    className="animated fadeInUp faster"
                    label="Souhaitez-vous laisser un message à la personne qui vous accueillera ?"
                    name="message"
                    value={message}
                    change={(e) => setMessage(e.target.value)}
                    error={errors}
                  />
                  {Object.keys(errors).length > 0 && (
                    <span className="text-danger animated flash">{Object.values(errors)}</span>
                  )}
                  {rdv && (
                    <button
                      onClick={save}
                      className="btn btn-success  animated fadeInUp faster mx-2"
                    >
                      Valider ce rendez-vous {rdvs.find((r) => r.id == rdv).label}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="card animated fadeInRight mb-5">
              <div className="card-header">
                <h3 className="mb-0">Votre rendez-vous</h3>
              </div>
              <div className="card-body p-4 p-lg-5 p-md-4">
                <div className="row mb-4">
                  <div className="col-12 col-lg-6 mb-3">RDV fixé le :</div>
                  <div className="col-12 col-lg-6 font-weight-bold text-center">
                    {formRdv.rdv.label}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-lg-6 mb-3">Lieu du RDV :</div>
                  <div className="col-12 col-lg-6 font-weight-bold text-center">
                    {sites.find((s) => s.id == formRdv.site).name} -{" "}
                    {sites.find((s) => s.id == formRdv.site).address}{" "}
                    {sites.find((s) => s.id == formRdv.site).location.postalCode}{" "}
                    {sites.find((s) => s.id == formRdv.site).location.name}
                  </div>
                </div>
                {/* <div className="row mb-4">
                  <div className="col-12 col-lg-6 mb-3">
                    Informations complémentaires :
                  </div>
                  <div className="col-12 col-lg-6 font-weight-bold text-center">
                    <a
                      target="_BLANK"
                      className="btn btn-primary m-auto"
                      href="/passorientation.pdf"
                    >
                      Télécharger le PASS'ORIENTATION
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
      {errors.other && (
        <div className="col-12 mt-3">
          <p className="alert alert-danger">{errors.other}</p>
        </div>
      )}
    </div>
  );
};

export default FormRdv;
