import { api_url } from "../config";
import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import axios from "axios";
import Geocode from "react-geocode";
import { DateTime } from "luxon";
import colors from "./colors";

const tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },
  round(num) {
    if (isNaN(parseFloat(num))) return "";
    return Math.round(parseFloat(num) * 100) / 100;
  },
  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },

  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.error("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
  },
  getLatLngFromAddress: async (address) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    return new Promise((resolve, reject) => {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve({ lat: lat, lng: lng });
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  getFullname: (person) => {
    return person && person?.firstname && person?.lastname
      ? (!isNaN(person.gender) ? (person.gender == 1 ? "Mr " : "Mme ") : "") +
          person.lastname.toUpperCase() +
          " " +
          person.firstname
      : "";
  },
  getGender: (gender) => {
    return gender == 1 ? "Mr" : "Mme";
  },
  getFullAddress: (location) => {
    return location ? `${location.name} ${location.postalCode}` : "";
  },
  getPeriodFormated: (start, end) => {
    var hs = DateTime.fromISO(start).hour + "";
    var ms = DateTime.fromISO(start).minute + "";
    var he = DateTime.fromISO(end).hour + "";
    var me = DateTime.fromISO(end).minute + "";

    return (
      (hs.length == 1 ? "0" + hs : hs) +
      "h" +
      (ms.length == 1 ? "0" + ms : ms) +
      " - " +
      (he.length == 1 ? "0" + he : he) +
      "h" +
      (me.length == 1 ? "0" + me : me)
    );
  },
  buildAnswersObject: (form) => {
    const multipleValuesTypes = ["checkBtnList", "checkboxList"];
    const booleanValuesTypes = ["checkbox"];
    var ans = {};
    form.forEach((qs) => {
      qs.questions.forEach((q) => {
        var inpValue = multipleValuesTypes.includes(q.type)
          ? typeof q.defaultValue !== "undefined"
            ? q.defaultValue
            : []
          : booleanValuesTypes.includes(q.type)
          ? false
          : typeof q.defaultValue !== "undefined"
          ? q.defaultValue
          : "";
        if (q.type == "info") return false;
        if (q.partOfSubObject) {
          ans[q.partOfSubObject] = ans[q.partOfSubObject]
            ? ans[q.partOfSubObject]
            : {};
          ans[q.partOfSubObject][q.name] = inpValue;
        }
        if (q.type == "component") {
          if (q.state) {
            var entryName = q.stateName ? q.stateName : q.name;
            ans[entryName] = ans[entryName] ? ans[entryName] : {};
            q.state.forEach((s) => {
              ans[entryName][s.name] = multipleValuesTypes.includes(s.type)
                ? s.defaultValue
                  ? s.defaultValue
                  : []
                : booleanValuesTypes.includes(s.type)
                ? false
                : s.defaultValue
                ? s.defaultValue
                : "";
            });
          }
        } else {
          ans[q.name] = inpValue;
        }
      });
    });
    return ans;
  },
  checkCanSimulate: (recipient) => {
    var result = true;
    var reason = [];
    if (recipient.rights?.length) {
      var lastEntry = recipient.rights[recipient.rights.length - 1];
      var beginAt = lastEntry.beginAt
        ? DateTime.fromISO(lastEntry.beginAt)
        : false;
      var endAt = lastEntry.endAt ? DateTime.fromISO(lastEntry.endAt) : false;
      if (!beginAt || beginAt > DateTime.local()) {
        result = false;
        reason.push(
          "la date de début des droits n'est pas renseignée ou n'a pas encore commencé"
        );
      }
      if (endAt && endAt < DateTime.local()) {
        result = false;
        reason.push("la date de fin des droits est passée");
      }
    } else {
      result = false;
      reason.push("l'allocataire n'a pas de droits actifs");
    }
    if (recipient.rightsAndDuties?.length) {
      var lastEntry =
        recipient.rightsAndDuties[recipient.rightsAndDuties.length - 1];
      var beginAt = lastEntry.beginAt
        ? DateTime.fromISO(lastEntry.beginAt)
        : false;
      var endAt = lastEntry.endAt ? DateTime.fromISO(lastEntry.endAt) : false;
      if (!beginAt || beginAt > DateTime.local()) {
        result = false;
        reason.push(
          "la date de début des droits et devoirs n'est pas renseignée ou n'a pas encore commencé"
        );
      }
      if (endAt && endAt < DateTime.local()) {
        result = false;
        reason.push("la date de fin des droits et devoirs est passée");
      }
    } else {
      result = false;
      reason.push("l'allocataire n'a pas de droits et devoirs actifs");
    }
    return { result, reason };
  },
  truenumberWithSpaces: (x) => {
    if (!x) return "";
    x = x == "0" ? "" : x + "";
    x = x.replace(/\s/g, "");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  numberWithLeadingZero: (x) => {
    return (x + "").length == 1 ? "0" + x : x;
  },
  groupBy: (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },
  getSanctionSentence: (avisEp, constants) => {
    const {
      EP_SANCTION_REDUCTIONS,
      EP_SANCTION_DURATIONS,
      EP_REDUCTION_LEVELS,
      EP_SANCTION_PRESENCES,
    } = constants;
    var sentence = "";

    let presence = EP_SANCTION_PRESENCES.find(
      (pres) => pres.id == avisEp.recipientPresence
    );

    sentence += presence ? `Allocataire ${presence.name}. ` : "";

    switch (avisEp.sanction.level + "") {
      case "1":
        sentence += "Sanction niveau 1, ";
        break;
      case "2":
        sentence += "Sanction niveau 2, ";
        break;
      case "3":
        sentence += "Pas de réduction, ";
        break;
    }

    var reductions = [];
    avisEp.sanction.sanctionType.forEach((sanc) => {
      if (!sanc.reduction || !sanc.duration) return false;
      reductions.push(
        `réduction de ${
          EP_SANCTION_REDUCTIONS.find((red) => sanc.reduction == red.id).name
        } pendant ${
          EP_SANCTION_DURATIONS.find((dur) => dur.id == sanc.duration).name
        }`
      );
    });
    if (reductions.length) {
      sentence += reductions.join(", ");
      sentence += ", ";
    }

    if (avisEp.sanction.isRightsMaintained) {
      sentence += "conservation des droits RSA";
    } else {
      sentence += "retrait des droits RSA (radiation)";
    }

    return sentence;
  },
  getReorientationSentence: (avisEp, constants, orgas) => {
    const { REORIENTATION_TYPES, ORIENTATION_COURSE_TYPES } = constants;
    var sentence = "";
    const reoType = REORIENTATION_TYPES.find(
      (rt) => rt.id == avisEp.reorientation?.reorientationType
    );

    if (reoType) {
      sentence += reoType.name + " ";
    }
    if (avisEp.reorientation?.partner) {
      var orgaFrom = orgas.find(
        (or) => or.id == avisEp.reorientation?.orientation?.fromPartner
      );
      orgaFrom = orgaFrom?.name;
      var orgaTo = orgas.find((or) => or.id == avisEp.reorientation?.partner);
      orgaTo = orgaTo?.name;
      if (orgaTo == orgaFrom) {
        sentence += " depuis " + orgaFrom;
      } else {
        sentence += " depuis " + orgaFrom + " vers " + orgaTo;
      }
    }
    if (avisEp.reorientation?.courseType) {
      var parcours = ORIENTATION_COURSE_TYPES.find(
        (or) => or.id == avisEp.reorientation?.courseType
      );
      parcours = parcours?.name;
      sentence += " pour " + parcours;
    }

    return sentence;
  },
  getReorientationOkSentence: (avisEp, constants, orgas) => {
    const { REORIENTATION_TYPES, ORIENTATION_COURSE_TYPES } = constants;
    var sentence = "";

    sentence += "Ré-orientation validée" + " ";

    if (avisEp.reorientation?.orientation) {
      var orgaFrom = orgas.find(
        (or) => or.id == avisEp.reorientation?.orientation?.fromPartner
      );
      var orgaTo = orgas.find(
        (or) => or.id == avisEp.reorientation?.orientation?.toPartner
      );
      sentence +=
        "depuis " +
        (orgaFrom?.name || "organisme indéfini") +
        " vers " +
        (orgaTo?.name || "organisme indéfini");
    }

    return sentence;
  },
  checkEpRecipientDone: (type, recipient) => {
    if (type == "SANCTION") {
      return recipient.EPNotice.recipientPresence;
    }
    if (type == "REORIENTATION_KO") {
      return recipient.EPNotice.reorientation?.partner;
    }
    return true;
  },
  copyText: (inputName) => {
    var copyText = document.querySelector("input[name='" + inputName + "']");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    toast.success("La valeur a été copiée dans votre presse-papier");
  },

  /**
   * Format recipient's rights for always have an array
   * @why ? Because if recipient is typeof UserRecipientDSP or UserRecipientEP, rights is simple Object
   *
   * @param {<UserRecipient>} recipient
   * @returns {Array}
   */
  getRights: (recipient) => {
    let rights = [];
    if (Array.isArray(recipient.rights)) {
      rights = recipient.rights;
    } else {
      rights = !recipient.rights ? [] : [recipient.rights];
    }
    return rights;
  },

  /**
   * Format recipient's rightsAndDuties for always have an array
   * @why ? Because if recipient is typeof UserRecipientDSP or UserRecipientEP, rightsAndDuties is simple Object
   *
   * @param {<UserRecipient>} recipient
   * @returns {Array}
   */
  getRightsAndDuties: (recipient) => {
    let rightsAndDuties = [];
    if (Array.isArray(recipient.rightsAndDuties)) {
      rightsAndDuties = recipient.rightsAndDuties;
    } else {
      rightsAndDuties = !recipient.rightsAndDuties
        ? []
        : [recipient.rightsAndDuties];
    }
    return rightsAndDuties;
  },

  /**
   * Format recipient's contracts for always have an array
   * @why ? Because if recipient is typeof UserRecipientDSP or UserRecipientEP, contracts is simple Object
   *
   * @param {<UserRecipient>} recipient
   * @returns {Array}
   */
  getContracts: (recipient) => {
    let contracts = [];
    if (Array.isArray(recipient.contracts)) {
      contracts = recipient.contracts;
    } else {
      contracts = !recipient.contracts ? [] : [recipient.contracts];
    }
    return contracts;
  },
  getReason1NameFromId: (constants, reason1) => {
    let reason1Ref =
      constants.EP_REASONS_1.find((rea) => rea.id == reason1) || {};

    return reason1Ref.name;
  },
  getReason2NameFromId: (constants, reason2) => {
    let reason2Ref =
      constants.EP_REASONS_2.find((rea) => rea.id == reason2) || {};

    return reason2Ref.name;
  },
  getMotifs: (recipient, constants) => {
    var motifs = [];
    if (recipient.type == "SANCTION") {
      recipient.reasons.forEach((reason) => {
        motifs.push({
          reason1: constants.EP_SANCTION_REASONS_1.find(
            (rea) => rea.id == reason.reason1
          ).name,
          reason2: reason.reasons2.map(
            (rea2) =>
              constants.EP_SANCTION_REASONS_2.find((rea) => rea.id == rea2).name
          ),
        });
      });
    }
    if (recipient.type == "REORIENTATION_KO") {
      recipient.reasons.forEach((reason) => {
        let reason1Name = tools.getReason1NameFromId(constants, reason.reason1);

        motifs.push({
          reason1: reason1Name,
          reason2: reason.reasons2.map((rea2) => {
            let reason2Name = tools.getReason2NameFromId(constants, rea2);

            return reason2Name;
          }),
        });
      });
    }
    if (recipient.type == "REORIENTATION_OK") {
      recipient.reasons.forEach((reason) => {
        let reason1Name = tools.getReason1NameFromId(constants, reason.reason1);

        motifs.push({
          reason1: reason1Name,
          reason2: reason.reasons2.map((rea2) => {
            let reason2Name = tools.getReason2NameFromId(constants, rea2);
            return reason2Name;
          }),
        });
      });
    }
    if (recipient.type == "SOCIAL_PATH_MAINTAIN") {
      motifs.push({
        reason1: "Maintien du parcours social",
        reason2: [],
      });
    }
    return motifs;
  },

  getCommunesFromLocation: (locations) => {
    var loc = [];
    locations.forEach((l) => {
      if (!loc.find((lc) => lc.townName == l.townName)) {
        loc.push({
          townName: l.townName,
          postalCodeTown: l.postalCodeTown,
          postalCodes: [],
        });
      }
      loc.find((t) => t.townName == l.townName).postalCodes.push(l);
    });
    return loc;
  },
  capitalize: (str) => {
    if (str) {
      str = str + "";
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return "";
    }
  },
  getISOFormatted: (dateStr) => {
    return DateTime.fromISO(dateStr).toFormat("dd/MM/yyyy");
  },
  getISOPlusDurationFormatted: (dateStr, scale, duration) => {
    return DateTime.fromISO(dateStr)
      .plus({ [scale]: duration })
      .toFormat("dd/MM/yyyy");
  },
  getNbMonthsFromDurationConstant: (constants, durationId) => {
    const durations = constants.CER_DURATIONS;
    const durationRef = durations.find((d) => d.id == durationId);
    return durationRef ? durationRef.value : "";
  },
  getObjectiveIndex: (objectives, objective) => {
    var index = null;
    objectives.forEach((g, gk) => {
      if (g.goal == objective.goal && g.pathType == objective.pathType) {
        index = gk;
      }
    });
    return index;
  },
  getStepIndex: (steps, step) => {
    var index = null;
    steps.forEach((g, gk) => {
      if (g.step == step.step) {
        index = gk;
      }
    });
    return index;
  },
  getOperatorIndex: (operators, operator) => {
    var index = null;
    operators.forEach((g, gk) => {
      if (g.operator == operator.operator) {
        index = gk;
      }
    });
    return index;
  },
  getObstacleIndex: (obstacles, obstacle) => {
    var index = null;
    obstacles.forEach((g, gk) => {
      if (g.obstacle == obstacle.obstacle) {
        index = gk;
      }
    });
    return index;
  },
  reorderItems: (items, sourceIndex, destinationIndex) => {
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    return items;
  },
  formatFollowingPattern: (value, separation, separator) => {
    value = value ? value + "" : "";
    var nirconcat = value.split(separator).join("");

    var newnir = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < nirconcat.length) {
        newnir += nirconcat.substring(curr, ns);
        var newnirsp = newnir.split(separator);
        if (
          newnirsp[newnirsp.length - 1].length == separation[key] &&
          newnirsp.length < separation.length
        ) {
          newnir += separator;
        }
      }

      curr = ns;
    }
    if (newnir.substr(newnir.length - separator.length) == separator) {
      newnir = newnir.substring(0, newnir.length - separator.length);
    }
    return newnir;
  },
  formatPhone: (value) => {
    if (!value) return "";
    let normalVal = value.split(".").join("").split(" ").join("");
    return tools.formatFollowingPattern(normalVal, [4, 2, 2, 2], " ");
  },
  formatDate: (value, format = "dd/MM/yyyy") => {
    return value
      ? DateTime.fromISO(value).toFormat(format, { locale: "fr" })
      : "";
  },
  getMonth: (value) => {
    return value
      ? tools.capitalize(
          DateTime.fromISO(value).toFormat("MMMM", { locale: "fr" })
        )
      : "";
  },
  getYear: (value) => {
    return value
      ? tools.capitalize(
          DateTime.fromISO(value).toFormat("yyyy", { locale: "fr" })
        )
      : "";
  },
  findIn: (arrayOfObjects, attribute, value) => {
    var ref = arrayOfObjects.find((o) => o[attribute] == value);
    return ref || {};
  },
  getCERElementUpdateTypeColor: (updateType) => {
    switch (updateType) {
      case "ADDED":
        return colors.success;

      case "DELETED":
        return colors.danger;
      default:
        return "#7f7f7f";
    }
  },
  getCERElementUpdateTypeStyle: (updateType) => {
    switch (updateType) {
      case "ADDED":
        return {
          color: tools.getCERElementUpdateTypeColor(updateType),
        };
      case "DELETED":
        return {
          color: tools.getCERElementUpdateTypeColor(updateType),
          textDecoration: "line-through",
        };
      default:
        return {
          color: tools.getCERElementUpdateTypeColor(updateType),
        };
    }
  },
  getRecipientTypeLibelle: (type) => {
    switch (type) {
      case "UserRecipient":
        return "Flux";
      case "UserRecipientEP":
        return "EP";
      case "UserRecipientDSP":
        return "Temporaire";
      case "UserRecipientStock":
        return "Stock";
      default:
        return "Flux";
    }
  },
  getRecipientTypeColor: (type) => {
    switch (type) {
      case "UserRecipient":
        return "primary";
      case "UserRecipientEP":
        return "danger";
      case "UserRecipientDSP":
        return "dark";
      case "UserRecipientStock":
        return "light";
      default:
        return "primary";
    }
  },
  getRecipientLink: (recipient) => {
    switch (recipient.model) {
      case "UserRecipient":
        return "/recipients/" + recipient.id;
      case "UserRecipientEP":
        return "/recipients-ep/" + recipient.id;
      case "UserRecipientDSP":
        return "/recipients-temp/" + recipient.id;
      case "UserRecipientStock":
        return "/recipients-stock/" + recipient.id;
      default:
        return "/recipients/" + recipient.id;
    }
  },
  getRecipientLinkFromParams: (model, id) => {
    switch (model) {
      case "UserRecipient":
        return "/recipients/" + id;
      case "UserRecipientEP":
        return "/recipients-ep/" + id;
      case "UserRecipientDSP":
        return "/recipients-temp/" + id;
      case "UserRecipientStock":
        return "/recipients-stock/" + id;
      default:
        return "/recipients/" + id;
    }
  },
  getRecipientType: (model) => {
    switch (model) {
      case "UserRecipient":
        return { name: "Flux", color: "primary" };
      case "UserRecipientEP":
        return { name: "EP", color: "secondary" };
      case "UserRecipientDSP":
        return { name: "Temporaire", color: "dark" };
      case "UserRecipientStock":
        return { name: "Stock", color: "info" };
      default:
        return { name: "ND", color: "default" };
    }
  },
  findLastRdsp: (userOrientation) => {
    if (userOrientation?.orientations) {
      const orientationsRdsp = userOrientation.orientations.filter(
        (o) => o.rdsp
      );

      return orientationsRdsp.length > 0
        ? DateTime.fromISO(
            orientationsRdsp[orientationsRdsp.length - 1].createdAt
          ).toFormat("dd/MM/yyyy")
        : "";
    }
    return "";
  },
  checkDateInRange: (currentDate, startDate, endDate) => {
    if (!endDate) {
      return currentDate > startDate;
    } else {
      return currentDate > startDate && currentDate <= endDate;
    }
  },
  getEtatSDD: (rightAndDuty) => {
    var rrAug = rightAndDuty;
    var isValid = new Date() >= new Date(rrAug.beginAt);
    if (rrAug.endAt) {
      isValid = isValid && new Date(rrAug.endAt) >= new Date();
    }
    return isValid ? "S" : "N";
  },
  getNbDaysFromNow: (date) => {
    return DateTime.fromISO(date).diff(DateTime.local(), "days").toObject()
      .days;
  },
  getAgeFromBirthdate: (birthdate) => {
    return Math.floor(
      DateTime.local().diff(DateTime.fromISO(birthdate), "years").toObject()
        .years
    );
  },
};

export default tools;
