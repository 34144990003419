import { atom, useAtom } from "jotai";
import dataService from "../../../helpers/dataService";

const organizationsAtom = atom([]);

const useOrganizations = () => {
  const [organizations, setOrganizations] = useAtom(organizationsAtom);

  const onGetOrganizations = () => {
    dataService.get(`organizations`, setOrganizations);
  };

  return {
    organizations,
    onGetOrganizations,
  };
};

export default useOrganizations;
