import React, { useState, useEffect } from "react";
import Axios from "axios";
import { api_url } from "../../../config";
import { DateTime } from "luxon";

const FormViewer = ({ form, answers }) => {
  const [formatedAnswers, setFormatedAnswers] = useState([]);

  useEffect(() => {
    getLocationDatas();
  }, []);

  function getLocationDatas() {
    var fAns = JSON.parse(JSON.stringify(answers));

    form.questions.forEach(async (q) => {
      var ans = answers.find((a) => a.question == q.id).value;

      if (q.type == 11) {
        var city = await getLocationByCityId(ans);

        fAns.find((a) => a.question == q.id).value =
          (city.locationName ? city.locationName + " " : "") +
          city.postalCode +
          " " +
          city.name;
      }
    });
    setFormatedAnswers(fAns);
  }

  function getLocationByCityId(id) {
    return new Promise((resolve, reject) => {
      Axios.get(api_url + "cities?_id=" + id)
        .then((res) => {
          resolve(res.data[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const getAnswerData = (ans, q) => {
    var ret = "";
    var objvalues = q.answers;

    if ([1, 5, 6, 9, 10, 11, 12].includes(q.type)) {
      ret = formatedAnswers.find((as) => as.question == q.id).value;
    } else if ([7].includes(q.type)) {
      ret = ans
        .map((a) => {
          return objvalues.find((ov) => ov.value == a).label;
        })
        .join(",");
    } else if ([11].includes(q.type)) {
      ret = getLocationByCityId(ans);
    } else if ([8].includes(q.type)) {
      ret = DateTime.fromISO(ans).toFormat("dd/MM/yyyy HH:mm");
    } else {
      ret = objvalues.find((ov) => ov.value == ans)
        ? objvalues.find((ov) => ov.value == ans).label
        : "";
    }

    return { ret };
  };

  return (
    <div className="row">
      {formatedAnswers.length > 0 &&
        form.sections.map((s, sk) => (
          <div className="col-12 my-2">
            <h4 className="text-primary">{s}</h4>
            {form.questions
              .filter((q) => q.section == sk)
              .map((q, qk) => {
                var ans = formatedAnswers.find((a) => a.question == q.id).value;
                if (ans != "") {
                  const { ret } = getAnswerData(ans, q);

                  return (
                    <div className="my-2">
                      <label className="mb-0">
                        <i className="fa fa-arrow-alt-circle-right mr-2" />
                        {q.question}
                      </label>
                      <div style={{ paddingLeft: 20 }}>{ret}</div>
                    </div>
                  );
                } else {
                  return (
                    <div className="my-2">
                      <label className="text-black-50 mb-0">
                        <i className="fa fa-arrow-alt-circle-right mr-2" />
                        {q.question}
                      </label>
                      <div style={{ paddingLeft: 20 }}>
                        <i className="text-muted">non applicable</i>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        ))}
    </div>
  );
};

export default FormViewer;
