import React, { useContext, useState } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERModule from "../../../../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "../../../ObjectiveContext";
import ObstacleSelect from "../AddObstacleForm/ObstacleSelect";
import { StepContext } from "../StepContext";
import { ObstacleContext } from "./ObstacleContext";

const ObstacleEdit = ({ goToViewMode }) => {
  const step = useContext(StepContext);
  const objective = useContext(ObjectiveContext);
  const obstacle = useContext(ObstacleContext);

  const { recipientCER, events } = useCERModule();
  const [newObstacle, setNewObstacle] = useState({ ...obstacle });

  const handleNewObstacleChange = (e) => {
    const { name, value } = e.target;
    setNewObstacle({ ...newObstacle, [name]: value });
  };

  const onValidateObstacle = () => {
    var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
    var stepIndex = tools.getStepIndex(objective.steps, step);
    var obstacleIndex = tools.getObstacleIndex(step.obstacles, obstacle);

    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex
            ? {
                ...st,
                obstacles: st.obstacles.map((o, ok) => {
                  return obstacleIndex == ok ? newObstacle : o;
                }),
              }
            : st;
        }),
      ],
    };
    events.onUpdateObjectiveAndSave(newObjective, objectiveIndex);
    goToViewMode();
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <ObstacleSelect
        objective={objective}
        step={step}
        obstacle={newObstacle}
        handleNewObstacleChange={handleNewObstacleChange}
        k="modifyObstacle"
      />

      <button
        className="btn border ml-2"
        disabled={!newObstacle.obstacle}
        onClick={onValidateObstacle}
        style={{ color: "#dee2e6" }}
      >
        <i className="fa fa-check" />
      </button>
    </div>
  );
};

export default ObstacleEdit;
