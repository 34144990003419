import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { api_url } from "../../../config";
import SimpleDynamicList from "../../common/SimpleDynamicList";
import SaveBtn from "../../common/SaveBtn";
import DynamicList from "../../common/DynamicList";

const ThemeDetail = (props) => {
  const [state, dispatch] = useStore();

  const [theme, setTheme] = useState({
    name: "",
    subThemes: [],
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.match.params.id != "create") {
      Axios.get(api_url + "themes/" + props.match.params.id)
        .then((res) => {
          setTheme(res.data);
        })
        .catch((err) => {
          setErrors(err.response.data);
        });
    }
  }, []);

  function updateData(e) {
    var usr = JSON.parse(JSON.stringify(theme));
    var { name, value } = e.target;
    if (e.target.formArray) {
      var elem = usr[name];
      if (e.target.formArray.target.checked) {
        elem.push(value);
      } else {
        elem = elem.filter((v) => v != value);
      }
    } else {
      elem = value;
    }
    usr[name] = elem;
    setTheme(usr);
  }

  function save() {
    setIsLoading(true);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      Axios.post(api_url + "themes/", theme)
        .then((res) => {
          setTheme(res.data);
          setIsLoading(false);
          window.location.href = "/themes/" + res.data.id;
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          setIsLoading(false);
        });
    } else {
      //update
      Axios.patch(api_url + "themes/" + props.match.params.id, theme)
        .then((res) => {
          setTheme(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          setIsLoading(false);
        });
    }
  }

  return state.constants.items.length == 0 ? (
    <Loader />
  ) : (
    <>
      <Page
        container={"container-fluid px-5"}
        title="Détail du thème"
        errors={errors}
        back={"/themes"}
      >
        <div className="row">
          <div className="col-12">
            <Control
              label="Nom"
              name="name"
              value={theme.name}
              change={updateData}
              error={errors}
            />
            <DynamicList
              listLabel="Sous-thèmes"
              uniqueKey="subthemelist"
              name="subThemes"
              fields={[{ name: "name", label: "Nom" }]}
              items={theme.subThemes}
              btnLabel="Ajouter un sous-thème"
              change={updateData}
              error={errors}
            />
            <div className="d-flex justify-content-end">
              <SaveBtn save={save} isSaving={isLoading} />
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default ThemeDetail;
