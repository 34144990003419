import React, { useState } from "react";
import colors from "../../../../helpers/colors";
import dataService from "../../../../helpers/dataService";
import SaveBtn from "../../../common/SaveBtn";
import useStockStat from "./useStockStat";

const Stock = ({ url, year }) => {
  const { isLoading, datas, getStockStat } = useStockStat({ url, year });

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={datas ? "btn-default d-center" : "btn-primary d-center"}
          color={datas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getStockStat}
          isSaving={isLoading}
          text={
            datas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {datas && (
        <div className="col-12">
          <div className="row">
            <StatCounter
              title="Nombre d'utilisateurs du flux d'origine stock"
              number={datas.userRecipientFromStock}
            />
            <StatCounter
              title="Allocataires du stock restant"
              number={
                <>
                  {datas.stockLeft}
                  <div className="d-center">
                    <span style={{ fontSize: 12 }}>initial : {datas.stockInitial}</span>
                  </div>
                  <div className="d-center">
                    <span style={{ fontSize: 12 }}>
                      différence : {parseInt(datas.stockLeft) - parseInt(datas.stockInitial)}
                    </span>
                  </div>
                </>
              }
            />

            {Object.keys(datas.orientationsByPartner).map((st) => {
              const leftNumber = datas.orientationsByPartner[st];
              const initialNumber = datas.initialOrientationsByPartner[st];
              const difference = parseInt(leftNumber) - parseInt(initialNumber);
              return (
                <StatCounter
                  title={`Nb d'orientations vers ${st}`}
                  number={
                    <>
                      {leftNumber}
                      <div className="d-center">
                        <span style={{ fontSize: 12 }}>initial : {initialNumber}</span>
                      </div>
                      <div className="d-center">
                        <span style={{ fontSize: 12 }}>différence : {difference}</span>
                      </div>
                    </>
                  }
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const StatCounter = ({ title, number }) => {
  return (
    <div className="col-4" style={{ textAlign: "center" }}>
      <div className="border rounded p-3 shadow-sm bg-primary h-100 text-white d-flex flex-column justify-content-between">
        <div>
          <strong>{title}</strong>
        </div>
        <div style={{ fontSize: 40 }}>
          <strong>{number}</strong>
        </div>
      </div>
    </div>
  );
};

export default Stock;
