import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useContext } from "react";
import colors from "../../../helpers/colors";
import useCERData from "../../Hooks/useCERData/useCERData";
import { AmendmentContext } from "./AmendmentContext";
import AmendmentStepsList from "./AmendmentStepsList";
import { GoalContext } from "./GoalContext";
import { PathTypeContext } from "./PathTypeContext";

const AmendmentGoalsList = ({ constants }) => {
  const { cerObjectives } = useCERData();
  const currentAmendment = useContext(AmendmentContext);
  const pathType = useContext(PathTypeContext);

  const goals = currentAmendment.goals;
  const filteredObjectives = goals.filter((g) => {
    return g.pathType == pathType.id;
  });

  var styles = StyleSheet.create({
    container: {
      paddingLeft: 10,
      paddingBottom: 4,
    },
  });

  const getStatusStyles = (objective) => {
    const titleStyles = {
      fontSize: 11,
      paddingBottom: 3,
    };
    if (objective.updateType) {
      switch (objective.updateType) {
        case "DELETED":
          return {
            ...titleStyles,
            color: colors.danger,
            textDecoration: "line-through",
          };
        case "ADDED":
          return {
            ...titleStyles,
            color: colors.success,
          };
      }
    }

    return {
      ...titleStyles,
      color: "#7f7f7f",
    };
  };

  return filteredObjectives.map((objective, ok) => {
    var objectiveRef = cerObjectives.find((o) => o.id == objective.goal);
    var categoryRef = constants.CER_GOAL_CATEGORIES.find((cat) => cat.id == objectiveRef.category);
    var objectifNum = parseInt(ok) + 1;

    const titleStyles = getStatusStyles(objective);

    return (
      <View style={{ ...styles.container }}>
        <Text style={{ ...titleStyles }}>
          <Text style={{ fontSize: 9 }}>{categoryRef?.name}/</Text>
          {objectiveRef?.name}
        </Text>
        <GoalContext.Provider value={objective}>
          <AmendmentStepsList />
        </GoalContext.Provider>
      </View>
    );
  });
};

export default AmendmentGoalsList;
