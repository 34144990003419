import React, { useEffect, useState, Fragment } from "react";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import useStore from "../../../context/useStore";
import AnswerInterpretor from "./AnswerInterpretor";
import "./form-generator.css";
import Axios from "axios";
import ProgressBar from "./ProgressBar";
import LoaderFinish from "./LoaderFinish";
import tools from "../../../helpers/tools";
import LoadLSOverlay from "./LoadLSOverlay";
import { toast } from "react-toastify";

const FormGenerator = ({
  formDataUrl,
  formValidationUrl = null,
  formSubmitUrl = null,
  isDspFinished,
  setIsDspFinished,
  setFormData,
  withContinue = false,
  formatSaveData = false,
  closingPhrase = "Veuillez patienter pendant que nous déterminons votre orientation.",
  successPhrase = "",
  additionalDataToSave = false,
  specialCaseRDSP = false,
}) => {
  const [state, dispatch] = useStore();
  const [form, setForm] = useState(false);
  const [answers, setAnswers] = useState(false);
  const constants = state.constants.items;
  const [step, setStep] = useState(0);
  const [oldStep, setOldStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (formDataUrl) {
      getForm();
    }
  }, [formDataUrl]);

  function getForm() {
    Axios.get(formDataUrl)
      .then((res) => {
        setForm(res.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (form && !answers) {
      var ans = [];
      form.questions.forEach((q) => {
        var value = q.type == 7 ? [] : "";
        ans.push({
          question: q.id,
          value: value,
        });
      });

      setAnswers(ans);
    }
  }, [form]);

  useEffect(() => {
    if (form && answers) {
      var cond = form.questions[step].condition;
      if (cond != "") {
        cond = cond.split("&&");
        cond.forEach((c) => {
          var isCondNotFullfiled = true;
          if (c.includes("in")) {
            var obj = c.split("in");
            var targetValue = obj[1];
            var qsId = parseInt(obj[0].split("q")[1]);
            var targetQuestion = form.questions.find((q) => q.id == qsId).id;
            isCondNotFullfiled = !answers
              .find((a) => a.question == targetQuestion)
              .value.includes(parseInt(targetValue));
          } else {
            var obj = c.split("==");
            var targetValue = obj[1];
            var qsId = parseInt(obj[0].split("q")[1]);
            var targetQuestion = form.questions.find((q) => q.id == qsId).id;
            isCondNotFullfiled =
              answers.find((a) => a.question == targetQuestion).value !=
              targetValue;
          }

          if (isCondNotFullfiled) {
            if (oldStep > step) {
              setStep(step - 1);
            } else {
              if (step + 1 > form.questions.length - 1) {
                save();
              } else {
                setStep(step + 1);
              }
            }
          }
        });
      }
    }
    if (step > 0 && withContinue) {
      tools.saveState("FormRDSP", "answers", answers);
      tools.saveState("FormRDSP", "step", step);
    }
  }, [step]);

  function updateAnswer(e) {
    var ans = JSON.parse(JSON.stringify(answers));

    var elem = ans.find((a) => a.question == e.target.name).value;
    var value = e.target.value;
    if (e.target.formArray) {
      var refAns = form.questions[step].answers;
      var hasExclusive = refAns.filter((a) => a.isExclusive);

      if (e.target.formArray.target.checked) {
        if (e.target.isExclusive) {
          elem = [value];
        } else {
          elem.push(value);
          hasExclusive.forEach((ae) => {
            elem = elem.filter((v) => v != ae.value);
          });
        }
      } else {
        elem = elem.filter((v) => v != value);
      }
    } else {
      elem = value;
    }
    ans.find((a) => a.question == e.target.name).value = elem;

    setAnswers(ans);
  }

  function prevStep() {
    setOldStep(step);
    setStep(step - 1);
  }
  function nextStep() {
    validateStep();
  }
  function validateStep(isLast) {
    setIsValidating(true);

    Axios.post(
      formValidationUrl
        ? formValidationUrl + "/" + step
        : formDataUrl + "validation/" + step,
      answers
    )
      .then((res) => {
        setIsValidating(false);
        if (isLast) {
          save();
        } else {
          setOldStep(step);
          setStep(step + 1);
        }
      })
      .catch((err) => {
        setIsValidating(false);

        if (err.response) {
          setErrors(err.response.data);
        }
      });
  }

  function save() {
    setIsSaving(true);
    var datas = JSON.parse(JSON.stringify(answers));

    if (formatSaveData) {
      datas = {};
      form.questions.forEach((ans) => {
        datas[ans.attribute] = answers.find((q) => q.question == ans.id).value;
      });

      if (additionalDataToSave) {
        datas = { ...additionalDataToSave, ...datas };
      }
    }
    if (specialCaseRDSP) {
      datas = {
        rdsp: datas,
        ...additionalDataToSave,
      };
    }
    Axios.post(formSubmitUrl ? formSubmitUrl : formDataUrl, datas)
      .then((res) => {
        setFormData(res.data);
        setIsDspFinished(true);
        if (successPhrase != "") {
          toast.success(successPhrase);
        }
      })
      .catch((err) => {
        if (err.response) setErrors(err.response.data);
        setIsSaving(false);
      });
  }

  return (
    <>
      <div id="form_container" className="col-12 col-lg-8 col-md-8 m-auto px-0">
        {!form || !answers || constants.length == 0 ? (
          <Loader />
        ) : isSaving ? (
          <LoaderFinish text={closingPhrase} />
        ) : (
          <Fragment>
            <h2 id="form_section_title">
              {form.sections[form.questions[step].section]}
            </h2>
            <ProgressBar
              progress={((step + 1) / form.questions.length) * 100}
              text={step + 1 + "/" + form.questions.length}
            />

            {form.questions.map((q, qk) => {
              return (
                <div
                  key={"q" + qk}
                  style={{ width: "100%", left: 0 }}
                  className={
                    "question_container animated faster " +
                    (step == qk
                      ? oldStep < step
                        ? "fadeInRight"
                        : "fadeInLeft"
                      : "hidden")
                  }
                >
                  {isValidating && step == qk && (
                    <div className="overlay_loading animated fadeIn faster">
                      <Loader />
                    </div>
                  )}
                  <div className="question_header">
                    <h4 className="question_title">{q.question}</h4>
                    <p className="question_description">{q.description}</p>
                    <div className="question_answer_container">
                      <AnswerInterpretor
                        question={q}
                        k={qk}
                        inputTypes={constants.INPUT_TYPES}
                        updateAnswer={updateAnswer}
                        answers={answers}
                        errors={errors}
                      />
                      {q.attribute == "email" && (
                        <>
                          <p>
                            Vous ne possédez pas encore de boite email ?
                            Utilisez un de ces liens pour en créer une :
                          </p>
                          <ul>
                            <li>
                              <a target="_BLANK" href="/creation-email.pdf">
                                Créer une boite email sur LA POSTE
                              </a>
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="question_navigation">
                    {step > 0 && (
                      <button
                        className="btn_nav btn_nav_prev"
                        onClick={prevStep}
                      >
                        <i className="fa fa-chevron-left mr-2"></i>
                        retour
                      </button>
                    )}
                    {step < form.questions.length - 1 && (
                      <button
                        className={
                          "btn_nav btn_nav_next btn btn-primary " +
                          (answers.find((a) => a.question + "" == step + 1 + "")
                            .value.length == 0
                            ? "disabled"
                            : "")
                        }
                        onClick={nextStep}
                      >
                        Continuer
                      </button>
                    )}
                    {step == form.questions.length - 1 && (
                      <button
                        className="btn btn-success"
                        onClick={() => validateStep(true)}
                      >
                        <i className="fa fa-check mr-2"></i>
                        Envoyer mes réponses
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default FormGenerator;
