import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import RdvList from "./RdvList";
import RdvDetail from "./RdvDetail";
import Axios from "axios";
import { api_url } from "../../../config";
import Page from "../../common/layout/Page";
import RdvFilters from "./RdvFilters";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import RecipientDetail from "../RecipientDetail/RecipientDetail";
import dataService from "../../../helpers/dataService";
import queryString, { parse } from "query-string";
import tools from "../../../helpers/tools";
import { modalActions } from "../../../context/actions/modalActions";
import RdvCERDetail from "./RdvCERDetail";

const filtersInitialState = {
  organization: "",
  site: "",
  box: "",
  start: DateTime.local().startOf("day").toISO(),
  end: DateTime.local().endOf("day").toISO(),
  isClosed: true,
  isNotClosed: true,
  isTreated: true,
  isNotTreated: true,
  userManager: "",
};

var timeInterval = null;

const RdvComponent = (props) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const [rdvs, setRdvs] = useState([]);
  const [errors, setErrors] = useState({});
  const [rdv, setRdv] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [sites, setSites] = useState([]);
  const parsed = queryString.parse(props.location.search);
  var searchState = tools.getState("RdvComponent", null);

  const [filters, setFilters] = useState(
    searchState && !parsed.organization
      ? JSON.parse(searchState.search)
      : {
          ...filtersInitialState,
          organization: parsed.organization || filtersInitialState.organization,
          site: parsed.site || filtersInitialState.site,
          box: parsed.box || filtersInitialState.box,
        }
  );
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rdvsExpired, setRdvsExpired] = useState([]);
  const [isInit, setIsInit] = useState(false);

  //-------------------------------
  //-------------------------------
  //-------------------------------
  const [preventBack, setPreventBack] = useState(false);

  useEffect(() => {
    if (rdv && !isLoading) {
      setErrors({});
      setPreventBack(false);
      if (!rdv.userManager || rdv.userManager.id != state.auth.user.id) attribRdvToManager(rdv.id);
    }
  }, [rdv]);

  useEffect(() => {
    setFiltersLoading(true);
    Axios.get(api_url + "organizations/mine")
      .then((res) => {
        setOrganizations(res.data);
        if (res.data.length == 1) {
          setFilters({ ...filters, organization: res.data[0].id });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setFiltersLoading(false);
      });
  }, []);

  useEffect(() => {
    setRdv(false);
    var fil = JSON.parse(JSON.stringify(filters));
    fil.userManager = "";
    setFilters(fil);
    clearInterval(timeInterval);
    timeInterval = setInterval(() => {
      var rdvsExp = [];
      if (rdvs.length > 0) {
        rdvs.forEach((r) => {
          let diff = DateTime.fromJSDate(new Date()).diff(DateTime.fromISO(r.start), [
            "hours",
            "minutes",
            "seconds",
          ]);
          let diffm = diff.toObject();
          let isLate =
            DateTime.fromJSDate(new Date()) > DateTime.fromISO(r.userManager ? r.start : r.end);
          var outPutStr = "";
          var hours = Math.abs(Math.round(parseInt(diffm.hours)));
          var minutes = Math.abs(Math.round(parseInt(diffm.minutes)));
          var seconds = Math.abs(Math.round(parseInt(diffm.seconds)));

          if (hours >= 24) {
            var days = Math.floor(hours / 24);
            hours = hours - days * 24;
            outPutStr = days + "j";
          } else if (hours > 1) {
            outPutStr = hours + "h" + minutes;
          } else {
            outPutStr = minutes + "m";
          }
          rdvsExp.push({
            id: r.id,
            diff: (isLate ? "Retard " : "Dans ") + outPutStr,
            isLate: isLate,
          });
        });

        setRdvsExpired(rdvsExp);
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [rdvs]);

  useEffect(() => {
    if (isInit) {
      setFilters({ ...filters, site: "" });
    }
    if (filters.organization != "") {
      getSitesOfOrganization();
    }
  }, [filters.organization]);

  useEffect(() => {
    tools.saveState("RdvComponent", "search", JSON.stringify(filters));
  }, [filters]);

  function resetFilters() {
    setFilters(filtersInitialState);
  }
  function getSitesOfOrganization() {
    setFiltersLoading(true);
    Axios.get(api_url + "organizations/" + filters.organization + "/sites/mine")
      .then((res) => {
        setSites(res.data);
        if (res.data.length == 1) {
          setFilters({ ...filters, site: res.data[0].id });
        }
        setIsInit(true);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setFiltersLoading(false);
      });
  }

  function getRdvs() {
    //if (!filters.site) return false;
    setFiltersLoading(true);
    setErrors({});
    Axios.post(api_url + "rdv/search/", {
      site: filters.site,
      start: filters.start,
      end: filters.end,
    })
      .then((res) => {
        setRdvs(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setFiltersLoading(false);
      });
  }

  function attribRdvToManager(rdvId) {
    if (rdvs.find((r) => r.id == rdvId).userManager) {
      if (
        !window.confirm(
          "Attention, un agent est déjà attribué au traitement de ce dossier, si vous souhaitez prendre à votre charge ce RDV à sa place, cliquez sur OK"
        )
      ) {
        setRdv(false);
        return false;
      }
    }
    setIsLoading(true);
    Axios.patch(api_url + "slotcalendars/" + rdvId + "/add_manager", {})
      .then((res) => {
        setRdv(res.data);
      })
      .catch((err) => {
        setRdv(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  function updateFilter(e) {
    var fil = JSON.parse(JSON.stringify(filters));
    var { name, value } = e.target;
    if (name == "start") {
      fil.end = DateTime.fromISO(value).endOf("day");
    }
    if (e.target.type == "checkbox") {
      value = e.target.checked ? true : false;
    }
    fil[name] = value;
    setFilters(fil);
  }

  function openRdv(r) {
    setRdv(r);
  }

  function openModal(id) {
    mActions.updateModal({
      isOpen: true,
      content: <RecipientDetail isModalOpen={true} recipientId={id} isModal={true} />,
      noClose: false,
    });
  }

  //----------------------------
  //----------------------------
  //----------------------------

  const renderRdvDetail = () => {
    switch (rdv.type) {
      case 1:
        return (
          <RdvDetail
            rdv={rdv}
            setRdv={setRdv}
            getRdvs={getRdvs}
            rdvsExpired={rdvsExpired}
            preventBack={preventBack}
            setPreventBack={setPreventBack}
            openModal={openModal}
            organizations={organizations}
            filters={filters}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 2:
        return (
          <RdvDetail
            rdv={rdv}
            setRdv={setRdv}
            getRdvs={getRdvs}
            rdvsExpired={rdvsExpired}
            preventBack={preventBack}
            setPreventBack={setPreventBack}
            openModal={openModal}
            organizations={organizations}
            filters={filters}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 3:
        return (
          <RdvCERDetail
            rdv={rdv}
            setRdv={setRdv}
            getRdvs={getRdvs}
            rdvsExpired={rdvsExpired}
            preventBack={preventBack}
            setPreventBack={setPreventBack}
            openModal={openModal}
            organizations={organizations}
            filters={filters}
            errors={errors}
            setErrors={setErrors}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!rdv ? (
        <Page container={"container-fluid px-5"} errors={errors} title="Mes rendez-vous">
          <div className="row">
            <div className="col-12">
              <RdvFilters
                filters={filters}
                setFilters={setFilters}
                organizations={organizations}
                sites={sites}
                updateFilter={updateFilter}
                filtersLoading={filtersLoading}
                rdvs={rdvs}
                resetFilters={resetFilters}
                submit={getRdvs}
              />
              <RdvList
                rdvs={rdvs.filter((r) => {
                  let hasManager = r.userManager ? true : false;
                  if (
                    (r.isClosed == filters.isClosed || r.isClosed == !filters.isNotClosed) &&
                    (hasManager == filters.isTreated || hasManager == !filters.isNotTreated)
                  ) {
                    if (filters.userManager != "") {
                      if (r.userManager && r.userManager.id == filters.userManager) {
                        return r;
                      }
                    } else {
                      return r;
                    }
                  }
                })}
                openRdv={openRdv}
                filters={filters}
                currentManager={state.auth.user}
                rdvsExpired={rdvsExpired}
                openModal={openModal}
              />
              {rdvs.length == 0 && (
                <div className="text-muted text-center mt-3">
                  Aucun rendez-vous trouvé pour cette recherche. Assurez vous d'avoir sélectionné
                  une organisation et un site.
                </div>
              )}
            </div>
          </div>
        </Page>
      ) : isLoading ? (
        <Loader />
      ) : (
        <Page errors={errors} title="Rendez-vous en cours" container={"container-fluid px-5"}>
          {renderRdvDetail()}
        </Page>
      )}
    </>
  );
};

export default RdvComponent;
