const Utils = {
  shouldDisplayBlock: (answers, questions, condition = false, state) => {
    var hasAtLeastOneConditionFullfiled = false;
    if (condition) {
      return eval(condition);
    }
    questions.forEach((q) => {
      if (Utils.shouldDisplayQuestion(answers, q.condition)) {
        hasAtLeastOneConditionFullfiled = true;
      }
    });
    return hasAtLeastOneConditionFullfiled;
  },
  shouldDisplayQuestion: (answers, condition, errors = {}, state) => {
    return condition ? eval(condition) : true;
  },
};

export default Utils;
