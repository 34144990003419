import React, { useState } from "react";
import dataService from "../../../../helpers/dataService";

// [
//   {
//     name: "Saint Paul",
//     zone: "OUEST",
//     months: getRandomArrayOf12(),
//   },
//   {
//     name: "Le Port",
//     zone: "OUEST",
//     months: getRandomArrayOf12(),
//   },
//   {
//     name: "Saint Denis",
//     zone: "NORD",
//     months: getRandomArrayOf12(),
//   },
//   {
//     name: "Sainte Marie",
//     zone: "NORD",
//     months: getRandomArrayOf12(),
//   },
//   {
//     name: "Saint Pierre",
//     zone: "SUD",
//     months: getRandomArrayOf12(),
//   },
//   {
//     name: "Le Tampon",
//     zone: "SUD",
//     months: getRandomArrayOf12(),
//   },
//   {
//     name: "Sainte Rose",
//     zone: "EST",
//     months: getRandomArrayOf12(),
//   },
// ]

const useNbOrientByTerritory = (url, year, otherPostParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalCommune, setTotalCommune] = useState(0);
  const [selectedCommune, setSelectedCommune] = useState("");
  const [allDatas, setAllDatas] = useState([]);
  const [zoneDatas, setZoneDatas] = useState([]);
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const lineOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: months,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year, ...otherPostParams },
      (data) => {
        transformDatas(data);
      },
      (e) => {
        console.log(e);
      },
      () => setIsLoading(false)
    );
  };

  const onClickCommune = (e) => {
    setSelectedCommune(e.target.value);
    const communeData = allDatas.filter((o) => o.name == e.target.value);
    setChartState({
      ...chartState,
      line2: {
        series: communeData.map((o) => ({
          name: o.name,
          data: o.months,
        })),
        options: lineOptions,
      },
      pie2: {
        series: communeData.map((o) => o.months.reduce((a, b) => a + b)),
        options: {
          labels: communeData.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
    });
    setTotalCommune(communeData[0].months.reduce((a, b) => a + b));
    console.log("zoneDatas", zoneDatas);
  };

  const getRandomArrayOf12 = () => {
    return Array.from({ length: 12 }, () => Math.floor(Math.random() * 100));
  };

  const transformDatas = (datas) => {
    setAllDatas(datas);
    setSelectedCommune(datas[0].name);

    //make an array of objects called zoneDatas using the datas array, zoneDatas must have the total of each months for each zone
    var zoneDatas = datas.reduce((acc, curr) => {
      var found = acc.find((o) => o.zone === curr.zone);
      if (found) {
        found.months = found.months.map((o, i) => o + curr.months[i]);
      } else {
        acc.push({ ...curr, name: curr.zone });
      }
      return acc;
    }, []);

    console.log("zoneDatas", zoneDatas);

    var allTotals = datas.map((o) => o.months.reduce((a, b) => a + b));

    setTotal(allTotals.reduce((a, b) => a + b));
    setTotalCommune(allTotals[0]);

    setChartState({
      line1: {
        series: zoneDatas.map((o) => ({
          name: o.name,
          data: o.months,
        })),
        options: lineOptions,
      },
      pie1: {
        series: zoneDatas.map((o) => o.months.reduce((a, b) => a + b)),
        options: {
          labels: zoneDatas.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      line2: {
        series: [datas[0]].map((o) => ({
          name: o.name,
          data: o.months,
        })),
        options: lineOptions,
      },
      pie2: {
        series: [datas[0]].map((o) => o.months.reduce((a, b) => a + b)),
        options: {
          labels: [datas[0]].map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
    });
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
    totalCommune,
    allDatas,
    selectedCommune,
    onClickCommune,
  };
};

export default useNbOrientByTerritory;
