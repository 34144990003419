import { DateTime } from "luxon";
import React from "react";
import EpItem from "./EpItem";

const EpsOfTheDay = ({ eps, openEp, sectors }) => {
  return (
    <div>
      <h4 className="my-3" style={{ fontSize: 14 }}>
        EP ayant lieu aujourd'hui{" "}
        <small>
          {DateTime.local().setLocale("FR").toFormat("EEEE dd MMMM yyyy")}
        </small>
      </h4>

      {eps.map((ep) => (
        <EpItem
          key={`epclist${ep.id}`}
          ep={ep}
          sectors={sectors}
          openEp={() => openEp(DateTime.local().toFormat("M"), ep)}
          style={{ maxWidth: 200 }}
        />
      ))}
    </div>
  );
};

export default EpsOfTheDay;
