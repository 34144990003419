import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import useOperatorList from "./useOperatorList";
import SaveBtn from "../../common/SaveBtn";

var strTimeout = null;

const OperatorList = () => {
  const {
    state,
    operators,
    isLoading,
    setIsLoading,
    themes,
    subThemes,
    search,
    errors,
    nbPages,
    nbResults,
    searchOperators,
    updateOperatorSearch,
    changePage,
    changeNbItemsByPage,
    removeOperator,
  } = useOperatorList();

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      tools.saveState("OperatorList", "search", JSON.stringify(search));
      searchOperators();
    }, 1500);
  }, []);

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        action={{ to: "/operators/create", text: "Créer un opérateur" }}
        title={"Liste des opérateurs"}
        errors={errors}
      >
        <div className="row mb-4">
          {state.constants.items.length == 0 ? <Loader /> : null}
          <div className="col-12 col-md-6 col-lg-4 justify-content-center">
            <Control
              margin={"my-0"}
              label={<>Recherche</>}
              name="terms"
              value={search.filters.terms}
              change={updateOperatorSearch}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-center">
            <Control
              label="Uniquement les opérateurs de confiance"
              name="isTrustedOnly"
              checked={search.filters.isTrustedOnly}
              change={updateOperatorSearch}
              type="checkbox"
              margin="my-4"
            />
          </div>
          <div className="col-12 mt-3">
            <SaveBtn
              margin="mt-0 mx-2 py-3 font-weight-bold"
              className=" w-100"
              type="btn-primary d-center"
              save={searchOperators}
              isSaving={isLoading}
              text="Rechercher"
            />
          </div>
          <div className="col-12 text-center mt-2">
            <strong> {nbResults} opérateurs trouvés</strong>
          </div>
        </div>
      </Page>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <>
              <Table
                datas={operators}
                fields={[
                  {
                    name: "Nom",
                    path: "*",
                    render: (el) => {
                      return (
                        <div>
                          <div>{el.name}</div>
                          {el.isTrusted && (
                            <div className="badge badge-success">
                              opérateur de confiance
                            </div>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Sous-thèmes",
                    path: "subThemes",
                    render: (el) => {
                      return el.map((subTheme) => (
                        <div className="badge badge-dark mr-1">
                          {tools.findIn(subThemes, "id", subTheme).name}
                        </div>
                      ));
                    },
                  },
                  {
                    name: "Lieu",
                    path: "*",
                    render: (elem) => (
                      <div>
                        <div>
                          {elem.address + " " + (elem.location?.name || "")}
                        </div>
                        <div>{elem.phone}</div>
                      </div>
                    ),
                  },

                  {
                    name: "Modifier",
                    path: "id",
                    render: (elem) => (
                      <Link
                        className="btn btn-primary btn-sm align-items-center justify-content-center d-flex"
                        to={"/operators/" + elem}
                      >
                        <i className="fa fa-edit"></i>
                      </Link>
                    ),
                  },
                  {
                    name: "Supprimer",
                    path: "id",
                    render: (elem) => (
                      <button
                        className="btn btn-danger btn-sm align-items-center justify-content-center d-flex"
                        onClick={() => removeOperator(elem)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
            nbItemsByPage={search.nbItemsByPage}
            changeNbItemsByPage={changeNbItemsByPage}
          />
        </div>
      </div>
    </>
  );
};

export default OperatorList;
