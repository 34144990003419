import { MODAL2_TYPES } from "../reducers/modal2Reducers";

export const modal2Actions = (state, dispatch, props) => {
  function updateModal(modalData) {
    dispatch({ type: MODAL2_TYPES.UPDATE_MODAL2_DATA, payload: modalData });
  }

  return {
    updateModal,
  };
};
