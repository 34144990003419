import { atom, useAtom } from "jotai";
import dataService from "../../../helpers/dataService";

export const allRecipientsAtom = atom([]);
export const isAllRecipientsLoadingAtom = atom(false);
export const allRecipientsNbResultsAtom = atom(0);
export const allRecipientsFiltersAtom = atom({
  filters: {
    firstname: "",
    lastname: "",
    nir: "",
    CAFId: "",
  },
});
export const isLoadingTimelineIdAtom = atom(null);

const useAllRecipients = () => {
  const [allRecipients, setAllRecipients] = useAtom(allRecipientsAtom);
  const [isAllRecipientsLoading, setIsAllRecipientsLoading] = useAtom(
    isAllRecipientsLoadingAtom
  );
  const [allRecipientsNbResults, setAllRecipientsNbResults] = useAtom(
    allRecipientsNbResultsAtom
  );
  const [allRecipientsFilters, setAllRecipientsFilters] = useAtom(
    allRecipientsFiltersAtom
  );
  const [isLoadingTimelineId, setIsLoadingTimelineId] = useAtom(
    isLoadingTimelineIdAtom
  );

  const searchAllRecipients = (searchData = null) => {
    const search = searchData || allRecipientsFilters;
    setIsAllRecipientsLoading(true);
    dataService.post(
      `recipients/search-all`,
      search,
      (data) => {
        setAllRecipients(data);
        setAllRecipientsNbResults(data.length);
      },
      (err) => {},
      () => setIsAllRecipientsLoading(false)
    );
  };

  const getRecipientTimeline = (recipientId) => {
    setIsLoadingTimelineId(recipientId);
    dataService.get(
      `recipients/${recipientId}/timeline`,
      (data) => {
        setAllRecipients((recs) =>
          recs.map((rec) =>
            rec.id == recipientId ? { ...rec, events: data } : rec
          )
        );
      },
      (err) => {},
      () => setIsLoadingTimelineId(null)
    );
  };

  const onUpdateFilters = setAllRecipientsFilters;

  return {
    allRecipients,
    isAllRecipientsLoading,
    allRecipientsNbResults,
    allRecipientsFilters,
    isLoadingTimelineId,
    searchAllRecipients,
    onUpdateFilters,
    getRecipientTimeline,
  };
};

export default useAllRecipients;
