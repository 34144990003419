import { DateTime } from "luxon";
import { toast } from "react-toastify";
import dataService from "../../../../../../helpers/dataService";
import tools from "../../../../../../helpers/tools";

export const itemsActions = (state, dispatch, gState, gDispatch, sidePanelActions) => {
  const initItems = async (loadUrls) => {
    setIsLoading(true);
    var allPromises = [];
    var actionUrls = {};
    var items = {};
    loadUrls.forEach((url) => {
      actionUrls[url.name] = { ...url.paths };
      if (url.paths?.get && url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            dataService.get(url.paths.get, resolve, reject);
          })
        );
      }
      if (!url.fetchOnLoad) {
        allPromises.push(
          new Promise((resolve, reject) => {
            resolve(url.initialState);
          })
        );
      }
    });
    if (allPromises.length) {
      Promise.all(allPromises)
        .then((values) => {
          values.forEach((value, key) => {
            items[loadUrls[key].name] = value;
          });

          dispatch({
            type: "INIT_ITEMS",
            payload: { items, actionUrls },
          });
        })
        .catch((err) => {
          Object.values(err).forEach((er) => toast.error(er));
        });
    } else {
      var defaultItems = {};
      loadUrls.forEach((url) => {
        defaultItems[url.name] = url.initialState;
      });

      dispatch({
        type: "INIT_ITEMS",
        payload: { items: defaultItems, actionUrls },
      });
    }
  };

  const fetchRecipients = () => {
    setIsLoading("RECIPIENTS", true);
    dataService.get(`eps/${state.items.ep.id}/recipients`, (datas) => {
      dispatch({
        type: "SET_RECIPIENTS",
        payload: datas,
      });
    });
  };

  const setIsLoading = (type = null, isLoading) => {
    dispatch({
      type: `IS_LOADING${type ? "_" + type : ""}`,
      payload: isLoading,
    });
  };

  const setItem = (itemName, newItem) => {
    dispatch({
      type: "UPDATE_ITEM",
      payload: {
        item: itemName,
        value: newItem,
      },
    });
  };

  const startEp = () => {
    dataService.patch(`eps/${state.items.ep.id}/start`, {}, (datas) => {
      setItem("ep", {
        ...state.items.ep,
        startedAt: datas.startedAt,
        writer: datas.writer,
      });
    });
  };

  const callMember = (userManagerId, isPresent) => {
    var newJury = state.items.ep.jury;
    newJury.find((j) => j.userManager.id == userManagerId).isPresent = isPresent;

    setItem("ep", { ...state.items.ep, jury: newJury });
  };

  const saveCalling = () => {
    setIsLoading("CALLING", true);
    var presentIds = state.items.ep.jury.filter((j) => j.isPresent).map((j) => j.userManager.id);

    dataService.patch(
      `eps/${state.items.ep.id}/jury/calling`,
      presentIds,
      (datas) => {
        setItem("ep", datas);
      },
      (err) => {},
      () => setIsLoading("CALLING", false)
    );
  };

  const setCurrentAlloc = (recipient) => {
    dataService.get(`epdetails/${recipient.id}`, (datas) => {
      dispatch({
        type: "SET_CURRENT_ALLOC",
        payload: datas,
      });
    });
  };
  const updateCurrentAlloc = (recipient) => {
    dispatch({
      type: "SET_CURRENT_ALLOC",
      payload: recipient,
    });
  };

  const saveWriterPresident = (writerAndPresident) => {
    setIsLoading("WRITERS", false);

    dataService.patch(
      `eps/${state.items.ep.id}/writers`,
      writerAndPresident,
      (datas) => {
        toast.success("Mise à jour réussie");
        setItem("ep", {
          ...state.items.ep,
          writer: datas.writer,
          president: datas.president,
        });
      },
      (err) => {},
      () => setIsLoading("WRITERS", false)
    );
  };

  const saveDecision = () => {
    var curAlloc = state.items.utils.currentAlloc;
    if (curAlloc.type == "REORIENTATION_KO") {
      curAlloc = {
        ...curAlloc,
        EPNotice: {
          ...curAlloc.EPNotice,
          reorientation: {
            ...curAlloc.EPNotice.reorientation,
            orientation: curAlloc.EPNotice.reorientation.orientation.id,
          },
        },
      };
    }
    dataService.patch(
      `epdetails/${curAlloc.id}/notice`,
      curAlloc,
      (datas) => {
        fetchRecipients();
        resetCurrentAlloc();
      },
      (errors) => {
        dispatch({
          type: "SET_ERRORS",
          payload: errors,
        });
      }
    );
  };

  const resetCurrentAlloc = () => {
    dispatch({
      type: "SET_CURRENT_ALLOC",
      payload: false,
    });
  };

  const saveAvisEp = () => {
    setIsLoading("CLOSE", true);
    dataService.patch(
      `eps/${state.items.ep.id}/close`,
      {},
      (datas) => {
        setItem("ep", { ...state.items.ep, closedAt: datas.closedAt });
      },
      (errors) => {
        dispatch({
          type: "SET_ERRORS",
          payload: errors,
        });
      },
      () => setIsLoading("CLOSE", false)
    );
  };
  const saveAvisDirector = () => {
    setIsLoading("CLOSE", true);
    var dataToSave = state.items.recipients.map((rec) => ({
      id: rec.id,
      decision: rec.directorNotice.decision,
      comment: rec.directorNotice.comment,
    }));
    dataService.patch(
      `epdetails/all`,
      dataToSave,
      (datas) => {
        toast.success("Vos décisions ont été sauvegardées");
      },
      (errors) => {
        dispatch({
          type: "SET_ERRORS",
          payload: errors,
        });
      },
      () => setIsLoading("CLOSE", false)
    );
  };
  const directorFinalizeEp = () => {
    setIsLoading("END", true);

    dataService.patch(
      `eps/${state.items.ep.id}/end`,
      {},
      (datas) => {
        toast.success("L'EP a été finalisée. Le PV a été généré");
        window.location.href = `/ep-pv/${state.items.ep.id}`;
      },
      (errors) => {
        dispatch({
          type: "SET_ERRORS",
          payload: errors,
        });
      },
      () => setIsLoading("END", false)
    );
  };

  const updateRecipients = (recipients) => {
    dispatch({
      type: "SET_RECIPIENTS",
      payload: recipients,
    });
  };

  return {
    initItems,
    setItem,
    setIsLoading,
    startEp,
    callMember,
    saveCalling,
    setCurrentAlloc,
    saveWriterPresident,
    updateCurrentAlloc,
    saveDecision,
    fetchRecipients,
    resetCurrentAlloc,
    saveAvisEp,
    updateRecipients,
    saveAvisDirector,
    directorFinalizeEp,
  };
};
