import React, { useContext, useState } from "react";
import tools from "../../../../../../../../helpers/tools";
import useCERModule from "../../../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "../../ObjectiveContext";
import StepSelect from "../AddStepForm/StepSelect";
import { StepContext } from "./StepContext";

const StepEdit = ({ goToViewMode }) => {
  const step = useContext(StepContext);
  const objective = useContext(ObjectiveContext);

  const { recipientCER, events } = useCERModule();
  const [newStep, setNewStep] = useState({ ...step });

  const handleNewStepChange = (e) => {
    const { name, value } = e.target;
    setNewStep({ ...newStep, [name]: value });
  };

  const onValidateStep = () => {
    var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
    var stepIndex = tools.getStepIndex(objective.steps, step);

    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex ? newStep : st;
        }),
      ],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
    goToViewMode();
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <StepSelect objective={objective} step={newStep} handleNewStepChange={handleNewStepChange} />

      <button
        className="btn btn-outline-primary border-primary border ml-2"
        disabled={!newStep.step}
        onClick={onValidateStep}
      >
        <i className="fa fa-check" />
      </button>
    </div>
  );
};

export default StepEdit;
