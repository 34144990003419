import React, { useContext } from "react";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { StepContext } from "../StepContext";
import { OperatorContext } from "./OperatorContext";
import OperatorView from "./OperatorView";

const OperatorsListSigned = () => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);

  return (
    <ul className="list-group pl-4">
      {step.operators.map((operator, ok) => {
        const operatorKey = `objoperator-${objective.pathType}-${objective.goal}-${step.step}-${operator.operator}`;

        return (
          <li className="list-group-item border-0 p-0 pb-2" key={operatorKey}>
            <OperatorContext.Provider value={operator}>
              <OperatorView goToEditMode={() => {}} />
            </OperatorContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};

export default OperatorsListSigned;
