import React from "react";
import Control from "../../common/Control";
import colors from "../../../helpers/colors";
const CalendarFilters = ({ filters, setFilters, boxes, rdvTypes }) => {
  const changeInput = (e) => {
    var fil = JSON.parse(JSON.stringify(filters));
    var { name, value, checked, type } = e.target;
    if (name == "boxes" || name == "types") {
      if (fil[name].includes(value)) {
        fil[name] = fil[name].filter((f) => f != value);
      } else {
        fil[name].push(value);
      }
    } else {
      fil[name] = value;
    }
    setFilters(fil);
  };

  return (
    <div className="row mb-2">
      <div className="col-12 col-md-6">
        <Control
          label="Box"
          value={filters.boxes}
          name="boxes"
          type="checkboxList"
          datas={boxes}
          dataIndex={"_id"}
          dataLabel={"name"}
          change={changeInput}
          btnInline={true}
          btnSm={true}
          className="px-0"
        />
      </div>
      <div className="col-12 col-md-3">
        <Control
          label="Types"
          value={filters.types}
          name="types"
          type="checkboxList"
          datas={rdvTypes}
          dataIndex={"id"}
          dataLabel={"name"}
          change={changeInput}
          btnInline={true}
          btnSm={true}
          className="px-0"
        />
      </div>
      <div className="col-12 col-md-3" style={{ paddingTop: 32 }}>
        {rdvTypes.map((t, tk) => {
          return (
            <div
              style={{
                borderRadius: "100%",
                width: 22.5,
                height: 22.5,
                background: colors[t.color],
              }}
            ></div>
          );
        })}
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Etat du slot"
          value={filters.isReserved}
          type="select"
          selectFirstLabel="Tous"
          datas={[
            { id: "0", name: "Libres" },
            { id: "1", name: "Réservés" },
          ]}
          change={changeInput}
          name="isReserved"
          className="px-0"
        />
      </div>
    </div>
  );
};

export default CalendarFilters;
