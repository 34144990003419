import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { api_url } from "../../../config";
import SaveBtn from "../../common/SaveBtn";

const ConstantDynamic = (props) => {
  const [state, dispatch] = useStore();

  const [dynamicConstants, setDynamicConstants] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    Axios.get(api_url + "constants/dynamic")
      .then((res) => {
        setDynamicConstants(res.data);
      })
      .catch((err) => {});
  }, []);

  function updateData(e, k) {
    setIsSaved(false);
    let dc = JSON.parse(JSON.stringify(dynamicConstants));
    var { name, value } = e.target;
    dc[k].values.find((v) => v.name == name).value = value;

    setDynamicConstants(dc);
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});

    //update
    Axios.patch(api_url + "constants/dynamic", dynamicConstants)
      .then((res) => {
        setDynamicConstants(res.data);
        setIsSaving(false);
        setIsSaved(true);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        setIsSaving(false);
      });
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page container={"container-fluid px-5"} title="Paramètres" errors={errors}>
      <div className="row">
        {dynamicConstants.map((dyn, dynk) => {
          return (
            <div className="col-12 col-md-6">
              <h5>{dyn.label}</h5>
              {dyn.values.map((val, valk) => {
                return (
                  <Control
                    label={val.label}
                    name={val.name}
                    value={val.value}
                    change={(e) => updateData(e, dynk)}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-end">
        <SaveBtn save={save} isSaving={isSaving} isSaved={isSaved} />
      </div>
    </Page>
  );
};

export default ConstantDynamic;
