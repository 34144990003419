import React, { useContext } from "react";
import tools from "../../../../../../../helpers/tools";
import useCERData from "../../../../../../Hooks/useCERData/useCERData";
import useCERModule from "../../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "../ObjectiveContext";
import ObjectiveStatus from "./ObjectiveStatus";
import "./ObjectiveView.css";

const ObjectiveView = ({ goToEditMode }) => {
  const objective = useContext(ObjectiveContext);
  const { cerObjectives, objectiveCategories } = useCERData();
  const { recipientCER, events, isRecipientCERDraft } = useCERModule();
  const objectiveRef = cerObjectives.find((o) => o.id == objective.goal);
  const categoryRef = objectiveCategories.find((o) => o.id == objectiveRef.category);
  var index = tools.getObjectiveIndex(recipientCER.goals, objective);

  return (
    <div
      className={`d-flex align-items-center justify-content-between  py-1 ${
        isRecipientCERDraft ? "item-list-view" : ""
      } `}
    >
      <div className="pt-2">
        {!isRecipientCERDraft && <ObjectiveStatus />}
        <strong className="text-info" style={{ fontSize: 17 }}>
          {objectiveRef?.name}
        </strong>
      </div>
      <i
        className="text-info"
        style={{
          position: "absolute",
          top: 0,
          fontSize: 11,
        }}
      >
        {categoryRef?.name}
      </i>

      {isRecipientCERDraft && (
        <div>
          <button className="btn btn-sm" onClick={goToEditMode}>
            <i className="fa fa-edit text-info" />
          </button>
          <button className="btn btn-sm" onClick={() => events.onDeleteObjective(objective, index)}>
            <i className="fa fa-times text-info" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ObjectiveView;
