import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";
import useRecipientStock from "./useRecipientStock";

const RecipientStockTable = () => {
  const [state] = useStore();
  const { EP_TYPES, CONTRACT_TYPES } = state.constants.items;
  const { organizations, onGetOrganizations } = useOrganizations();
  const { recipientStock, recipientStockFilters } = useRecipientStock();

  useEffect(() => {
    onGetOrganizations();
  }, []);

  return (
    <Table
      datas={recipientStock}
      exportOptions={{
        fetchDatas: "recipient-stocks/search",
        fetchParams: {
          ...recipientStockFilters,
          nbItemsByPage: "*",
        },
        onBeforeExport: () =>
          toast.info("Cet export peut prendre quelques minutes, veuillez patienter."),
        formatDatasFn: (datas) => {
          return datas.map((r) => ({
            ...r,
            name: tools.getFullname(r),
            orientation: organizations.find((m) => m.slugName == r.orientation?.orientation)
              ? tools.findIn(organizations, "slugName", r.orientation?.orientation).name
              : "-",
            orientedAt: tools.formatDate(r.orientation?.createdAt),
            lastStateSDD:
              r.rightsAndDuties?.length > 0
                ? tools.getEtatSDD(r.rightsAndDuties[r.rightsAndDuties.length - 1])
                : "N",
            lastTypeContract:
              r.contracts?.length > 0
                ? tools.findIn(CONTRACT_TYPES, "id", r.contracts[r.contracts.length - 1].type).name
                : "",
            lastContractBeginAt:
              r.contracts?.length > 0
                ? tools.formatDate(r.contracts[r.contracts.length - 1].beginAt)
                : "",
            lastContractEndAt:
              r.contracts?.length > 0
                ? tools.formatDate(r.contracts[r.contracts.length - 1].endAt)
                : "",
            createdAt: tools.formatDate(r.createdAt),
          }));
        },
        excludedFields: ["Voir"],
        additionalFields: [
          { name: "Date orientation", path: "orientedAt" },
          { name: "Dernier état SDD", path: "lastStateSDD" },
          { name: "Type dernier contrat", path: "lastTypeContract" },
          { name: "Début dernier contrat", path: "lastContractBeginAt" },
          { name: "Fin dernier contrat", path: "lastContractEndAt" },
          { name: "Date création", path: "createdAt" },
        ],
      }}
      fields={[
        {
          name: "Nom",
          path: "*",
          exportPath: "name",
          render: (el) => <Link to={`recipients-stock/${el.id}`}>{tools.getFullname(el)}</Link>,
        },
        {
          name: "NIR",
          path: "nir",
        },
        {
          name: "Matr.",
          path: "CAFId",
        },
        {
          name: "Email",
          path: "*",
          exportPath: "email",
          render: (el) => (
            <div>
              <a href={`mailto: ${el.email}`} style={{ color: "inherit" }}>
                {el.email}
              </a>
            </div>
          ),
        },
        {
          name: "Tél",
          path: "phone",
        },
        {
          name: "Orientation",
          path: "orientation",
          render: (el) =>
            el ? (
              <div>
                <small>Orienté le {tools.formatDate(el.createdAt)} vers</small>{" "}
                {organizations.find((m) => m.slugName == el.orientation).name}
              </div>
            ) : (
              "-"
            ),
        },
        {
          name: "Procédure",
          path: "*",
          render: (el) => {
            let content = [];
            if (el.epDetail?.EP) {
              content.push(
                <div>
                  Inscrit EP du {tools.formatDate(el.epDetail.EP.beginAt)} pour{" "}
                  {tools.findIn(EP_TYPES, "id", el.epDetail.type).name}
                </div>
              );
            }
            if (el.reorientation?.createdAt) {
              content.push(
                <div>
                  Réo en cours depuis {tools.formatDate(el.reorientation.createdAt)} vers{" "}
                  {tools.findIn(organizations, "id", el.reorientation.toPartner).name}
                </div>
              );
            }

            return content;
          },
        },
        {
          name: "Voir",
          path: "id",
          render: (el) => (
            <Link
              to={"/recipients-stock/" + el}
              className="btn btn-primary w-100 btn-sm d-flex align-items-center justify-content-center"
            >
              <i className="fa fa-eye"></i>
            </Link>
          ),
        },
      ]}
    />
  );
};

export default RecipientStockTable;
