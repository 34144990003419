import React from "react";
import useCERData from "../../../../Hooks/useCERData/useCERData";
import ModifyStep from "./ModifyStep";

const StepItem = ({ step }) => {
  const { modifiedStepId, setModifiedStepId, removeCerStep } = useCERData();

  const isEditMode = modifiedStepId == step.id;

  return (
    <li className={`list-group-item ${isEditMode ? "bg-body" : ""}`}>
      {isEditMode ? (
        <ModifyStep />
      ) : (
        <div className="d-flex flex-row align-items-center justify-content-between">
          <span className="badge badge-dark mr-2">démarche</span>
          <span className="py-1 flex-fill" style={{ fontSize: 16 }}>
            {step.name}
          </span>
          {/* <span className="mx-2 d-flex flex-row align-items-center">
            <button
              className="btn text-primary"
              onClick={() => setModifiedStepId(step.id)}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            >
              <i className="fa fa-edit" />
            </button>
            <button
              className="btn text-danger"
              onClick={() => removeCerStep(step.id)}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              <i className="fa fa-times" />
            </button>
          </span> */}
        </div>
      )}
    </li>
  );
};

export default StepItem;
