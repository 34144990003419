import React from "react";
import Control from "../../../common/Control";
import useManagers from "../../../Hooks/useManagers/useManagers";
import useCERList from "../useCERList";
import { useEffect } from "react";
import useStore from "../../../../context/useStore";

const CERListFilters = () => {
  const [state] = useStore();
  const { CER_STATUSES, ZONES } = state.constants.items;
  const { cerListFilters, actions } = useCERList();
  const { status, userManager, beginAt, endAt, zone, isUnderage } = cerListFilters.filters;
  const { managers, getManagers } = useManagers();

  useEffect(() => {
    getManagers();
  }, []);

  const updateBtnLists = (e) => {
    const { name, value } = e.target;
    let newEntry = cerListFilters.filters[name];
    if (newEntry.includes(value)) {
      newEntry = newEntry.filter((v) => v != value);
    } else {
      newEntry.push(value);
    }
    actions.updateCerListFilters({ target: { name, value: newEntry } });
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Control
          label="Statut"
          type="checkboxList"
          name="status"
          value={status}
          change={updateBtnLists}
          dataIndex="id"
          dataLabel="name"
          datas={CER_STATUSES}
          btnInline
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Référent unique"
          name="userManager"
          type="select"
          value={userManager}
          change={actions.updateCerListFilters}
          dataIndex="id"
          dataLabel="fullName"
          datas={managers.filter((m) => ["MANAGER", "AGENT_CAF_TS"].includes(m.role))}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Début du contrat"
          name="beginAt"
          type="date"
          value={beginAt}
          change={actions.updateCerListFilters}
        />
      </div>

      <div className="col-12 col-md-6">
        <Control
          label="Fin du contrat"
          name="endAt"
          type="date"
          value={endAt}
          change={actions.updateCerListFilters}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Secteur"
          name="zone"
          type="btnList"
          value={zone}
          change={actions.updateCerListFilters}
          datas={ZONES}
          dataIndex="id"
          dataLabel="name"
          btnInline
        />
      </div>
      <div className="col-12 col-md-6 d-center">
        <Control
          label="N'afficher que les mineurs"
          name="isUnderage"
          type="checkbox"
          checked={isUnderage}
          change={actions.updateCerListFilters}
          btnInline
        />
      </div>
      <div className="col-12 col-md-6 offset-0 offset-md-6">
        <button className="btn btn-link w-100 mt-2" onClick={actions.filterByMyCER}>
          Mes CER
        </button>
        <button className="btn btn-link w-100 mt-2" onClick={actions.filterByDraftCER2Months}>
          Mes CER non signés depuis 2 mois
        </button>
        <button className="btn btn-link w-100 mt-2" onClick={actions.filterByDraftCERToEnd}>
          Mes CER qui arrivent à échéance dans 2 mois
        </button>
        <button className="btn btn-link text-dark w-100 mt-2" onClick={actions.resetCerListFilters}>
          Retirer les filtres
        </button>
      </div>
    </div>
  );
};

export default CERListFilters;
