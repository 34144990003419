import { DateTime } from "luxon";
import React from "react";
import tools from "../../../helpers/tools";

const EpItem = ({ ep, sectors, openEp, style = {} }) => {
  let color = ep.startedAt
    ? ep.closedAt
      ? ep.endedAt
        ? "bg-dark text-white"
        : "bg-info text-white"
      : "bg-success text-white"
    : "bg-primary text-white";

  color = ep.cancelledAt ? "bg-body text-dark" : color;
  color = ep.isBackupSession ? "bg-light text-dark" : color;

  return (
    <div
      className={`mb-2 cursor-pointer w-100  rounded overflow-hidden border ep-date ${color}`}
      onClick={openEp}
      style={{
        opacity: ep.cancelledAt ? "0.5" : "1",
        ...style,
      }}
    >
      <div
        className={`d-flex position-relative justify-content-between align-items-center flex-column `}
      >
        {ep.isFull && (
          <div
            title="Cet EP a atteint le maximum de 30 allocataires inscrits"
            onClick={(e) => {
              e.stopPropagation();
              alert("Cet EP a atteint le maximum de 30 allocataires inscrits.");
            }}
            className="d-center cursor-pointer p-2"
            style={{
              position: "absolute",
              top: -8,
              right: -8,
              background: "black",
              width: 20,
              height: 20,
              borderRadius: "100%",
              fontSize: 10,
            }}
          >
            <i className="fa fa-lock" />
          </div>
        )}
        <div
          className="d-flex flex-row align-items-center justify-content-between w-100 flex-wrap px-2"
          style={{ fontSize: 12, fontWeight: "500" }}
        >
          <div className="mr-1">
            <small className="font-weight-bold">
              {DateTime.fromISO(ep.beginAt).toFormat("dd/MM")}
            </small>
          </div>
          <div className="">
            <small className="font-weight-bold">
              {tools.numberWithLeadingZero(ep.beginHourAt)}H
              {tools.numberWithLeadingZero(ep.beginMinuteAt)}
            </small>
          </div>
        </div>
        <div className="font-weight-bold text-center px-2" style={{ fontSize: 12 }}>
          {sectors.find((s) => s.id == ep.sector).name}
          {ep.isBackupSession && <i className="fa fa-history ml-1" />}
        </div>
        {ep.startedAt ? (
          ep.closedAt ? (
            ep.endedAt ? (
              <div className="d-center  w-100">
                <small className="font-weight-bold">Finalisée</small>
              </div>
            ) : (
              <div className="d-center w-100">
                <small className="font-weight-bold">Clôturée</small>
              </div>
            )
          ) : (
            <div className="d-center  w-100">
              <small className="font-weight-bold">Démarrée</small>
            </div>
          )
        ) : null}
        {ep.cancelledAt ? (
          <div className="d-center w-100 text-dark">
            <small className="font-weight-bold">Annulée</small>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EpItem;
