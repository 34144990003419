import React from "react";

const ReorientationIcon = ({ fromPartner, toPartner, fromAgent, toAgent }) => {
  return (
    <div
      className="alert alert-primary mb-0"
      style={{ fontSize: 12, maxWidth: 400 }}
    >
      <div className="d-center justify-content-between">
        <div className="d-center flex-column justify-content-start text-left">
          {fromPartner}
          <div className="text-left">
            {fromAgent && <small>{fromAgent}</small>}
          </div>
        </div>
        <i className=" fa fa-arrow-right mx-2" />
        <div className="d-center flex-column justify-content-end text-right">
          {toPartner}
          <div className="text-right">
            {toAgent && <small>{toAgent}</small>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReorientationIcon;
