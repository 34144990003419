import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Control";
import Loader from "../../../common/Loader";

const ChooseSiteReport = ({ getReportDates, rdspId, siteId }) => {
  const [sites, setSites] = useState([]);
  const [errors, setErrors] = useState({});
  const [site, setSite] = useState(siteId);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    dataService.get(
      `rdsp/${rdspId}/orientation`,
      (data) => {
        setSites(data.sites || []);
      },
      setErrors,
      () => setIsLoading(false)
    );
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="p-3 border-bottom my-2">
      {sites.length == 0 ? (
        <p className="alert alert-danger">
          La dernière orientation de l'allocataire ne vous permet pas de reporter ce RDV.
        </p>
      ) : (
        <>
          <Control
            label="Choisissez un site de RDV"
            type="select"
            value={site}
            datas={sites}
            change={(e) => setSite(e.target.value)}
            dataIndex="id"
            dataLabel="name"
            error={errors}
          />
          <button className="btn btn-light" onClick={() => getReportDates(site)}>
            Sélectionner
          </button>
        </>
      )}
    </div>
  );
};

export default ChooseSiteReport;
