import React, { useState } from "react";
import { sidePanelActions } from "../../../context/actions/sidePanelActions";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";

const AssignToAgent = ({ reorientation, users, assignToAgent, toSites }) => {
  const [state, dispatch] = useStore();
  const spActions = sidePanelActions(state, dispatch);
  const [selectedAgent, setSelectedAgent] = useState(reorientation.receivedBy);
  const [search, setSearch] = useState({
    agent: "",
    site: "",
  });
  const [isAssiging, setIsAssigning] = useState(false);

  const saveAssign = () => {
    setIsAssigning(true);
    assignToAgent(reorientation, selectedAgent);
    setTimeout(() => {
      setIsAssigning(false);
      spActions.updateSidePanel({
        isOpen: false,
        content: null,
      });
    }, 2000);
  };

  return (
    <div>
      <Control
        label="Rechercher par nom/prénom"
        type="text"
        value={search.agent}
        change={(e) => setSearch({ ...search, agent: e.target.value })}
      />
      {toSites.length > 0 && (
        <Control
          label="Filtrer par site"
          type="select"
          datas={toSites}
          value={search.site}
          change={(e) => setSearch({ ...search, site: e.target.value })}
          dataIndex="id"
          dataLabel="name"
        />
      )}
      <Control
        label="Agent assigné"
        type="btnList"
        datas={users
          .map((u) => ({ ...u, fullName: tools.getFullname(u) }))
          .filter(
            (u) =>
              (u.fullName.toLowerCase().includes(search.agent.toLowerCase()) ||
                selectedAgent == u.id) &&
              (search.site ? u.sites.includes(search.site) : true)
          )}
        dataIndex="id"
        dataLabel="fullName"
        value={selectedAgent}
        change={(e) => setSelectedAgent(e.target.value)}
      />
      <SaveBtn
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
        }}
        text="Assigner à cet agent"
        save={saveAssign}
        isSaving={isAssiging}
      />
    </div>
  );
};

export default AssignToAgent;
