import React from "react";
import tools from "../../../../../../../helpers/tools";
import { ObjectiveContext } from "../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import useAmendmentModule from "../../../hooks/useAmendmentModule";
import AmendmentObjectiveView from "./AmendmentObjectiveView/AmendmentObjectiveView";
import AddAmendmentStepForm from "./AmendmentStepsList/AddAmendmentStepForm/AddAmendmentStepForm";
import AmendmentStepsList from "./AmendmentStepsList/AmendmentStepsList";

const AmendmentObjectiveList = ({ pathType }) => {
  const { currentAmendment, isCurrentAmendmentDraft } = useAmendmentModule();

  const objectivesOfPath = currentAmendment.goals.filter((g) => g.pathType == pathType.id);

  return (
    <ul className="list-group objectives">
      {objectivesOfPath.map((objective, obk) => {
        const objectiveKey = `obj${pathType.id}${objective.goal}`;
        const borderLeft = `36px solid ${tools.getCERElementUpdateTypeColor(objective.updateType)}`;

        return (
          <li className="list-group-item border-0 p-0 mb-2" key={objectiveKey}>
            <ObjectiveContext.Provider value={objective}>
              <div
                className="pt-2"
                style={{
                  borderLeft,
                }}
              >
                {/* <ObjectiveView goToEditMode={() => {}} /> */}
                <AmendmentObjectiveView />
                <AmendmentStepsList />
              </div>
              {objective.updateType != "DELETED" && isCurrentAmendmentDraft && (
                <div className="pr-2 pt-0" style={{ borderLeft }}>
                  <AddAmendmentStepForm />
                </div>
              )}
            </ObjectiveContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};

export default AmendmentObjectiveList;
