import React, { useState, useEffect } from "react";
import Control from "../common/Control";
import { DateTime } from "luxon";
import RecurringBox from "./RecurringBox";
import { Link } from "react-router-dom";
import $ from "jquery";
import tools from "../../helpers/tools";

const EventModal = ({
  modalOpen,
  title = "",
  selectedEvent,
  setSelectedEvent,
  k = 1,
  addEvent,
  eventDropResize,
  removeEvent,
  selectedDate,
  hourSlots,
  minuteSlots,
  site,
  errors,
}) => {
  useEffect(() => {
    $("#eventModal1").modal(modalOpen ? "show" : "hide");
  }, [modalOpen]);

  function updateSelectedEvent(e, pos) {
    var ev = JSON.parse(JSON.stringify(selectedEvent));
    var { name, value } = e.target;
    var timeObj = {
      hourStart: new Date(ev.start).getHours(),
      minuteStart: new Date(ev.start).getMinutes(),
      hourEnd: new Date(ev.end).getHours(),
      minuteEnd: new Date(ev.end).getMinutes(),
    };
    timeObj[name] = value == "" ? 0 : value;
    ev[name] = value;
    if (name == "box") {
      ev.title = site.boxes.find((b) => b._id == value).name;
    }
    ev.start = getDateWithTime(timeObj.hourStart, timeObj.minuteStart);
    ev.end = getDateWithTime(timeObj.hourEnd, timeObj.minuteEnd);

    setSelectedEvent(ev);
  }
  function getDateWithTime(hour, minute) {
    var wtdate = new Date(JSON.parse(JSON.stringify(selectedDate)));

    wtdate.setHours(parseInt(hour));

    wtdate.setMinutes(parseInt(minute));

    return wtdate;
  }
  function updateReccuring(rec) {
    var ev = JSON.parse(JSON.stringify(selectedEvent));
    ev.slotCalendarConfig = rec;

    setSelectedEvent(ev);
  }

  const isDisabled = [3, 2].includes(selectedEvent.type);

  return (
    <div
      className="modal fade"
      id={"eventModal" + k}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="eventModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="eventModalLabel">
              {title}
              {" le "}
              {selectedDate && DateTime.fromJSDate(new Date(selectedDate)).toFormat("dd/MM/yyyy")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {selectedEvent.userRecipient && (
            <>
              <div
                className="w-100 p-2 btn-primary d-flex align-items-center justify-content-between"
                style={{
                  top: 0,
                  left: 0,
                  fontSize: 12,
                }}
              >
                <div>
                  <Link
                    className="text-white"
                    title="Voir la fiche de l'allocataire"
                    to={tools.getRecipientLink(selectedEvent.userRecipient)}
                    target="_blank"
                  >
                    <i className="fa fa-user mr-2" style={{ color: "yellow" }} />

                    {(selectedEvent.userRecipient.gender == 1 ? "Mr " : "Mme ") +
                      selectedEvent.userRecipient.firstname +
                      " " +
                      selectedEvent.userRecipient.lastname}
                    <i
                      className="fa fa-external-link-alt text-white ml-1"
                      style={{ fontSize: 10 }}
                    />
                  </Link>
                  {selectedEvent.comment && (
                    <p className="font-italic mb-0">Commentaire : {selectedEvent.comment}</p>
                  )}
                </div>
                <span className="badge float-right badge-light">réservé</span>
              </div>
            </>
          )}
          {selectedEvent.userManager && (
            <div
              className="w-100 p-2 btn-primary d-flex align-items-center justify-content-between"
              style={{
                top: 0,
                left: 0,
                fontSize: 12,
              }}
            >
              <div>
                <i className="fa fa-check mr-2" style={{ color: "#efff00" }} />

                {"Traité par " +
                  (selectedEvent.userManager.gender == 1 ? "Mr " : "Mme ") +
                  selectedEvent.userManager.lastname +
                  " " +
                  selectedEvent.userManager.firstname}
              </div>
            </div>
          )}
          {selectedEvent.userRecipient && selectedEvent.userManager && (
            <div
              className="w-100 p-2 btn-primary d-flex align-items-center justify-content-between"
              style={{
                top: 0,
                left: 0,
                fontSize: 12,
              }}
            >
              <div>
                <i
                  className={"fa mr-2 fa-" + (selectedEvent.isClosed ? "check" : "times")}
                  style={{ color: "#efff00" }}
                />

                {selectedEvent.isClosed ? "Clôturé" : "Non clôturé"}
              </div>
            </div>
          )}

          <div className="modal-body">
            {selectedDate && selectedEvent && (
              <>
                {/* <Control
                  margin={"mb-3"}
                  label="Titre"
                  name="title"
                  value={selectedEvent.title}
                  change={updateSelectedEvent}
                /> */}
                <label>Début</label>
                <div className="input-group d-flex justify-content-around">
                  <Control
                    className="w-25"
                    label="Heure"
                    type="select"
                    name="hourStart"
                    margin={"my-0"}
                    disabled={isDisabled}
                    datas={hourSlots.map((h) => {
                      return {
                        name: h,
                        id: h,
                      };
                    })}
                    value={new Date(selectedEvent.start).getHours()}
                    change={(e) => updateSelectedEvent(e, "start")}
                    error={errors}
                  />
                  <Control
                    className="w-25"
                    label="Minute"
                    type="select"
                    name="minuteStart"
                    margin={"my-0"}
                    selectFirstLabel={"0"}
                    disabled={isDisabled}
                    datas={minuteSlots.map((h) => {
                      return {
                        name: h,
                        id: h,
                      };
                    })}
                    value={new Date(selectedEvent.start).getMinutes()}
                    change={(e) => updateSelectedEvent(e, "start")}
                    error={errors}
                  />
                </div>
                <label>Fin</label>
                <div className="input-group d-flex justify-content-around">
                  <Control
                    className="w-25"
                    label="Heure"
                    type="select"
                    name="hourEnd"
                    margin={"my-0"}
                    disabled={isDisabled}
                    datas={hourSlots.map((h) => {
                      return {
                        name: h,
                        id: h,
                      };
                    })}
                    value={new Date(selectedEvent.end).getHours()}
                    change={(e) => updateSelectedEvent(e, "end")}
                    error={errors}
                  />
                  <Control
                    className="w-25"
                    label="Minute"
                    type="select"
                    name="minuteEnd"
                    margin={"my-0"}
                    selectFirstLabel={"0"}
                    disabled={isDisabled}
                    datas={minuteSlots.map((h) => {
                      return {
                        name: h,
                        id: h,
                      };
                    })}
                    value={new Date(selectedEvent.end).getMinutes()}
                    change={(e) => updateSelectedEvent(e, "end")}
                    error={errors}
                  />
                </div>

                <Control
                  label="Box de réception"
                  margin={"my-2 mb-3"}
                  type="select"
                  name="box"
                  value={selectedEvent.box}
                  datas={site.boxes}
                  dataIndex={"_id"}
                  dataLabel={"name"}
                  disabled={isDisabled}
                  change={updateSelectedEvent}
                  error={errors}
                />
                {!isDisabled && (
                  <RecurringBox
                    updateReccuring={updateReccuring}
                    slotCalendarConfig={selectedEvent.slotCalendarConfig}
                    errors={errors}
                    selectedEvent={selectedEvent}
                  />
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            {selectedEvent.id && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => removeEvent(selectedEvent)}
                disabled={isDisabled ? "disabled" : false}
              >
                Supprimer
              </button>
            )}
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={isDisabled ? "disabled" : false}
              onClick={() => {
                if (selectedEvent.id) {
                  eventDropResize({ event: selectedEvent });
                } else {
                  addEvent(selectedEvent);
                }
              }}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventModal;
