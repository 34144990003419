import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { leftPanelActions } from "../../../context/actions/leftPanelActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";
import CancelErrors from "./CancelErrors";
import EpItem from "./EpItem";

const CancelEp = ({ ep, sectors }) => {
  const [state, dispatch] = useStore();
  const [eps, setEps] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const lpActions = leftPanelActions(state, dispatch);
  const [failErrors, setFailErrors] = useState([]);

  useEffect(() => {
    fetchBackupEps();
  }, []);

  const fetchBackupEps = () => {
    setIsLoading(true);
    dataService.get(`eps/isbackupsession`, setEps, setErrors, () =>
      setIsLoading(false)
    );
  };

  const removeEp = (epTarget) => {
    if (
      window.confirm(
        `Confirmez-vous le report de cet EP vers l'EP de secours du ${DateTime.fromISO(
          epTarget.beginAt
        )
          .setLocale("FR")
          .toFormat("dd MMMM yyyy")} sélectionné ?`
      )
    ) {
      setIsLoading(true);
      dataService.patch(
        `eps/${ep.id}/cancel`,
        {
          epTargetId: epTarget.id,
        },
        (datas) => {
          toast.success("L'EP et son contenu ont bien été reportés.");
          if (datas.errors && datas.errors.length) {
            setFailErrors(datas.errors);
          } else {
            lpActions.updateLeftPanel({
              isOpen: false,
              content: null,
            });
          }
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  return (
    <div>
      <h4 className="mb-3">
        Choisissez une EP de secours vers laquelle migrer
      </h4>

      {isLoading ? (
        <Loader />
      ) : failErrors.length ? (
        <CancelErrors errors={failErrors} />
      ) : (
        <>
          <p>
            Si aucune des sessions ci-dessous ne convient, revenez au
            calendrier, créez une EP de secours à la date souhaitée puis revenez
            la selectionner ici.
          </p>
          <ul className="list-group">
            {!eps.length ? (
              <p>Aucune EP de secours disponible. Veuillez en créer une.</p>
            ) : (
              eps
                .filter((epf) => epf.sector == ep.sector)
                .sort((a, b) => (a.beginAt < b.beginAt ? -1 : 1))
                .map((epsec) => (
                  <li
                    className="list-group-item p-0 border-0"
                    key={`epsec${epsec.id}`}
                  >
                    <EpItem
                      ep={epsec}
                      sectors={sectors}
                      openEp={() => removeEp(epsec)}
                    />
                  </li>
                ))
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default CancelEp;
