import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { api_url } from "../../../config";
import PostalCode from "../../common/PostalCode";
import SaveBtn from "../../common/SaveBtn";
import SimpleDynamicList from "../../common/SimpleDynamicList";

const OrganizationDetail = (props) => {
  const [state, dispatch] = useStore();

  const [organization, setOrganization] = useState({
    name: "",
    slugName: "",
    isCaf: false,
    isOrientationPartner: false,
    nbDayReport: 3,
    emails: [],
    isOnlyEG: false,
  });
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (props.match.params.id != "create") {
      Axios.get(api_url + "organizations/" + props.match.params.id)
        .then((res) => {
          setOrganization(res.data);
        })
        .catch((err) => {
          setErrors(err.response?.data || {});
        });
    }
  }, []);

  function updateData(e) {
    var value = e.target.value;
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setOrganization({ ...organization, [e.target.name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      Axios.post(api_url + "organizations/", organization)
        .then((res) => {
          setOrganization(res.data);
          setIsSaving(false);
          setIsSaved(true);
          window.location.href = "/organizations/" + res.data.id;
        })
        .catch((err) => {
          setErrors(err.response.data);
          setIsSaving(false);
        });
    } else {
      //update
      Axios.patch(api_url + "organizations/" + props.match.params.id, organization)
        .then((res) => {
          setOrganization(res.data);
          setIsSaving(false);
          setIsSaved(true);
        })
        .catch((err) => {
          setErrors(err.response.data);
          setIsSaving(false);
        });
    }
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page
      container={"container-fluid px-5"}
      title="Détail organisation"
      errors={errors}
      back={"/organizations"}
    >
      <Control label="Nom" name="name" value={organization.name} change={updateData} />
      {props.match.params.id == "create" && (
        <Control label="Code" name="slugName" value={organization.slugName} change={updateData} />
      )}
      <Control
        label="Est un partenaire d'orientation"
        type="checkbox"
        name="isOrientationPartner"
        checked={organization.isOrientationPartner}
        change={updateData}
      />
      <Control
        label="Ne s'occupe que des EG"
        type="checkbox"
        name="isOnlyEG"
        checked={organization.isOnlyEG}
        change={updateData}
      />
      <Control
        margin={"mt-3"}
        label="Nombre de jours minimum pour reporter un RDV avant sa date effective"
        value={organization.nbDayReport}
        name="nbDayReport"
        change={updateData}
      />
      <SimpleDynamicList
        listLabel="Adresses email"
        element="emails"
        items={organization.emails}
        fields={[
          {
            name: "email",
            type: "text",
          },
        ]}
        change={updateData}
        btnLabel="Ajouter une adresse email"
      />
      <div className="d-flex align-items-center justify-content-end">
        <SaveBtn save={save} isSaving={isSaving} isSaved={isSaved} />
      </div>
    </Page>
  );
};

export default OrganizationDetail;
