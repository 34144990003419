import React, { useEffect } from "react";
import SaveBtn from "../../../common/SaveBtn";
import colors from "../../../../helpers/colors";
import useReoByPartner from "./useReoByPartner";

const ReoByPartner = ({ url, year }) => {
  const { isLoading, chartState, getDatas, hasDatas } = useReoByPartner(url, year);

  useEffect(() => {
    var elements = document.getElementsByClassName(`highlight-same-value`);
    if (elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener("mouseover", highlightSameValue, false);
      }
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener("mouseout", unHighlightSameValue, false);
      }
    }

    return () => {
      var elements = document.getElementsByClassName(`highlight-same-value`);
      if (elements) {
        for (var i = 0; i < elements.length; i++) {
          elements[i].removeEventListener("mouseover", highlightSameValue, false);
        }
        for (var i = 0; i < elements.length; i++) {
          elements[i].removeEventListener("mouseout", unHighlightSameValue, false);
        }
      }
    };
  });

  const highlightSameValue = (e) => {
    var val = e.target.getAttribute("data-value");
    var sameElems = document.querySelectorAll(`[data-value="${val}"]`);
    for (var i = 0, max = sameElems.length; i < max; i++) {
      var elem = sameElems[i];
      elem.style.color = colors.info;
    }
  };
  const unHighlightSameValue = (e) => {
    var val = e.target.getAttribute("data-value");
    var sameElems = document.querySelectorAll(`[data-value="${val}"]`);
    for (var i = 0, max = sameElems.length; i < max; i++) {
      var elem = sameElems[i];
      elem.style.color = "black";
    }
  };

  const calculateTotalGlobal = () => {
    var total = 0;
    chartState.forEach((orga) => {
      total += Object.values(orga.statuses).reduce((a, b) => a + b);
    });
    return total;
  };

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          color={hasDatas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas && chartState ? (
        <>
          <div className="col-12">
            <strong>Total pour tous les organismes : {calculateTotalGlobal()}</strong>
          </div>
          {chartState.map((orga, ok) => {
            return (
              <div
                className="col-12 col-md-6 d-center flex-column"
                key={`reobypartn${orga.organization}`}
              >
                <div className="p-3 rounded d-center flex-column bg-body my-3">
                  <div className="mb-2">
                    <strong>{orga.organization}</strong>
                  </div>
                  <ul className="p-0">
                    {Object.keys(orga.statuses).map((status, sk) => {
                      let nb = orga.statuses[status];
                      return (
                        <li
                          className="d-flex flex-row justify-content-between align-items-center border-bottom mb-2"
                          key={`orgstat${orga.organization}${status.name}`}
                        >
                          <div
                            className="d-center flex-row align-items-baseline highlight-same-value"
                            data-value={status}
                          >
                            <i className="fa fa-chevron-right mr-1" />
                            {status}
                          </div>
                          <strong className="text-primary">{nb}</strong>
                        </li>
                      );
                    })}
                    <li className="d-flex flex-row justify-content-between align-items-center border-bottom mb-2 font-weight-bold">
                      <div className="d-center flex-row align-items-baseline ">TOTAL</div>
                      <strong className="text-primary">
                        {Object.values(orga.statuses).reduce((a, b) => a + b)}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default ReoByPartner;
