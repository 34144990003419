import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div
        className="d-center w-100 h-100 position-absolute"
        style={{
          fontSize: "30vw",
          color: "#f1f1f1",
        }}
      >
        404
      </div>
      <div className="w-100 h-100 d-center flex-column position-absolute">
        <img
          src="/assets/logo-espoar.png"
          style={{
            maxHeight: 80,
            zIndex: "99",
          }}
        />
        <p className="my-3 font-weight-bold">
          Désolé, cette page n'existe pas sur ESPOAR
        </p>
        <Link to="/" className="btn btn-primary">
          <i className="fa fa-arrow-left mr-2" />
          Revenir à l'accueil
        </Link>
      </div>
    </>
  );
};

export default NotFound;
