import React from "react";
import tools from "../../../../../../helpers/tools";
import useStoreState from "../../Context/Store/useStoreState";
import AllocCounter from "./AllocCounter";
import EpAllocBlock from "./EpAllocBlock";

const EpSessionTables = () => {
  const { state, items, gState, items_actions, mActions } = useStoreState();
  const { EP_TYPES } = gState.constants.items;
  const { recipients, utils, ep } = items;

  return EP_TYPES.map((epType) => {
    var recs = recipients.filter((rec) => rec.type == epType.id);
    var nbRecs = recs.length;
    var nbRecsDone = recs.filter((rec) =>
      tools.checkEpRecipientDone(epType.id, rec)
    ).length;

    return (
      <div className="my-5 custom-card" key={`alb${epType.id}`}>
        <strong
          style={{ fontSize: 24 }}
          className={
            "text-primary d-flex align-items-center justify-content-between flex-row"
          }
        >
          {epType.name}
          <AllocCounter nbRecs={nbRecs} nbRecsDone={nbRecsDone} />
        </strong>
        <EpAllocBlock
          recipients={recs}
          epType={epType}
          isClosed={ep.closedAt}
          allRecipients={recipients}
        />
      </div>
    );
  });
};

export default EpSessionTables;
