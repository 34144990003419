import { DateTime } from "luxon";
import React, { useState } from "react";
import useStore from "../../../../context/useStore";
import ChooseSiteReport from "./ChooseSiteReport";
import RdvItemContainer from "./RdvItemContainer";
import RdvLocation from "./RdvLocation";
import RecipientRdvResult from "./RecipientRdvResult";
import ReportRdv from "./ReportRdv";

const RecipientRdvListItem = ({
  rdv,
  rdvIndex,
  getReportDates,
  errors,
  rdvSlots,
  setOriginSelected,
  originSelected,
  reasonSelected,
  saveReportRdv,
  isSaved,
  isSaving,
  rdvSelected,
  rdspId,
  reportRdvOpen,
  isLoadingSlots,
  setReasonSelected,
  setRdvSelected,
  isReportSiteOpen,
  setIsReportSiteOpen,
}) => {
  const [state] = useStore();
  const constants = state.constants.items;
  const isRdvDateManual = rdv.slotCalendar.type == 3;
  const [selectedSiteId, setSelectedSiteId] = useState("");

  const fetchReportDates = (rdv, siteId) => {
    getReportDates(rdv, siteId);
    setSelectedSiteId(siteId);
  };

  return (
    <RdvItemContainer
      rdvIndex={rdvIndex}
      headerContent={
        <>
          <span style={{ fontSize: 24 }}>
            <i className="fa fa-calendar-alt mr-2"></i>
            {DateTime.fromISO(rdv.date).toFormat("dd LLLL yyyy à HH:mm", {
              locale: "fr",
            })}
          </span>

          {rdvIndex == 0 && (
            <div data-priv="report_recipient_appointments">
              <button
                id="rdv-modal"
                className={`btn  btn-sm  ${
                  rdv.result ? "bg-light" : "btn-primary"
                }`}
                onClick={() => {
                  setIsReportSiteOpen(isReportSiteOpen ? null : rdv.id);
                  //getReportDates(rdv);
                }}
              >
                Reporter
              </button>
            </div>
          )}
        </>
      }
    >
      {rdv.slotCalendar.subType && (
        <div className="badge mx-2 badge-info">
          {
            constants.SLOT_SUBTYPES.find(
              (st) => st.id == rdv.slotCalendar.subType
            ).name
          }
        </div>
      )}

      {isReportSiteOpen == rdv.id && (
        <ChooseSiteReport
          getReportDates={(siteId) => fetchReportDates(rdv, siteId)}
          siteId={rdv.site.id}
          rdspId={rdspId}
        />
      )}
      {reportRdvOpen == rdv.id && selectedSiteId ? (
        <ReportRdv
          isLoadingSlots={isLoadingSlots}
          errors={errors}
          rdvSlots={rdvSlots}
          setOriginSelected={setOriginSelected}
          setReasonSelected={setReasonSelected}
          constants={constants}
          setRdvSelected={setRdvSelected}
          originSelected={originSelected}
          reasonSelected={reasonSelected}
          saveReportRdv={saveReportRdv}
          isSaved={isSaved}
          isSaving={isSaving}
          rdvSelected={rdvSelected}
          isRdvDateManual={isRdvDateManual}
          selectedSiteId={selectedSiteId}
        />
      ) : null}
      <RdvLocation rdv={rdv} />
      {rdv.result && <RecipientRdvResult rdv={rdv} />}
    </RdvItemContainer>
  );
};

export default RecipientRdvListItem;
