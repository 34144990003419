import React from "react";
import ReactApexChart from "react-apexcharts";
import SaveBtn from "../../../common/SaveBtn";
import colors from "../../../../helpers/colors";
import useNbCERByTerritory from "./useNbCERByTerritory";
import Control from "../../../common/Control";
import tools from "../../../../helpers/tools";

const NbCERByTerritory = ({
  url,
  year,
  displayTotal,
  showLine = true,
  showPie = true,
  otherPostParams = {},
}) => {
  const {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    totalInitial,
    totalAvenant,
    totalRenouvellement,
    totalCommuneHonore,
    totalCommuneNonHonore,
    totalCommuneAMener,
    allDatas,
    selectedCommune,
    onClickCommune,
  } = useNbCERByTerritory(url, year, otherPostParams);

  const communes = allDatas.map((m) => ({
    label: `${m.name} (${
      m.initialMonths.reduce((a, b) => a + b) +
      m.avenantMonths.reduce((a, b) => a + b) +
      m.renouvellementMonths.reduce((a, b) => a + b)
    })`,
    id: m.name,
  }));

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          color={hasDatas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas ? (
        <>
          <div className="col-12">
            <h5>CER Initial par territoire ({totalInitial})</h5>
          </div>
          <div className="col-12 col-md-6">
            {showLine && (
              <ReactApexChart
                options={chartState.line1.options}
                series={chartState.line1.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className="col-12 col-md-6">
            {showPie && (
              <ReactApexChart
                options={chartState.pie1.options}
                series={chartState.pie1.series}
                type="pie"
                height={350}
              />
            )}
          </div>

          <div className="col-12">
            <h5>Avenants CER par territoire ({totalAvenant})</h5>
          </div>
          <div className="col-12 col-md-6">
            {showLine && (
              <ReactApexChart
                options={chartState.line2.options}
                series={chartState.line2.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className="col-12 col-md-6">
            {showPie && (
              <ReactApexChart
                options={chartState.pie2.options}
                series={chartState.pie2.series}
                type="pie"
                height={350}
              />
            )}
          </div>

          <div className="col-12">
            <h5>Renouvellement CER par territoire ({totalRenouvellement})</h5>
          </div>
          <div className="col-12 col-md-6">
            {showLine && (
              <ReactApexChart
                options={chartState.line3.options}
                series={chartState.line3.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className="col-12 col-md-6">
            {showPie && (
              <ReactApexChart
                options={chartState.pie3.options}
                series={chartState.pie3.series}
                type="pie"
                height={350}
              />
            )}
          </div>
          <div className="col-12 mb-3">
            <h5 className="text-center py-3">
              Total général :{" "}
              {parseInt(totalInitial + totalAvenant + totalRenouvellement)}
            </h5>
            <hr />
          </div>

          <div className="col-12 mt-3">
            <h5>Détail par commune</h5>
            <Control
              type="btnList"
              label="Sélectionnez une commune"
              datas={communes}
              btnInline
              value={selectedCommune}
              change={onClickCommune}
              dataIndex="id"
              dataLabel="label"
              name="selectedCommune"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3">
            <h5>CER Initial ({totalCommuneHonore})</h5>
            <ReactApexChart
              options={chartState.line4.options}
              series={chartState.line4.series}
              type="bar"
              height={350}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3">
            <h5>Avenant CER ({totalCommuneNonHonore})</h5>
            <ReactApexChart
              options={chartState.line5.options}
              series={chartState.line5.series}
              type="bar"
              height={350}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3">
            <h5>Renouvellement CER ({totalCommuneAMener})</h5>
            <ReactApexChart
              options={chartState.line6.options}
              series={chartState.line6.series}
              type="bar"
              height={350}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default NbCERByTerritory;
