import React from "react";

import RecipientCard from "./RecipientCard";

const ImportFastResults = ({ datas }) => {
  return (
    <div className="row">
      <div className="col-12">
        <h4 className="text-center mb-4">
          Allocataires importés{" "}
          <span className="text-success">({datas.length})</span>
        </h4>
      </div>
      {datas.map((d, dk) => (
        <RecipientCard recipient={d} key={`rec${dk}`} />
      ))}
    </div>
  );
};

export default ImportFastResults;
