import { DateTime } from "luxon";
import React, { useEffect } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Control";
import SaveBtn from "../../../../common/SaveBtn";
import useCreateRdvOnTheFly from "./useCreateRdvOnTheFly";

const CreateRdvOnTheFly = ({
  recipient,
  organizationSlugName,
  onSaved = () => {},
  siteId = null, //optionnel, si on veut passer directement un site sans sélectionner d'organisation (organizationSlugName)
  hasExternalSubmitHandler = false, //true si le composant est incorporé ailleurs et ne crée pas lui même le RDV directement au submit
}) => {
  const {
    event,
    handleChangeEvent,
    errors,
    hasErrors,
    isSaving,
    submit,
    loadSitesOfOrgaSlugName,
    loadSiteById,
    sites,
    SLOT_TYPES,
    SLOT_SUBTYPES,
  } = useCreateRdvOnTheFly({
    recipient,
    onSaved,
    siteId,
    hasExternalSubmitHandler,
  });

  useEffect(() => {
    if (organizationSlugName) {
      loadSitesOfOrgaSlugName(organizationSlugName);
    }
    if (siteId) {
      loadSiteById(siteId);
    }
  }, []);

  return (
    <div className={`${hasExternalSubmitHandler ? "" : "custom-card"}`}>
      <div className="d-flex justify-content-start w-100 flex-column">
        {!hasExternalSubmitHandler && (
          <>
            <h4 style={{ fontSize: 16 }}>
              Créer un rendez-vous à la volée pour{" "}
              {tools.getFullname(recipient)}
            </h4>
            <div>
              <small>
                Cette étape est obligatoire pour finaliser le rendez-vous.
              </small>
            </div>
          </>
        )}
        <div>
          <Control
            label="Type de RDV"
            type="btnList"
            name="type"
            change={handleChangeEvent}
            value={event.type}
            datas={SLOT_TYPES.filter((s) => s.id == 3)}
            dataIndex="id"
            dataLabel="name"
            btnInline
            error={errors}
          />
          {event.type && (
            <Control
              label="Sous-type de RDV"
              type="btnList"
              name="subType"
              change={handleChangeEvent}
              value={event.subType}
              datas={SLOT_SUBTYPES.filter((s) => s.slotTypeId == event.type)}
              dataIndex="id"
              dataLabel="name"
              btnInline
              error={errors}
            />
          )}

          {!siteId && (
            <Control
              label="Site du RDV"
              type="select"
              name="site"
              change={handleChangeEvent}
              value={event.site}
              datas={sites}
              dataIndex="id"
              dataLabel="name"
              error={errors}
            />
          )}
          {event.site && sites.length > 0 && (
            <Control
              label="Box du RDV"
              type="btnList"
              name="box"
              change={handleChangeEvent}
              value={event.box}
              datas={sites.find((s) => s.id == event.site).boxes}
              dataIndex="_id"
              dataLabel="name"
              btnInline
              error={errors}
            />
          )}
          <div className="row">
            <div className="col-4">
              <Control
                label="Date de début du RDV"
                type="date"
                name="start"
                change={handleChangeEvent}
                value={event.start}
                error={errors}
              />
            </div>
            <div className="col-4">
              <Control
                label="Heure de début du RDV"
                type="select"
                name="hourStart"
                change={handleChangeEvent}
                value={event.hourStart}
                datas={Array(12)
                  .fill(1)
                  .map((i, ik) => {
                    var hour = ik + 7;
                    return {
                      name: `${hour} heure`,
                      id: hour,
                    };
                  })}
                dataLabel="name"
                dataIndex="id"
                error={errors}
              />
            </div>
            <div className="col-4">
              <Control
                label="Minute de début du RDV"
                type="select"
                name="minuteStart"
                change={handleChangeEvent}
                value={event.minuteStart}
                datas={Array(4)
                  .fill(1)
                  .map((i, ik) => {
                    var minute = ik * 15;
                    return {
                      name: `${minute} minute`,
                      id: minute,
                    };
                  })}
                dataLabel="name"
                dataIndex="id"
                error={errors}
              />
            </div>
          </div>
          {event.start && (
            <Control
              label={`Durée du RDV`}
              type="btnList"
              name="duration"
              change={handleChangeEvent}
              datas={[
                { id: "15", name: "15 minutes" },
                { id: "30", name: "30 minutes" },
                { id: "45", name: "45 minutes" },
                { id: "60", name: "60 minutes" },
              ]}
              dataIndex="id"
              dataLabel="name"
              value={event.duration}
              btnInline
              error={errors}
            />
          )}
          {event.end && (
            <div>
              Heure de fin du RDV :{" "}
              {DateTime.fromISO(event.end).toFormat("HH'h'mm")}
            </div>
          )}
          <Control
            label={
              <div className="d-flex flex-column">
                <span>RDV Téléphonique ?</span>
                <small>
                  Si coché, aucune notification (email, sms) de rappel ne sera
                  envoyée pour ce RDV.
                </small>
              </div>
            }
            type="checkbox"
            name="isPhoneCall"
            checked={event.isPhoneCall}
            change={handleChangeEvent}
            error={errors}
            className="mt-2"
          />
          <SaveBtn save={submit} isSaving={isSaving} text="Créer le RDV" />
        </div>
      </div>
    </div>
  );
};

export default CreateRdvOnTheFly;
