import { DateTime } from "luxon";
import React, { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../../../helpers/dataService";
import tools from "../../../../../../../helpers/tools";
import Control from "../../../../../../common/Control";
import SaveBtn from "../../../../../../common/SaveBtn";
import ReorientationIcon from "../../../../../Reorientations/ReorientationIcon";
import useReorientationTools from "../../../../../Reorientations/useReorientationTools";
import useStoreState from "../../../Context/Store/useStoreState";

const AvisReo = () => {
  const { items, gState, items_actions } = useStoreState();
  const recipient = items.utils.currentAlloc;
  const { EP_REASONS_1, EP_REASONS_2, EP_ADDITIONAL_REASONS, CONTRACT_TYPES } =
    gState.constants.items;

  var recipientLastContract = recipient.userRecipient.contracts?.length
    ? recipient.userRecipient.contracts[recipient.userRecipient.contracts.length - 1]
    : false;

  const { organizations } = items;
  const { actions } = useReorientationTools({ organizations });
  const [showAdditional, setShowAdditional] = useState(false);
  const [isSavingAdd, setIsSavingAdd] = useState(false);
  const [avis, setAvis] = useState({ ...recipient });

  const saveAdditional = () => {
    setIsSavingAdd(true);
    dataService.patch(
      `epdetails/${recipient.id}`,
      {
        type: avis.type,
        reasons: avis.reasons,
        additionalReasons: avis.additionalReasons,
      },
      (datas) => {
        items_actions.updateCurrentAlloc({
          ...recipient,
          additionalReasons: avis.additionalReasons,
        });
        setShowAdditional(false);
        toast.success("Les motifs d'inscription ont bien été modifiés");
      },
      (err) => {},
      () => setIsSavingAdd(false)
    );
  };

  return (
    <div>
      <div>
        {/* <div>
          <strong>Orientation actuelle</strong>
          {recipient.userRecipient.orientation ? (
            <div className="text-primary">
              {recipient.userRecipient.orientation.partner}
            </div>
          ) : (
            <div>
              <i>Aucune orientation</i>
            </div>
          )}
        </div> */}
        <div>
          <strong>Réorientation à résoudre</strong>
          <ReorientationIcon
            fromPartner={
              actions.getOrganizationFromId(
                recipient.EPNotice.reorientation.orientation?.fromPartner || ""
              ).name
            }
            toPartner={
              actions.getOrganizationFromId(
                recipient.EPNotice.reorientation.orientation?.toPartner || ""
              ).name
            }
            fromAgent={tools.getFullname(
              recipient.EPNotice.reorientation.orientation?.createdBy || ""
            )}
            toAgent={tools.getFullname(
              recipient.EPNotice.reorientation.orientation?.receivedBy || ""
            )}
          />
        </div>
        <div>
          <strong>Contractualisation</strong>
          <div>
            {recipientLastContract ? (
              <div>
                {DateTime.fromISO(recipientLastContract.beginAt).toFormat("dd/MM/yyyy")}
                {" - "}
                {CONTRACT_TYPES.find((ct) => ct.id == recipientLastContract.type).name}
              </div>
            ) : (
              "Aucun contrat"
            )}
          </div>
        </div>
        {recipient.reasons.map((reason) => (
          <div>
            <strong>Motif 1</strong>
            <div>{tools.getReason1NameFromId(gState.constants.items, reason.reason1)}</div>

            <strong>Motif 2</strong>
            <ul>
              {reason.reasons2.map((rea2) => (
                <li>{tools.getReason2NameFromId(gState.constants.items, rea2)}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div>
        <strong>Motifs complémentaires de saisine</strong>
        <div>
          {!showAdditional && (
            <>
              <ul>
                {recipient.additionalReasons?.length
                  ? recipient.additionalReasons.map((ad) => (
                      <li>{EP_ADDITIONAL_REASONS.find((a) => a.id == ad).name}</li>
                    ))
                  : "Aucun"}
              </ul>
              <button className="btn btn-default" onClick={() => setShowAdditional(true)}>
                Modifier les motifs complémentaires
              </button>
            </>
          )}
          {showAdditional && (
            <>
              <Control
                name="additionalReasons"
                type="checkBtnList"
                datas={EP_ADDITIONAL_REASONS}
                dataIndex="id"
                dataLabel="name"
                value={avis.additionalReasons}
                change={(e) => {
                  if (avis.additionalReasons.includes(e.target.value)) {
                    setAvis({
                      ...avis,
                      additionalReasons: avis.additionalReasons.filter((r) => r != e.target.value),
                    });
                  } else {
                    setAvis({
                      ...avis,
                      additionalReasons: [...avis.additionalReasons, e.target.value],
                    });
                  }
                }}
              />
              <SaveBtn text="Enregistrer" save={saveAdditional} isSaving={isSavingAdd} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AvisReo;
