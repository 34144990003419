import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";

const AnswerToInvitation = ({ invitation, updateInvitation }) => {
  const [state, dispatch] = useStore();
  var EP = invitation.EP;
  const constants = state.constants.items;

  return (
    <div className="w-100 h-100 d-center flex-column">
      <h4 className="text-center">
        EP du{" "}
        {DateTime.fromISO(EP.beginAt).setLocale("FR").toFormat("dd MMMM yyyy")}{" "}
        à {tools.numberWithLeadingZero(EP.beginHourAt)}h
        {tools.numberWithLeadingZero(EP.beginMinuteAt)} - SECTEUR{" "}
        {EP.sector.name}
      </h4>
      <small className="my-3">
        Confirmez ou infirmez votre présence à cette session d'entretien
        pluridisciplinaire.
      </small>
      <div className="d-flex flex-row">
        <div className="dropdown">
          <button
            className="btn btn-success mr-2 dropdown-toggle"
            id="dropdownMandateButton"
            data-toggle="dropdown"
          >
            <span className="pr-3">Je serai présent(e)</span>
          </button>

          <div class="dropdown-menu">
            {constants.INVITATION_MANDATES.map((invm) => (
              <button
                key={`inv${invm.id}`}
                className="dropdown-item"
                onClick={() => updateInvitation("ACCEPT", invm.id)}
              >
                En tant que {invm.name}
              </button>
            ))}
          </div>
        </div>
        <button
          className="btn btn-danger"
          onClick={() => updateInvitation("REJECT", null)}
        >
          Je ne serai pas présent(e)
        </button>
      </div>
    </div>
  );
};

export default AnswerToInvitation;
