import React from "react";
import Control from "../../../../common/Control";
import tools from "../../../../../helpers/tools";
import CEREditSection from "../CEREditSection/CEREditSection";
import useContractForm from "./useContractForm";
import { DateTime } from "luxon";

const CERContractForm = ({ isRenew }) => {
  const { currentRecipient, currentEntity, handleChangeAttribute, errors, CER_DURATIONS, state } =
    useContractForm(isRenew);

  return (
    <CEREditSection>
      <div className="row mx-0">
        <div className="col-12">
          <strong>Informations du contrat</strong>
        </div>
        <div className="col-12">
          Partenaire : <strong>{currentRecipient.lastOrientation?.partnerName}</strong>
        </div>
        <div className="col-6">
          <Control
            label="Date de début"
            type="date"
            name="beginAt"
            disabled={isRenew}
            value={DateTime.fromISO(currentEntity.beginAt).toFormat("yyyy-MM-dd")}
            change={handleChangeAttribute}
            error={errors}
          />
        </div>
        <div className="col-6">
          <Control
            label="Durée"
            type="select"
            name="duration"
            value={currentEntity.duration}
            change={handleChangeAttribute}
            datas={CER_DURATIONS}
            error={errors}
            noSelectFirstOption={true}
          />
        </div>
        {currentEntity.duration && currentEntity.beginAt && (
          <div className="col-6 offset-6">
            <div className="px-2">
              Date de fin : {DateTime.fromISO(currentEntity.endAt).toFormat("dd/MM/yyyy")}
            </div>
          </div>
        )}
      </div>
    </CEREditSection>
  );
};

export default CERContractForm;
