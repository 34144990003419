import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Page from "../../../common/layout/Page";
import Table from "../../../common/Table";
import EpRecipients from "../EpRecipients/EpRecipients";
import AnswerToInvitation from "./AnswerToInvitation";

const MyEps = (props) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const mActions = modalActions(state, dispatch);
  const [invitationSelected, setInvitationSelected] = useState(false);
  let history = useHistory();

  var propsParams = props.location.search;

  const [myEps, setMyEps] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getInvitations = () => {
    setIsLoading(true);
    dataService.get("eps/invitations/mines", setMyEps, setErrors, () => setIsLoading(false));
  };

  const getEpRecipients = (ep) => {
    mActions.updateModal({
      isOpen: true,
      content: <EpRecipients ep={ep} />,
      style: { zIndex: "999999" },
    });
  };

  const updateInvitation = (status, userMandate) => {
    setIsLoading(true);
    dataService.patch(
      `eps/invitations/${invitationSelected.id}`,
      {
        invitationStatus: status,
        userMandate,
      },
      (d) => {
        getInvitations();
        toast.success("Votre réponse a bien été enregistrée");
        history.push("/myeps");
        mActions.updateModal({
          isOpen: false,
        });
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    getInvitations();
  }, []);

  useEffect(() => {
    if (myEps.length && propsParams.length) {
      var param = propsParams.split("=");
      if (param[0] == "?invitation") {
        setInvitationSelected(myEps.find((e) => e.id == param[1]));
      }
    }
  }, [myEps]);

  useEffect(() => {
    if (invitationSelected) {
      mActions.updateModal({
        isOpen: true,
        content: (
          <AnswerToInvitation invitation={invitationSelected} updateInvitation={updateInvitation} />
        ),
      });
    }
  }, [invitationSelected]);

  useEffect(() => {
    if (!state.modal.isOpen) {
      setInvitationSelected(false);
    }
  }, [state.modal.isOpen]);

  return (
    <Page
      isLoading={isLoading}
      container={"container-fluid"}
      title="Mes invitations aux EP"
      bgTransparent
      errors={errors}
    >
      <Table
        fields={[
          {
            name: "EP",
            path: "EP",
            render: (el) => (
              <div>
                {DateTime.fromISO(el.beginAt).setLocale("FR").toFormat("dd MMMM yyyy")} à{" "}
                {el.beginHourAt}h{el.beginMinuteAt}
              </div>
            ),
          },

          {
            name: "Secteur",
            path: "EP",
            render: (el) => el.sector.name,
          },
          {
            name: "Mandat",
            path: "userMandate",
            render: (el) => el && constants.INVITATION_MANDATES.find((man) => man.id == el).name,
          },
          {
            name: "Allocataires",
            path: "EP",
            render: (el) => (
              <button className="btn btn-default" onClick={() => getEpRecipients(el)}>
                Voir
              </button>
            ),
          },
          {
            name: "Statut",
            path: "*",
            render: (el) => {
              var status = constants.INVITATION_STATUS.find((e) => e.id == el.invitationStatus);
              return (
                <>
                  <span className={`font-weight-bold text-${status.color}`}>{status.name}</span>
                  <small className="d-block">
                    invité le {DateTime.fromISO(el.invitationSentAt).toFormat("dd/MM/yyyy HH:mm")}
                  </small>
                  {el.invitationStatusUpdatedAt ? (
                    <small className="d-block">
                      répondu le{" "}
                      {DateTime.fromISO(el.invitationStatusUpdatedAt).toFormat("dd/MM/yyyy HH:mm")}
                    </small>
                  ) : null}
                </>
              );
            },
          },
          {
            name: "Répondre",
            path: "*",
            render: (el) => {
              var status = constants.INVITATION_STATUS.find((e) => e.id == el);
              var isExpired = DateTime.fromISO(el.EP.beginAt) > DateTime.local().minus({ days: 9 });
              return !isExpired ? (
                "Expiré car début de l'EP dans moins de 9j"
              ) : (
                <button className="btn btn-default" onClick={() => setInvitationSelected(el)}>
                  Répondre
                </button>
              );
            },
          },
        ]}
        datas={myEps}
      />
    </Page>
  );
};

export default MyEps;
