import React, { useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";

import TimelineEvent from "./TimelineEvent";

const RecipientTimeline = ({ events, nbCol = "2-columns" }) => {
  const [layout, setLayout] = useState(nbCol);

  function toggleLayout() {
    if (layout == "2-columns") setLayout("1-column");
    if (layout == "1-column") setLayout("2-columns");
  }

  return (
    <div style={{ background: "#ececec", borderRadius: "8px" }}>
      <div className="justify-content-end d-flex flex-row p-2">
        <i
          style={{ cursor: "pointer" }}
          onClick={toggleLayout}
          className={"fa fa-columns " + (layout == "2-columns" ? "active" : "")}
        />
      </div>
      <VerticalTimeline layout={layout}>
        {events.map((ev, evk) => (
          <TimelineEvent key={"ev" + evk} event={ev} layout={layout} />
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default RecipientTimeline;
