import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import tools from "../../../helpers/tools";
import useRecipientData from "../../Hooks/useRecipientData/useRecipientData";

const Signatures = () => {
  const { recipient } = useRecipientData();
  var styles = StyleSheet.create({
    container: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    text: {
      fontSize: 9,
    },
  });

  return (
    <View
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "flex-start",
        paddingTop: 20,
        paddingBottom: 10,
      }}
    >
      <View style={{ width: "49%" }}>
        <Text style={{ fontSize: 11 }}>Signature du Bénéficiaire</Text>
        <Text style={{ fontSize: 11 }}>{tools.getFullname(recipient)}</Text>
      </View>
      <View style={{ width: "49%" }}>
        <Text style={{ fontSize: 11 }}>Signature du Référent Unique</Text>
        <Text style={{ fontSize: 9 }}>Par délégation du Directeur de la CAF de la Réunion</Text>
        <Text style={{ fontSize: 11 }}>{recipient.userManagerDisplayed?.fullName || "..."}</Text>
      </View>
    </View>
  );
};

export default Signatures;
