import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import RecipientInfo from "./RecipientInfo";
import { toast } from "react-toastify";
import { modalActions } from "../../../context/actions/modalActions";
import roleConfig from "../../common/layout/roleConfig";
import dataService from "../../../helpers/dataService";

const RecipientCreateEP = (props) => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const [recipient, setRecipient] = useState({
    email: "",
    nir: "",
    gender: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    cityId: "",
    address: "",
    addressAdd: "",
    location: {
      cityId: "",
      postalCode: "",
      name: "",
      locationName: "",
    },
    phone: "",
    phone2: "",
    maritalStatus: "",
    nbChildrenOrDependant: 0,
    nbDependantChildren: 0,
    CAFId: "",
    rights: [],
    origin: "",
    rightsAndDuties: [],
    contracts: [],
    professionalProject: "",
    driveLicenses: [],
    transports: [],
    numSubscribePE: "",
    cafAgent: "",
    jobSearch: "",
    jobSearchExp: "",
    job2Search: "",
    job2SearchExp: "",
    isActivated: false,
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [allDisabled, setAllDisabled] = useState(
    state.auth?.user
      ? roleConfig[state.auth.user.role].includes("update_recipients")
      : false
  );

  const [infoUpdated, setInfoUpdated] = useState(false);
  const [mouseOverSaveBtn, setMouseOverSaveBtn] = useState(false);
  const [canSave, setCanSave] = useState(false);

  function updateData(e) {
    setIsSaved(false);
    var { type, name, value, checked, formArray } = e.target;
    var usr = JSON.parse(JSON.stringify(recipient));
    var elem = usr[name];
    if (type == "checkbox") {
      if (checked) {
        elem = true;
      } else {
        elem = false;
      }
    } else if (formArray) {
      if (type == "checkBtnList") {
        if (elem.includes(value)) {
          elem = elem.filter((v) => v != value);
        } else {
          elem.push(value);
        }
      } else {
        if (formArray.target.checked) {
          elem.push(value);
        } else {
          elem = elem.filter((v) => v != value);
        }
      }
    } else {
      elem = value;
    }
    usr[name] = elem;
    setInfoUpdated(true);
    setRecipient(usr);
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});

    //create
    dataService.post(
      `recipients/private`,
      recipient,
      (data) => {
        //setRecipient({ ...data, cityId: data.location?.cityId });
        //setIsSaving(false);
        //setIsSaved(true);
        toast.success("Création de l'allocataire réussie");
        window.location.href = "/recipients/" + data.id;
      },
      setErrors,
      () => {
        setIsSaving(false);
        setInfoUpdated(false);
      }
    );
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page
      container={"container-fluid px-5"}
      title="Créer un allocataire"
      errors={errors}
      back={"/recipients"}
    >
      <div className="row">
        <div className={"col-12"} style={{ animationDelay: "100ms" }}>
          <div className="p-4 bg-light" style={{ overflow: "hidden" }}>
            <>
              <RecipientInfo
                recipient={recipient}
                allDisabled={allDisabled}
                genders={state.constants.items.GENDERS}
                maritalStatuses={state.constants.items.MARITAL_STATUS}
                errors={errors}
                updateData={updateData}
                setCanSave={setCanSave}
                setErrors={setErrors}
              />

              {!allDisabled && canSave && (
                <SaveBtn
                  margin="mx-auto my-3 py-3 align-items-center justify-content-center"
                  className={infoUpdated ? "animated pulse infinite" : ""}
                  text={`Créer l'allocataire ${
                    recipient.isActivated
                      ? "et activer son compte"
                      : "sans activer son compte"
                  }`}
                  save={save}
                  isSaving={isSaving}
                  isSaved={isSaved}
                />
              )}

              {!allDisabled && canSave && (
                <div
                  onMouseEnter={() => setMouseOverSaveBtn(true)}
                  onMouseLeave={() => setMouseOverSaveBtn(false)}
                >
                  <SaveBtn
                    margin="mx-auto my-3 py-3 align-items-center justify-content-center position-fixed animated"
                    className={`${
                      infoUpdated
                        ? `${
                            mouseOverSaveBtn ? "pulse" : "jello"
                          } faster infinite`
                        : "bounceInRight"
                    } 
                      
                      `}
                    style={{ bottom: 0, right: 0, zIndex: "9999" }}
                    text={
                      <>
                        {mouseOverSaveBtn && (
                          <span className="mr-2 animated fadeInRight faster">
                            Enregistrer
                          </span>
                        )}{" "}
                        <i className="fa fa-save px-2" />
                      </>
                    }
                    save={save}
                    isSaving={isSaving}
                    isSaved={isSaved}
                  />
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default RecipientCreateEP;
