import React from "react";

const RdvLocation = ({ rdv }) => {
  return (
    <div className="p-3">
      <div className="">
        Lieu :{" "}
        {rdv.slotCalendar.isPhoneCall && (
          <span className="badge badge-dark mr-2">
            <i className="fa fa-phone" /> téléphonique
          </span>
        )}
        <span className="text-primary">
          {rdv.site.organization.name} - {rdv.site.name}
        </span>
      </div>
      {rdv.comment && rdv.comment != "" && (
        <div className=" p-1 mb-0">
          <b className="mr-2" style={{ color: "#6f6f6f" }}>
            <i className="far fa-comment mr-2" />
            Allocataire -
          </b>
          <i>{rdv.comment}</i>
        </div>
      )}
    </div>
  );
};

export default RdvLocation;
