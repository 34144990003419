import React, { useContext } from "react";
import tools from "../../../../../../../../helpers/tools";
import useCERData from "../../../../../../../Hooks/useCERData/useCERData";
import { ObjectiveContext } from "../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import useAmendmentModule from "../../../../hooks/useAmendmentModule";

const AmendmentObjectiveView = () => {
  const objective = useContext(ObjectiveContext);
  const { cerObjectives, objectiveCategories } = useCERData();
  const { currentAmendment, events, isCurrentAmendmentDraft } = useAmendmentModule();
  const objectiveRef = cerObjectives.find((o) => o.id == objective.goal);
  const categoryRef = objectiveCategories.find((o) => o.id == objectiveRef.category);
  var index = tools.getObjectiveIndex(currentAmendment.goals, objective);

  const itemNameStyle = tools.getCERElementUpdateTypeStyle(objective.updateType);

  return (
    <div
      className={`d-flex align-items-center justify-content-between pl-2 py-1 ${
        isCurrentAmendmentDraft ? "item-list-view" : ""
      } `}
    >
      <div className="pt-2">
        <strong className="" style={{ fontSize: 17, ...itemNameStyle }}>
          {objectiveRef?.name}
        </strong>
      </div>
      <i
        className=""
        style={{
          position: "absolute",
          top: 0,
          fontSize: 11,
        }}
      >
        {categoryRef?.name}
      </i>

      {isCurrentAmendmentDraft && (
        <div>
          {objective.updateType == "DELETED" ? (
            <button
              className="btn btn-sm btn-link text-danger"
              onClick={() => events.onCancelDeleteObjective(index)}
            >
              Restaurer l'objectif
            </button>
          ) : (
            <button
              className="btn btn-sm btn-outline-dark"
              onClick={() => events.onDeleteObjective(index)}
            >
              <i className="fa fa-times " />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AmendmentObjectiveView;
