import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modal2Actions } from "../../../../context/actions/modal2Actions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Control";
import DynamicList from "../../../common/DynamicList";
import SaveBtn from "../../../common/SaveBtn";
import ReorientationIcon from "../../Reorientations/ReorientationIcon";
import useReorientationTools from "../../Reorientations/useReorientationTools";

const ModifyMotifsReo = ({ epDatas, getEpRecipients }) => {
  const recipient = epDatas.userRecipient;
  const [isSaving, setIsSaving] = useState(false);
  const [state, dispatch] = useStore();
  const mActions = modal2Actions(state, dispatch);
  const {
    EP_TYPES,
    EP_REASONS_1,
    EP_REASONS_2,
    EP_SANCTION_REASONS_1,
    EP_SANCTION_REASONS_2,
    EP_ADDITIONAL_REASONS,
  } = state.constants.items;
  const [errors, setErrors] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [avis, setAvis] = useState(
    epDatas || {
      type: "",
      crossInterviewType: "",
      additionalReasons: [],
      reasons: [],
    }
  );
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      setAvis({ ...avis, reasons: [] });
    }
  }, [avis.type]);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = () => {
    dataService.get(`organizations`, setOrganizations);
  };

  const { actions } = useReorientationTools({ organizations });

  const orientationDatas = avis.EPNotice.reorientation.orientation;

  const save = () => {
    setIsSaving(true);
    dataService.patch(
      `epdetails/${epDatas.id}`,
      {
        type: avis.type,
        crossInterviewType: avis.crossInterviewType,
        reasons: avis.reasons,
        additionalReasons: avis.additionalReasons,
      },
      (datas) => {
        toast.success("Les motifs complémentaires de saisine ont bien été modifiés");
        try {
          getEpRecipients();
        } catch (err) {}
        mActions.updateModal({
          isOpen: false,
          content: null,
        });
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  return (
    <>
      <div className="custom-card">
        <div className="d-flex justify-content-start w-100 flex-column">
          <h4 style={{ fontSize: 16 }}>Réorientation de {tools.getFullname(recipient)}</h4>
          {orientationDatas ? (
            <div style={{ maxWidth: 500 }}>
              <ReorientationIcon
                fromPartner={actions.getOrganizationFromId(orientationDatas.fromPartner).name}
                toPartner={actions.getOrganizationFromId(orientationDatas.toPartner).name}
                fromAgent={tools.getFullname(orientationDatas.createdBy)}
                toAgent={tools.getFullname(orientationDatas.receivedBy)}
              />
            </div>
          ) : (
            "données manquantes..."
          )}
          <DynamicList
            listLabel={
              <>
                Motifs <span className="text-danger ml-1">*</span>
              </>
            }
            btnLabel="Ajouter un motif"
            uniqueKey="recrights-1"
            name="reasons"
            fieldContainerClassName="d-flex flex-row"
            fields={[
              {
                label: "Motif 1",
                name: "reason1",
                type: "btnList",
                datas: EP_REASONS_1,
                dataIndex: "id",
                dataLabel: "name",
                className: "align-items-start flex-fill w-50",
                disabled: true,
              },
              {
                label: "Motif 2",
                name: "reasons2",
                type: "checkBtnList",
                btnInline: true,
                datas: EP_REASONS_2.filter((eps) => !eps.isDisabled),
                dataIndex: "id",
                dataLabel: "name",
                filteredBy: "reason1",
                filterFn: (val) => {
                  return EP_REASONS_2.filter((r) => !r.isDisabled && r.reason1 == val);
                },
                className: "align-items-start flex-fill w-50",
                disabled: true,
              },
            ]}
            disabled={true}
            items={avis.reasons}
            change={(e) => {}}
            error={{}}
          />
          <Control
            label="Motifs complémentaires de saisine (optionnels)"
            name="additionalReasons"
            type="checkBtnList"
            datas={EP_ADDITIONAL_REASONS.filter((adr) => !adr.isDisabled)}
            dataIndex="id"
            dataLabel="name"
            value={avis.additionalReasons}
            change={(e) => {
              if (avis.additionalReasons.includes(e.target.value)) {
                setAvis({
                  ...avis,
                  additionalReasons: avis.additionalReasons.filter((r) => r != e.target.value),
                });
              } else {
                setAvis({
                  ...avis,
                  additionalReasons: [...avis.additionalReasons, e.target.value],
                });
              }
            }}
          />
          <div className="d-flex justify-content-end">
            {Object.values(errors).map((err) => (
              <span className="badge badge-danger d-center mt-5 mr-2 rounded" key={`error${err}`}>
                {err}
              </span>
            ))}
            <SaveBtn save={save} isSaving={isSaving} text={`Enregistrer les modifications`} />
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          zIndex: "999999",
          bottom: 60,
          right: 30,
        }}
      >
        <SaveBtn
          className="p-2"
          save={save}
          isSaving={isSaving}
          text={<i className="fa fa-2x fa-save" />}
        />
      </div>
    </>
  );
};

export default ModifyMotifsReo;
