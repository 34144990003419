import React from "react";
import useAmendmentModule from "../../../hooks/useAmendmentModule";
import useCERModule from "../../../hooks/useCERModule";

const CERAmendmentButtons = () => {
  const { setCurrentCERView } = useCERModule();
  const { cerAmendments, events } = useAmendmentModule();

  let hasAmendmentsDraft = false;
  cerAmendments.forEach((amend) => {
    if (amend.status == "DRAFT") hasAmendmentsDraft = true;
  });

  const createAmendment = () => {
    events.onCreateAmendment("UPDATED");
    setCurrentCERView("AMENDMENT_EDIT");
  };
  const createRenewal = () => {
    events.onCreateAmendment("RENEWED");
    setCurrentCERView("AMENDMENT_EDIT");
  };

  return (
    !hasAmendmentsDraft && (
      <div className="d-flex align-items-center justify-content-between mt-3 p-2 bg-white rounded shadow-sm">
        <button className="btn btn-warning text-white btn-sm" onClick={createAmendment}>
          Créer un avenant
        </button>
        <button className="btn btn-progress btn-sm" onClick={createRenewal}>
          Créer un renouvellement
        </button>
      </div>
    )
  );
};

export default CERAmendmentButtons;
