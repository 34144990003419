import React from "react";
import tools from "../../../../../../../../../helpers/tools";
import Autocomplete from "../../../../../../../../common/Autocomplete/Autocomplete";
import Control from "../../../../../../../../common/Control";
import useCERData from "../../../../../../../../Hooks/useCERData/useCERData";

const kid = Date.now();

const OperatorSelect = ({ objective, step, operator, handleNewOperatorChange = () => {}, k }) => {
  const { operators } = useCERData();
  const onSelectOperator = (e) => {
    if (e.target.value) {
      handleNewOperatorChange(e);
    }
  };

  return (
    <>
      <Autocomplete
        placeholder="+ Ajouter un opérateur"
        type="autocomplete"
        value={""}
        name="operator"
        autoCompleteDisplayIndex="name"
        change={onSelectOperator}
        datas={operators.map((op) => ({
          ...op,
          postalCode: op.location?.postalCode,
          phone: tools.formatPhone(op.phone),
        }))}
        dataIndex="id" //default
        dataLabel="name" //default (sera inclut dans la recherche)
        dataLabel2="address" //default (sera inclut dans la recherche)
        dataLabel3="postalCode" //default (sera inclut dans la recherche)
        dataLabel4="phone" //default (sera inclut dans la recherche)
        dataLabel5="subThemesMatch" //default (sera inclut dans la recherche)
        required={true}
        btnInline={false}
        hideAutocompleteOnFocusOut={true} //buggé
        noResults={false} //si aucun résultat api
        autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
        labelStyle={{ minHeight: 0 }}
        k={kid}
        inputClassName="border-0"
      />
      {/* <select
        className="form-control border-0 text-black-50"
        style={{ width: "auto", opacity: "0.5" }}
        type="select"
        name="operator"
        onChange={handleNewOperatorChange}
        value={operator.operator}
      >
        <option value="">+ Ajouter un opérateur</option>
        {operators.map((op) => (
          <option
            disabled={op.isDisabled}
            value={op.id}
            key={`
        selop${k}${objective.goal}${step.step}${op.id}
        `}
          >
            {op.name}
          </option>
        ))}
      </select> */}
    </>
  );
};

export default OperatorSelect;
