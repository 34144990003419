import React, { useContext, useEffect, useState } from "react";
import tools from "../../../../../../../../../../../helpers/tools";
import AddEntityForm from "../../../../../../../../common/CERGoalsForm/common/AddEntityForm";
import { ObjectiveContext } from "../../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import OperatorSelect from "../../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/AddOperatorForm/OperatorSelect";
import { StepContext } from "../../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/StepContext";
import useAmendmentModule from "../../../../../../../hooks/useAmendmentModule";

const AddAmendmentOperatorForm = () => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const { currentAmendment, events } = useAmendmentModule();

  var objectiveIndex = tools.getObjectiveIndex(currentAmendment.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);

  const [newOperator, setNewOperator] = useState({
    operator: "",
    updateType: "ADDED",
  });

  const handleNewOperatorChange = (e) => {
    const { name, value } = e.target;
    setNewOperator({ ...newOperator, [name]: value });
  };

  const handleAddOperator = () => {
    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex ? { ...st, operators: [...st.operators, newOperator] } : st;
        }),
      ],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
    setNewOperator({ operator: "", updateType: "ADDED" });
  };

  useEffect(() => {
    if (newOperator.operator) {
      handleAddOperator();
    }
  }, [newOperator]);

  return (
    <AddEntityForm onSubmit={handleAddOperator} pbClass="pb-0">
      <OperatorSelect
        handleNewOperatorChange={handleNewOperatorChange}
        objective={objective}
        step={step}
        operator={newOperator}
        k={`addOperator`}
      />
    </AddEntityForm>
  );
};

export default AddAmendmentOperatorForm;
