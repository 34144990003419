import React, { useState } from "react";
import useCERData from "../../../../Hooks/useCERData/useCERData";
import ModifyObjective from "./ModifyObjective";

const ObjectiveItem = ({ objective, children }) => {
  const { modifiedObjectiveId, setModifiedObjectiveId, cerSteps, removeCerObjective } =
    useCERData();

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((o) => !o);
  };

  const isEditMode = modifiedObjectiveId == objective.id;
  const stepCount = cerSteps.filter((step) => step.cerGoal == objective.id);

  return (
    <li className={`list-group-item ${isEditMode ? "bg-body" : ""}`}>
      {isEditMode ? (
        <ModifyObjective />
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <span className="badge badge-primary mr-2">objectif</span>
          <span
            className="flex-fill cursor-pointer py-1"
            onClick={toggleIsOpen}
            style={{ fontSize: 18, fontWeight: "bold" }}
          >
            {objective.name}
          </span>

          {/* <span className="mx-2 d-flex flex-row align-items-center">
            <button
              className="btn text-primary"
              onClick={() => setModifiedObjectiveId(objective.id)}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            >
              <i className="fa fa-edit" />
            </button>
            <button
              className="btn text-danger"
              onClick={() => removeCerObjective(objective.id)}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              <i className="fa fa-times" />
            </button>
          </span> */}

          <span className="badge badge-light">{stepCount.length}</span>

          <button className="btn btn-link text-default" onClick={toggleIsOpen}>
            <i className={isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down"} />
          </button>
        </div>
      )}
      {isOpen && children}
    </li>
  );
};

export default ObjectiveItem;
