import React from "react";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";
import TimeBadge from "./TimeBadge";
import ModalBtn from "../../common/ModalBtn";
import useStore from "../../../context/useStore";

const RdvList = ({ rdvs, openRdv, filters, currentManager, rdvsExpired, openModal }) => {
  const [state] = useStore();
  const { SLOT_TYPES, SLOT_SUBTYPES } = state.constants.items;

  return (
    <ul className="list-group mt-3">
      {rdvs
        .filter((r) => r.box == filters.box || filters.box == "")
        .map((r, rk) => {
          const rdvType = tools.findIn(SLOT_TYPES, "id", r.type);
          const rdvSubType = tools.findIn(SLOT_SUBTYPES, "id", r.subType);
          return (
            <li
              key={"r" + rk}
              className={"list-group-item animated fadeInUp faster border-0 bg-body mb-3 "}
              style={{
                animationDelay: rk * 50 + "ms",
                transition: "400ms",
              }}
            >
              <div className="row">
                <div className="col-md-5 col-lg-5">
                  <b>{tools.getFullname(r.userRecipient)}</b>
                  {rdvType?.name && (
                    <div className={`ml-2 badge badge-${rdvType.color}`}>{rdvType.name}</div>
                  )}
                  {rdvSubType?.name && (
                    <div className={`ml-2 badge badge-dark`}>{rdvSubType.name}</div>
                  )}
                </div>
                <div
                  className={
                    "col-md-7 col-lg-7 d-flex justify-content-end font-weight-bold flex-wrap"
                  }
                >
                  {r.userManager && r.userManager.id == currentManager.id ? (
                    <span
                      className="d-flex align-items-center badge font-weight-normal mr-2  badge-primary"
                      style={{ fontSize: 12, height: 20 }}
                    >
                      Pris en charge par vous
                    </span>
                  ) : r.userManager ? (
                    <span
                      className="d-flex align-items-center badge font-weight-normal mr-2 badge-primary"
                      style={{ fontSize: 12, height: 20 }}
                    >
                      Pris en charge par {r.userManager.lastname + " " + r.userManager.firstname}
                    </span>
                  ) : (
                    ""
                  )}
                  <span
                    style={{ fontSize: 12, height: 20 }}
                    className={
                      "d-flex align-items-center badge font-weight-normal mr-2 badge-" +
                      (r.isClosed ? "success" : "warning")
                    }
                  >
                    {r.isClosed ? "Clôturé" : "Non clôturé"}
                  </span>
                  <div>
                    {tools.getPeriodFormated(r.start, r.end)}
                    {!r.isClosed && <TimeBadge rdvsExpired={rdvsExpired} rdv={r} />}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">Motif : {r.motive}</div>
                <div className="col-12">Message : {r.comment}</div>
              </div>
              <div className="row mt-2">
                <div className="col-12 d-flex justify-content-between align-items-center flex-wrap">
                  <ModalBtn
                    onClick={() => {
                      openModal(r.userRecipient.id);
                    }}
                    id="recipientDetailModal"
                    btnClass="btn btn-secondary btn-sm"
                    label="Voir son parcours"
                  />

                  {!r.isClosed && (
                    <button className="btn btn-primary btn-sm" onClick={() => openRdv(r)}>
                      {r.userManager && r.userManager.id == currentManager.id
                        ? "Voir le RDV"
                        : "Prendre en charge le RDV"}
                    </button>
                  )}
                </div>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export default RdvList;
