import React from "react";
import { ClipLoader } from "react-spinners";
import "./loader.scss";

const Loader = ({
  style = {},
  color = "#0e6bb0",
  height = 15,
  width = 1,
  overlay = false,
}) => {
  var sty = style;
  if (overlay) {
    sty.position = "absolute";
    sty.height = "100%";
    sty.background = "#ffffff73";
    sty.zIndex = "99";
    sty.top = 0;
  }
  return (
    <div
      style={sty}
      className="d-flex justify-content-center w-100 align-items-center"
    >
      <ClipLoader color={color} height={height} width={width} size={12} />
      {/* <div
        className="loader position-relative"
        style={{ width: 60, height: 60 }}
      >
        <LoaderElem />
      </div> */}
    </div>
  );
};

export default Loader;
