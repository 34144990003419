import React from "react";
import useStore from "../../../../../../context/useStore";
import colors from "../../../../../../helpers/colors";
import tools from "../../../../../../helpers/tools";
import Loader from "../../../../../common/Loader";

const StatusIcon = ({
  status,
  text,
  onClick,
  isLoading,
  type = "GOAL",
  size = 36,
  style = {},
  title = "",
  disabled = false,
}) => {
  const [state] = useStore();

  const { CER_GOAL_STATUSES, CER_STEP_STATUSES, CER_OBSTACLE_STATUSES } = state.constants.items;
  const statuses =
    type == "GOAL" ? CER_GOAL_STATUSES : type == "STEP" ? CER_STEP_STATUSES : CER_OBSTACLE_STATUSES;
  const statusRef = tools.findIn(statuses, "id", status);

  return (
    <div
      className="d-flex justify-content-start align-items-center cursor-pointer"
      onClick={onClick}
    >
      <button
        title={title}
        className={`btn rounded-circle d-center`}
        style={{
          width: size,
          height: size,
          background: disabled ? "transparent" : colors[statusRef.color],
          color: disabled ? "#d7d7d7" : "white",
          ...style,
        }}
        type="button"
        disabled={isLoading}
      >
        {isLoading ? <Loader color={"white"} /> : <i className={`fa fa-${statusRef.icon}`} />}
      </button>
      {text && <span className="pl-2">{text}</span>}
    </div>
  );
};

export default StatusIcon;
