import { itemsActions } from "../actions/ItemsActions";
import useItemStore from "./useItemStore";
import useGlobalState from "./ItemGlobalState";
import { sidePanelActions } from "../../../../../../context/actions/sidePanelActions";
import { modalActions } from "../../../../../../context/actions/modalActions";

const useStoreState = () => {
  const [state, dispatch] = useItemStore();
  const { gState, gDispatch } = useGlobalState();
  const mActions = modalActions(gState, gDispatch);
  const spActions = sidePanelActions(gState, gDispatch);
  const items_actions = itemsActions(
    state,
    dispatch,
    gState,
    gDispatch,
    spActions,
    mActions
  );

  return {
    state,
    dispatch,
    gState,
    gDispatch,
    items_actions,
    items: state.items,
    spActions,
    mActions,
  };
};

export default useStoreState;
