import { useAtom } from "jotai";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import { isLoadingManagersAtom, managersAtom } from "./managerAtoms";

const useManagers = () => {
  const [managers, setManagers] = useAtom(managersAtom);
  const [isLoadingManagers, setIsLoadingManagers] = useAtom(isLoadingManagersAtom);

  const getManagers = () => {
    setIsLoadingManagers(true);
    dataService.get(
      `managers/agents/mine`,
      (datas) => setManagers(datas.map((d) => ({ ...d, fullName: tools.getFullname(d) }))),
      (err) => {},
      () => setIsLoadingManagers(false)
    );
  };

  return {
    isLoadingManagers,
    managers,
    getManagers,
  };
};

export default useManagers;
