import React from "react";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";
import useCERModule from "../../CERModule/hooks/useCERModule";
import CEREditSection from "../CEREditSection/CEREditSection";

const RecipientInfoRecap = () => {
  const [state] = useStore();
  const { PARTNERS } = state.constants.items;
  const { currentRecipient } = useCERModule();

  return (
    <CEREditSection>
      <div className="row mx-0">
        <div className="col-12 col-md-6">
          <div>
            <strong>Informations de l'allocataire</strong>
          </div>
          <div>{tools.getFullname(currentRecipient)}</div>
          <div>{currentRecipient.CAFId}</div>
          <div>{currentRecipient.phone}</div>
          <div>{currentRecipient.email}</div>
        </div>
        <div className="col-12 col-md-6">
          <div>
            <strong>Référent unique</strong>
          </div>
          <div>{currentRecipient.userManagerDisplayed?.fullName}</div>
          <div>{tools.formatPhone(currentRecipient.userManagerDisplayed?.phone)}</div>
          <div>{currentRecipient.userManagerDisplayed?.email}</div>
        </div>
      </div>
    </CEREditSection>
  );
};

export default RecipientInfoRecap;
