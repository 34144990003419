import React, { useContext, useState } from "react";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { StepContext } from "../StepContext";
import { ObstacleContext } from "./ObstacleContext";
import ObstacleEdit from "./ObstacleEdit";
import ObstacleView from "./ObstacleView";

const ObstaclesList = ({ disabled = false }) => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const [obstacleEditingId, setObstacleEditingId] = useState(null);

  const goToViewMode = () => {
    setObstacleEditingId(null);
  };

  const goToEditMode = (obstacleId) => {
    setObstacleEditingId(obstacleId);
  };

  return (
    <ul className="list-group pl-4">
      {step.obstacles?.map((obstacle, ok) => {
        const obstacleKey = `objobstacle-${objective.pathType}-${objective.goal}-${step.step}-${obstacle.obstacle}`;
        const isBeingEdited = obstacleEditingId == obstacle.obstacle;

        return (
          <li className="list-group-item border-0 p-0 " key={obstacleKey}>
            <ObstacleContext.Provider value={obstacle}>
              {isBeingEdited ? (
                <ObstacleEdit goToViewMode={goToViewMode} />
              ) : (
                <ObstacleView
                  goToEditMode={() => goToEditMode(obstacle.obstacle)}
                  disabled={disabled}
                />
              )}
            </ObstacleContext.Provider>
          </li>
        );
      }) || null}
    </ul>
  );
};

export default ObstaclesList;
