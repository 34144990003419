import React from "react";

const ModalBtn = ({ id, label, btnClass, onClick = false }) => {
  return (
    <button
      type="button"
      className={btnClass}
      data-toggle="modal"
      data-target={"#" + id}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default ModalBtn;
