import React, { useEffect, useState } from "react";

let timerInterval = null;
const EPTimer = ({ beginAt }) => {
  const [timeSentence, setTimeSentence] = useState("");

  useEffect(() => {
    timerInterval = setInterval(function () {
      getTime();
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const getTime = () => {
    if (!beginAt) return "...";
    var now = new Date().getTime();
    var t = now - new Date(beginAt);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((t % (1000 * 60)) / 1000);
    var result = "";
    if (days > 0) {
      result += ((days + "").length == 1 ? "0" + days : days) + "j ";
      result += ((hours + "").length == 1 ? "0" + hours : hours) + "h ";
      result += ((minutes + "").length == 1 ? "0" + minutes : minutes) + "m ";
    } else if (hours > 0) {
      result += ((hours + "").length == 1 ? "0" + hours : hours) + "h ";
      result += ((minutes + "").length == 1 ? "0" + minutes : minutes) + "m ";
      result += ((seconds + "").length == 1 ? "0" + seconds : seconds) + "s ";
    } else {
      result += ((minutes + "").length == 1 ? "0" + minutes : minutes) + "m ";
      result += ((seconds + "").length == 1 ? "0" + seconds : seconds) + "s ";
    }
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      result = "...";
    }
    setTimeSentence(result);
  };

  return <div className="time-int">{timeSentence}</div>;
};

export default EPTimer;
