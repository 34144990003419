import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import tools from "../../../../../../../../../helpers/tools";
import useCERModule from "../../../../../../CERModule/hooks/useCERModule";
import SelectStatus from "../../../../common/SelectStatus";
import StatusIcon from "../../../../common/StatusIcon";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { ObstacleContext } from "./ObstacleContext";
import { StepContext } from "../StepContext";

const ObstacleStatus = ({ disabled = false }) => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const obstacle = useContext(ObstacleContext);
  const { recipientCER, events, actions, isLoadingCERGoal } = useCERModule();
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const onChangeStepStatus = (status) => {
    if (disabled) return false;
    setIsLoading(true);
    var objectiveIndex = tools.getObjectiveIndex(recipientCER.goals, objective);
    var stepIndex = tools.getStepIndex(objective.steps, step);
    var obstacleIndex = tools.getObstacleIndex(step.obstacles, obstacle);
    const newObstacle = {
      ...obstacle,
      detail: {
        status,
        date: DateTime.local().toISO(),
      },
    };
    const newStep = {
      ...step,
      obstacles: [
        ...step.obstacles.map((o, ok) => {
          return ok == obstacleIndex ? newObstacle : o;
        }),
      ],
    };

    let newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((st, stk) => {
          return stk == stepIndex ? newStep : st;
        }),
      ],
    };

    events.onUpdateObjectiveAndSave(newObjective, objectiveIndex);
    toggleStatuses();
  };

  const toggleStatuses = () => {
    if (disabled) return false;
    setIsStatusOpen(!isStatusOpen);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [obstacle.isDone]);

  return (
    <div
      style={{
        position: "absolute",
        top: 3,
        left: -32,
        zIndex: "9",
      }}
    >
      <StatusIcon
        onClick={toggleStatuses}
        isLoading={isLoadingCERGoal}
        status={obstacle.detail.status}
        type="OBSTACLE"
        size={24}
        style={{ fontSize: 11 }}
        title={`Depuis le ${tools.formatDate(obstacle.detail.date)}`}
        disabled={disabled}
      />

      {isStatusOpen && <SelectStatus type="OBSTACLE" onSelectStatus={onChangeStepStatus} />}
    </div>
  );
};

export default ObstacleStatus;
