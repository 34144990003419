import React, { useState, useEffect } from "react";

const FormNavigation = ({ step, setStep, questions, answers, isCondNotFullfiled, preventBack }) => {
  return (
    answers && (
      <ul className="list-group">
        {answers.map((a, ak) => {
          var qOrig = questions.find((q) => q.id == a.question);
          if ((a.value + "").length > 0 && !isCondNotFullfiled(ak)) {
            return (
              <li
                key={"sumq" + ak}
                style={{ cursor: "pointer" }}
                onClick={() => (!preventBack ? setStep(ak) : "")}
                className={"list-group-item " + (step == ak ? "bg-primary text-white" : "")}
              >
                {step == ak && <i className="fa fa-check mr-2" />}
                <b>{a.label}</b>
                <br />
                <i>
                  {Array.isArray(a.value)
                    ? qOrig.answers.map((qa, qak) => {
                        if (a.value.includes(qa.value)) {
                          return qa.label + " - ";
                        }
                      })
                    : qOrig.answers.find((qa) => qa.value == a.value)
                    ? qOrig.answers.find((qa) => qa.value == a.value).label
                    : ""}
                </i>
              </li>
            );
          }
        })}
      </ul>
    )
  );
};

export default FormNavigation;
