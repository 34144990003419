import { DateTime } from "luxon";
import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import useStore from "../../../../../../../context/useStore";
import colors from "../../../../../../../helpers/colors";
import tools from "../../../../../../../helpers/tools";
import useAmendmentModule from "../../../hooks/useAmendmentModule";
import useCERModule from "../../../hooks/useCERModule";

const AmendmentStatusBadge = ({ amendment }) => {
  const isDraft = amendment.status == "DRAFT";
  const colorClass = isDraft ? "dark" : "success";
  const text = isDraft ? "BROUILLON" : "SIGNÉ";
  return (
    <span
      style={{
        fontSize: 11,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        borderRadius: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
      className={`badge badge-${colorClass}`}
    >
      {text}
    </span>
  );
};

const AmendmentInfo = ({ text, icon = "clock" }) => {
  return (
    <div className="d-flex align-items-center">
      <i className={`fa fa-${icon} mr-1`} style={{ width: 20 }} />
      <span style={{ fontSize: 13 }}>{text}</span>
    </div>
  );
};

const CERTimeline = () => {
  const [state] = useStore();
  const { CER_DURATIONS } = state.constants.items;
  const { cerAmendments, events } = useAmendmentModule();
  const { currentRecipient } = useCERModule();

  const openAmendment = (amend) => {
    events.onOpenAmendment(amend);
  };

  const printAmendment = (amend) => {
    window.open(
      `/ceramendment/${currentRecipient.id}/${amend.id}`,
      "Avenant CER",
      "height=400,width=400"
    );
  };

  return (
    <VerticalTimeline layout="1-column">
      {cerAmendments.map((amend) => {
        const isRenew = amend.type == "RENEWED";
        const isDraft = amend.status == "DRAFT";
        const entityName = !isRenew ? "Avenant" : "Renouvellement";
        const icon = !isRenew ? "file-signature" : "file-medical";
        const typeColor = !isRenew ? colors.warning : colors.progress;
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "#fff",
              color: "#404040",
              padding: 11,
              paddingTop: 4,
              borderRadius: 8,
              borderTop: `4px ${isDraft ? "dashed" : "solid"} ${typeColor}`,
              boxShadow: `0 0 8px -3px #afafaf`,
            }}
            contentArrowStyle={{ borderRight: "7px solid " + typeColor }}
            iconStyle={{
              background: typeColor,
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 30,
              height: 30,
            }}
            icon={<i className={`fa fa-1x fa-${icon}`} />}
          >
            <div className="p-2">
              <h4 className="d-flex align-items-center mb-2">
                <span className="pr-2" style={{ fontSize: 18, color: typeColor }}>
                  {entityName}
                </span>{" "}
                <AmendmentStatusBadge amendment={amend} />
              </h4>
              <div className="mb-2">
                <AmendmentInfo text={`Créé le ${tools.formatDate(amend.createdAt)}`} />
                {isRenew && (
                  <>
                    <AmendmentInfo
                      icon="calendar-alt"
                      text={`Du ${tools.formatDate(amend.beginAt)} au ${tools.formatDate(
                        amend.endAt
                      )}`}
                    />
                    <AmendmentInfo
                      icon="stopwatch"
                      text={`Durée ${tools.findIn(CER_DURATIONS, "id", amend.duration).name}`}
                    />
                  </>
                )}
                {amend.signedAt && (
                  <AmendmentInfo
                    text={`Signé le ${tools.formatDate(amend.signedAt)}`}
                    icon="signature"
                  />
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between pt-2">
                <button className="btn btn-sm btn-primary" onClick={() => openAmendment(amend)}>
                  Consulter
                </button>
                <button
                  className="btn btn-sm btn-link text-primary"
                  onClick={() => printAmendment(amend)}
                >
                  Imprimer
                </button>
              </div>
            </div>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};

export default CERTimeline;
