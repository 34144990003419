import { DateTime } from "luxon";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";
import useAmendmentModule from "../../CERModule/hooks/useAmendmentModule";
import useCERModule from "../../CERModule/hooks/useCERModule";

const useContractForm = (isRenew = false) => {
  const { recipientCER, cerErrors, actions, currentRecipient } = useCERModule();

  const { currentAmendment, actions: amendmentActions, amendmentErrors } = useAmendmentModule();

  const currentEntity = isRenew ? currentAmendment : recipientCER;
  const updateAction = isRenew
    ? amendmentActions.updateCurrentAmendment
    : actions.updateRecipientCER;

  const [state] = useStore();
  const { CER_DURATIONS } = state.constants.items;

  const handleChangeAttribute = (e) => {
    const { name, value } = e.target;
    const newEntity = { ...currentEntity, [name]: value };
    if (name == "duration") {
      newEntity.endAt = DateTime.fromISO(currentEntity.beginAt)
        .plus({
          months: tools.getNbMonthsFromDurationConstant(state.constants.items, value),
        })
        .minus({ days: 1 })
        .toISO();
    }
    if (name == "beginAt" && currentEntity.duration) {
      newEntity.endAt = DateTime.fromISO(value)
        .plus({
          months: tools.getNbMonthsFromDurationConstant(
            state.constants.items,
            currentEntity.duration
          ),
        })
        .minus({ days: 1 })
        .toISO();
    }

    updateAction({
      ...newEntity,
    });
  };

  return {
    currentRecipient,
    currentEntity,
    handleChangeAttribute,
    errors: isRenew ? amendmentErrors : cerErrors,
    CER_DURATIONS,
    state,
  };
};

export default useContractForm;
