import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import ExportCsv from "../../common/ExportCsv";

var strTimeout = null;

const ThemeList = () => {
  const [state, dispatch] = useStore();
  const [organizations, setOrganizations] = useState(false);
  const [sites, setSites] = useState(false);
  const [themes, setThemes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [search, setSearch] = useState({
    filters: {
      terms: "",
    },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);

  useEffect(() => {
    var lsState = tools.getState("ThemeList", { search: search });
    if (!lsState.search.filters?.terms) {
      lsState = { search: search };
    }
    setSearch(lsState.search);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchThemes();
    }, 1500);
    tools.saveState("ThemeList", "search", search);
  }, [search]);

  function searchThemes() {
    setErrors(false);
    Axios.post(api_url + "themes/search", search)
      .then((res) => {
        setThemes(res.data.data);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
        setNbResults(res.data.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        setThemes([]);
      });
  }
  function updateThemeSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (value == "") {
      delete su.filters[e.target.name];
    } else {
      su.filters[e.target.name] = value;
    }

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        action={{ to: "/themes/create", text: "Créer un thème" }}
        title={"Liste des thèmes"}
      >
        <div className="row mb-4">
          {state.constants.items.length == 0 ? <Loader /> : <div className="col-5"></div>}
          <div className="col">
            <Control
              margin={"my-0"}
              label="Recherche"
              name="terms"
              value={search.filters.terms}
              change={updateThemeSearch}
              suffix={<i className="fa fa-search ml-2" style={{ lineHeight: "34px" }}></i>}
            />
          </div>
          <div className="col align-items-center justify-content-end d-flex">
            {nbResults} thèmes trouvés
          </div>
        </div>
      </Page>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <>
              <Table
                datas={themes}
                fields={[
                  {
                    name: "Nom",
                    path: "name",
                  },
                  {
                    name: "Sous-thèmes",
                    path: "subThemes",
                    render: (elem) =>
                      elem.map((el, elk) => (
                        <span className="badge badge-secondary mr-1" key={"el" + elk}>
                          {el.name}
                        </span>
                      )),
                  },

                  {
                    name: "Modifier",
                    path: "id",
                    render: (elem) => (
                      <Link
                        className="btn btn-primary btn-sm align-items-center justify-content-center d-flex"
                        to={"/themes/" + elem}
                      >
                        <i className="fa fa-edit"></i>
                      </Link>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Pagination nbPages={nbPages} page={search.page} changePage={changePage} />
        </div>
      </div>
    </>
  );
};

export default ThemeList;
