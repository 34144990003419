import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Control";
import Page from "../../../common/layout/Page";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import Table from "../../../common/Table";
import SectorDetail from "./SectorDetail";

const Sectors = () => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const mActions = modalActions(state, dispatch);
  const [sectors, setSectors] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoadingSectors, setIsLoadingSectors] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingOrgas, setIsLoadingOrgas] = useState(false);
  const [errors, setErrors] = useState({});
  const [saveErrors, setSaveErrors] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [searchState, setSearchState] = useState({});

  useEffect(() => {
    fetchSectors();
    fetchUsers();
    fetchOrganizations();
  }, []);

  const fetchSectors = () => {};
  const fetchOrganizations = () => {
    setIsLoadingOrgas(true);
    dataService.get(`organizations`, setOrganizations, setErrors, () => {
      setIsLoadingOrgas(false);
    });
  };

  const fetchUsers = () => {
    setIsLoadingUsers(true);

    dataService.get(
      `managers/ep`,
      (datas) => {
        setUsers(datas);
        setIsLoadingSectors(true);
        dataService.get(
          `sectors`,
          (sdatas) => {
            var filteredDatas = sdatas.map((s) => ({
              ...s,
              userManagers: s.userManagers.filter((su) => datas.find((usr) => usr.id == su)),
            }));
            setSectors(filteredDatas);
          },
          setErrors,
          () => {
            setIsLoadingSectors(false);
          }
        );
      },
      setErrors,
      () => setIsLoadingUsers(false)
    );
  };

  const saveSectors = () => {
    setIsSaving(true);
    var allPromises = [];
    sectors.map((s) => {
      allPromises.push(saveSector(s));
    });

    Promise.all(allPromises).then((values) => {
      var errs = [];
      values.forEach((val) => {
        if (val.errors) {
          errs.push(val.errors);
        }
      });
      toast.success("Secteurs enregistrés");
      setIsSaving(false);
      setSaveErrors(errs);
      setSectors(values);
    });
  };

  const saveSector = (sector) => {
    return new Promise((resolve, reject) => {
      dataService.patch(`sectors/${sector.id}`, sector, resolve, reject);
    });
  };

  const addUserToSector = (user, sector) => {
    const secs = [...sectors];
    var originSector = secs.find((s) => s.name == sector.name);
    var originUsers = originSector.userManagers;

    if (originUsers.find((u) => u == user.id)) {
      originUsers = originUsers.filter((u) => u != user.id);
    } else {
      originUsers.push(user.id);
    }
    secs.find((s) => s.name == sector.name).userManagers = originUsers;
    setSectors(secs);
  };

  const handleSearch = (e, orgaId) => {
    const { value } = e.target;
    var newSearchState = { ...searchState };

    newSearchState[orgaId] = value;
    setSearchState(newSearchState);
  };

  const openEditSector = (sector) => {
    mActions.updateModal({
      isOpen: true,
      content: <SectorDetail sector={sector} refresh={fetchUsers} />,
    });
  };

  return (
    <Page
      bgTransparent
      container={"container-fluid "}
      title="Répartition des utilisateurs par secteur"
    >
      <div className="row">
        <div className="col-12">
          {saveErrors.map((s) => (s.lengh ? s.map((err) => err) : null))}
        </div>
        <div className="col-12 col-md-4">
          <div className="mb-2">
            <strong>Secteurs</strong>
          </div>
          <div className="row">
            {sectors.length ? (
              sectors.map((sec, seck) => (
                <div className="col-12 custom-card mb-2" key={`sector${sec.name}`}>
                  <div className="d-flex align-items-center justify-content-between flex-row">
                    <strong>
                      {sec.name}
                      <button className="btn btn-default ml-2" onClick={() => openEditSector(sec)}>
                        <i className="fa fa-edit" />
                      </button>
                    </strong>

                    <strong>{sec.userManagers.length}</strong>
                  </div>

                  <div>
                    {sec.userManagers.map((u, uk) => (
                      <div
                        className="d-flex align-items-center justify-content-between border-bottom mb-1 py-1"
                        key={`sectoruser${sec.name}${u}`}
                      >
                        {tools.getFullname(users.find((us) => us.id == u))}
                        <button
                          className="btn btn-light text-danger btn-sm"
                          onClick={() =>
                            addUserToSector(
                              users.find((uss) => uss.id == u),
                              sec
                            )
                          }
                        >
                          <i className="fa fa-times" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : isLoadingSectors ? (
              <Loader />
            ) : null}
          </div>
        </div>
        <div className="col-12 col-md-8">
          {users.length ? (
            <div>
              <div>
                <strong>Tous les utilisateurs par organisation</strong>
              </div>
              {organizations.map((orga, orgk) => {
                return (
                  <div key={`${orga.id}`}>
                    <div className="mt-3 d-flex justify-content-between align-items-center">
                      <strong>{orga.name}</strong>
                      <Control
                        type="text"
                        name="org"
                        value={searchState[orga.id]}
                        change={(e) => handleSearch(e, orga.id)}
                        suffix={
                          <div className="d-center pr-2">
                            <i className="fa fa-search" />
                          </div>
                        }
                      />
                    </div>
                    <div
                      style={{
                        maxHeight: 400,
                        overflowY: "auto",
                      }}
                    >
                      <Table
                        fullWidth
                        datas={users.filter((u) => {
                          return (
                            u.organizations.includes(orga.id) &&
                            (searchState[orga.id]
                              ? tools.getFullname(u).includes(searchState[orga.id])
                              : true)
                          );
                        })}
                        fields={[
                          {
                            name: "Utilisateur",
                            path: "*",
                            render: (el) => tools.getFullname(el),
                          },
                          {
                            name: "Rôle",
                            path: "role",
                            render: (el) =>
                              constants.ROLES_MANAGER.find((r) => r.id == el)
                                ? constants.ROLES_MANAGER.find((r) => r.id == el).name
                                : el,
                          },
                          {
                            name: "Secteurs",
                            path: "*",
                            render: (el) => (
                              <div className="d-flex flex-row justify-content-around">
                                {sectors.map((s, sk) => {
                                  var isInSector = sectors
                                    .find((se) => se.name == s.name)
                                    .userManagers.includes(el.id);
                                  return (
                                    <button
                                      className={`btn btn-default btn-sm ${
                                        isInSector ? "active" : ""
                                      }`}
                                      onClick={() => addUserToSector(el, s)}
                                      key={`usersector${el.id}${s.name}`}
                                    >
                                      {s.name}
                                    </button>
                                  );
                                })}
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : isLoadingUsers ? (
            <Loader />
          ) : null}
        </div>

        <div className="col-12 d-flex justify-content-end">
          <SaveBtn save={saveSectors} isSaving={isSaving} />
        </div>
      </div>
    </Page>
  );
};

export default Sectors;
