import React, { useState, useEffect } from "react";
import FormGenerator from "./FormGenerator";
import FormHeader from "./FormHeader";
import FormUtils from "./FormUtils";
import FormContinue from "./FormContinue";

const FormPage = ({
  step,
  setStep,
  formConfig,
  isLoading,
  nextStep,
  previousStep,
  answers,
  setAnswers,
  save,
  errors,
  loginOpen,
  setLoginOpen,
  specialActions,
}) => {
  const [canContinue, setCanContinue] = useState(false);
  var lastStep = formConfig.form.length - 1;

  useEffect(() => {
    var cc = true;
    formConfig.form[step].questions.forEach((q) => {
      if (FormUtils.shouldDisplayQuestion(answers, q.condition)) {
        if (q.required) {
          if (!answers[q.name] || answers[q.name].length == 0) {
            cc = false;
          }
        }
      }
    });
    setCanContinue(cc);
  }, [answers]);

  return (
    <div className="container-fluid">
      <FormHeader
        step={step}
        lastStep={lastStep}
        previousStep={previousStep}
        nextStep={nextStep}
        title="Formulaire"
        loginOpen={loginOpen}
        setLoginOpen={setLoginOpen}
        answers={answers}
        setAnswers={setAnswers}
      />

      <div
        id="blur-container"
        className={
          "mt-5 pt-0 pt-md-5 " +
          (formConfig.form[step].displayBasket ? "big-margin-top" : "")
        }
        style={{
          filter: loginOpen ? "blur(5px)" : "none",
        }}
      >
        <div className="">
          <div className="row">
            <div
              className="col-12 pb-5 mx-auto mt-4"
              style={{
                maxWidth: formConfig.form[step].size == "fullwidth" ? "" : 900,
              }}
            >
              <FormGenerator
                form={formConfig.form}
                answers={answers}
                setAnswers={setAnswers}
                step={step}
                setStep={setStep}
                nextStep={nextStep}
                previousStep={previousStep}
                isLoading={isLoading}
                errors={errors}
                specialActions={specialActions}
              />
              {errors.other && (
                <div
                  className="alert alert-danger mx-auto my-5"
                  style={{ maxWidth: "80%", width: "80%" }}
                >
                  {errors.other}
                </div>
              )}
              {step < lastStep &&
                !isLoading &&
                !formConfig.form[step].hideNextBtn && (
                  <div
                    class="form-continue-btn"
                    style={{
                      marginTop: 60,
                    }}
                  >
                    <button
                      className={
                        "btn btn-primary " +
                        (canContinue ? "animated pulse infinite" : "")
                      }
                      disabled={!canContinue}
                      onClick={nextStep}
                    >
                      {formConfig.form[step].nextBtnText
                        ? formConfig.form[step].nextBtnText
                        : "Étape suivante"}
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPage;
