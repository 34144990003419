import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import ModalBtn from "../../common/ModalBtn";
import Modal from "../../common/Modal";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import Accordion from "../../common/Accordion";
import SaveBtn from "../../common/SaveBtn";
import $ from "jquery";
import InfoBox from "../../common/InfoBox";

var strTimeout = null;

const MyAnomalyList = () => {
  const [anomalies, setAnomalies] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState({
    anomaly: { status: [], agent: "" },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [selectedMyAnomaly, setSelectedMyAnomaly] = useState(false);
  const [agents, setAgents] = useState(false);
  const [comment, setComment] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const anomalyStatus = state.constants.items.ANOMALY_STATUS;
  const [nbResults, setNbResuts] = useState(0);
  const [selectedRecipient, setSelectedRecipient] = useState(false);
  const [selectedAnomaly, setSelectedAnomaly] = useState(false);

  useEffect(() => {
    //var lsState = tools.getState("MyAnomalyList", { search: search });

    setSearch(search);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchData();
    }, 1500);
    //tools.saveState("MyAnomalyList", "search", search);
  }, [search]);

  useEffect(() => {
    if (selectedMyAnomaly && anomalies.length > 0) {
      setNewStatus(anomalies.find((a) => a.id == selectedMyAnomaly).status);
    }
    setComment("");
    setIsSaved(false);
  }, [selectedMyAnomaly]);

  useEffect(() => {
    setIsSaved(false);
  }, [newStatus, comment]);

  function searchData() {
    setErrors(false);
    setIsLoading(true);
    Axios.get(api_url + "anomalies/mine")
      .then((res) => {
        setAnomalies(res.data.filter((an) => an.status != "DONE"));
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
        setNbResuts(res.data.count || 0);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error("Une erreur s'est produite lors de la récupération de vos anomalies");
        }
      });
  }

  function updateStatus() {
    setIsSaving(true);
    Axios.patch(api_url + "anomalies/" + selectedMyAnomaly + "/status", {
      status: newStatus,
      comment: comment,
    })
      .then((res) => {
        setSelectedMyAnomaly(false);
        setNewStatus("");
        setComment("");
        searchData();
        $("#action-on-anomaly").modal("hide");
        toast.success("Le statut de l'anomalie a bien été modifié");
      })
      .catch((err) => {
        toast.error("Une erreur est survenue lors de la modification du statut de l'anomalie");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSaving(false);
        setIsSaved(true);
      });
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }

  return (
    <>
      <Page container={"container-fluid px-5"} title={"Liste des anomalies"} errors={errors}>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">{anomalies.length} anomalie(s) trouvée(s)</div>
        </div>

        <Modal id="action-on-anomaly" title="Changer le statut de l'anomalie">
          {anomalyStatus && anomalies.length > 0 && selectedMyAnomaly ? (
            <>
              {anomalyStatus.map((a, ak) => {
                var an = anomalies.find((a) => a.id == selectedMyAnomaly);

                return a.isDisabled ? (
                  ""
                ) : (
                  <button
                    data-toggle="modal"
                    data-target={"#assign-to-agent"}
                    className={`btn btn-sm mr-2 mt-2 btn-${a.color}`}
                    onClick={() => setNewStatus(a.id)}
                  >
                    {a.name}
                    {a.id == newStatus && <i className="fa fa-check ml-2" />}
                  </button>
                );
              })}

              <Control
                margin={"mt-3"}
                type="textarea"
                label="Commentaire"
                value={comment}
                change={(e) => setComment(e.target.value)}
              />
              {newStatus != anomalies.find((a) => a.id == selectedMyAnomaly).status && (
                <SaveBtn isSaved={isSaved} isSaving={isSaving} save={updateStatus} />
              )}
              <Accordion
                className={"mt-3"}
                items={[
                  {
                    title: "Historique des modifications de statut",
                    content: (
                      <ul className="list-group">
                        {anomalies
                          .find((a) => a.id == selectedMyAnomaly)
                          .anomalyHistory.map((h, hk) => {
                            var an = anomalyStatus.find((as) => as.id == h.status);
                            return (
                              <li className="list-group-item ">
                                <div className="d-flex align-items-start justify-content-between">
                                  <div>
                                    <span className={"mr-2 badge badge-" + an.color}>
                                      {an.name}
                                    </span>
                                    <br />
                                    par {tools.getFullname(h.updatedBy)}
                                  </div>
                                  <span>
                                    le{" "}
                                    <b>{DateTime.fromISO(h.date).toFormat("dd/MM/yyyy HH:mm")}</b>
                                  </span>
                                </div>
                                {h.comment && (
                                  <div className="alert alert-light">
                                    <i className=" fa fa-comment mr-2" />
                                    {h.comment}
                                  </div>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <Loader />
          )}
        </Modal>

        <Modal id="view-histo" title="Historique des modifications de statut">
          {selectedAnomaly && (
            <ul className="list-group">
              {selectedAnomaly.anomalyHistory.map((h, hk) => {
                var an = anomalyStatus.find((as) => as.id == h.status);
                return (
                  <li className="list-group-item ">
                    <div className="d-flex align-items-start justify-content-between">
                      <div>
                        <span className={"mr-2 badge badge-" + an.color}>{an.name}</span>
                        <br />
                        par {h.updatedBy ? tools.getFullname(h.updatedBy) : "Système"}
                      </div>
                      <span>
                        le <b>{DateTime.fromISO(h.date).toFormat("dd/MM/yyyy HH:mm")}</b>
                      </span>
                    </div>
                    {h.comment && (
                      <div className="alert alert-light">
                        <i className=" fa fa-comment mr-2" />
                        {h.comment}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </Modal>
        <Modal id="view-recipient" title="Allocataire lié à l'anomalie">
          {selectedRecipient && (
            <>
              <InfoBox
                name="Civilité : "
                value={
                  state.constants.items.GENDERS.find((g) => g.id == selectedRecipient.gender).name
                }
              />
              <InfoBox name="Nom : " value={selectedRecipient.lastname} />
              <InfoBox name="Prénom : " value={selectedRecipient.firstname} />
              <InfoBox
                name="Date de naissance : "
                value={DateTime.fromISO(selectedRecipient.birthdate).toFormat("dd/MM/yyyy")}
              />
              <InfoBox name="NIR : " value={selectedRecipient.nir} />
              <InfoBox name="Email : " value={selectedRecipient.email} />
              <a
                target="_BLANK"
                href={tools.getRecipientLink(selectedRecipient)}
                className="btn btn-default mt-2"
              >
                Voir sa fiche
              </a>
            </>
          )}
        </Modal>
      </Page>
      <div className="row">
        <div className="col-12 p-4">
          {isLoading || !state.constants.items || !state.constants.items.ANOMALY_STATUS ? (
            <Loader />
          ) : (
            <>
              <Table
                datas={anomalies}
                fields={[
                  {
                    name: "Date",
                    path: "createdAt",
                    render: (elem) => DateTime.fromISO(elem).toFormat("dd/MM/yyyy HH:mm"),
                  },
                  {
                    name: "Description",
                    path: "*",
                    render: (elem) => {
                      var content = (
                        <span className={`badge badge-${elem.infos.color}`}>
                          <i className={"mr-2 fa fa-" + elem.infos.icon} />
                          {elem.infos.description}
                        </span>
                      );
                      return elem.status == "DONE" || elem.status == "ARCHIVED" ? (
                        <del>{content}</del>
                      ) : (
                        content
                      );
                    },
                  },
                  {
                    name: "Statut",
                    path: "*",
                    render: (elem) => {
                      var content = (
                        <span
                          className={`"text-white badge badge-"${
                            state.constants.items.ANOMALY_STATUS.find((a) => a.id == elem.status)
                              .color
                          }`}
                        >
                          {
                            state.constants.items.ANOMALY_STATUS.find((a) => a.id == elem.status)
                              .name
                          }
                        </span>
                      );
                      return elem.status == "DONE" || elem.status == "ARCHIVED" ? (
                        <del>{content}</del>
                      ) : (
                        content
                      );
                    },
                  },
                  {
                    name: "Site",
                    path: "*",
                    render: (elem) => elem.infos && elem.infos.site,
                  },
                  {
                    name: "Historique",
                    path: "*",
                    render: (elem) =>
                      elem.anomalyHistory.length > 0 ? (
                        <>
                          <ModalBtn
                            id="view-histo"
                            label={<i className="fa fa-eye" />}
                            btnClass="btn btn-primary btn-sm"
                            onClick={() => setSelectedAnomaly(elem)}
                          />
                        </>
                      ) : (
                        <></>
                      ),
                  },
                  {
                    name: "Allocataire concerné",
                    path: "*",
                    render: (elem) =>
                      elem.infos && elem.infos.userRecipient ? (
                        <>
                          <ModalBtn
                            id="view-recipient"
                            label={<i className="fa fa-user" />}
                            btnClass="btn btn-default btn-sm"
                            onClick={() => setSelectedRecipient(elem.infos.userRecipient)}
                          />
                        </>
                      ) : (
                        <></>
                      ),
                  },
                  {
                    name: "Traiter",
                    path: "*",
                    render: (elem) => (
                      <>
                        {
                          <ModalBtn
                            id="action-on-anomaly"
                            label="Traiter"
                            btnClass="btn btn-primary btn-sm"
                            onClick={() => setSelectedMyAnomaly(elem.id)}
                          />
                        }
                      </>
                    ),
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyAnomalyList;
