import React, { useContext, useEffect, useState } from "react";
import Loader from "../../common/Loader";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import useCERModule from "../RecipientDetail/CERModule/hooks/useCERModule";
import { useParams } from "react-router-dom";
import useStore from "../../../context/useStore";
import useCERData from "../../Hooks/useCERData/useCERData";
import useRecipientData from "../../Hooks/useRecipientData/useRecipientData";
import tools from "../../../helpers/tools";
import LegalText from "./LegalText";
import Signatures from "./Signatures";
import RecipientCERInfo from "./RecipientCERInfo";
import useAmendmentModule from "../RecipientDetail/CERModule/hooks/useAmendmentModule";
import AmendmentPathTypesList from "./AmendmentPathTypesList";
import AmendmentContractInfo from "./AmendmentContratctInfo";
import colors from "../../../helpers/colors";
import { AmendmentContext } from "./AmendmentContext";

const Section = ({ wrap = true, style = {}, children }) => {
  var styles = StyleSheet.create({
    section: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 20,
      paddingRight: 20,
      ...style,
    },
  });
  return (
    <View style={{ ...styles.section }} wrap={wrap}>
      {children}
    </View>
  );
};

const MyDoc = ({ constants, currentAmendment }) => {
  var styles = StyleSheet.create({
    logoContainer: {
      position: "absolute",
      top: 20,
      left: 20,
    },
    logo: {
      height: 78,
      width: 54,
    },

    title: {
      paddingBottom: 0,
      paddingRight: 20,
      fontSize: 13,
      paddingLeft: 70,
      paddingTop: 20,
      textAlign: "center",
    },
  });

  const isRenew = currentAmendment?.type == "RENEWED";

  return (
    <AmendmentContext.Provider value={currentAmendment}>
      <Document>
        <Page wrap size="A4" orientation="portrait">
          <View
            style={{
              ...styles.logoContainer,
            }}
          >
            <Image src="/assets/logo-espoar.png" style={{ ...styles.logo }} />
          </View>

          <View style={{ ...styles.title }}>
            <Text>
              Contractualisation du parcours d'accompagnement défini entre les signataires
            </Text>
            <Text>
              <Text style={{ color: colors.primary }}>
                {isRenew ? "Renouvellement" : "Avenant"}
              </Text>{" "}
              au Contrat d'Engagements Réciproques
            </Text>
            <Text>« notre CER »</Text>
          </View>

          <Section>
            <RecipientCERInfo />
          </Section>

          <Section>
            <Text style={{ fontSize: 13 }}>Nos objectifs partagés :</Text>
            <AmendmentPathTypesList constants={constants} />
          </Section>

          <Section wrap={false}>
            <LegalText />
          </Section>

          <Section wrap={false}>
            <AmendmentContractInfo CER_DURATIONS={constants.CER_DURATIONS} />
          </Section>

          <Section wrap={false}>
            <Signatures />
          </Section>
        </Page>
      </Document>
    </AmendmentContext.Provider>
  );
};

const AmendmentPrint = () => {
  const [state] = useStore();
  const constants = state.constants.items;
  const { CER_DURATIONS, CER_GOAL_CATEGORIES } = constants;
  const { recipientId, amendmentId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [pdfKey, setPdfKey] = useState(1);
  const [docReady, setDocReady] = useState(false);
  const [displayPreview, setDisplayPreview] = useState(true);

  const {
    setObjectiveCategories,
    getCerObjectives,
    getCerSteps,
    getCerOperators,
    setPathTypes,
    cerObjectives,
    cerSteps,
    operators,
    pathTypes,
  } = useCERData();

  const { isLoadingCER, actions } = useCERModule();
  const { isLoadingAmendment, currentAmendment, actions: amendmentActions } = useAmendmentModule();
  const { recipient, getRecipient } = useRecipientData();

  useEffect(() => {
    setIsLoading(true);
    document.querySelector("#sidebar").style.display = "none";
    document.querySelector("nav.navbar").style.display = "none";
    document.querySelector("#sidebarCollapse").style.display = "none";
    if (recipientId) {
      getRecipient(recipientId);
      actions.getRecipientCER(recipientId);
      amendmentActions.getAmendmentById(amendmentId);
      getCerObjectives();
      getCerSteps();
      getCerOperators();
      setObjectiveCategories(state.constants.items.CER_GOAL_CATEGORIES);
      setPathTypes(state.constants.items.CER_GOAL_PATH_TYPES);
    }
  }, [recipientId]);

  useEffect(() => {
    if (
      !isLoadingCER &&
      !isLoadingAmendment &&
      cerObjectives.length > 0 &&
      cerSteps.length > 0 &&
      operators.length > 0 &&
      pathTypes.length > 0 &&
      recipient
    ) {
      setIsLoading(false);
    }
  }, [isLoadingCER, cerObjectives, cerSteps, operators, pathTypes, recipient]);

  const isRenew = currentAmendment?.type == "RENEWED";

  return (
    <div key={pdfKey}>
      <img src="/assets/logo-po.jpg" width="1" />

      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="text-center pt-3 bg-white pb-3 px-3 mt-2 d-flex justify-content-center align-items-center flex-column animated fadeIn  faster"
          style={{ gap: "1rem" }}
        >
          <div className="alert alert-info">
            <i className="fa fa-exclamation-circle mr-2" />
            Attention, {isRenew ? "le renouvellement" : "l'avenant"} au Contrat CER est généré à la
            volée. Ainsi, les informations que vous avez modifiées après avoir ouvert cette fenêtre
            ne seront pas prise en compte. Si vous avez effectué des changements impactant{" "}
            {isRenew ? "le renouvellement" : "l'avenant"} veuillez fermer cette fenêtre puis la
            rouvrir.
          </div>
          <PDFDownloadLink
            className="btn btn-primary dl-po-btn mb-2 animated zoomIn  faster"
            document={
              <MyDoc
                constants={{ CER_DURATIONS, CER_GOAL_CATEGORIES }}
                currentAmendment={currentAmendment}
              />
            }
            fileName={`Avenant au contrat CER ${tools.getFullname(recipient)}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              var text = loading ? (
                "Chargement..."
              ) : (
                <>
                  <i className="fa fa-download mr-2" />
                  Télécharger l'avenant au Contrat CER
                </>
              );
              if (error) {
                setPdfKey(pdfKey + 1);
              }
              if (!loading) {
                setDocReady(true);
              }
              return <>{text}</>;
            }}
          </PDFDownloadLink>
          {docReady && (
            <button
              className="btn btn-info mb-2 animated zoomIn faster"
              onClick={() => setDisplayPreview(!displayPreview)}
            >
              <i className={`fa mr-2 ${displayPreview ? "fa-eye-slash" : "fa-eye"}`} />
              {displayPreview ? "Fermer" : "Voir dans"} la visionneuse
            </button>
          )}
          {displayPreview && (
            <div
              className="animated fadeIn fast"
              style={{
                width: "100%",
                height: 600,
              }}
            >
              <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
                <MyDoc
                  constants={{ CER_DURATIONS, CER_GOAL_CATEGORIES }}
                  currentAmendment={currentAmendment}
                />
              </PDFViewer>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AmendmentPrint;
