import React from "react";
import { DateTime } from "luxon";
import useStore from "../../../../context/useStore";

const RecipientRdvResult = ({ rdv }) => {
  const [state] = useStore();
  const constants = state.constants.items;
  const results = rdv.result;

  const getRdvResult = () => {
    switch (results.status) {
      case 2:
        return (
          <div className="">
            Nouveau RDV prévu pour le :{" "}
            <b className="text-primary">
              {results.newDate
                ? DateTime.fromISO(results.newDate).toFormat("dd/MM/yyyy HH:mm")
                : "Aucune date"}
            </b>
          </div>
        );

      case 3:
        return (
          <div className="">
            Réorientation vers : <b className="text-primary">{results.newOrientation}</b>
          </div>
        );

      case 4:
        return (
          <div>
            <div className="">
              Responsable de l'annulation :{" "}
              <b className="text-primary">
                {results.cancelOrigin ? results.cancelOrigin : "Inconnu"}
              </b>
            </div>
            {results.cancelReason ? (
              <div className="">
                Motif :{" "}
                <b className="text-primary">
                  {constants.RDV_REPORT_TYPES.find((r) => r.id == results.cancelReason).name}
                </b>
              </div>
            ) : null}
            {results.comment && results.comment != "" ? (
              <div className=" mt-2 p-1 mb-0">
                <b className="mr-2" style={{ color: "#6f6f6f" }}>
                  <i className="far fa-comment mr-2" />
                  Agent -
                </b>
                <i>{results.comment}</i>
              </div>
            ) : null}
          </div>
        );

      case 8:
        return (
          <div>
            Motif de sortie :{" "}
            <strong className="text-primary">
              {constants.RDV_EXIT_REASONS.find((ex) => ex.id == results.exitReason).name}
            </strong>
            {results.exitSocialResults && results.exitSocialResults.length > 0 && (
              <div className="pl-2">
                <div className="badge badge-light">Social</div>
                <ul className="text-primary font-weight-bold">
                  {results.exitSocialResults.map((result) => (
                    <li key={`socialres${result.id}`}>
                      {constants.RDV_EXIT_SOCIAL_RESULTS.find((r) => r.id == result).name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {results.exitProResults && results.exitProResults.length > 0 && (
              <div className="pl-2">
                <div className="badge badge-light">Socio-professionnel</div>
                <ul className="text-primary font-weight-bold">
                  {results.exitProResults.map((result) => (
                    <li key={`prores${result.id}`}>
                      {constants.RDV_EXIT_PRO_RESULTS.find((r) => r.id == result).name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {results.newOrientation && (
              <div>
                Réorienté vers : <strong className="text-primary">{results.newOrientation}</strong>
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div
      className=""
      style={
        {
          //borderTop: "1px solid #d8d8d8",
        }
      }
    >
      <div className="pb-3 px-3 pt-1">
        <div
          className="font-weight-bold text-success d-flex flex-column my-2"
          style={{ fontSize: 15 }}
        >
          <span>
            <i className="fa fa-arrow-down mr-2" />
            Rendez-vous terminé
          </span>
        </div>
        <div className="">
          Statut :{" "}
          <b className="text-primary">
            {constants.RDV_CLOSE_TYPES.find((ct) => ct.id == results.status).name}
          </b>
        </div>

        {getRdvResult()}
      </div>
    </div>
  );
};

export default RecipientRdvResult;
