import React, { useEffect, useState } from "react";
import Control from "../../../common/Control";
import SaveBtn from "../../../common/SaveBtn";
import useCERData from "../../../Hooks/useCERData/useCERData";

const AddObstacleForm = () => {
  const { addCerObstacle } = useCERData();
  const [newObstacle, setNewObstacle] = useState({
    name: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleChangeObstacle = (e) => {
    const { name, value } = e.target;
    setNewObstacle({ ...newObstacle, [name]: value });
  };

  const save = () => {
    setIsSaving(true);
    addCerObstacle(newObstacle);
    setNewObstacle({ name: "" });
  };

  return (
    <li className="list-group-item d-flex align-items-center justify-content-start">
      <Control
        className="w-100"
        label="Ajouter un frein"
        type="text"
        name="name"
        change={handleChangeObstacle}
        value={newObstacle.name}
        suffix={
          newObstacle.name.length > 0 && <SaveBtn save={save} isSaving={false} margin="d-center" />
        }
      />
    </li>
  );
};

export default AddObstacleForm;
