import React, { useState, useEffect } from "react";
import Control from "../common/Control";
import { modal } from "bootstrap";
import $ from "jquery";

const OptionModal = ({
  k,
  optionModalOpen,
  changeOption,
  setChangeOption,
  cancelDrop,
  confirmDrop,
  isDelete,
}) => {
  useEffect(() => {
    $("#optionModal1").modal(optionModalOpen ? "show" : "hide");
  }, [optionModalOpen]);

  return (
    <div
      className="modal fade"
      id={"optionModal" + k}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="optionModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="optionModalLabel"></h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              L’événement que vous souhaitez{" "}
              {isDelete ? "supprimer" : "modifier"} est récurrent.
            </p>
            <p>
              Souhaitez-vous {isDelete ? "supprimer" : "déplacer"} uniquement
              cette occurrence ou bien
              {isDelete ? "supprimer" : "déplacer"} toutes les occurrences
              futures ?
            </p>
            <Control
              type="btnList"
              name={"changeOption"}
              value={changeOption}
              change={(e) => setChangeOption(e.target.value)}
              datas={[
                { id: 1, name: "Uniquement cet évènement" },
                {
                  id: 2,
                  name: "Cet évènement et toutes les occurences futures",
                },
              ]}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={confirmDrop}
            >
              Valider
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={cancelDrop}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionModal;
