import React, { useEffect } from "react";
import Questions from "./Questions";
import Progress from "./Progress";
import FormInfo from "./FormInfo";
import tools from "../../../helpers/tools";

const FormGenerator = ({
  form,
  answers,
  setAnswers,
  step,
  nextStep,
  previousStep,
  isLoading,
  errors,
  specialActions,
  setStep,
}) => {
  useEffect(() => {
    var ans = tools.buildAnswersObject(form);
    setAnswers(ans);
  }, []);

  const updateAnswer = (e) => {
    var ans = JSON.parse(JSON.stringify(answers));
    var { name, value, type, checked, formArray } = e.target;

    if (type == "checkbox") {
      value = checked;
    }
    if (formArray) {
      if (type == "checkBtnList") {
        if (ans[name].includes(value)) {
          ans[name] = ans[name].filter((v) => v != value);
        } else {
          ans[name].push(value);
        }
      } else {
        if (formArray.target.checked) {
          ans[name].push(value);
        } else {
          ans[name] = ans[name].filter((v) => v != value);
        }
      }
    } else {
      ans[name] = value;
    }

    setAnswers(ans);
  };

  return (
    <>
      <div style={{ maxWidth: 900 }} className="m-auto">
        <FormInfo
          text={form[step].text}
          isLoading={isLoading}
          answers={answers}
        />
      </div>
      <div className="form-generator">
        {answers && !isLoading && (
          <div className="animated fadeIn fast">
            <Questions
              form={form}
              answers={answers}
              updateAnswer={updateAnswer}
              step={step}
              nextStep={nextStep}
              previousStep={previousStep}
              errors={errors}
              specialActions={specialActions}
              setStep={setStep}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FormGenerator;
