import React from "react";
import Control from "./Control";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

const DynamicList = ({
  listLabel,
  uniqueKey,
  name,
  fields,
  items,
  btnLabel,
  change,
  minItems = 0,
  error = {},
  disabled = false,
  fieldContainerClassName = false,
}) => {
  function addItem() {
    if (disabled) {
      return false;
    }
    var newItem = {};
    fields.forEach((f) => {
      if (f.type == "checkBtnList") {
        newItem[f.name] = [];
      } else {
        newItem[f.name] = "";
      }
      if (f.staticValue) {
        newItem[f.name] = f.staticValue;
      }
    });
    items.push(newItem);
    change({ target: { name: name, value: items } });
  }

  function changeItem(e, k) {
    if (disabled) {
      return false;
    }
    if (e.target.type == "checkBtnList") {
      if (items[k][e.target.name].includes(e.target.value)) {
        items[k][e.target.name] = items[k][e.target.name].filter((c) => c != e.target.value);
      } else {
        items[k][e.target.name].push(e.target.value);
      }
    } else {
      items[k][e.target.name] = e.target.value;
    }
    change({ target: { name: name, value: items } });
  }
  function removeItem(kk) {
    if (items.length == minItems) {
      toast.error("Cette liste doit contenir au moins 1 entrée");
      return false;
    }
    items = items.filter((i, k) => k !== kk);
    change({ target: { name: name, value: items } });
  }

  function filterDatas(fn, fnBy, k) {
    return fn(items[k][fnBy]);
  }

  return (
    <div className="form-group">
      <label className="d-flex align-items-center">{listLabel}</label>
      <ul className="list-group list-unstyled">
        {items.map((it, k) => (
          <li key={"dyn" + name + uniqueKey + "_" + k}>
            <div className={fieldContainerClassName ? fieldContainerClassName : "form-inline"}>
              {fields.map((fi, kk) => (
                <Control
                  labelStyle={fi.labelStyle ? fi.labelStyle : { color: "#a7a7a7", marginRight: 5 }}
                  className={`${fi.className} mx-1`}
                  margin="my-0"
                  k={k}
                  key={"dynlistinp" + kk}
                  label={fi.label}
                  name={fi.name}
                  type={fi.type}
                  value={
                    fi.type == "date"
                      ? DateTime.fromISO(it[fi.name]).toFormat("yyyy-MM-dd")
                      : it[fi.name]
                  }
                  inputStyle={fi.inputStyle || {}}
                  placeholder={fi.placeholder ? fi.placeholder : ""}
                  change={changeItem}
                  suffix={fi.suffix ? fi.suffix : null}
                  inputClassName=""
                  datas={fi.filterFn ? filterDatas(fi.filterFn, fi.filteredBy, k) : fi.datas}
                  btnInline={fi.btnInline}
                  disabled={disabled || fi.disabled}
                />
              ))}
              {!disabled && (
                <button className="btn btn-default btn-sm" onClick={() => removeItem(k)}>
                  <i className="fa fa-times text-danger" />
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
      {btnLabel && (
        <div className="d-flex align-items-center justify-content-start ">
          <button disabled={disabled} className="btn btn-default btn-sm " onClick={addItem}>
            <i className="fa fa-plus mr-2" />
            {btnLabel}
          </button>
        </div>
      )}
      <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
        {error[name]}
      </small>
    </div>
  );
};

export default DynamicList;
