import { DateTime } from "luxon";
import React, { useState } from "react";
import useStore from "../../../../context/useStore";
import downloadTools from "../../../../helpers/useDownload";
import SaveBtn from "../../../common/SaveBtn";

const DownloadARDoc = ({ elem, docData, epBeginAt = null }) => {
  const [state] = useStore();
  const { SENDING_TYPES, SENDING_STATUS, EP_PARAMS } = state.constants.items;
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadElem = async () => {
    setIsDownloading(true);
    await downloadTools
      .downloadFile(
        `sending/generate`,
        {
          entityId: docData.entity,
          type: docData.type,
        },
        `doc.docx`,
        true
      )
      .then((res) => setIsDownloading(false));
  };

  let sendArDate = epBeginAt
    ? DateTime.fromISO(epBeginAt)
        .minus({ days: EP_PARAMS.find((epp) => epp.id == "SEND_AR").value })
        .toFormat("dd/MM/yyyy")
    : null;

  return (
    <div className="d-flex flex-column justify-content-around align-items-start">
      <SaveBtn
        data-priv="download_ar_mail"
        save={downloadElem}
        text={
          <>
            <i className="fa fa-download mr-1" />
            {SENDING_TYPES.find((st) => st.id == docData.type)?.name || ""}
          </>
        }
        isSaving={isDownloading}
        margin={"mt-0"}
      />

      {docData.historics.length ? (
        <ol className="mt-2 pl-0">
          {docData.historics?.map((histo, hk) => (
            <li key={`hist${elem.id}hist${hk}`}>
              {histo.data || (
                <>
                  <small>
                    <strong className="text-info">
                      {SENDING_STATUS.find((ss) => ss.id == histo.status).name}
                    </strong>{" "}
                    - {DateTime.fromISO(histo.updatedAt).toFormat("dd/MM/yyyy")}
                  </small>
                </>
              )}
            </li>
          )) || ""}
        </ol>
      ) : sendArDate ? (
        <div>
          <small>
            <strong>Envoi prévu : </strong>
            {sendArDate}
          </small>
        </div>
      ) : null}
    </div>
  );
};

export default DownloadARDoc;
