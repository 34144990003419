import React, { useState, useEffect } from "react";
import Page from "../../../common/layout/Page";
import FormGenerator from "../../../common/FormGeneratorRdsp/FormGenerator";
import { api_url } from "../../../../config";
import ProgressSection from "./ProgressSection";

const FormRDSP = (props) => {
  const [isDspFinished, setIsDspFinished] = useState(false);
  const [isAccountFinished, setIsAccountFinished] = useState(false);
  const [isRdvFinished, setIsRdvFinished] = useState(false);

  const [userCreated, setUserCreated] = useState(false);
  const [formData, setFormData] = useState(false);
  const [userData, setUserData] = useState(false);

  const [showRedirectMsg, setShowRedirectMsg] = useState(false);

  function reset() {
    setIsDspFinished(false);
    setIsAccountFinished(false);
    setIsRdvFinished(false);
    //tools.saveState("FormRDSP", "answers", false);
    //tools.saveState("FormRDSP", "step", 0);
  }

  useEffect(() => {}, [userData]);

  return (
    <>
      {!isDspFinished ? (
        <Page
          title="Recueil des données socio-professionnelles"
          containerClassname=""
        >
          <FormGenerator
            // formDataUrl={api_url + "rdsp/"}
            isDspFinished={isDspFinished}
            setIsDspFinished={setIsDspFinished}
            setFormData={setFormData}
            withContinue={true}
            specialCaseRDSP={true}
            additionalDataToSave={{ user: userData.id }}
          />
        </Page>
      ) : (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center font-weight-bolder text-primary">
          Terminé
        </div>
      )}
    </>
  );
};

export default FormRDSP;
