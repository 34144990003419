import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import DynamicList from "../../common/DynamicList";
import SaveBtn from "../../common/SaveBtn";

const AddRecipientToEp = ({ recipient, refresh = () => {} }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const {
    EP_TYPES,
    EP_REASONS_1,
    EP_REASONS_2,
    EP_SANCTION_REASONS_1,
    EP_SANCTION_REASONS_2,
    EP_ADDITIONAL_REASONS,
  } = state.constants.items;
  const [errors, setErrors] = useState({});
  const [avis, setAvis] = useState({
    type: "",
    crossInterviewType: "",
    additionalReasons: [],
    reasons: [],
    organizationOrigin: "",
  });

  const [myOrganizations, setMyOrganizations] = useState([]);

  useEffect(() => {
    dataService.get(`organizations/mine`, (datas) => {
      setMyOrganizations(datas);
      if (datas.length == 1) {
        setAvis({ ...avis, organizationOrigin: datas[0].id });
      }
    });
  }, []);

  useEffect(() => {
    setAvis({ ...avis, reasons: [] });
  }, [avis.type]);

  const save = () => {
    setIsSaving(true);
    dataService.post(
      `eps/recipients/add`,
      {
        userRecipient: recipient.id,
        type: avis.type,
        reasons: avis.reasons,
        organizationOrigin: avis.organizationOrigin,
        additionalReasons: avis.additionalReasons,
      },
      (datas) => {
        toast.success(
          `L'allocataire a bien été inscrit à l'EP ${DateTime.fromISO(datas.EP.beginAt).toFormat(
            "dd/MM/yyyy"
          )}`
        );
        refresh();
        mActions.updateModal({
          isOpen: false,
          content: null,
        });
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  return (
    <div className="custom-card">
      <div className="d-flex justify-content-start w-100 flex-column">
        <h4 style={{ fontSize: 16 }}>Inscrire {tools.getFullname(recipient)} à la prochaine EP</h4>
        <Control
          label="Quel est l'organisme de saisine ?"
          value={avis.organizationOrigin}
          type="btnList"
          dataIndex="id"
          dataLabel="name"
          datas={myOrganizations}
          change={(e) => setAvis({ ...avis, organizationOrigin: e.target.value })}
          btnInline
        />
        <Control
          label="Raison de l'inscription"
          name="type"
          type="btnList"
          datas={EP_TYPES.filter(
            (type) => type.id != "REORIENTATION_KO" && type.id != "REORIENTATION_OK"
          )}
          dataIndex="id"
          dataLabel="name"
          value={avis.type}
          change={(e) => setAvis({ ...avis, type: e.target.value })}
          btnInline
        />
        {["SANCTION"].includes(avis.type) && (
          <>
            <DynamicList
              listLabel="Motif"
              btnLabel="Ajouter un motif"
              uniqueKey="recrights-1"
              name="reasons"
              fieldContainerClassName="d-flex flex-row"
              fields={[
                {
                  label: "Motif 1",
                  name: "reason1",
                  type: "btnList",
                  datas: EP_SANCTION_REASONS_1.filter((eps) => !eps.isDisabled),
                  dataIndex: "id",
                  dataLabel: "name",
                  className: "align-items-start flex-fill w-50",
                },
                {
                  label: "Motif 2",
                  name: "reasons2",
                  type: "checkBtnList",
                  btnInline: true,
                  datas: EP_SANCTION_REASONS_2,
                  dataIndex: "id",
                  dataLabel: "name",
                  className: "align-items-start flex-fill w-50",
                },
              ]}
              items={avis.reasons}
              change={(e) => setAvis({ ...avis, reasons: e.target.value })}
              error={{}}
            />
          </>
        )}
        {!["SANCTION", ""].includes(avis.type) && (
          <>
            <DynamicList
              listLabel="Motif"
              btnLabel="Ajouter un motif"
              uniqueKey="recrights-1"
              name="reasons"
              fieldContainerClassName="d-flex flex-row"
              fields={[
                {
                  label: "Motif 1",
                  name: "reason1",
                  type: "btnList",
                  datas: EP_REASONS_1,
                  dataIndex: "id",
                  dataLabel: "name",
                  className: "align-items-start flex-fill w-50",
                },
                {
                  label: "Motif 2",
                  name: "reasons2",
                  type: "checkBtnList",
                  btnInline: true,
                  datas: EP_REASONS_2.filter((eps) => !eps.isDisabled),
                  dataIndex: "id",
                  dataLabel: "name",
                  filteredBy: "reason1",
                  filterFn: (val) => {
                    return EP_REASONS_2.filter((r) => !r.isDisabled && r.reason1 == val);
                  },
                  className: "align-items-start flex-fill w-50",
                },
              ]}
              items={avis.reasons}
              change={(e) => setAvis({ ...avis, reasons: e.target.value })}
              error={{}}
            />
          </>
        )}
        <Control
          label="Motif complémentaire de saisine (optionnels)"
          name="additionalReasons"
          type="checkBtnList"
          datas={EP_ADDITIONAL_REASONS.filter((adr) => !adr.isDisabled)}
          dataIndex="id"
          dataLabel="name"
          value={avis.additionalReasons}
          btnSm
          change={(e) =>
            setAvis({
              ...avis,
              additionalReasons: avis.additionalReasons.includes(e.target.value)
                ? [...avis.additionalReasons.filter((ad) => ad != e.target.value)]
                : [...avis.additionalReasons, e.target.value],
            })
          }
        />
        <div className="d-flex justify-content-end">
          {Object.values(errors).map((err) => (
            <span className="badge badge-danger d-center mt-5 mr-2 rounded" key={`error${err}`}>
              {err}
            </span>
          ))}
          <SaveBtn
            save={save}
            isSaving={isSaving}
            text={`Inscrire ${tools.getFullname(recipient)} à la prochaine EP`}
          />
        </div>
      </div>
    </div>
  );
};

export default AddRecipientToEp;
