import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import tools from "../../../helpers/tools";
import useRecipientData from "../../Hooks/useRecipientData/useRecipientData";

const Label = ({ children }) => {
  var styles = StyleSheet.create({
    label: {
      fontSize: 9,
    },
  });
  return <Text style={{ ...styles.label }}>{children}</Text>;
};

const RecipientCERInfo = () => {
  const { recipient } = useRecipientData();
  var styles = StyleSheet.create({
    colTitle: {
      fontSize: 11,
      textDecoration: "underline",
      paddingBottom: 2,
    },
    label: {
      fontSize: 9,
      fontWeight: "bold",
    },
    value: {
      fontSize: 9,
    },
    twoCol: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "flex-start",
      paddingTop: 50,
      paddingBottom: 10,
    },
    col: {
      width: "49%",
    },
  });

  return (
    <View
      style={{
        ...styles.twoCol,
      }}
    >
      <View style={{ ...styles.col }}>
        <Text style={{ ...styles.colTitle }}>Allocataire</Text>
        <View>
          <Label>
            Nom : {tools.getGender(recipient.gender)} {recipient.lastname}
          </Label>
          <Label>Prénom : {recipient.firstname}</Label>
          <Label>N°AF : {recipient.CAFId}</Label>
          <Label>Tél : {tools.formatPhone(recipient.phone)}</Label>
          <Label>Email : {recipient.email}</Label>
        </View>
      </View>
      <View style={{ ...styles.col }}>
        <Text style={{ ...styles.colTitle }}>Référent Unique CAF</Text>
        <View>
          <Label>Nom : {recipient.userManagerDisplayed?.lastname}</Label>
          <Label>Prénom : {recipient.userManagerDisplayed?.firstname}</Label>
          <Label>Tél : {tools.formatPhone(recipient.userManagerDisplayed?.phone)}</Label>
          <Label>Email : {recipient.userManagerDisplayed?.email}</Label>
        </View>
      </View>
    </View>
  );
};

export default RecipientCERInfo;
