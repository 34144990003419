import { DateTime } from "luxon";
import React, { useState } from "react";
import colors from "../../../helpers/colors";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";

const EgBlock = ({ actions, isLoadingAction }) => {
  const [day, setDay] = useState(
    DateTime.local().minus({ day: 1 }).startOf("day").toFormat("yyyy-MM-dd")
  );
  const [dayEnd, setDayEnd] = useState(
    DateTime.local().minus({ day: 1 }).endOf("day").toFormat("yyyy-MM-dd")
  );

  return (
    <>
      <h5 className="mt-4 mb-2">Allocataires EG/site/commune</h5>
      <div className="row">
        <div className="col-12 col-md-6">
          <Control
            type="date"
            label="Entre le"
            value={day}
            change={(e) => setDay(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            type="date"
            label="Et le"
            value={dayEnd}
            change={(e) => setDayEnd(e.target.value)}
          />
        </div>
      </div>
      <ul className="list-group">
        <li
          className="list-group-item d-flex align-items-center justify-content-between"
          key={"partep"}
        >
          Allocataires EG
          <div className="d-center flex-column">
            <SaveBtn
              color={colors.primary}
              type="btn-default flex-fill w-100 text-center justify-content-center d-center"
              margin=" mb-2"
              save={() => actions.export.eg.download(day, dayEnd)}
              isSaving={isLoadingAction.includes("egd")}
              text={
                <div className="d-center">
                  <i className="fa fa-download mr-2" />
                  Télécharger
                </div>
              }
            />
            <SaveBtn
              type="btn-primary flex-fill w-100 text-center justify-content-center d-center"
              margin=""
              save={() => actions.export.eg.export(day, dayEnd)}
              isSaving={isLoadingAction.includes("ege")}
              text={
                <div className="d-center">
                  <i className="fa fa-file-export mr-2" />
                  Exporter vers FAST
                </div>
              }
            />
          </div>
        </li>
      </ul>
    </>
  );
};

export default EgBlock;
