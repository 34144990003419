import React, { useEffect, useState } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Control";
import useCERData from "../../../../Hooks/useCERData/useCERData";

const ModifyStep = () => {
  const { cerSteps, modifyCerStep, modifiedStepId, setModifiedStepId } = useCERData();

  const [updatedStep, setUpdatedStep] = useState({});

  useEffect(() => {
    const stepRef = cerSteps.find((o) => o.id == modifiedStepId);
    setUpdatedStep(stepRef);
  }, [modifiedStepId]);

  const handleUpdateStep = (e) => {
    const { name, value } = e.target;
    setUpdatedStep({
      ...updatedStep,
      [name]: value,
    });
  };

  const handleClickSaveStep = () => {
    modifyCerStep(updatedStep);
    setModifiedStepId(null);
  };

  return (
    <div>
      <div>
        <strong>Modifier la démarche</strong>
      </div>
      <Control
        label=""
        name="name"
        type="text"
        change={handleUpdateStep}
        value={tools.capitalize(updatedStep.name)}
      />
      <div className="d-flex align-items-center justify-content-between px-2">
        <div className="alert alert-info">
          <i className="fa fa-exclamation-circle mr-2" />
          La démarche sera modifiée dans tous les contrats CER
        </div>
        <div>
          <button className="btn btn-link" onClick={() => setModifiedStepId(null)}>
            Annuler
          </button>
          <button className="btn btn-primary" onClick={handleClickSaveStep}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModifyStep;
