import React, { useContext, useState } from "react";
import tools from "../../../../../../../helpers/tools";
import useCERModule from "../../../../CERModule/hooks/useCERModule";
import ObjectiveSelect from "../../AddObjectiveForm/ObjectiveSelect";
import { ObjectiveContext } from "../ObjectiveContext";

const ObjectiveEdit = ({ goToViewMode }) => {
  const objective = useContext(ObjectiveContext);
  const { events, recipientCER } = useCERModule();

  const [newObjective, setNewObjective] = useState({ ...objective });

  const handleNewObjectiveChange = (e) => {
    const { name, value } = e.target;
    setNewObjective({ ...newObjective, [name]: value });
  };

  const onValidateObjective = () => {
    var index = tools.getObjectiveIndex(recipientCER.goals, objective);

    events.onUpdateObjective(newObjective, index);
    goToViewMode();
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <ObjectiveSelect
        objective={newObjective}
        handleNewObjectiveChange={handleNewObjectiveChange}
      />

      <button
        className="btn btn-outline-info border-info btn-sm border ml-2"
        disabled={!newObjective.goal}
        onClick={onValidateObjective}
      >
        <i className="fa fa-check" />
      </button>
    </div>
  );
};

export default ObjectiveEdit;
