import React from "react";
import { ClipLoader } from "react-spinners";

const SaveBtn = ({
  save,
  isSaving,
  isSaved,
  text = "Enregistrer",
  margin = "mt-5",
  className = "",
  style = {},
  type = "btn-success",
  color = "#ffffff",
  disabled = false,
  typeBtn = "button",
  ...props
}) => {
  return (
    <button
      className={"d-flex btn  " + margin + " " + className + " " + type}
      onClick={save}
      style={style}
      disabled={disabled || isSaving}
      type={typeBtn}
      {...props}
    >
      {isSaving && <ClipLoader size={18} color={color} />}
      {isSaved && !isSaving && <i className="fa fa-check" />}
      {!isSaving && !isSaved && text}
    </button>
  );
};

export default SaveBtn;
