import React, { useEffect } from "react";
import tools from "../../../helpers/tools";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import useSectors from "../../Hooks/useSectors/useSectors";
import useRecipientStock from "../RecipientStockList/useRecipientStock";
import RecipientStockActions from "./RecipientStockActions";
import RecipientStockInfo from "./RecipientStockInfo";

const RecipientStockDetail = (props) => {
  const { onGetRecipientStockById, currentRecipientStock, isLoadingCurrentRecipientStock } =
    useRecipientStock();
  const { sectors, getSectors } = useSectors();

  useEffect(() => {
    const { id } = props.match.params;
    onGetRecipientStockById(id);
    if (sectors.length == 0) {
      getSectors();
    }
  }, []);

  return isLoadingCurrentRecipientStock || !currentRecipientStock ? (
    <Loader />
  ) : (
    <Page
      bgTransparent
      back={`/recipients-stock`}
      container="container"
      title={`Allocataire stock - ${tools.getFullname(currentRecipientStock)}`}
    >
      <RecipientStockActions />
      <RecipientStockInfo />
    </Page>
  );
};

export default RecipientStockDetail;
