import { DateTime } from "luxon";
import React, { useState } from "react";
import colors from "../../../helpers/colors";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";

const EpBlock = ({ actions, isLoadingAction }) => {
  return (
    <>
      <h5 className="mt-4 mb-2">Allocataires EP en attente SID/NIMS</h5>

      <ul className="list-group">
        <li
          className="list-group-item d-flex align-items-center justify-content-between"
          key={"partep"}
        >
          Allocataires EP
          <div className="d-center flex-column">
            <SaveBtn
              color={colors.primary}
              type="btn-default flex-fill w-100 text-center justify-content-center d-center"
              margin=" mb-2"
              save={() => actions.export.ep.download("epd")}
              isSaving={isLoadingAction.includes("epd")}
              text={
                <div className="d-center">
                  <i className="fa fa-download mr-2" />
                  Télécharger
                </div>
              }
            />
            <SaveBtn
              type="btn-primary flex-fill w-100 text-center justify-content-center d-center"
              margin=""
              save={() => actions.export.ep.export("epe")}
              isSaving={isLoadingAction.includes("epe")}
              text={
                <div className="d-center">
                  <i className="fa fa-file-export mr-2" />
                  Exporter vers FAST
                </div>
              }
            />
          </div>
        </li>
      </ul>
    </>
  );
};

export default EpBlock;
