import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";

var strTimeout = null;

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  var searchState = tools.getState("OrganizationList", null);

  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          organization: { isCAF: false },
          page: 1,
          nbItemsByPage: 25,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);

  useEffect(() => {
    //var lsState = tools.getState("OrganizationList", { search: search });
    setSearch(search);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      tools.saveState("OrganizationList", "search", JSON.stringify(search));

      searchData();
    }, 1500);
    //tools.saveState("OrganizationList", "search", search);
  }, [search]);

  function searchData() {
    setErrors(false);
    setIsLoading(true);
    Axios.post(api_url + "organizations/search", search)
      .then((res) => {
        setOrganizations(res.data.data);
        setNbPages(Math.ceil(parseInt(res.data.count) / search.nbItemsByPage));
        setIsLoading(false);
        setNbResults(res.data.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        tools.filterError(err);
        setOrganizations([]);
      });
  }
  function updateSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        su.organization[e.target.name] = true;
      } else {
        delete su.organization[e.target.name];
      }
    } else {
      if (value == "") {
        delete su.organization[e.target.name];
      } else {
        su.organization[e.target.name] = value;
      }
    }

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function changeNbItemsByPage(i) {
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
  }

  function deleteOrganization(id) {
    if (window.confirm("Confirmez-vous la suppression de cette organisation ?")) {
      setIsLoading(true);
      Axios.delete(api_url + "organizations/" + id)
        .then((res) => {
          searchData();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
            tools.filterError(err);
          }
        });
    }
  }

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        action={{ to: "/organizations/create", text: "Créer une organisation" }}
        title={"Liste des organisations"}
      >
        <div className="row mb-4">
          {state.constants.items.length == 0 ? (
            <Loader />
          ) : (
            <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center">
              <Control
                margin={"my-0"}
                label="Afficher la caf"
                type="checkbox"
                name="isCAF"
                checked={search.organization.isCAF}
                change={updateSearch}
              />
            </div>
          )}
          <div className="col-12 col-md-6 col-lg-4 justify-content-center">
            <Control
              margin={"my-0"}
              label="Recherche"
              name="searchString"
              value={search.organization.searchString}
              change={updateSearch}
              suffix={<i className="fa fa-search ml-2 " style={{ lineHeight: "34px" }}></i>}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 align-items-center justify-content-center d-flex">
            {nbResults} organisations trouvées
          </div>
        </div>
      </Page>
      <div className="row">
        <div className="col-12 p-4">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Table
                datas={organizations}
                fields={[
                  {
                    name: "Nom",
                    path: "name",
                  },
                  {
                    name: "Code",
                    path: "slugName",
                  },
                  {
                    name: "Partenaire orientation ?",
                    path: "isOrientationPartner",
                    render: (elem) => (elem ? "Oui" : "Non"),
                  },
                  {
                    name: "Ne s'occupe que des EG",
                    path: "isOnlyEG",
                    render: (elem) => (elem ? "Oui" : "Non"),
                  },
                  {
                    name: "Modifier",
                    path: "id",
                    render: (elem) => (
                      <Link
                        className="btn btn-primary btn-sm d-flex align-items-center justify-content-center"
                        to={"/organizations/" + elem}
                      >
                        <i className="fa fa-edit"></i>
                      </Link>
                    ),
                  },
                  {
                    name: "Supprimer",
                    path: "id",
                    render: (elem) => (
                      <button
                        className="btn btn-danger btn-sm d-flex align-items-center justify-content-center"
                        onClick={() => deleteOrganization(elem)}
                      >
                        <i className="fa fa-times" />
                      </button>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
            nbItemsByPage={search.nbItemsByPage}
            changeNbItemsByPage={changeNbItemsByPage}
          />
        </div>
      </div>
    </>
  );
};

export default OrganizationList;
