import { atom, useAtom } from "jotai";
import dataService from "../../../helpers/dataService";

const sectorsAtom = atom([]);
const isLoadingSectorsAtom = atom(false);

const useSectors = () => {
  const [sectors, setSectors] = useAtom(sectorsAtom);
  const [isLoadingSectors, setIsLoadingSectors] = useAtom(isLoadingSectorsAtom);

  const getSectors = () => {
    setIsLoadingSectors(true);
    dataService.get(
      `sectors`,
      setSectors,
      (err) => {},
      () => setIsLoadingSectors(false)
    );
  };

  return {
    sectors,
    isLoadingSectors,
    getSectors,
  };
};

export default useSectors;
