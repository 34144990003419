import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { leftPanelActions } from "../../../context/actions/leftPanelActions";
import { sidePanelActions } from "../../../context/actions/sidePanelActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import CancelEp from "./CancelEp";
import EPEditDetail from "./EPEditDetail";
import EpItem from "./EpItem";
import EpsOfTheDay from "./EpsOfTheDay";

const EPCalendar = () => {
  const [state, dispatch] = useStore();
  const spActions = sidePanelActions(state, dispatch);
  const lpActions = leftPanelActions(state, dispatch);

  const [isSavingEp, setIsSavingEp] = useState(false);
  const [isRemovingEp, setIsRemovingEp] = useState(false);
  const [Eps, setEps] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [errors, setErrors] = useState({});
  const epInitialState = {
    beginAt: "",
    endedAt: "",
    closedAt: "",
    beginHourAt: "",
    beginMinuteAt: "0",
    sector: "",
    isBackupSession: false,
    jury: [],
    recipients: [],
    address1: "",
    address2: "",
    postalCode: "",
    city: "",
  };
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    fetchEps();
    fetchSectors();
  }, []);

  useEffect(() => {
    if (isInit) {
      fetchEps();
    }
  }, [year]);

  const fetchEps = () => {
    dataService.post(
      `eps/search`,
      { year },
      (datas) => {
        setEps(datas);
        setIsInit(true);
      },
      setErrors
    );
  };
  const fetchSectors = () => {
    dataService.get(`sectors`, setSectors, setErrors);
  };

  var months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const openEp = (month, ep) => {
    var myEp = ep;
    var monthIdx = months.findIndex((p) => p == month);
    if (!ep.id) {
      myEp = { ...ep, beginAt: DateTime.local(year, monthIdx + 1, 1).toISO() };
    }

    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <EPEditDetail
          ep={myEp}
          saveEp={saveEp}
          removeEp={removeEp}
          month={month}
          errors={errors}
          setErrors={setErrors}
          sectors={sectors}
          isSavingEp={isSavingEp}
          isRemovingEp={isRemovingEp}
        />
      ),
    });
  };

  const saveEp = (ep) => {
    spActions.updateSidePanel({
      isOpen: false,
      content: null,
    });
    setIsSavingEp(true);

    if (ep.id) {
      dataService.patch(
        `eps/${ep.id}`,
        ep,
        (datas) => fetchEps(),
        setErrors,
        () => setIsSavingEp(false)
      );
    } else {
      dataService.post(
        `eps`,
        ep,
        (datas) => fetchEps(),
        setErrors,
        () => setIsSavingEp(false)
      );
    }
  };

  const removeEp = (ep) => {
    lpActions.updateLeftPanel({
      isOpen: true,
      content: <CancelEp ep={ep} sectors={sectors} />,
    });
  };

  var years = [];
  for (var i = new Date().getFullYear() + 1; i >= 2021; i--) {
    years.push(i);
  }

  return (
    <>
      <div className="custom-card p-2">
        <Control
          label="Année"
          type="btnList"
          btnInline
          value={year}
          datas={years
            .sort((a, b) => (a > b ? 1 : -1))
            .map((y) => ({ id: y, name: y }))}
          change={(e) => setYear(e.target.value)}
        />
      </div>
      {!sectors.length ? null : (
        <EpsOfTheDay
          eps={Eps.filter((ep) => {
            var mon = DateTime.fromISO(ep.beginAt).toFormat("M");
            var day = DateTime.fromISO(ep.beginAt).toFormat("d");

            return (
              mon == DateTime.local().toFormat("M") &&
              day == DateTime.local().toFormat("d")
            );
          }).sort((a, b) => (a.beginAt < b.beginAt ? -1 : 1))}
          sectors={sectors}
          openEp={openEp}
        />
      )}

      <h4 className="mb-3 pt-4" style={{ fontSize: 14 }}>
        EP de toute l'année
      </h4>
      <div
        className="d-flex flex-row align-items-start justify-content-start custom-card p-0 py-2"
        style={{ minHeight: 800, height: 800 }}
      >
        {months.map((month, mk) => (
          <div
            className={`d-flex flex-fill ${
              mk > 0 ? "border-left" : ""
            } justify-content-start flex-column h-100 align-items-center px-2`}
            key={`month${month}`}
          >
            <strong className={`mb-2 text-primary `}>
              <i
                className={`fa fa-caret-right mr-2 text-dark animated ${
                  mk == parseInt(DateTime.local().month) - 1
                    ? "fadeInLeft infinite"
                    : "d-none"
                }`}
              />
              {month}
            </strong>

            {!sectors.length
              ? null
              : Eps.filter((ep) => {
                  var mon = DateTime.fromISO(ep.beginAt).toFormat("M");

                  return mk + 1 == mon;
                })
                  .sort((a, b) => (a.beginAt < b.beginAt ? -1 : 1))
                  .map((ep, epk) => (
                    <EpItem
                      key={`eplist${ep.id}`}
                      ep={ep}
                      sectors={sectors}
                      openEp={() => openEp(month, ep)}
                    />
                  ))}
            <button
              className="btn btn-sm btn-link d-center flex-wrap w-100"
              data-priv="edit_ep"
              onClick={() => openEp(month, epInitialState)}
            >
              <i style={{ fontSize: 8 }} className="fa fa-plus mr-1" />
              ajouter
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default EPCalendar;
