import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { api_url } from "../../../config";
import Calendar from "../../Calendar/Calendar";
import colors from "../../../helpers/colors";
import CalendarFilters from "./CalendarFilters";
import CalendarStats from "./CalendarStats";
import roleConfig from "../../common/layout/roleConfig";

const CalendarDetail = (props) => {
  const [state, dispatch] = useStore();
  const [canModify, setCanModify] = useState(
    !roleConfig[state.auth.user.role].includes("crud_event_calendar")
  );
  const [events, setEvents] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [site, setSite] = useState(false);
  const [search, setSearch] = useState({
    site: props.match.params.id,
    start: DateTime.local().startOf("week").toISO(),
    end: DateTime.local().endOf("week").toISO(),
  });
  const [filters, setFilters] = useState({
    boxes: [],
    isReserved: "",
    types: [],
  });
  const [boxes, setBoxes] = useState([]);
  const [rdvTypes, setRdvTypes] = useState([]);
  useEffect(() => {
    getSite();
  }, []);

  useEffect(() => {
    if (state.constants.items.SLOT_TYPES) {
      getCalendar();
    }
  }, [state.constants.items]);

  useEffect(() => {
    if (state.constants.items.SLOT_TYPES) getCalendar();
  }, [search]);

  function getSite() {
    setIsLoading(true);
    Axios.get(api_url + "sites/" + props.match.params.id)
      .then((res) => {
        setSite(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  function getCalendar() {
    setIsLoading(true);
    setEvents([]);
    setErrors({});
    Axios.post(api_url + "slotcalendars/search", {
      slotCalendar: search,
    })
      .then((res) => {
        res.data.forEach((d) => {
          d.backgroundColor = colors[state.constants.items.SLOT_TYPES.find((s) => s.id == d.type)];
        });
        setEvents(res.data);
      })
      .catch((err) => {
        setErrors(err.response.data);
      })
      .then(() => {
        setIsLoading(false);
      });
  }
  function postEvent(event) {
    setIsLoading(true);
    Axios.post(api_url + "slotcalendars", event)
      .then((res) => {
        getCalendar();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }
  function patchEvent(event) {
    setIsLoading(true);
    Axios.patch(api_url + "slotcalendars/" + event.id, event)
      .then((res) => {
        getCalendar();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }
  function deleteEvent(id, rec) {
    if (window.confirm("Confirmez-vous cette suppression ?")) {
      setIsLoading(true);
      Axios.delete(api_url + "slotcalendars/" + id + "/" + rec)
        .then((res) => {
          getCalendar();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    if (site && events.length > 0 && state.constants.items) {
      var fil = JSON.parse(JSON.stringify(filters));
      fil.boxes = site.boxes.map((b) => b._id);

      fil.types = state.constants.items.SLOT_TYPES.map((s) => s.id);
      setBoxes(site.boxes);

      setRdvTypes(state.constants.items.SLOT_TYPES);
      setFilters(fil);
    }
  }, [events, site]);

  var filteredEvents = [];
  filteredEvents = events.filter((ev) => {
    if (filters.boxes.includes(ev.box)) {
      if (filters.types.includes(ev.type)) {
        if (filters.isReserved == "0" && !ev.userRecipient) {
          return ev;
        }
        if (filters.isReserved == "1" && ev.userRecipient) {
          return ev;
        }
        if (filters.isReserved == "") {
          return ev;
        }
      }
    }
  });

  return (
    <Page
      style={{ position: "relative" }}
      container={"container-fluid px-5"}
      title={"Détail calendrier : " + (site ? site.name : "...")}
      errors={errors}
      back={"/sites"}
    >
      {(state.constants.items.length == 0 || isLoading) && <Loader overlay={true} />}
      <CalendarFilters
        filters={filters}
        setFilters={setFilters}
        boxes={boxes}
        rdvTypes={rdvTypes}
      />
      <CalendarStats events={filteredEvents} />
      <Calendar
        events={filteredEvents}
        setSearch={setSearch}
        search={search}
        site={site}
        postEvent={postEvent}
        patchEvent={patchEvent}
        deleteEvent={deleteEvent}
        errors={errors}
        canModify={canModify}
      />
    </Page>
  );
};

export default CalendarDetail;
