import React, { useContext } from "react";
import tools from "../../../../../../../../../../../helpers/tools";
import useCERData from "../../../../../../../../../../Hooks/useCERData/useCERData";
import { ObjectiveContext } from "../../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import { OperatorContext } from "../../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/OperatorList/OperatorContext";
import { StepContext } from "../../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/StepContext";
import useAmendmentModule from "../../../../../../../hooks/useAmendmentModule";

const IconBlock = ({ icon, children }) => {
  return (
    <div className="text-black-50">
      <i className={`fa fa-${icon} pr-1`} />
      {children}
    </div>
  );
};

const AmendmentOperatorView = () => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const operator = useContext(OperatorContext);
  const { operators } = useCERData();
  const { currentAmendment, events, isCurrentAmendmentDraft } = useAmendmentModule();
  const operatorRef = operators.find((o) => o.id == operator.operator);
  var objectiveIndex = tools.getObjectiveIndex(currentAmendment.goals, objective);
  var stepIndex = tools.getStepIndex(objective.steps, step);
  var operatorIndex = tools.getOperatorIndex(step.operators, operator);

  const deleteOperator = () => {
    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((s, sk) => {
          let newOperators = [...s.operators];
          if (sk == stepIndex) {
            if (newOperators[operatorIndex].updateType == "ADDED") {
              newOperators = newOperators.filter((o, ok) => ok != operatorIndex);
            } else
              newOperators = newOperators.map((o, ok) =>
                ok == operatorIndex ? { ...o, updateType: "DELETED" } : o
              );
          }

          return sk == stepIndex
            ? {
                ...s,
                operators: newOperators,
              }
            : s;
        }),
      ],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
  };
  const cancelDeleteOperator = () => {
    const newObjective = {
      ...objective,
      steps: [
        ...objective.steps.map((s, sk) => {
          let newOperators = [...s.operators];
          if (sk == stepIndex) {
            newOperators = newOperators.map((o, ok) =>
              ok == operatorIndex ? { ...o, updateType: "" } : o
            );
          }

          return sk == stepIndex
            ? {
                ...s,
                operators: newOperators,
              }
            : s;
        }),
      ],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
  };

  const linksClassName = "px-0 bg-transparent";

  const itemNameStyle = tools.getCERElementUpdateTypeStyle(operator.updateType);

  return (
    <div
      className="d-flex align-items-center justify-content-between item-list-view pl-2"
      style={{ fontSize: 12 }}
    >
      <div>
        <strong className="" style={{ ...itemNameStyle }}>
          {operatorRef?.name}
        </strong>
        {operator.updateType != "DELETED" && (
          <div style={{ paddingLeft: 10 }}>
            {operatorRef?.address && (
              <IconBlock icon="compass">
                {operatorRef.address} {operatorRef.location?.postalCode}{" "}
                {operatorRef.location?.name}
              </IconBlock>
            )}
            {operatorRef?.phone && (
              <IconBlock icon="phone">
                <a className={linksClassName} href={`tel:${operatorRef.phone}`}>
                  {tools.formatPhone(operatorRef.phone)}
                </a>
              </IconBlock>
            )}
            {operatorRef?.email && (
              <IconBlock icon="at">
                <a className={linksClassName} href={`mailto:${operatorRef.email}`}>
                  {operatorRef.email}
                </a>
              </IconBlock>
            )}
            {operatorRef?.link && (
              <IconBlock icon="link">
                <a className={linksClassName} target="_blank" href={operatorRef.link}>
                  {operatorRef.link}
                </a>
              </IconBlock>
            )}
          </div>
        )}
      </div>

      {isCurrentAmendmentDraft && (
        <div>
          {operator.updateType == "DELETED" &&
            step.updateType != "DELETED" &&
            objective.updateType != "DELETED" && (
              <button className="btn btn-sm btn-link text-danger" onClick={cancelDeleteOperator}>
                Restaurer l'opérateur
              </button>
            )}
          {operator.updateType != "DELETED" && (
            <button className="btn btn-sm btn-outline-dark" onClick={deleteOperator}>
              <i className="fa fa-times" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AmendmentOperatorView;
