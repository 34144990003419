import React, { useEffect, useState } from "react";
import tools from "../../../../../../helpers/tools";
import Control from "../../../../../common/Control";
import SaveBtn from "../../../../../common/SaveBtn";
import useStoreState from "../../Context/Store/useStoreState";

const SelectModerators = () => {
  const { state, gState, items, items_actions } = useStoreState();
  const { ep, utils } = items;

  const canModify = [ep.writer, ep.president].includes(gState.auth.user.id);

  const [writerPresident, setWriterPresident] = useState({
    writer: ep.writer,
    president: ep.president,
  });

  const saveWriterAndPresident = () => {
    items_actions.saveWriterPresident(writerPresident);
  };

  useEffect(() => {
    setWriterPresident({
      writer: ep.writer,
      president: ep.president,
    });
  }, [ep.writer, ep.president]);

  return !canModify ? null : (
    <div className="custom-card">
      <div className="row">
        <div className="col-12 col-md-4">
          <Control
            label="Rédacteur(trice)"
            name="writer"
            type="select"
            disabled={!canModify}
            datas={ep.jury
              .filter((j) => j.isPresent)
              .map((j) => ({
                name: tools.getFullname(j.userManager),
                ...j.userManager,
              }))}
            dataIndex="id"
            dataLabel="name"
            value={writerPresident.writer}
            change={(e) =>
              setWriterPresident({ ...writerPresident, writer: e.target.value })
            }
          />
        </div>
        <div className="col-12 col-md-4">
          <Control
            label="Président"
            name="writer"
            type="select"
            disabled={!canModify}
            datas={ep.jury
              .filter((j) => j.isPresent)
              .map((j) => ({
                name: tools.getFullname(j.userManager),
                ...j.userManager,
              }))}
            dataIndex="id"
            dataLabel="name"
            value={writerPresident.president}
            change={(e) =>
              setWriterPresident({
                ...writerPresident,
                president: e.target.value,
              })
            }
          />
        </div>
        {canModify && (
          <div className="col-12 col-md-4">
            <div className="pb-2 mt-4 pt-2">
              <SaveBtn
                margin="mt-0"
                text="Valider"
                save={saveWriterAndPresident}
                isSaving={state.isLoadingWriters}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectModerators;
