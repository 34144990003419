import React, { useEffect, useState } from "react";
import useCERData from "../../../../../Hooks/useCERData/useCERData";
import useCERModule from "../../../CERModule/hooks/useCERModule";
import AddEntityForm from "../common/AddEntityForm";
import ObjectiveSelect from "./ObjectiveSelect";

const AddObjectiveForm = ({ pathType }) => {
  const { events } = useCERModule();

  const [newObjective, setNewObjective] = useState({
    goal: "",
    pathType: pathType.id,
    steps: [],
    detail: { status: "UNFINISH" },
  });

  const handleNewObjectiveChange = (e) => {
    const { name, value } = e.target;
    setNewObjective({ ...newObjective, [name]: value });
  };

  const handleAddObjective = () => {
    events.onAddObjective(newObjective);
    setNewObjective({
      goal: "",
      pathType: pathType.id,
      steps: [],
      detail: { status: "UNFINISH" },
    });
  };

  useEffect(() => {
    if (newObjective.goal) {
      handleAddObjective();
    }
  }, [newObjective]);

  return (
    <AddEntityForm onSubmit={handleAddObjective}>
      <div className="mt-2 d-flex">
        <ObjectiveSelect
          handleNewObjectiveChange={handleNewObjectiveChange}
          objective={newObjective}
          k={`addObj`}
        />
        {/* <div className="d-flex justify-content-end">
          <button className="btn btn-sm" type="submit" disabled={!newObjective.goal}>
            <i className="fa fa-plus" />
          </button>
        </div> */}
      </div>
    </AddEntityForm>
  );
};

export default AddObjectiveForm;
