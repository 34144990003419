import React from "react";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import ReorientationsFilters from "./ReorientationsFilters";
import ReorientationsList from "./ReorientationsList";
import useReorientations from "./useReorientations";
import useReorientationTools from "./useReorientationTools";

const Reorientations = (props) => {
  var fetchId = props.match?.params?.id || props.userRecipientId || null;

  const {
    isLoading,
    organizations,
    reorientations,
    users,
    search,
    errors,
    changePage,
    changeNbItemsByPage,
    updateSearch,
    nbPages,
    nbResults,
    constants,
    openAssignModal,
    myOrgas,
    acceptReo,
    refuseReo,
    keepReo,
    resendReo,
    sendToEp,
    fetchReorientations,
    deleteReo,
    fromSites,
    toSites,
  } = useReorientations(fetchId, props.isModal);

  const { actions } = useReorientationTools({ organizations });

  console.log("reorientations", reorientations);

  return (
    <Page
      container={"container-fluid px-5"}
      title={`Liste des réorientations ${fetchId ? "de l'allocataire" : ""}`}
      bgTransparent
      errors={errors}
      noPadding
      back={fetchId && !props.isModal ? "/reorientations" : null}
    >
      {organizations.length && !fetchId && (
        <div className="custom-card mb-3">
          <ReorientationsFilters
            search={search}
            updateSearch={updateSearch}
            organizations={organizations}
            fetchReorientations={fetchReorientations}
            isLoading={isLoading}
            fromSites={fromSites}
            toSites={toSites}
            nbResults={nbResults}
          />
        </div>
      )}
      {isLoading && <Loader />}
      {reorientations.length && organizations.length ? (
        <>
          <ReorientationsList
            reorientations={reorientations}
            users={users}
            actions={actions}
            constants={constants}
            openAssignModal={openAssignModal}
            myOrgas={myOrgas}
            acceptReo={acceptReo}
            refuseReo={refuseReo}
            keepReo={keepReo}
            resendReo={resendReo}
            sendToEp={sendToEp}
            deleteReo={deleteReo}
          />
        </>
      ) : (
        <p className="text-black-50 d-block text-center">-aucune donnée-</p>
      )}
      {!fetchId && (
        <Pagination
          nbPages={nbPages}
          page={search.page}
          changePage={changePage}
          nbItemsByPage={search.nbItemsByPage}
          changeNbItemsByPage={changeNbItemsByPage}
        />
      )}
    </Page>
  );
};

export default Reorientations;
