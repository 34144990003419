import React from "react";
import Control from "../../common/Control";
import PostalCode from "../../common/PostalCode";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import AddRecipientToEp from "../RecipientDetail/AddRecipientToEp";
import { modalActions } from "../../../context/actions/modalActions";

const RecipientInfo = ({
  recipient,
  genders,
  maritalStatuses,
  allDisabled,
  errors,
  updateData,
  resendActivationMail,
  getRecipientsEp,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const mActions = modalActions(state, dispatch);

  const addRecipientToNextEp = () => {
    mActions.updateModal({
      isOpen: true,
      content: (
        <AddRecipientToEp recipient={recipient} refresh={getRecipientsEp} />
      ),
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div data-priv="send_rdsp_link" className="form-group">
            <button
              onClick={resendActivationMail}
              className="btn btn-primary btn-sm mr-2"
            >
              <i className="fa fa-envelope mr-2" />
              Envoyer le lien de la RDSP par email
            </button>
            {/* {!recipient.currentEP && (
              <button className="btn btn-info btn-sm my-2 mr-1" onClick={addRecipientToNextEp}>
                <i className="fa fa-user-tag mr-2" />
                Inscrire à la prochaine EP
              </button>
            )}*/}
            {recipient.currentEP && (
              <span className="badge badge-info mr-1">
                Inscrit à l'EP du{" "}
                {DateTime.fromISO(recipient.currentEP.EP.beginAt).toFormat(
                  "dd/MM/yyyy"
                )}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="custom-card mb-3">
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="N° d'allocataire"
              name="CAFId"
              value={recipient.CAFId}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="NIR"
              name="nir"
              value={recipient.nir}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="N° d'inscription à France Travail"
              name="numSubscribePE"
              value={recipient.numSubscribePE}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Mise à jour SID"
              name="updatedFromSIDAt"
              value={
                recipient.updatedFromSIDAt
                  ? DateTime.fromISO(recipient.updatedFromSIDAt).toFormat(
                      "dd/MM/yyyy HH:mm"
                    )
                  : ""
              }
              change={() => {}}
              disabled={true}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Import SID"
              name="importedFromSIDAt"
              value={
                recipient.importedFromSIDAt
                  ? DateTime.fromISO(recipient.importedFromSIDAt).toFormat(
                      "dd/MM/yyyy HH:mm"
                    )
                  : ""
              }
              change={() => {}}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="custom-card mb-3">
        <div className="row">
          <div className="col-12">
            <Control
              disabled={allDisabled}
              label="Civilité"
              type="btnList"
              btnInline={true}
              datas={constants.GENDERS}
              name="gender"
              value={recipient.gender}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Nom"
              name="lastname"
              value={recipient.lastname}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Prénom"
              name="firstname"
              value={recipient.firstname}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Email"
              name="email"
              type="email"
              value={recipient.email}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Date de naissance"
              name="birthdate"
              type="date"
              value={DateTime.fromISO(recipient.birthdate).toFormat(
                "yyyy-MM-dd"
              )}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Téléphone"
              type="tel"
              name="phone"
              value={recipient.phone}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Téléphone 2"
              type="tel"
              name="phone2"
              value={recipient.phone2}
              change={updateData}
              error={errors}
            />
          </div>

          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Adresse"
              name="address"
              value={recipient.address}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Complément d'adresse"
              name="addressAdd"
              value={recipient.addressAdd}
              change={updateData}
              error={errors}
            />
          </div>

          <div className="col-12">
            <PostalCode
              disabled={allDisabled}
              value={recipient.cityId}
              name="cityId"
              updateData={updateData}
              inputLabel="Code postal"
              error={errors}
              location={recipient.location}
            />
          </div>

          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Situation familiale"
              type="select"
              datas={constants.MARITAL_STATUS}
              name="maritalStatus"
              value={recipient.maritalStatus}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Nombre d'enfants à charge au sens des PF"
              type="numberInt"
              name="nbDependantChildren"
              value={recipient.nbDependantChildren}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={allDisabled}
              label="Nombre de personnes à charge"
              type="numberInt"
              name="nbChildrenOrDependant"
              value={recipient.nbChildrenOrDependant}
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Situation professionnelle"
              type="select"
              name="professionalSituation"
              disabled={allDisabled}
              value={recipient.professionalSituation}
              change={updateData}
              error={errors}
              datas={constants.PROFESSIONAL_SITUATIONS}
            />
          </div>
        </div>
      </div>

      <div className="custom-card">
        <div className="row">
          <div className="col-12 col-md-4">
            <Control
              disabled={allDisabled}
              label="Type de droits"
              type="text"
              name="rights"
              value={
                recipient.rights
                  ? constants.RSA_TYPES.find(
                      (r) => recipient.rights.type == r.id
                    ).name
                  : ""
              }
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-4">
            <Control
              disabled={allDisabled}
              label="Début droits"
              type="text"
              name="rights"
              value={
                recipient.rights && recipient.rights.beginAt
                  ? DateTime.fromISO(recipient.rights.beginAt).toFormat(
                      "dd/MM/yyyy"
                    )
                  : ""
              }
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-4">
            <Control
              disabled={allDisabled}
              label="Fin droits"
              type="text"
              name="rights"
              value={
                recipient.rights && recipient.rights.endAt
                  ? DateTime.fromISO(recipient.rights.endAt).toFormat(
                      "dd/MM/yyyy"
                    )
                  : ""
              }
              change={updateData}
              error={errors}
            />
          </div>
          <div className="border-top border-bottom w-100">
            <div className="row m-0">
              <div className="col-12 col-md-3">
                <Control
                  disabled={allDisabled}
                  label="Droits et devoirs depuis"
                  type="text"
                  name="rightsAndDuties"
                  value={
                    recipient.rightsAndDuties
                      ? DateTime.fromISO(
                          recipient.rightsAndDuties.beginAt
                        ).toFormat("dd/MM/yyyy")
                      : ""
                  }
                  change={updateData}
                  error={errors}
                />
              </div>
              <div className="col-12 col-md-3">
                <Control
                  disabled={allDisabled}
                  label="Fin droits et devoirs"
                  type="text"
                  name="rightsAndDuties"
                  value={
                    recipient.rightsAndDuties && recipient.rightsAndDuties.endAt
                      ? DateTime.fromISO(
                          recipient.rightsAndDuties.endAt
                        ).toFormat("dd/MM/yyyy")
                      : ""
                  }
                  change={updateData}
                  error={errors}
                />
              </div>
              <div className="col-12 col-md-3">
                <Control
                  disabled={allDisabled}
                  label="Etat SDD"
                  type="text"
                  name="stateSdd"
                  value={(() => {
                    if (recipient.rightsAndDuties) {
                      var rrAug = recipient.rightsAndDuties;
                      var isValid = new Date() >= new Date(rrAug.beginAt);
                      if (rrAug.endAt) {
                        isValid =
                          isValid && new Date(rrAug.endAt) >= new Date();
                      }

                      var stateSdd = isValid ? "S" : "N";

                      return stateSdd;
                    } else {
                      return "N";
                    }
                  })()}
                  change={updateData}
                  error={errors}
                />
              </div>
              <div className="col-12 col-md-3">
                <Control
                  disabled={allDisabled}
                  label="Date m.à.j"
                  type="text"
                  name="rightsAndDuties"
                  value={
                    recipient.rightsAndDuties &&
                    recipient.rightsAndDuties.updatedFromSIDAt
                      ? DateTime.fromISO(
                          recipient.rightsAndDuties.updatedFromSIDAt
                        ).toFormat("dd/MM/yyyy")
                      : ""
                  }
                  change={updateData}
                  error={errors}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <Control
              disabled={allDisabled}
              label="Type de contrat"
              type="text"
              name="contracts"
              value={
                recipient.contracts
                  ? constants.CONTRACT_TYPES.find(
                      (r) => recipient.contracts.type == r.id
                    ).name
                  : ""
              }
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-4">
            <Control
              disabled={allDisabled}
              label="Début contrat"
              type="text"
              name="contracts"
              value={
                recipient.contracts && recipient.contracts.beginAt
                  ? DateTime.fromISO(recipient.contracts.beginAt).toFormat(
                      "dd/MM/yyyy"
                    )
                  : ""
              }
              change={updateData}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-4">
            <Control
              disabled={allDisabled}
              label="Fin contrat"
              type="text"
              name="contracts"
              value={
                recipient.contracts && recipient.contracts.endAt
                  ? DateTime.fromISO(recipient.contracts.endAt).toFormat(
                      "dd/MM/yyyy"
                    )
                  : ""
              }
              change={updateData}
              error={errors}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipientInfo;
