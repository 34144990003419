import React, { useEffect } from "react";
import useStore from "../../../../context/useStore";
import useCERData from "../../../Hooks/useCERData/useCERData";
import AmendmentEditModule from "./AmendmentEditModule/AmendmentEditModule";
import CEREditModule from "./CEREditModule/CEREditModule";
import CERListModule from "./CERListModule/CERListModule";
import useAmendmentModule from "./hooks/useAmendmentModule";
import useCERModule from "./hooks/useCERModule";

const CERModule = ({ recipient }) => {
  const [state] = useStore();
  const { currentCERView, setCurrentRecipient, resetAtoms, actions, recipientCER } = useCERModule();

  const { actions: amendmentActions } = useAmendmentModule();

  const {
    setObjectiveCategories,
    getCerObjectives,
    getCerSteps,
    getCerOperators,
    getCerObstacles,
    setPathTypes,
  } = useCERData();

  useEffect(() => {
    if (recipient?.id) {
      //on populate l'atom du currentRecipient
      setCurrentRecipient(recipient);
      //on fetch le CER du recipient
      actions.getRecipientCER(recipient.id);
      getCerObjectives();
      getCerSteps();
      getCerObstacles();
      setObjectiveCategories(state.constants.items.CER_GOAL_CATEGORIES);
      setPathTypes(state.constants.items.CER_GOAL_PATH_TYPES);
      getCerOperators();
    }
    return () => {
      //on reset tous les atoms lorsque le composant est démonté
      resetAtoms();
    };
  }, [recipient]);

  useEffect(() => {
    if (recipientCER.id) {
      amendmentActions.getAmendments(recipientCER.id);
    }
  }, [recipientCER.id]);

  const getViewComponent = () => {
    switch (currentCERView) {
      case "CER_LIST":
        return <CERListModule />;
      case "CER_EDIT":
        return <CEREditModule />;
      case "AMENDMENT_EDIT":
        return <AmendmentEditModule />;
    }
  };

  return <div>{getViewComponent()}</div>;
};

export default CERModule;
