import React, { useEffect, useState, Fragment } from "react";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import useStore from "../../../context/useStore";
import AnswerInterpretor from "./AnswerInterpretor";
import "./form-generator.css";
import Axios from "axios";
import ProgressBar from "./ProgressBar";
import LoaderFinish from "./LoaderFinish";
import tools from "../../../helpers/tools";
import LoadLSOverlay from "./LoadLSOverlay";
import FormNavigation from "./FormNavigation";

const FormGenerator = ({
  formDataUrl = false,
  isDspFinished,
  setIsDspFinished,
  setFormData,
  withContinue = false,
  formConfig = false,
  updateFormAnswers = false,
  step,
  setStep,
  withNavigation = true,
  isVertical = false,
  preventBack = false,
  justDisplayAnswers = false,
  isLoading = false,
}) => {
  const [state, dispatch] = useStore();
  const [form, setForm] = useState(false);
  const [answers, setAnswers] = useState(false);
  const constants = state.constants.items;

  const [oldStep, setOldStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (formDataUrl) {
      getForm();
    }
    if (formConfig) {
      setForm(formConfig);
    }
  }, [formDataUrl]);

  useEffect(() => {
    if (formConfig) {
      setForm(formConfig);
    }
  }, [formConfig]);

  function getForm() {
    Axios.get(formDataUrl)
      .then((res) => {
        setForm(res.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (form && !answers) {
      if (justDisplayAnswers) {
        setAnswers(justDisplayAnswers);
      } else {
        var ans = [];
        form.questions.forEach((q) => {
          var value = q.type == 7 ? [] : "";
          value = !q.type ? "shadow" : value;
          ans.push({
            question: q.id,
            value: value,
            label: q.question,
            name: q.name,
          });
        });
        setAnswers(ans);
      }
    }
  }, [form]);

  useEffect(() => {
    if (form && answers) {
      if (isCondNotFullfiled(step)) {
        if (oldStep > step) {
          setStep(step - 1);
        } else {
          setStep(step + 1);
        }
      }
    }
    if (step > 0 && withContinue) {
      tools.saveState("FormRDSP", "answers", answers);
      tools.saveState("FormRDSP", "step", step);
    }
  }, [step]);

  function isCondNotFullfiled(st) {
    var cond = form.questions[st].condition;
    var result = false;
    if (cond != "") {
      cond = cond.split("&&");

      cond.forEach((c) => {
        var notFullFiled = true;
        if (c.includes("in")) {
          var obj = c.split("in");
          var targetValue = obj[1];
          var qsId = parseInt(obj[0].split("q")[1]);
          var targetQuestion = form.questions.find((q) => q.id == qsId).id;
          notFullFiled = !answers
            .find((a) => a.question == targetQuestion)
            .value.includes(parseInt(targetValue));
        } else if (c.includes("!=")) {
          var obj = c.split("!=");
          var targetValue = obj[1];
          var qsId = parseInt(obj[0].split("q")[1]);
          var targetQuestion = form.questions.find((q) => q.id == qsId).id;
          notFullFiled = answers.find((a) => a.question == targetQuestion).value == targetValue;
        } else {
          var obj = c.split("==");
          var targetValue = obj[1];
          var qsId = parseInt(obj[0].split("q")[1]);
          var targetQuestion = form.questions.find((q) => q.id == qsId).id;
          notFullFiled = answers.find((a) => a.question == targetQuestion).value != targetValue;
        }

        if (notFullFiled) {
          result = true;
          return true;
        }
      });
    }
    return result;
  }

  function updateAnswer(e) {
    var ans = JSON.parse(JSON.stringify(answers));

    var elem = ans.find((a) => a.question == e.target.name).value;
    var value = e.target.value;
    if (e.target.formArray) {
      var refAns = form.questions[step].answers;
      var hasExclusive = refAns.filter((a) => a.isExclusive);

      if (e.target.formArray.target.checked) {
        if (e.target.isExclusive) {
          elem = [value];
        } else {
          elem.push(value);
          hasExclusive.forEach((ae) => {
            elem = elem.filter((v) => v != ae.value);
          });
        }
      } else {
        elem = elem.filter((v) => v != value);
      }
    } else {
      elem = value;
    }
    ans.find((a) => a.question == e.target.name).value = elem;

    setAnswers(ans);
  }

  function prevStep() {
    if (preventBack) return;
    setOldStep(step);
    setStep(step - 1);
  }
  function nextStep() {
    validateStep();
  }
  function validateStep(isLast) {
    setIsValidating(true);
    if (formDataUrl) {
      Axios.post(formDataUrl + "validation/" + step, answers)
        .then((res) => {
          setIsValidating(false);
          if (isLast) {
            save();
          } else {
            setOldStep(step);
            setStep(step + 1);
          }
        })
        .catch((err) => {
          setIsValidating(false);

          if (err.response) {
            setErrors(err.response.data);
          }
        });
    } else {
      if (isLast) {
        updateFormAnswers(answers);
        save();
      } else {
        setIsValidating(false);
        setOldStep(step);
        setStep(step + 1);
        updateFormAnswers(answers);
      }
    }
  }

  function save() {
    setIsSaving(true);
    if (formDataUrl) {
      Axios.post(formDataUrl, answers)
        .then((res) => {
          setFormData(res.data);
          setIsDspFinished(true);
        })
        .catch((err) => {
          if (err.response) setErrors(err.response.data);
          setIsSaving(false);
        });
    } else {
      setFormData(answers);
      setTimeout(() => {
        setIsDspFinished(answers);
      }, 1000);
    }
  }

  return (
    <>
      {withContinue && <LoadLSOverlay setAnswers={setAnswers} setStep={setStep} />}
      <div className="row">
        {withNavigation && (
          <div
            className="col-12 col-md-4 col-lg-4 pl-0 pr-3"
            style={{ borderRight: "1px solid #eaeaea" }}
          >
            <FormNavigation
              step={step}
              setStep={setStep}
              questions={formConfig ? formConfig.questions : form.questions}
              answers={answers}
              isCondNotFullfiled={isCondNotFullfiled}
              preventBack={preventBack}
            />
          </div>
        )}
        {!justDisplayAnswers && (
          <div id="form_container" className="col-12 col-lg-8 col-md-8 m-auto pr-0 pl-3">
            {!form || !answers || constants.length == 0 ? (
              <Loader />
            ) : isSaving ? (
              <LoaderFinish />
            ) : (
              <Fragment>
                <h2 id="form_section_title">{form.sections[form.questions[step].section]}</h2>
                <ProgressBar
                  progress={((step + 1) / form.questions.length) * 100}
                  text={step + 1 + "/" + form.questions.length}
                />

                {isLoading ? (
                  <Loader />
                ) : (
                  form.questions.map((q, qk) => {
                    return (
                      <div
                        key={"q" + qk}
                        style={{
                          width: "100%",
                          left: 0,
                        }}
                        className={
                          "question_container animated faster px-3 " +
                          (step == qk ? "fadeIn" : "hidden")
                        }
                      >
                        {isValidating && step == qk && (
                          <div className="overlay_loading animated fadeIn faster">
                            <Loader />
                          </div>
                        )}
                        <div className="question_header">
                          <h4 className="question_title">{q.question}</h4>
                          <p className="question_description">{q.description}</p>
                          <div
                            className="question_answer_container px-3"
                            style={{
                              height: 345,
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            <AnswerInterpretor
                              question={q}
                              k={qk}
                              inputTypes={constants.INPUT_TYPES}
                              updateAnswer={updateAnswer}
                              answers={answers}
                              errors={errors}
                            />
                          </div>
                        </div>
                        <div className="question_navigation">
                          {step > 0 && !preventBack && (
                            <button className="btn_nav btn_nav_prev" onClick={prevStep}>
                              <i className="fa fa-chevron-left mr-2"></i>
                              retour
                            </button>
                          )}
                          {step < form.questions.length - 1 && (
                            <>
                              <button
                                className={
                                  "btn_nav btn_nav_next btn btn-primary " +
                                  (answers.find((a) => a.question + "" == step + 1 + "").value
                                    .length == 0
                                    ? "disabled"
                                    : "")
                                }
                                id={`continue-btn-step${qk}`}
                                onClick={nextStep}
                                disabled={
                                  answers.find((a) => a.question + "" == step + 1 + "").value
                                    .length == 0
                                }
                              >
                                Continuer
                              </button>
                              <button
                                className={
                                  "btn_nav d-none btn_nav_next btn btn-primary " +
                                  (answers.find((a) => a.question + "" == step + 1 + "").value
                                    .length == 0
                                    ? "disabled"
                                    : "")
                                }
                                id={`continue-hbtn-step${qk}`}
                                onClick={nextStep}
                              >
                                Continuer
                              </button>
                            </>
                          )}
                          {step == form.questions.length - 1 && (
                            <button className="btn btn-success" onClick={() => validateStep(true)}>
                              <i className="fa fa-check mr-2"></i>
                              Continuer
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </Fragment>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FormGenerator;
