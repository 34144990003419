import React, { useEffect, useState } from "react";
import colors from "../../../../helpers/colors";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import SaveBtn from "../../../common/SaveBtn";
import useOrganizations from "../../../Hooks/useOrganizations/useOrganizations";
import useContractsStat from "./useContractsStat";
import useStockStat from "./useContractsStat";

const ContractsNextEP = ({ url, year }) => {
  const { organizations, onGetOrganizations } = useOrganizations();
  const { isLoading, datas, getContractsStat } = useContractsStat({ url, year });

  useEffect(() => {
    onGetOrganizations();
  }, []);

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={datas ? "btn-default d-center" : "btn-primary d-center"}
          color={datas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getContractsStat}
          isSaving={isLoading}
          text={
            datas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {datas && (
        <div className="col-12">
          <div className="row">
            {Object.keys(datas).map((key) => {
              let entry = datas[key];
              console.log("key", organizations);
              return (
                <StatCounter
                  title={`${tools.findIn(organizations, "slugName", key).name || key}`}
                  number={Object.keys(entry).map((dataKey) => {
                    let nb = entry[dataKey].nb;

                    return (
                      <div className="d-center">
                        <span style={{ fontSize: 12, fontWeight: "400", paddingRight: 5 }}>
                          {dataKey} :{" "}
                        </span>
                        <span style={{ fontSize: 12, fontWeight: nb !== 0 ? "800" : "400" }}>
                          {nb}{" "}
                          {nb !== 0 && (
                            <span style={{ fontWeight: "400" }}>
                              (soit{" "}
                              <span style={{ fontWeight: "800" }}>{entry[dataKey].percent}%</span>)
                            </span>
                          )}
                        </span>
                      </div>
                    );
                  })}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const StatCounter = ({ title, number }) => {
  return (
    <div className="col-4" style={{ textAlign: "center" }}>
      <div className="border rounded p-3 shadow-sm bg-primary h-100 text-white d-flex flex-column justify-content-between">
        <div>
          <strong>{title}</strong>
        </div>
        <div style={{ fontSize: 40 }}>
          <strong>{number}</strong>
        </div>
      </div>
    </div>
  );
};

export default ContractsNextEP;
