import React from "react";
import SaveBtn from "../../../common/SaveBtn";
import colors from "../../../../helpers/colors";
import ReactApexChart from "react-apexcharts";
import { DateTime } from "luxon";
import tools from "../../../../helpers/tools";
import useDelayMoyContract from "./useDelayMoyContract";

const DelayMoyContractualisation = ({ url, year }) => {
  const { isLoading, chartState, getDatas, hasDatas, total } = useDelayMoyContract(url, year);

  const calcTotal = (array) => {
    var thisyear = new Date().getFullYear();
    var nbMonths = 0;
    if (year < thisyear) {
      nbMonths = 12;
    } else nbMonths = DateTime.local().month;
    return tools.round(array.reduce((a, b) => a + b, 0) / nbMonths);
  };

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          color={hasDatas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas && chartState ? (
        <>
          <div className="col-12 col-md-6">
            <ReactApexChart
              options={chartState.line1.options}
              series={chartState.line1.series}
              type="bar"
              height={350}
            />
          </div>

          <div className="col-12 col-md-6 d-center flex-column">
            <div className="p-3 rounded d-center flex-column bg-body my-3">
              <div>
                <strong>{chartState.line1.series[0].name}</strong>
              </div>
              <div className="text-primary">
                <strong style={{ fontSize: 18 }}>
                  {calcTotal(chartState.line1.series[0].data)} jours
                </strong>
              </div>
            </div>
            <div className="p-3 rounded d-center flex-column bg-body my-3">
              <div>
                <strong>{chartState.line1.series[1].name}</strong>
              </div>
              <div className="text-primary">
                <strong style={{ fontSize: 18 }}>
                  {calcTotal(chartState.line1.series[1].data)} jours
                </strong>
              </div>
            </div>
            <div className="p-3 rounded d-center flex-column bg-body my-3">
              <div>
                <strong>{chartState.line1.series[2].name}</strong>
              </div>
              <div className="text-primary">
                <strong style={{ fontSize: 18 }}>
                  {calcTotal(chartState.line1.series[2].data)} jours
                </strong>
              </div>
            </div>
            <div className="p-3 rounded d-center flex-column bg-body my-3">
              <div>
                <strong>{chartState.line1.series[3].name}</strong>
              </div>
              <div className="text-primary">
                <strong style={{ fontSize: 18 }}>
                  {calcTotal(chartState.line1.series[3].data)} jours
                </strong>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DelayMoyContractualisation;
