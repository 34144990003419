import React from "react";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import Table from "../../common/Table";

const SearchRecipient = ({
  search,
  setSearch,
  searchByNir,
  isSearching,
  searchResult,
  addRecipientToNextEp,
}) => {
  return (
    <div className="custom-card">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchByNir();
        }}
      >
        <Control
          label="NIR de l'allocataire"
          type="text"
          name="search-alloc"
          value={search.nir}
          change={(e) => setSearch({ ...search, nir: e.target.value })}
        />

        <div className="d-flex justify-content-end">
          <SaveBtn text="Rechercher" isSaving={isSearching} save={searchByNir} />
        </div>
      </form>
      {searchResult.exists ? (
        <Table
          fullWidth
          fields={[
            {
              path: "nir",
              name: "NIR",
            },
            {
              path: "*",
              name: "Nom",
              render: (el) => tools.getFullname(el),
            },
            {
              name: "Inscrire",
              path: "*",
              render: (el) => (
                <button className="btn btn-primary" onClick={(e) => addRecipientToNextEp(el)}>
                  Inscrire
                </button>
              ),
            },
          ]}
          datas={[searchResult.userRecipient]}
        />
      ) : null}
    </div>
  );
};

export default SearchRecipient;
