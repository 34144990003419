import React, { useContext, useEffect, useState } from "react";
import tools from "../../../../../../../../../helpers/tools";
import AddEntityForm from "../../../../../../common/CERGoalsForm/common/AddEntityForm";
import { ObjectiveContext } from "../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import StepSelect from "../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/AddStepForm/StepSelect";
import useAmendmentModule from "../../../../../hooks/useAmendmentModule";

const AddAmendmentStepForm = () => {
  const objective = useContext(ObjectiveContext);
  const { currentAmendment, events } = useAmendmentModule();

  var objectiveIndex = tools.getObjectiveIndex(currentAmendment.goals, objective);

  const [newStep, setNewStep] = useState({
    step: "",
    operators: [],
    detail: { status: "UNFINISH" },
    updateType: "ADDED",
  });

  const handleNewStepChange = (e) => {
    const { name, value } = e.target;
    setNewStep({ ...newStep, [name]: value });
  };

  const handleAddStep = () => {
    const newObjective = {
      ...objective,
      steps: [...objective.steps, newStep],
    };
    events.onUpdateObjective(newObjective, objectiveIndex);
    setNewStep({ step: "", operators: [], detail: { status: "UNFINISH" }, updateType: "ADDED" });
  };

  useEffect(() => {
    if (newStep.step) {
      handleAddStep();
    }
  }, [newStep]);

  return (
    <AddEntityForm onSubmit={handleAddStep} pbClass="pb-0">
      <StepSelect
        handleNewStepChange={handleNewStepChange}
        objective={objective}
        step={newStep}
        k={`addStep`}
      />
      {/* <div className="d-flex justify-content-end">
        <button className="btn btn-sm" type="submit" disabled={!newStep.step}>
          <i className="fa fa-plus" />
        </button>
      </div> */}
    </AddEntityForm>
  );
};

export default AddAmendmentStepForm;
