import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import $ from "jquery";

const TopHeader = () => {
  const [state, dispatch] = useStore();
  const { ROLES_MANAGER } = state.constants.items;
  const actions = authActions(state, dispatch);
  useEffect(() => {
    if (state.auth.isAuthenticated) {
      $(document).ready(function () {
        $("#sidebarCollapse").on("click", function () {
          $("#sidebar").toggleClass("active");
          $(this).toggleClass("active");
        });
      });
    }
  }, [state.auth.isAuthenticated]);
  var authData = state.auth;

  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <nav className={`navbar navbar-expand-lg navbar-light`}>
      <div className="container-fluid">
        <button
          className="btn btn-dark d-inline-block d-lg-none ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-align-justify"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav navbar-nav ml-auto d-flex align-items-center">
            <li className="nav-item active mr-2 text-black-50">
              <Link to="/me" style={{ textTransform: "capitalize" }}>
                {authData.user.firstname + " " + authData.user.lastname} -{" "}
                {ROLES_MANAGER
                  ? ROLES_MANAGER.find((r) => r.id == authData.user.role)
                      ?.name || "SUPER ADMIN"
                  : ""}
              </Link>
            </li>
            <li className="nav-item">
              <button
                className="btn btn-sm btn-default"
                onClick={actions.logoutUser}
              >
                <i className="fa fa-power-off" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  ) : (
    <></>
  );
};

export default TopHeader;
