import { DateTime } from "luxon";
import React from "react";
import tools from "../../../../../helpers/tools";
import useStoreState from "../Context/Store/useStoreState";

const StartEpPage = () => {
  const { items, items_actions } = useStoreState();

  const { ep } = items;

  return (
    <div className="d-center flex-column h-100 w-100 animated fadeInDown faster">
      <h4>
        EP du{" "}
        {DateTime.fromISO(ep.beginAt).setLocale("FR").toFormat("dd MMMM yyyy")}{" "}
        à {tools.numberWithLeadingZero(ep.beginHourAt)}h
        {tools.numberWithLeadingZero(ep.beginMinuteAt)}
      </h4>
      <strong>Secteur {ep.sector.name}</strong>
      <button className="btn btn-info mt-2" onClick={items_actions.startEp}>
        DÉMARRER LA SESSION D'EP
        <i className="fa fa-chevron-right ml-2" />
      </button>
    </div>
  );
};

export default StartEpPage;
