import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../../context/useStore";
import DynamicList from "../../../../common/DynamicList";
import SaveBtn from "../../../../common/SaveBtn";
import SimpleDynamicList from "../../../../common/SimpleDynamicList";
import useCERModule from "../hooks/useCERModule";

const MeansInPlace = () => {
  const { recipientCER, isSavingCERMeans, actions } = useCERModule();
  const [state] = useStore();
  const { CER_MEANS } = state.constants.items;

  const handleChange = (e) => {
    actions.updateRecipientCER({
      ...recipientCER,
      means: e.target.value,
    });
  };

  return (
    <div className="p-3 custom-card mx-3">
      <div>
        <strong>Moyens mis en place</strong>
      </div>
      <DynamicList
        listLabel=""
        name="means"
        items={recipientCER.means}
        fields={[
          {
            name: "mean",
            type: "select",
            datas: CER_MEANS,
            dataIndex: "id",
            dataLabel: "name",
          },
          {
            name: "createdAt",
            type: "date",
            datas: CER_MEANS,
            disabled: true,
            staticValue: DateTime.local().toFormat("yyyy-MM-dd"),
          },
        ]}
        change={handleChange}
        btnLabel="Ajouter un moyen"
      />
      <SaveBtn
        isSaving={isSavingCERMeans}
        text="Enregistrer les moyens"
        save={actions.patchRecipientCERMeans}
      />
    </div>
  );
};

export default MeansInPlace;
