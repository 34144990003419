import React, { useState } from "react";
import Page from "../../common/layout/Page";
import SaveBtn from "../../common/SaveBtn";
import useStore from "../../../context/useStore";
import SmsTest from "./SmsTest";

const SmsCron = () => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  return (
    <Page
      container={"container-fluid px-5"}
      title="Simulateur de notifications SMS"
    >
      <div className="d-center flex-column">
        <p className="alert alert-light">
          Ici, vous pouvez simuler l'envoi des notifications SMS qui partiront
          lors du CRON exécuté la nuit.
        </p>
      </div>
      {constants.SMS_TYPES.map((s, sk) => {
        return <SmsTest key={`smstype${s.id}`} name={s.name} id={s.id} />;
      })}
    </Page>
  );
};

export default SmsCron;
