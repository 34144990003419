import React, { useContext, useState } from "react";
import { ObjectiveContext } from "../../../ObjectiveContext";
import { StepContext } from "../StepContext";
import { OperatorContext } from "./OperatorContext";
import OperatorEdit from "./OperatorEdit";
import OperatorView from "./OperatorView";

const OperatorsList = () => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);
  const [operatorEditingId, setOperatorEditingId] = useState(null);

  const goToViewMode = () => {
    setOperatorEditingId(null);
  };

  const goToEditMode = (operatorId) => {
    setOperatorEditingId(operatorId);
  };

  return (
    <ul className="list-group pl-4">
      {step.operators.map((operator, ok) => {
        const operatorKey = `objoperator-${objective.pathType}-${objective.goal}-${step.step}-${operator.operator}`;
        const isBeingEdited = operatorEditingId == operator.operator;

        return (
          <li className="list-group-item border-0 p-0 pb-2" key={operatorKey}>
            <OperatorContext.Provider value={operator}>
              {isBeingEdited ? (
                <OperatorEdit goToViewMode={goToViewMode} />
              ) : (
                <OperatorView goToEditMode={() => goToEditMode(operator.operator)} />
              )}
            </OperatorContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};

export default OperatorsList;
