import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import ModalBtn from "../../common/ModalBtn";
import Modal from "../../common/Modal";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import dataService from "../../../helpers/dataService";
import $ from "jquery";
import RecipientDetail from "../RecipientDetail/RecipientDetail";
import RecipientTempDetail from "../RecipientTempDetail/RecipientTempDetail";
import SaveBtn from "../../common/SaveBtn";
import roleConfig from "../../common/layout/roleConfig";
import ReorientationIcon from "../Reorientations/ReorientationIcon";
import useReorientationTools from "../Reorientations/useReorientationTools";

var strTimeout = null;

const AnomalyList = () => {
  const [anomalies, setAnomalies] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  var searchState = tools.getState("AnomalyList", null);

  const [search, setSearch] = useState(
    searchState
      ? JSON.parse(searchState.search)
      : {
          anomaly: {
            status: [],
            agent: "",
            type: "",
            userRecipient: "",
            organization: "",
          },
          page: 1,
          nbItemsByPage: 25,
        }
  );
  const [nbPages, setNbPages] = useState(1);
  const [selectedAnomaly, setSelectedAnomaly] = useState(false);
  const [nbResults, setNbResults] = useState(0);
  const [agents, setAgents] = useState(false);
  const anomalyStatus = state.constants.items.ANOMALY_STATUS;
  const anomalyTypes = state.constants.items.ANOMALY_TYPES;
  const genders = state.constants.items.GENDERS;
  const [selectedRecipient, setSelectedRecipient] = useState(false);
  const [selectedRecipientTemp, setSelectedRecipientTemp] = useState(false);
  const [anomalyCron, setAnomalyCron] = useState([]);
  const [isLoadingCron, setIsLoadingCron] = useState(false);
  const [anomalyCronErrors, setAnomalyCronErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTempOpen, setIsModalTempOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(false);
  const [assignToSelected, setAssignToSelected] = useState("");
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    $("#view-recipient").on("show.bs.modal", () => {
      setIsModalOpen(true);
    });
    $("#view-recipient").on("hide.bs.modal", () => {
      setIsModalOpen(false);
    });
    $("#view-recipient-temp").on("show.bs.modal", () => {
      setIsModalOpen(true);
    });
    $("#view-recipient-temp").on("hide.bs.modal", () => {
      setIsModalOpen(false);
    });

    getDatas();
    //searchData();
    setSearch(search);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [search]);

  function getDatas() {
    Axios.get(api_url + "managers/agents/mine")
      .then((res) => {
        setAgents(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error(
            "Une erreur est survenue lors de la récupération des agents"
          );
        }
      });

    dataService.get("organizations", setOrganizations);
  }

  function searchData(pagi = false, nbi = false) {
    setErrors(false);
    setIsLoading(true);
    var sh = {
      ...search,
      page: pagi || search.page,
      nbItemsByPage: nbi || search.nbItemsByPage,
    };

    if (agents && !agents.find((a) => a.id == sh.anomaly.agent)) {
      sh.anomaly.agent = "";
    }

    Axios.post(api_url + "anomalies/search", { ...sh })
      .then((res) => {
        setAnomalies(res.data.data);
        setNbPages(Math.ceil(parseInt(res.data.count) / search.nbItemsByPage));
        setNbResults(res.data.count);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        tools.filterError(err);
      })
      .then(() => setIsLoading(false));
  }
  function updateSearch(e) {
    var value = e.target.value;
    var su = { ...search };

    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        su.anomaly[e.target.name] = true;
      } else {
        delete su.anomaly[e.target.name];
      }
    } else if (e.target.formArray) {
      if (e.target.formArray.target.checked) {
        su.anomaly[e.target.name].push(e.target.value);
      } else {
        su.anomaly[e.target.name] = su.anomaly[e.target.name].filter(
          (f) => f != value
        );
      }
    } else {
      if (value == "") {
        delete su.anomaly[e.target.name];
      } else {
        su.anomaly[e.target.name] = value;
      }
    }

    setSearch(su);
  }

  function assignAnomaly(managerId) {
    Axios.patch(api_url + "anomalies/" + selectedAnomaly.id + "/status", {
      status: "IN_PROGRESS",
      managerId: managerId,
    })
      .then((res) => {
        searchData();
        toast.success("L'anomalie a bien été attribuée");
      })
      .catch((err) => {
        toast.error(
          "Une erreur est survenue lors de l'attribution de l'anomalie à cet agent"
        );
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  const getAnomalyCron = () => {
    // setAnomalyCron([
    //   {
    //     nbNew: 0,
    //     nbProblem: 0,
    //     nbDontMove: 1,
    //     name: "RDSP enregistré sans aucun allocataire",
    //   },
    //   {
    //     nbNew: 0,
    //     nbProblem: 0,
    //     nbDontMove: 3,
    //     name: "Allocataire en attente depuis plus de 8 jours",
    //   },
    // ]);
    // return;

    setAnomalyCron([]);
    setIsLoadingCron(true);
    dataService.get(
      "anomalies/checks",
      setAnomalyCron,
      setAnomalyCronErrors,
      () => setIsLoadingCron(false)
    );
  };

  const removeAnomaly = (id) => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 3000);
    dataService.remove(
      `anomalies/${id}`,
      {},
      (datas) => searchData(),
      setErrors,
      () => setIsLoading(false)
    );
  };

  function changePage(i) {
    searchData(i);
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function changeNbItemsByPage(i) {
    searchData(false, i);
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
  }

  const { actions } = useReorientationTools({ organizations });

  const getConditionalColumns = (el) => {
    var conditionalColumns = [];

    if (search.anomaly.type == "ORIENTATION_WITHOUT_RECEIVED_BY") {
      const entry = el.orientation;
      if (!entry) return null;
      conditionalColumns.push(
        <>
          <span>
            Réo. créée le{" "}
            {DateTime.fromISO(entry.createdAt).toFormat("dd/MM/yyyy")}
          </span>
          <ReorientationIcon
            fromPartner={actions.getOrganizationFromId(entry.fromPartner).name}
            toPartner={actions.getOrganizationFromId(entry.toPartner).name}
            fromAgent={
              <>
                <div>{tools.getFullname(entry.createdBy)}</div>
                <div>
                  <a href={`mailto:${entry.createdBy?.email}`}>
                    {entry.createdBy?.email}
                  </a>
                </div>
                <div>{entry.createdBy?.phone}</div>
              </>
            }
            toAgent={
              <>
                <div>{tools.getFullname(entry.receivedBy)}</div>
                <div>
                  <a href={`mailto:${entry.receivedBy?.email}`}>
                    {entry.receivedBy?.email}
                  </a>
                </div>
                <div>{entry.receivedBy?.phone}</div>
              </>
            }
          />
        </>
      );
    }

    return conditionalColumns;
  };

  return (
    <>
      <Page
        container={"container-fluid px-5"}
        title={"Liste des anomalies"}
        errors={errors}
      >
        <div className="row mb-4">
          <div className="col-12">
            <Control
              margin={"my-0"}
              label="Recherche"
              name="userRecipient"
              value={search.anomaly.userRecipient}
              change={updateSearch}
              placeholder="Nom, prénom, email, téléphone, N° d'allocataire, NIR..."
              type="text"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 justify-content-center">
            {state.constants.items && state.constants.items.ANOMALY_STATUS ? (
              <Control
                margin={"my-0"}
                label="Statut"
                name="status"
                value={search.anomaly.status}
                change={updateSearch}
                type="checkboxList"
                datas={state.constants.items.ANOMALY_STATUS}
              />
            ) : (
              <Loader />
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4 justify-content-center">
            {agents ? (
              <>
                {/* <Control
                type="select"
                label="Agent"
                name="agent"
                value={search.anomaly.agent}
                change={updateSearch}
                datas={agents}
                dataIndex={"id"}
                dataLabel={"firstname"}
                dataLabel2={"lastname"}
              /> */}
                <Control
                  label="Agent"
                  type="autocomplete"
                  value={search.anomaly.agent}
                  name="agent"
                  autoCompleteDisplayIndex="lastname"
                  change={(e) =>
                    setSearch({
                      ...search,
                      anomaly: { ...search.anomaly, agent: e.target.value },
                    })
                  }
                  datas={agents}
                  dataIndex="id" //default
                  dataLabel="lastname" //default (sera inclut dans la recherche)
                  dataLabel2="firstname" //index dans datas (sera inclut dans la recherche)
                  required={true}
                  btnInline={false}
                  hideAutocompleteOnFocusOut={false} //buggé
                  noResults={false} //si aucun résultat api
                  autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
                />
              </>
            ) : (
              <Loader />
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4 justify-content-center">
            {anomalyTypes ? (
              <Control
                type="select"
                label="Type d'anomalie"
                name="type"
                value={search.anomaly.type}
                change={updateSearch}
                datas={anomalyTypes.filter((at) => !at.isHidden)}
                dataIndex={"id"}
                dataLabel={"name"}
              />
            ) : (
              <Loader />
            )}
            {anomalyTypes
              .filter((at) => at.isOrganization && !at.isHidden)
              .map((at) => at.id)
              .includes(search.anomaly.type) && (
              <Control
                type="select"
                label="Organisation"
                name="organization"
                value={search.anomaly.organization}
                change={updateSearch}
                datas={organizations}
                dataIndex={"id"}
                dataLabel={"name"}
              />
            )}
          </div>
          <div className="col-12 form-group d-center flex-column">
            <p className="m-0">{nbResults} anomalies trouvées</p>
            <SaveBtn
              margin="mt-2"
              text="Rechercher"
              save={() => searchData()}
              isSaving={isLoading}
            />
          </div>
          <div
            className="col-4 text-center offset-8 d-flex align-items-center justify-content-end"
            data-priv="cron_anomaly"
          >
            {
              <ModalBtn
                onClick={() => {}}
                id="anomaly-cron"
                label={
                  <>
                    <i className="fa fa-cog mr-2"></i>Lancer le cron des
                    anomalies
                  </>
                }
                btnClass="btn btn-default btn-sm"
              />
            }
          </div>
        </div>

        <Modal id="anomaly-cron" title="Résultat du cron des anomalies">
          {anomalyCron ? (
            isLoadingCron ? (
              <Loader />
            ) : (
              <>
                <button className="btn btn-primary" onClick={getAnomalyCron}>
                  Cette action dure quelques minutes et mettra à jour toutes les
                  anomalies. <br /> Cliquez ici pour lancer ou fermez la
                  fenêtre.
                </button>
                <ul className="list-group">
                  {anomalyCron.map((a, ak) => (
                    <li className="list-group-item d-flex flex-column">
                      <strong>{a.name}</strong>
                      <div className="d-flex align-items-center justify-content-between text-success">
                        Nouvelles
                        <span
                          className="bg-success p-2 text-white d-center"
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        >
                          {a.nbNew}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between text-danger">
                        En erreur
                        <span
                          className="bg-danger p-2 text-white d-center"
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        >
                          {a.nbProblem}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        Inchangées
                        <span
                          className="bg-dark p-2 text-white d-center"
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        >
                          {a.nbDontMove}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )
          ) : (
            <Loader />
          )}
        </Modal>
        <Modal
          id="assign-to-agent"
          title="Assigner l'anomalie à un agent"
          actions={
            <button
              className="btn btn-success"
              onClick={() => assignAnomaly(assignToSelected)}
              data-toggle="modal"
              data-target={"#assign-to-agent"}
            >
              Assigner à l'agent sélectionné
            </button>
          }
        >
          {agents ? (
            <>
              <Control
                label="Agent"
                type="autocomplete"
                value={assignToSelected}
                name="assignToSelected"
                autoCompleteDisplayIndex="lastname"
                change={(e) => setAssignToSelected(e.target.value)}
                datas={agents}
                dataIndex="id" //default
                dataLabel="lastname" //default (sera inclut dans la recherche)
                dataLabel2="firstname" //index dans datas (sera inclut dans la recherche)
                required={true}
                btnInline={false}
                hideAutocompleteOnFocusOut={false} //buggé
                noResults={false} //si aucun résultat api
                autoCompleteHasApiDatas={false} // true si afficher tous les résultats api
              />

              {/* <ul className="list-group">
              {agents
                .filter((a) => (selectedAgent ? a.id == selectedAgent : a))
                .map((a, ak) => (
                  <li
                    className="list-group-item d-flex justify-content-between align-items-center"
                    key={`${a.id}`}
                  >
                    {a.id == selectedAgent && (
                      <i
                        className="position-absolute fa fa-arrow-alt-circle-right text-primary animated slideInLeft infinite"
                        style={{ left: -1 }}
                      />
                    )}
                    <span>
                      {a.firstname} {a.lastname}
                    </span>
                    <button
                      data-toggle="modal"
                      data-target={"#assign-to-agent"}
                      className="btn btn-primary btn-sm"
                      onClick={() => assignAnomaly(a.id)}
                    >
                      Assigner à cet agent
                    </button>
                  </li>
                ))}
            </ul> */}
            </>
          ) : (
            <Loader />
          )}
        </Modal>
        <Modal id="view-histo" title="Historique des modifications de statut">
          {selectedAnomaly && (
            <ul className="list-group">
              {selectedAnomaly.anomalyHistory.map((h, hk) => {
                var an = anomalyStatus.find((as) => as.id == h.status);
                return (
                  <li className="list-group-item " key={`aok${an.id}`}>
                    <div className="d-flex align-items-start justify-content-between">
                      <div>
                        <span className={"mr-2 badge badge-" + an.color}>
                          {an.name}
                        </span>
                        <br />
                        par{" "}
                        {h.updatedBy
                          ? tools.getFullname(h.updatedBy)
                          : "Système"}
                      </div>
                      <span>
                        le{" "}
                        <b>
                          {DateTime.fromISO(h.date).toFormat(
                            "dd/MM/yyyy HH:mm"
                          )}
                        </b>
                      </span>
                    </div>
                    {h.comment && (
                      <div className="alert alert-light">
                        <i className=" fa fa-comment mr-2" />
                        {h.comment}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </Modal>
        <Modal id="view-recipient" title="Allocataire lié à l'anomalie" large>
          {selectedRecipient && (
            <RecipientDetail
              isModalOpen={isModalOpen}
              recipientId={selectedRecipient.id}
              isModal={true}
            />
          )}
        </Modal>
        <Modal
          id="view-recipient-temp"
          title="Allocataire lié à l'anomalie"
          large
        >
          {selectedRecipientTemp && (
            <RecipientTempDetail
              isModalOpen={isModalTempOpen}
              recipientId={selectedRecipientTemp.id}
              isModal={true}
            />
          )}
        </Modal>
      </Page>
      <div className="row ">
        <div className="col-12 p-4">
          {isLoading ||
          !state.constants.items ||
          !state.constants.items.ANOMALY_STATUS ? (
            <Loader />
          ) : (
            <>
              <Table
                datas={anomalies}
                exportOptions={{
                  fetchDatas: "anomalies/search",
                  fetchParams: { ...search, nbItemsByPage: "*" },
                  formatDatasFn: (datas) =>
                    datas.map((r) => {
                      let userRecipient =
                        r.infos?.userRecipient ||
                        r.infos?.userRecipientDSP ||
                        null;

                      return {
                        ...r,
                        createdAt: DateTime.fromISO(r.createdAt).toFormat(
                          "dd/MM/yyyy"
                        ),
                        status: state.constants.items.ANOMALY_STATUS.find(
                          (a) => a.id == r.status
                        ).name,
                        assignTo: r.assignTo
                          ? r.assignTo.lastname + " " + r.assignTo.firstname
                          : "",
                        info: r.infos.description,

                        userRecipientGender: tools.findIn(
                          genders,
                          "id",
                          userRecipient.gender
                        ).name,
                        userRecipientLastname: userRecipient.lastname,
                        userRecipientFirstname: userRecipient.firstname,
                        userRecipientEmail: userRecipient?.email,
                        userRecipientPhone: userRecipient?.phone,
                        userRecipientPhone2: userRecipient?.phone2,
                        userRecipientNIR: userRecipient?.nir,
                        userRecipientCAFId: userRecipient?.CAFId,
                        userRecipientAddress: userRecipient?.address,
                        userRecipientAddress2: userRecipient?.addressAdd,
                        userRecipientCity: userRecipient?.location?.name,
                        userRecipientPostalCode:
                          userRecipient?.location?.postalCode,
                        site: r.infos?.site,
                        userManager: userRecipient?.userManager
                          ? tools.getFullname(
                              agents.find(
                                (a) => a.id == r.infos.userRecipient.userManager
                              )
                            )
                          : "",
                        reorientation: `${
                          actions.getOrganizationFromId(
                            r.infos?.orientation?.fromPartner
                          ).name || ""
                        } -> ${
                          actions.getOrganizationFromId(
                            r.infos?.orientation?.toPartner
                          ).name || ""
                        }\n${tools.getFullname(
                          r.infos?.orientation?.createdBy
                        )} -> ${
                          tools.getFullname(r.infos?.orientation?.receivedBy) ||
                          "non assigné"
                        }`,
                        dateMail1: tools.formatDate(r.infos.dateMail1),
                        dateMail2: tools.formatDate(r.infos.dateMail2),
                        dateOrientation: tools.formatDate(
                          r.infos.dateOrientation
                        ),
                        currentOrientation: r.infos.currentOrientation,
                      };
                    }),
                  excludedFields: ["Historique"],
                  additionalFields: [
                    { name: "Civilité", path: "userRecipientGender" },
                    { name: "Nom", path: "userRecipientLastname" },
                    { name: "Prénom", path: "userRecipientFirstname" },
                    { name: "Email allocataire", path: "userRecipientEmail" },
                    {
                      name: "Téléphone 1 allocataire",
                      path: "userRecipientPhone",
                    },
                    {
                      name: "Téléphone 2 allocataire",
                      path: "userRecipientPhone2",
                    },
                    { name: "NIR", path: "userRecipientNIR" },
                    { name: "CAF Id", path: "userRecipientCAFId" },
                    { name: "Adresse", path: "userRecipientAddress" },
                    { name: "Adresse 2", path: "userRecipientAddress2" },
                    { name: "Code postal", path: "userRecipientPostalCode" },
                    { name: "Ville", path: "userRecipientCity" },
                    { name: "Date envoi mail 1", path: "dateMail1" },
                    { name: "Date envoi mail 2", path: "dateMail2" },
                    { name: "Date d'orientation", path: "dateOrientation" },
                    {
                      name: "Orientation actuelle",
                      path: "currentOrientation",
                    },
                  ],
                  objectFields: {
                    location: {
                      dataIndexes: ["name", "postalCode"],
                    },
                    infos: {
                      dataIndexes: ["description"],
                    },
                  },
                }}
                fields={[
                  {
                    name: "Date",
                    path: "createdAt",
                    render: (elem) =>
                      DateTime.fromISO(elem).toFormat("dd/MM/yyyy"),
                  },
                  {
                    name: "Description",
                    path: "infos",
                    render: (elem) => (
                      <>
                        <div
                          className={` bg-${elem.color} text-white font-weight-bold p-1 rounded d-center mb-2`}
                          style={{
                            fontSize: 12,
                            lineHeight: "13px",
                          }}
                        >
                          <i className={"mr-2 fa fa-" + elem.icon} />
                          {elem.description}
                        </div>
                      </>
                    ),
                  },
                  {
                    name: "Réo.",
                    path: "*",
                    exportPath: "reorientation",
                    render: (el) => getConditionalColumns(el.infos),
                  },
                  {
                    name: "Statut",
                    path: "status",
                    render: (elem) => (
                      <span
                        className={`text-white badge badge-${
                          state.constants.items.ANOMALY_STATUS.find(
                            (a) => a.id == elem
                          ).color
                        }
                        `}
                      >
                        {
                          state.constants.items.ANOMALY_STATUS.find(
                            (a) => a.id == elem
                          ).name
                        }
                      </span>
                    ),
                  },
                  {
                    name: "Site",
                    path: "*",
                    exportPath: "site",
                    render: (elem) => elem.infos && elem.infos.site,
                  },
                  {
                    name: "Assigné à",
                    path: "*",
                    exportPath: "assignTo",
                    render: (elem) => (
                      <>
                        <div data-priv="assign_anomaly">
                          <ModalBtn
                            id="assign-to-agent"
                            label={
                              elem.assignTo
                                ? tools.getFullname(elem.assignTo)
                                : "Assigner"
                            }
                            btnClass={
                              "btn btn-sm " +
                              (elem.assignTo ? "btn-default" : "btn-primary")
                            }
                            onClick={() => {
                              setSelectedAgent(false);
                              setSelectedAnomaly(elem);
                            }}
                          />
                        </div>
                      </>
                    ),
                  },
                  {
                    name: "Agent en charge",
                    path: "*",
                    exportPath: "userManager",
                    render: (elem) =>
                      elem.infos?.userRecipient?.userManager && (
                        <div
                          data-priv="assign_anomaly"
                          style={{ fontSize: 13 }}
                        >
                          <ModalBtn
                            id="assign-to-agent"
                            label={tools.getFullname(
                              agents.find(
                                (a) =>
                                  a.id == elem.infos.userRecipient.userManager
                              )
                            )}
                            btnClass={"btn btn-sm btn-default"}
                            onClick={() => {
                              setSelectedAnomaly(elem);
                              setSelectedAgent(
                                elem.infos.userRecipient.userManager
                              );
                            }}
                          />
                        </div>
                      ),
                  },
                  {
                    name: "Historique",
                    path: "*",
                    render: (elem) =>
                      elem.anomalyHistory.length > 0 ? (
                        <>
                          <ModalBtn
                            id="view-histo"
                            label={<i className="fa fa-eye" />}
                            btnClass="btn btn-primary btn-sm"
                            onClick={() => setSelectedAnomaly(elem)}
                          />
                        </>
                      ) : (
                        <></>
                      ),
                  },
                  {
                    name: "Allocataire",
                    path: "*",
                    exportPath: "userRecipient",
                    render: (elem) =>
                      elem.infos && (
                        <>
                          {elem.infos.userRecipient ? (
                            <div className="d-center justify-content-end ">
                              <a
                                target="_BLANK"
                                title="Ouvrir la fiche dans un nouvel onglet"
                                href={tools.getRecipientLink(
                                  elem.infos.userRecipient
                                )}
                                className="btn btn-light btn-sm d-center"
                              >
                                {tools.getFullname(elem.infos.userRecipient)}{" "}
                                {elem.infos.userRecipient.CAFId}
                                <i className="fa fa-link ml-1" />
                              </a>
                            </div>
                          ) : elem.infos.userRecipientDSP ? (
                            <div className="d-flex align-items-end justify-content-end">
                              <a
                                target="_BLANK"
                                title="Ouvrir la fiche dans un nouvel onglet"
                                href={`/recipients-temp/${elem.infos.userRecipientDSP.id}`}
                                className="btn btn-light btn-sm d-center"
                              >
                                {tools.getFullname(elem.infos.userRecipientDSP)}{" "}
                                {elem.infos.userRecipientDSP.CAFId}
                                <i className="fa fa-link ml-1" />
                              </a>
                            </div>
                          ) : null}
                        </>
                      ),
                  },
                  !roleConfig[state.auth.user.role].includes(
                    "remove_anomaly"
                  ) && {
                    name: "Suppr.",
                    path: "id",
                    render: (elem) => (
                      <button
                        className="btn btn-danger btn-sm"
                        data-priv="remove_anomaly"
                        onClick={() => removeAnomaly(elem)}
                      >
                        <i className="fa fa-times" />
                      </button>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
            nbItemsByPage={search.nbItemsByPage}
            changeNbItemsByPage={changeNbItemsByPage}
          />
        </div>
      </div>
    </>
  );
};

export default AnomalyList;
