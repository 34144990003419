import React, { useEffect, useState } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Control";
import useCERData from "../../../../Hooks/useCERData/useCERData";

const ModifyObjective = () => {
  const {
    cerObjectives,
    modifyCerObjective,
    modifiedObjectiveId,
    setModifiedObjectiveId,
    objectiveCategories,
  } = useCERData();

  const [updatedObjective, setUpdatedObjective] = useState({});

  useEffect(() => {
    const objectiveRef = cerObjectives.find((o) => o.id == modifiedObjectiveId);
    setUpdatedObjective(objectiveRef);
  }, [modifiedObjectiveId]);

  const handleUpdateObjective = (e) => {
    const { name, value } = e.target;
    setUpdatedObjective({
      ...updatedObjective,
      [name]: value,
    });
  };

  const handleClickSaveObjective = () => {
    modifyCerObjective(updatedObjective);
    setModifiedObjectiveId(null);
  };

  return (
    <div>
      <div>
        <strong>Modifier l'objectif</strong>
      </div>

      <Control
        label=""
        name="category"
        type="select"
        change={handleUpdateObjective}
        datas={objectiveCategories}
        dataIndex="id"
        dataLabel="name"
        value={updatedObjective.category}
      />
      <Control
        label=""
        name="name"
        type="text"
        change={handleUpdateObjective}
        value={tools.capitalize(updatedObjective.name)}
      />
      <div className="d-flex align-items-center justify-content-between px-2">
        <div className="alert alert-info">
          <i className="fa fa-exclamation-circle mr-2" />
          L'objectif sera modifié dans tous les contrats CER
        </div>
        <div>
          <button className="btn btn-link" onClick={() => setModifiedObjectiveId(null)}>
            Annuler
          </button>
          <button className="btn btn-primary" onClick={handleClickSaveObjective}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModifyObjective;
