import React, { useEffect } from "react";

const PathTypeBlock = ({ pathType, children }) => {
  return (
    <div className="p-3 custom-card mb-3">
      <div className="mb-3">
        <strong>{pathType?.name}</strong>
      </div>
      {children}
    </div>
  );
};

export default PathTypeBlock;
