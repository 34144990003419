import React, { useContext } from "react";
import colors from "../../../../../../../../helpers/colors";
import { ObjectiveContext } from "../../ObjectiveContext";
import AddObstacleForm from "./AddObstacleForm/AddObstacleForm";
import ObstaclesList from "./ObstacleList/ObstaclesList";
import OperatorsListSigned from "./OperatorList/OperatorsListSigned";
import { StepContext } from "./StepContext";
import StepView from "./StepView";

const stepDragHandleWidth = 22;

const StepsListSigned = () => {
  const objective = useContext(ObjectiveContext);

  return (
    <ul className="list-group " style={{ position: "relative", left: -14 }}>
      {objective.steps.map((step, stk) => {
        const stepKey = `objstep-${objective.pathType}-${objective.goal}-${step.step}`;

        return (
          <li className="list-group-item border-0 p-0" key={stepKey}>
            <StepContext.Provider value={step}>
              <div
                className="pr-0 pt-2 pl-3"
                style={{
                  borderLeft: `${stepDragHandleWidth}px solid transparent`,
                }}
              >
                <StepView goToEditMode={() => {}} />

                <OperatorsListSigned />
                <ObstaclesList />
              </div>
              <div className="pr-2 pt-0 pl-4">
                <AddObstacleForm />
              </div>
            </StepContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};

export default StepsListSigned;
