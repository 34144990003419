import React from "react";
import Loader from "../../../../common/Loader";
import useCERModule from "../hooks/useCERModule";
import CERDisplay from "./CERDisplay/CERDisplay";

const CERListModule = () => {
  const { recipientCER, isLoadingCER, setCurrentCERView } = useCERModule();

  return (
    <>
      {isLoadingCER ? (
        <div className="my-4">
          <Loader />
        </div>
      ) : (
        <div className="my-4">
          {recipientCER.id ? (
            <div>
              <CERDisplay />
            </div>
          ) : (
            <div>
              <p>Aucun contrat CER pour cet allocataire</p>
              <button className="btn btn-primary" onClick={() => setCurrentCERView("CER_EDIT")}>
                Créer un contrat CER
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CERListModule;
