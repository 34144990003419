import React, { useState } from "react";
import Control from "../../common/Control";
import Axios from "axios";
import { api_url, PLATFORM } from "../../../config";
import Loader from "../../common/Loader";
import colors from "../../../helpers/colors";

const ForgotPassword = ({ setForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [emailOk, setEmailOk] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function checkEmail() {
    setCheckingEmail(true);
    Axios.post(`${api_url}auth/renew-password/${PLATFORM}`, { login: email })
      .then((res) => {
        setCheckingEmail(false);
        setEmailSent(true);
      })
      .catch((err) => {
        setCheckingEmail(false);

        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  return (
    <div
      className="card px-0 animated fadeInRight faster overflow-hidden shadow-lg border-0"
      style={{
        maxWidth: 500,
        background: colors.primary,
      }}
    >
      {emailSent ? (
        <div className="card-body p-4 p-lg-5 p-md-4 text-center overflow-hidden">
          <p className="text-white m-0">
            Si votre email est enregistré dans notre système, un email vous sera
            envoyé pour réinitialiser votre mot de passe. Consultez votre boite
            email et cilquez sur le lien qu'il contient.
          </p>
        </div>
      ) : (
        <>
          <div className="card-header pt-3 border-0">
            <h3>Mot de passe oublié</h3>
          </div>
          <div className="card-body">
            <p className="text-white " style={{ padding: "0 7px" }}>
              Une fois ce formulaire rempli, vous recevrez un email contenant un
              lien qui vous permettra de définir un nouveau mot de passe pour
              votre compte.
            </p>
            <Control
              labelStyle={{ color: "white" }}
              label="Entrez votre email"
              value={email}
              change={(e) => setEmail(e.target.value)}
              name="email"
              error={errors}
            />
            {checkingEmail ? (
              <Loader />
            ) : (
              <>
                <div className="text-center m-auto">
                  <button
                    className="btn btn-default  mt-3"
                    onClick={checkEmail}
                  >
                    Envoyer le lien de réinitialisation
                  </button>
                </div>

                <button
                  onClick={() => setForgotPassword(false)}
                  className="btn btn-link text-white w-100 mt-3"
                >
                  <i className="fa fa-chevron-left mr-2"></i>Revenir à la
                  connexion
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
