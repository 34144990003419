import React, { useContext, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import colors from "../../../../../../../../helpers/colors";
import OperatorList from "../../../../../../OperatorList/OperatorList";
import { ObjectiveContext } from "../../ObjectiveContext";
import AddOperatorForm from "./AddOperatorForm/AddOperatorForm";
import OperatorsList from "./OperatorList/OperatorsList";
import { StepContext } from "./StepContext";
import StepEdit from "./StepEdit";
import StepView from "./StepView";

const stepDragHandleWidth = 22;

const StepHandle = ({ dragHandleProps }) => {
  return (
    <div
      className="d-flex align-items-start justify-content-center pt-2 text-white"
      style={{
        background: colors.primary,
        width: stepDragHandleWidth,
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        borderTop: "1px solid white",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
      }}
      {...dragHandleProps}
    >
      <i className="fa fa-grip-vertical" />
    </div>
  );
};

const StepsList = () => {
  const objective = useContext(ObjectiveContext);
  const [stepEditingId, setStepEditingId] = useState(null);

  const goToViewMode = () => {
    setStepEditingId(null);
  };

  const goToEditMode = (stepId) => {
    setStepEditingId(stepId);
  };

  return (
    <Droppable
      droppableId={`steps${objective.pathType}${objective.goal}`}
      type={`${objective.pathType}-${objective.goal}`}
    >
      {(provided, snapshot) => (
        <ul
          ref={provided.innerRef}
          className="list-group "
          style={{ position: "relative", left: -14 }}
        >
          {objective.steps.map((step, stk) => {
            const stepKey = `objstep-${objective.pathType}-${objective.goal}-${step.step}`;
            const isBeingEdited = stepEditingId == step.step;

            return (
              <Draggable key={stepKey} draggableId={stepKey} index={stk}>
                {(provided, snapshot) => (
                  <li
                    className="list-group-item border-0 p-0"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <StepContext.Provider value={step}>
                      <div
                        className="pr-0 py-2 pl-3"
                        style={{
                          borderLeft: `${stepDragHandleWidth}px solid transparent`,
                        }}
                      >
                        <StepHandle dragHandleProps={provided.dragHandleProps} />
                        {isBeingEdited ? (
                          <StepEdit goToViewMode={goToViewMode} />
                        ) : (
                          <StepView goToEditMode={() => goToEditMode(step.step)} />
                        )}

                        <OperatorsList />
                      </div>
                      <div
                        className="pr-2 pt-0 pl-5"
                        style={{
                          borderLeft: `1px dashed ${colors.info}`,
                        }}
                      >
                        <AddOperatorForm />
                      </div>
                    </StepContext.Provider>
                  </li>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default StepsList;
