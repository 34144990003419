import React, { useState } from "react";
import useCERModule from "../../../CERModule/hooks/useCERModule";
import { ObjectiveContext } from "./ObjectiveContext";
import ObjectiveView from "./ObjectiveItem/ObjectiveView";
import StepsList from "./ObjectiveItem/StepsList/StepsList";
import StepsListSigned from "./ObjectiveItem/StepsList/StepsListSigned";

const objectiveDragHandleWidth = 30;

const ObjectivesListSigned = ({ pathType }) => {
  const { recipientCER } = useCERModule();

  const objectivesOfPath = recipientCER.goals.filter((g) => g.pathType == pathType.id);

  return (
    <ul className="list-group objectives">
      {objectivesOfPath.map((objective, obk) => {
        const objectiveKey = `obj${pathType.id}${objective.goal}`;
        return (
          <li className="list-group-item border-0 p-0" key={objectiveKey}>
            <ObjectiveContext.Provider value={objective}>
              <div
                className="pt-2 px-3"
                style={{
                  borderLeft: `${objectiveDragHandleWidth}px solid transparent`,
                }}
              >
                <ObjectiveView goToEditMode={() => {}} />

                <StepsListSigned />
              </div>
            </ObjectiveContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};

export default ObjectivesListSigned;
