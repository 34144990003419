import React, { useState } from "react";
import Control from "../../common/Control";

const StatsFilters = ({ year, setYear }) => {
  var years = [];
  for (var i = 2020; i <= new Date().getFullYear(); i++)
    years.push({ id: i, name: i });

  const getAllStats = () => {
    setClicked(true);
    document.querySelectorAll(".get-stat-btn").forEach((el) => el.click());
    setTimeout(() => {
      setClicked(false);
    }, 3000);
  };

  const [clicked, setClicked] = useState(false);

  return (
    <div className="col-12 d-flex align-items-center  justify-content-between p-0">
      <Control
        label="Année"
        type="select"
        value={year}
        datas={years}
        change={(e) => setYear(e.target.value)}
      />
      {/* {!clicked && (
        <div className="d-flex flex-column animated fadeIn">
          <button className="btn btn-default bg-white" onClick={getAllStats}>
            Charger toutes les statistiques
          </button>
          <div className="badge badge-default text-danger">
            peut causer un usage intensif du serveur
          </div>
        </div>
      )} */}
    </div>
  );
};

export default StatsFilters;
