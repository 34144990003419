import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { api_url } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import RecipientTempInfo from "./RecipientTempInfo";
import { toast } from "react-toastify";

const RecipientDetail = (props) => {
  const [state, dispatch] = useStore();

  const [recipient, setRecipient] = useState({
    email: "",
    nir: "",
    gender: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    cityId: "",
    address: "",
    addressAdd: "",
    location: {
      cityId: "",
      postalCode: "",
      name: "",
      locationName: "",
    },
    phone: "",
    phone2: "",
    maritalStatus: "",
    nbChildrenOrDependant: "",
    CAFId: "",
  });
  const [events, setEvents] = useState(false);
  const [rdsps, setRdsps] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [allDisabled, setAllDisabled] = useState(true);
  const [formConfig, setFormConfig] = useState(false);

  var idToGet = props.recipientId ? props.recipientId : props.match.params.id;
  useEffect(() => {
    getRecipientsEp();
  }, []);

  const getRecipientsEp = () => {
    Axios.get(api_url + "recipients_dsp/" + idToGet)
      .then((res) => {
        setRecipient(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  };

  function updateData(e) {
    setIsSaved(false);
    var value = e.target.value;
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setRecipient({ ...recipient, [e.target.name]: value });
  }

  function resendActivationMail() {
    setIsLoading(true);
    Axios.get(api_url + "recipients_dsp/" + recipient.id + "/rdsplink")
      .then((res) => {
        toast.success("L'email d'activation a bien été renvoyé à " + recipient.email);
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite pendant l'envoi de l'email d'activation");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page
      noMargin={props.recipientId}
      bgTransparent
      container={`container-fluid ${props.recipientId ? "" : "px-5"}`}
      title={props.recipientId ? false : "Détail allocataire temporaire"}
      errors={errors}
      back={props.recipientId ? false : "/recipients-temp"}
    >
      <RecipientTempInfo
        recipient={recipient}
        allDisabled={allDisabled}
        genders={state.constants.items.GENDERS}
        maritalStatuses={state.constants.items.MARITAL_STATUS}
        errors={errors}
        updateData={updateData}
        resendActivationMail={resendActivationMail}
        getRecipientsEp={getRecipientsEp}
      />
    </Page>
  );
};

export default RecipientDetail;
