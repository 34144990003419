import React from "react";
import ExportCSV from "./ExportCsv";

const Table = ({ datas, fields, exportOptions, fullWidth, style = {} }) => {
  return (
    <>
      <div
        className={` mt-2 mx-auto`}
        style={{
          maxWidth: fullWidth ? "100%" : "95%",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        {exportOptions && (
          <div
            className="d-flex align-items-center justify-content-end mb-2"
            data-priv="export_csv"
          >
            <ExportCSV
              fields={[...fields, ...(exportOptions?.additionalFields || [])]}
              datas={exportOptions.datas}
              exportOptions={exportOptions}
            />
          </div>
        )}

        <table
          className="table table-striped table-borderless custom-table bg-white rounded"
          style={{ ...style }}
        >
          <thead>
            <tr>
              {fields.map((h, hk) => (
                <th key={"thk" + hk}>{h.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {datas.map((d, dk) => (
              <tr
                key={"dk" + dk}
                className="animated fadeInUp faster"
                style={{
                  animationDelay: dk * 30 + "ms",
                }}
              >
                {fields.map((f, fk) => (
                  <td key={"df" + dk + "" + fk}>
                    {f.render
                      ? f.render(f.path == "*" ? d : d[f.path])
                      : d[f.path]}
                  </td>
                ))}
              </tr>
            ))}
            {!datas.length && (
              <tr>
                <td style={{ color: "grey" }} colSpan={fields.length}>
                  -aucune entrée trouvée-
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
