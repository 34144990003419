import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { leftPanelActions } from "../../../context/actions/leftPanelActions";
import { modalActions } from "../../../context/actions/modalActions";
import { sidePanelActions } from "../../../context/actions/sidePanelActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import ChangeEpAddress from "./ChangeEpAddress";
import EPARList from "./ARList/EPARList";
import EpRecipients from "./EpRecipients/EpRecipients";
import Jury from "./Jury/Jury";
import EPTimer from "./EpForm/EpFormPage/EpSession/EPTimer";

const EPEditDetail = ({
  ep,
  saveEp,
  removeEp,
  errors,
  setErrors,
  isSavingEp,
  isRemovingEp,
  sectors,
}) => {
  const [state, dispatch] = useStore();
  const lpActions = leftPanelActions(state, dispatch);
  const mActions = modalActions(state, dispatch);
  const spActions = sidePanelActions(state, dispatch);
  const [myEp, setMyEp] = useState(ep);
  const hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  const minutes = [0, 15, 30, 45];
  const [epRecipients, setEpRecipients] = useState([]);
  const [isGettingRecipients, setIsGettingRecipients] = useState(true);

  const [allSectors, setAllSectors] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [jury, setJury] = useState([]);
  const [juryFetched, setJuryFetched] = useState(ep.id ? false : true);
  const [allMembersFetched, setAllMembersFetched] = useState(false);
  const [sectorsFetched, setSectorsFetched] = useState(false);
  const [juryDepsFetched, setJuryDepsFetched] = useState(false);

  const openJury = () => {
    lpActions.updateLeftPanel({
      isOpen: true,
      content: (
        <Jury
          ep={ep}
          myjury={jury}
          allMembers={allMembers}
          sectors={allSectors}
          fetchJuryDatas={fetchJuryDatas}
        />
      ),
    });
  };

  useEffect(() => {
    setMyEp(ep);
    fetchJuryDatas();
    if (ep.id) {
      getEpRecipients();
    }
  }, [ep]);

  const fetchJuryDatas = () => {
    if (ep.id && state.auth.user.role != "AGENT_PARTNER") {
      dataService.get(`eps/${ep.id}/jury`, setJury, setErrors, () => setJuryFetched(true));
    } else {
      setJuryFetched(true);
    }
    dataService.get(`sectors/${ep.sector}`, setAllMembers, setErrors, () =>
      setAllMembersFetched(true)
    );
    dataService.get(`sectors`, setAllSectors, setErrors, setSectorsFetched(true));
  };

  useEffect(() => {
    if (juryFetched && allMembersFetched && sectorsFetched) {
      setJuryDepsFetched(true);
    }
  }, [juryFetched, allMembersFetched, sectorsFetched]);

  const openRecipients = () => {
    mActions.updateModal({
      isOpen: true,
      content: <EpRecipients ep={ep} />,
      style: { zIndex: "999999" },
    });
  };

  const getEpRecipients = () => {
    setIsGettingRecipients(true);
    dataService.get(
      `eps/${ep.id}/recipients`,
      setEpRecipients,
      (err) => {},
      () => setIsGettingRecipients(false)
    );
  };

  const openAR = () => {
    lpActions.updateLeftPanel({
      isOpen: true,
      content: <EPARList epData={ep} />,
    });
  };

  const canOpenEP = () => {
    // if (ep.closedAt) {
    //   return (
    //     state.auth.user.role == "CAF_DIRECTOR" || state.auth.user.isDirector
    //   );
    // }
    // if (ep.endedAt) {
    //   return false;
    // }
    return true;
  };
  var nbWeeksBeforeEp = DateTime.fromISO(myEp.beginAt)
    .diff(DateTime.local(), "weeks")
    .toObject().weeks;

  var is5WeeksBeforeEp = nbWeeksBeforeEp >= 5;
  var isDisabled = myEp.id && (myEp.closedAt || !is5WeeksBeforeEp) ? true : false;

  return (
    <>
      <div className="" style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
        {ep.startedAt ? (
          ep.closedAt ? (
            ep.endedAt ? (
              <div className="d-center bg-dark text-white  w-100 py-2">
                <small className=" font-weight-bold">EP Finalisée</small>
              </div>
            ) : (
              <div className="d-center bg-success text-white w-100 py-2">
                <small className=" font-weight-bold">EP Clôturée</small>
              </div>
            )
          ) : (
            <div className="d-center text-white bg-info w-100 py-2">
              <small className=" font-weight-bold">EP Démarrée</small>
            </div>
          )
        ) : null}
        {ep.cancelledAt ? (
          <div className="d-center bg-body w-100 py-2 text-dark">
            <small className=" font-weight-bold">EP Annulée</small>
          </div>
        ) : null}
      </div>
      <div className="row mt-4">
        {ep.isFull && (
          <div className="col-12 d-flex flex-row">
            <div className="badge badge-dark">
              <i className="fa fa-lock mr-2" />
              Cet EP a atteint la capacité maximale de 30 allocataires inscrits
            </div>
          </div>
        )}
        <div className="col-12">
          <Control
            disabled={isDisabled}
            label="Date"
            type="date"
            name="beginAt"
            value={DateTime.fromISO(myEp.beginAt).toFormat("yyyy-MM-dd")}
            change={(e) =>
              setMyEp({
                ...myEp,
                beginAt: e.target.value,
              })
            }
            error={errors}
          />
        </div>
        <div className="col-6">
          <Control
            disabled={isDisabled}
            label="Heure de début"
            type="select"
            name="beginHourAt"
            datas={hours.map((h) => ({ id: h, name: h + " H" }))}
            value={myEp.beginHourAt}
            change={(e) => setMyEp({ ...myEp, beginHourAt: e.target.value })}
            error={errors}
          />
        </div>
        <div className="col-6">
          <Control
            disabled={isDisabled}
            label="Minute de début"
            type="select"
            name="beginMinuteAt"
            datas={minutes.map((m) => ({ id: m, name: m }))}
            value={myEp.beginMinuteAt}
            change={(e) => setMyEp({ ...myEp, beginMinuteAt: e.target.value })}
            error={errors}
          />
        </div>
        <div className="col-12">
          <Control
            disabled={isDisabled}
            label="Secteur"
            type="select"
            name="sector"
            value={myEp.sector}
            datas={sectors}
            change={(e) => setMyEp({ ...myEp, sector: e.target.value })}
            error={errors}
          />
        </div>
        <div className="col-12 px-4">
          <ChangeEpAddress
            myEp={myEp}
            setMyEp={setMyEp}
            errors={errors}
            sectors={allSectors}
            isDisabled={isDisabled}
          />
        </div>
        <div className="col-12 px-4">
          <Control
            disabled={isDisabled}
            label={
              <>
                Est une session de secours
                <i className="fa fa-history ml-1" />
                {" ?"}
              </>
            }
            type="checkbox"
            name="isBackupSession"
            checked={myEp.isBackupSession}
            change={(e) => setMyEp({ ...myEp, isBackupSession: e.target.checked })}
            error={errors}
          />
        </div>
        {ep.cancelledAt ? (
          <div className="col-12 d-center flex-column p-3 bg-body mt-4">
            <p className="m-0">
              EP annulé le{" "}
              <strong>
                {DateTime.fromISO(ep.cancelledAt)
                  .setLocale("FR")
                  .toFormat("dd MMMM yyyy à HH'h'mm")}
              </strong>{" "}
              par <strong>{tools.getFullname(ep.cancelledBy)}</strong>
            </p>
          </div>
        ) : isDisabled ? null : (
          <div className="col-12 d-center flex-column px-4" data-priv="modify_epdetails">
            <SaveBtn
              isSaving={isSavingEp}
              className="w-100 justify-content-center"
              text="Enregistrer"
              save={() => saveEp(myEp)}
            />
            <SaveBtn
              type="btn-link"
              className="btn text-danger"
              margin="mt-2"
              isSaving={isRemovingEp}
              save={() => removeEp(myEp)}
              text="Annuler cet EP"
            />
          </div>
        )}
      </div>
      <div className="row bg-light pb-2 mb-5 px-2">
        {ep.id && (
          <div className="d-flex justify-content-around flex-column align-items-center border-top w-100 p-3">
            {!juryDepsFetched ? (
              <Loader />
            ) : (
              <button
                className="btn btn-primary w-100 my-1"
                onClick={openJury}
                disabled={ep.startedAt}
                data-priv="edit_ep"
              >
                Membres du jury
                <span className={`badge badge-${jury.length > 0 ? "success" : "dark"} ml-2`}>
                  {jury.length}
                </span>
              </button>
            )}

            {isGettingRecipients ? (
              <Loader />
            ) : (
              <button
                className="btn btn-primary w-100  my-1"
                onClick={openRecipients}
                disabled={ep.startedAt}
              >
                Allocataires inscrits
                <span
                  className={`badge badge-${epRecipients.length > 0 ? "success" : "dark"} ml-2`}
                >
                  {epRecipients.length}
                </span>
              </button>
            )}

            <button
              className="btn btn-warning text-white w-100    my-1"
              data-priv="view_ar_mail"
              onClick={openAR}
            >
              Voir l'état des AR
              <i className="fa fa-paper-plane ml-2" />
            </button>
          </div>
        )}

        {ep.id && !ep.cancelledAt ? (
          //&&
          // DateTime.fromISO(myEp.beginAt).toFormat("dd/MM/yyyy") >=
          //   DateTime.local().toFormat("dd/MM/yyyy")
          <>
            <div className="d-center flex-column w-100 px-3">
              {ep.startedAt && (
                <div className="font-weight-bold">
                  Démarré le{" "}
                  {DateTime.fromISO(ep.startedAt)
                    .setLocale("FR")
                    .toFormat("dd MMMM yyyy 'à' HH'h'mm")}
                </div>
              )}

              {!ep.closedAt && (
                <div className="d-inline-flex flex-row">
                  <span className="mr-1">
                    <i className="fa fa-stopwatch" />
                  </span>
                  <EPTimer beginAt={ep.startedAt} />
                </div>
              )}

              {ep.calledAt && (
                <div>Appel effectué à {DateTime.fromISO(ep.calledAt).toFormat("HH'H'mm")}</div>
              )}
              {ep.closedAt && (
                <div>Clôturé à {DateTime.fromISO(ep.closedAt).toFormat("HH'H'mm")}</div>
              )}
              {ep.endedAt && (
                <div>
                  Finalisé le {DateTime.fromISO(ep.endedAt).toFormat("dd/MM/yyyy 'à' HH'H'mm")}
                </div>
              )}

              {canOpenEP() && (
                <Link
                  className="btn btn-info  my-1 w-100"
                  data-priv="edit_ep"
                  onClick={() => spActions.updateSidePanel({ isOpen: false })}
                  to={`/ep/${ep.id}`}
                >
                  {myEp.startedAt
                    ? ep.closedAt
                      ? ep.endedAt
                        ? "Consulter"
                        : "Ouvrir"
                      : "Rejoindre"
                    : "Démarrer"}{" "}
                  cet EP
                </Link>
              )}
              {ep.endedAt && (
                <Link
                  to={`ep-pv/${ep.id}`}
                  onClick={() => spActions.updateSidePanel({ isOpen: false })}
                  className="btn btn-primary  my-1 w-100"
                >
                  Voir le Procès Verbal
                </Link>
              )}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default EPEditDetail;
