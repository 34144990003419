import React from "react";
import Control from "../../../common/Control";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";
import CreateRdvOnTheFly from "../common/CreateRdvOnTheFly/CreateRdvOnTheFly";

const ReportRdv = ({
  isLoadingSlots,
  errors,
  rdvSlots,
  setOriginSelected,
  setReasonSelected,
  constants,
  setRdvSelected,
  originSelected,
  reasonSelected,
  saveReportRdv,
  isSaved,
  isSaving,
  rdvSelected,
  isRdvDateManual,
  selectedSiteId,
}) => {
  const submitReportRdv = (overrideData = null) => {
    if (isRdvDateManual) {
      saveReportRdv(overrideData.rdvOnTheFly);
    } else {
      saveReportRdv();
    }
  };

  return (
    <div className="d-block my-3 p-3 w-100">
      {isLoadingSlots ? (
        <Loader />
      ) : rdvSlots.length == 0 && !isRdvDateManual ? (
        errors.other ? (
          <span className="text-danger">{errors.other}</span>
        ) : (
          <span className="text-danger">
            Il n'y a pour l'instant aucun rendez-vous disponible, veuillez réessayer plus tard ou
            contacter votre agent référent.
          </span>
        )
      ) : (
        <>
          <Control
            label="Qui est à l'origine de ce report ?"
            type="btnList"
            name="cancelOrigin"
            datas={[
              { value: 1, label: "L'allocataire" },
              { value: 2, label: "L'agent en charge" },
            ]}
            dataIndex={"value"}
            dataLabel={"label"}
            change={(e) => setOriginSelected(e.target.value)}
            value={originSelected}
            error={errors}
          />
          {originSelected == 1 && (
            <Control
              label="Quel est le motif de ce report ?"
              name="reasonSelected"
              type="btnList"
              datas={constants.RDV_REPORT_TYPES}
              dataIndex="id"
              dataLabel="name"
              value={reasonSelected}
              change={(e) => setReasonSelected(e.target.value)}
              error={errors}
            />
          )}

          {isRdvDateManual ? (
            <CreateRdvOnTheFly
              siteId={selectedSiteId}
              hasExternalSubmitHandler={true}
              recipient={{}}
              onSaved={submitReportRdv}
            />
          ) : (
            <Control
              label="Choisissez la date à laquelle vous souhaitez reporter"
              type="btnList"
              datas={rdvSlots}
              name="rdvSlot"
              change={(e) => setRdvSelected(e.target.value)}
              value={rdvSelected}
              dataIndex="id"
              dataLabel="label"
              error={errors}
              inputContainerStyle={{
                height: 400,
                overflow: "scroll",
                padding: 5,
              }}
            />
          )}

          {rdvSelected && (
            <SaveBtn
              save={submitReportRdv}
              isSaving={isSaving}
              isSaved={isSaved}
              text="Reporter le RDV à cette date"
              margin=""
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReportRdv;
