import React from "react";
import colors from "../../../../../../../../helpers/colors";
import useCERData from "../../../../../../../Hooks/useCERData/useCERData";

const StepSelect = ({ objective, step, handleNewStepChange = () => {}, k }) => {
  const { cerSteps } = useCERData();

  const stepsOfObjective = [...cerSteps]
    .filter((c) => c.cerGoal == objective.goal)
    .map((st) => (objective.steps.find((s) => s.step == st.id) ? { ...st, isDisabled: true } : st));

  return (
    <select
      className="form-control border-0"
      style={{ width: "auto", color: colors.primary, opacity: "0.5" }}
      type="select"
      name="step"
      onChange={handleNewStepChange}
      value={step.step}
    >
      <option value="">+ Ajouter une démarche</option>
      {stepsOfObjective.map((st) => (
        <option
          disabled={st.isDisabled}
          value={st.id}
          key={`
        selst${k}${objective.goal}${st.id}
        `}
        >
          {st.name}
        </option>
      ))}
    </select>
  );
};

export default StepSelect;
