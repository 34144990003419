import React from "react";

const AddEntityForm = ({ onSubmit, children, pbClass = "pb-2" }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      className={`d-flex align-items-center justify-content-start  flex-row ${pbClass}`}
    >
      {children}
    </form>
  );
};

export default AddEntityForm;
