import { useState } from "react";
import dataService from "../../../../helpers/dataService";

const usePositiveOutcome = ({ url, year }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState(null);

  const getDatas = () => {
    setIsLoading(true);
    dataService.post(url, { year }, setDatas);
    setIsLoading(false);
  };

  return {
    isLoading,
    getDatas,
    datas,
  };
};

export default usePositiveOutcome;
