import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Accordion from "../../../common/Accordion";
import Page from "../../../common/layout/Page";
import Loader from "../../../common/Loader";
import Table from "../../../common/Table";

const AllInvitations = (props) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const mActions = modalActions(state, dispatch);
  const [invitationSelected, setInvitationSelected] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [myEps, setMyEps] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openedOrgas, setOpenedOrgas] = useState([]);

  const getOrgas = () => {
    dataService.get("organizations", setOrgs);
  };

  const getInvitations = () => {
    setIsLoading(true);
    dataService.get(
      "eps/invitations",
      (eps) => {
        var groupedByEps = [];
        eps.forEach((ep) => {
          var target = groupedByEps.find((e) => e.epId == ep.EP.id);
          if (!target) {
            groupedByEps.push({
              epId: ep.EP.id,
              epData: ep.EP,
              invitations: [ep],
            });
          } else {
            groupedByEps.find((e) => e.epId == ep.EP.id).invitations.push(ep);
          }
        });
        setMyEps(groupedByEps);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const updateInvitation = (invitationId, status, userMandate = null) => {
    setIsLoading(true);
    dataService.patch(
      `eps/invitations/${invitationId}`,
      {
        invitationStatus: status,
        userMandate,
      },
      (d) => {
        getInvitations();
        toast.success("La réponse a bien été enregistrée");
        mActions.updateModal({
          isOpen: false,
        });
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    getOrgas();
    getInvitations();
  }, []);

  return (
    <Page
      isLoading={isLoading}
      container={"container-fluid"}
      title="Gestions des invitations aux EP"
      bgTransparent
      errors={errors}
    >
      <Accordion
        items={myEps.map((ep, epk) => ({
          title: (
            <>
              EP du {DateTime.fromISO(ep.epData.beginAt).setLocale("FR").toFormat("dd MMMM yyyy")} à{" "}
              {tools.numberWithLeadingZero(ep.epData.beginHourAt)}h
              {tools.numberWithLeadingZero(ep.epData.beginMinuteAt)} - Secteur{" "}
              {ep.epData.sector.name}
            </>
          ),
          content: (
            <Table
              bgTransparent
              fields={[
                {
                  name: "Utilisateur",
                  path: "userManager",
                  render: (el) => (
                    <>
                      <div>{tools.getFullname(el)}</div>
                      <small>{el.phone}</small>
                    </>
                  ),
                },
                {
                  name: (
                    <>
                      Organismes
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          if (openedOrgas.length) {
                            setOpenedOrgas([]);
                          } else {
                            setOpenedOrgas(ep.invitations.map((inv) => inv.userManager.id));
                          }
                        }}
                      >
                        {openedOrgas.length ? "Masquer tout" : "Afficher tout"}
                      </button>
                    </>
                  ),
                  path: "userManager",
                  render: (el) => (
                    <>
                      {orgs.length && openedOrgas.includes(el.id)
                        ? el.organizations.map((or) => (
                            <div>
                              <small>{orgs.find((org) => org.id == or).name}</small>
                            </div>
                          ))
                        : null}
                      {orgs.length ? (
                        <button
                          className="badge badge-primary border-0"
                          onClick={() => {
                            if (openedOrgas.includes(el.id)) {
                              setOpenedOrgas((o) => o.filter((e) => e != el.id));
                            } else {
                              setOpenedOrgas((o) => [...o, el.id]);
                            }
                          }}
                        >
                          {el.organizations.length} organismes
                        </button>
                      ) : null}
                    </>
                  ),
                },
                {
                  name: "Secteur",
                  path: "EP",
                  render: (el) => el.sector.name,
                },
                {
                  name: "Statut",
                  path: "*",
                  render: (el) => {
                    var status = constants.INVITATION_STATUS.find(
                      (e) => e.id == el.invitationStatus
                    );
                    return (
                      <>
                        <span className={`font-weight-bold text-${status.color}`}>
                          {status.name}
                        </span>
                        <small className="d-block">
                          invité le{" "}
                          {DateTime.fromISO(el.invitationSentAt).toFormat("dd/MM/yyyy HH:mm")}
                        </small>
                        {el.invitationStatusUpdatedAt ? (
                          <small className="d-block">
                            répondu le{" "}
                            {DateTime.fromISO(el.invitationStatusUpdatedAt).toFormat(
                              "dd/MM/yyyy HH:mm"
                            )}
                          </small>
                        ) : null}
                      </>
                    );
                  },
                },
                {
                  name: "Mandat",
                  path: "userMandate",
                  render: (el) =>
                    el && constants.INVITATION_MANDATES.find((man) => man.id == el).name,
                },
                {
                  name: "Modifier le statut",
                  path: "*",
                  render: (el) => {
                    var status = constants.INVITATION_STATUS.find((e) => e.id == el);
                    var isExpired = DateTime.fromISO(el.EP.beginAt) < DateTime.local();
                    return isExpired ? (
                      "Expiré"
                    ) : (
                      <div className="row">
                        <div className="col-6 text-center">
                          {el.invitationStatus != "ACCEPT" && (
                            <div className="">
                              <div>Sera présent : </div>
                              {isLoading ? (
                                <Loader />
                              ) : (
                                constants.INVITATION_MANDATES.map((invm) => (
                                  <button
                                    key={`inv${invm.id}`}
                                    className="btn btn-success mb-1 btn-sm"
                                    onClick={() => updateInvitation(el.id, "ACCEPT", invm.id)}
                                  >
                                    En tant que {invm.name}
                                  </button>
                                ))
                              )}
                            </div>
                          )}
                        </div>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <div className="col-6 d-center">
                            {el.invitationStatus != "REJECT" && (
                              <button
                                className="btn btn-danger btn-sm "
                                onClick={() => updateInvitation(el.id, "REJECT")}
                              >
                                Sera absent
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              datas={ep.invitations}
            />
          ),
        }))}
      />
    </Page>
  );
};

export default AllInvitations;
