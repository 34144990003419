import { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useStockStat = ({ url, year }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState(null);

  const getStockStat = () => {
    setIsLoading(true);
    dataService.post(
      url,
      {},
      setDatas,
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return {
    isLoading,
    datas,
    getStockStat,
  };
};

export default useStockStat;
