import React, { useEffect, useState } from "react";
import dataService from "../../../../../../../helpers/dataService";
import tools from "../../../../../../../helpers/tools";
import Control from "../../../../../../common/Control";
import DynamicList from "../../../../../../common/DynamicList";
import Loader from "../../../../../../common/Loader";
import SaveBtn from "../../../../../../common/SaveBtn";
import useStoreState from "../../../Context/Store/useStoreState";
import DecisionReorientationForm from "./DecisionReorientationForm";

const DecisionReorientation = () => {
  const { state, items, items_actions, gState } = useStoreState();
  const recipient = items.utils.currentAlloc;
  const avisEp = recipient.EPNotice;
  const { organizations } = items;
  const isDirector = gState.auth.user.role == "CAF_DIRECTOR" || gState.auth.user.isDirector;

  const setAvisEp = (updatedAvis) => {
    items_actions.updateCurrentAlloc({
      ...recipient,
      EPNotice: updatedAvis,
    });
  };

  const getReorientationSentence = () => {
    return tools.getReorientationSentence(avisEp, gState.constants.items, organizations);
  };

  return !organizations.length ? (
    <Loader />
  ) : (
    <div>
      <DecisionReorientationForm
        avisEp={avisEp}
        setAvisEp={setAvisEp}
        recipient={recipient}
        isClosed={items.ep.closedAt}
        orgas={organizations}
      />
      {items.ep.closedAt && !isDirector ? (
        <div className="alert alert-success">
          <div>Avis de l'EP</div>
          <p>{getReorientationSentence()}</p>
        </div>
      ) : (
        <SaveBtn
          className="w-100 d-center"
          text={
            <div className="d-center flex-column">
              <div>{getReorientationSentence()}</div>
              <strong style={{ fontSize: 18 }}>Valider la décision</strong>
            </div>
          }
          save={items_actions.saveDecision}
          isSaving={state.isLoadingDecision}
        />
      )}
    </div>
  );
};

export default DecisionReorientation;
