import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import RecipientInfo from "./RecipientInfo";
import { toast } from "react-toastify";
import { modalActions } from "../../../context/actions/modalActions";
import roleConfig from "../../common/layout/roleConfig";
import dataService from "../../../helpers/dataService";
import AddRecipientToEp from "../RecipientDetail/AddRecipientToEp";
import SearchRecipient from "./SearchRecipient";
import { useHistory } from "react-router-dom";

const RecipientCreateEP = (props) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const history = useHistory();
  const [recipient, setRecipient] = useState({
    nir: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    cityId: "",
    email: "",
    CAFId: "",
    initialOrientation: "",
    location: {
      cityId: "",
      postalCode: "",
      name: "",
      locationName: "",
    },
  });
  const [search, setSearch] = useState({
    nir: "",
  });
  const [isSearching, setIsSearching] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [searchResult, setSearchResult] = useState({
    exists: false,
    userRecipient: {},
  });
  const [isManualCreation, setIsManualCreation] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [myOrganizations, setMyOrganizations] = useState([]);

  useEffect(() => {
    getOrganizations();
  }, []);

  function updateData(e) {
    setIsSaved(false);
    var { type, name, value, checked, formArray } = e.target;
    var usr = JSON.parse(JSON.stringify(recipient));
    var elem = usr[name];
    if (type == "checkbox") {
      if (checked) {
        elem = true;
      } else {
        elem = false;
      }
    } else if (formArray) {
      if (type == "checkBtnList") {
        if (elem.includes(value)) {
          elem = elem.filter((v) => v != value);
        } else {
          elem.push(value);
        }
      } else {
        if (formArray.target.checked) {
          elem.push(value);
        } else {
          elem = elem.filter((v) => v != value);
        }
      }
    } else {
      elem = value;
    }
    usr[name] = elem;

    setRecipient(usr);
  }

  const searchByNir = () => {
    setIsSearching(true);
    dataService.post(
      `recipients_ep/exists`,
      search,
      (datas) => {
        setSearchResult(datas);
        setHasSearched(true);
      },
      setErrors,
      () => setIsSearching(false)
    );
  };

  const addRecipientToNextEp = (rec) => {
    mActions.updateModal({
      isOpen: true,
      content: (
        <AddRecipientToEp
          recipient={rec}
          refresh={() => history.push("/recipients-ep")}
        />
      ),
    });
  };

  const getOrganizations = () => {
    dataService.get("organizations/mine", setMyOrganizations);
  };

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});

    //create
    dataService.post(
      `recipients_ep`,
      recipient,
      (datas) => {
        toast.success("Création de l'allocataire réussie");
        addRecipientToNextEp(datas);
      },
      setErrors,
      () => {
        setIsSaving(false);
      }
    );
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page
      container={"container-fluid px-5"}
      bgTransparent
      title="Inscrire manuellement un allocataire à la prochaine EP"
      errors={errors}
    >
      <div className="row">
        <div className="col-12 mb-3">
          <SearchRecipient
            search={search}
            setSearch={setSearch}
            searchByNir={searchByNir}
            isSearching={isSearching}
            searchResult={searchResult}
            addRecipientToNextEp={addRecipientToNextEp}
          />
        </div>

        {hasSearched && (
          <div className="d-flex w-100 justify-content-center">
            <button
              className="btn btn-link"
              onClick={() => setIsManualCreation(true)}
            >
              <i className="fa fa-edit mr-2" />
              Créer manuellement
            </button>
          </div>
        )}
        {isManualCreation && (
          <div className={"col-12"} style={{ animationDelay: "100ms" }}>
            <div className="custom-card">
              <RecipientInfo
                recipient={recipient}
                genders={state.constants.items.GENDERS}
                maritalStatuses={state.constants.items.MARITAL_STATUS}
                organizations={myOrganizations}
                errors={errors}
                updateData={updateData}
                setErrors={setErrors}
              />

              <div className="d-flex justify-content-end">
                <SaveBtn
                  text={`Créer l'allocataire`}
                  save={save}
                  isSaving={isSaving}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default RecipientCreateEP;
