import { DateTime } from "luxon";
import React from "react";
import useStoreState from "../../../Context/Store/useStoreState";
import MotifDisplay from "./MotifDisplay";

const OldEpDetails = ({ oldEpDetails }) => {
  const { state, gState, items, items_actions, spActions } = useStoreState();

  const {
    EP_TYPES,
    EP_SANCTION_REDUCTIONS,
    EP_SANCTION_DURATIONS,
    EP_REDUCTION_LEVELS,
  } = gState.constants.items;

  const openMotifs = (rec) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: <MotifDisplay recipient={rec} />,
    });
  };

  const oldSanctions = oldEpDetails?.length
    ? oldEpDetails.filter((epd) => epd.type == "SANCTION")
    : [];

  return oldSanctions.length ? (
    <div className="alert alert-warning my-2">
      <div>
        <strong>Des antécédants ont été trouvés</strong>
      </div>
      <ul>
        {oldEpDetails.map((old) => {
          return (
            <li className="mb-2">
              <span className="mr-1 font-italic text-black-50">
                EP du{" "}
                {DateTime.fromISO(old.updatedAt)
                  .setLocale("FR")
                  .toFormat("dd LLLL yyyy")}{" "}
                -
              </span>
              <span className="mr-1">
                {EP_TYPES.find((type) => type.id == old.type).name}
              </span>
              <span className="mr-1">
                {
                  EP_REDUCTION_LEVELS.find(
                    (red) => red.id == old.EPNotice.sanction.level
                  )?.name
                }
              </span>
              <span className="mr-1">
                {old.EPNotice.sanction.isRightsMaintained
                  ? "Droits RSA maintenus"
                  : "Retrait des droits RSA"}
              </span>
              <span className="mr-1">
                <button
                  className="btn btn-outline-danger border-danger border btn-sm position-relative"
                  style={{ zIndex: "9999" }}
                  onClick={() => openMotifs(old)}
                >
                  Motifs
                </button>
              </span>
              <div>
                {old.EPNotice.sanction?.sanctionType?.map((st) => (
                  <span className="badge badge-light border">
                    {
                      EP_SANCTION_DURATIONS.find((dur) => dur.id == st.duration)
                        .name
                    }{" "}
                    {
                      EP_SANCTION_REDUCTIONS.find(
                        (red) => red.id == st.reduction
                      ).name
                    }
                  </span>
                ))}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default OldEpDetails;
