import React from "react";

const Accordion = ({ items, k = "1", className }) => {
  return (
    <div id="accordion" className={className}>
      {items.map((it, itk) => (
        <div className="card" key={"acc" + itk + k}>
          <div className="card-header p-0" id={"heading" + itk + k}>
            <h5 className="mb-0">
              <button
                className="btn btn-link text-white d-flex align-items-center justify-content-between w-100"
                data-toggle="collapse"
                data-target={"#collapseOne" + itk + k}
                aria-expanded="false"
                aria-controls="collapseOne"
                style={{ textDecoration: "none" }}
              >
                {it.title}
                <i className="fa fa-ellipsis-v" />
              </button>
            </h5>
          </div>

          <div
            id={"collapseOne" + itk + k}
            className="collapse"
            aria-labelledby={"heading" + itk + k}
            data-parent="#accordion"
          >
            <div className="card-body p-0">{it.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
