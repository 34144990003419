import React, { useState } from "react";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import useCERData from "../../Hooks/useCERData/useCERData";

const AddObjectiveItem = ({ category }) => {
  const { addCerObjective, objectiveCategories } = useCERData();

  const [newObjective, setNewObjective] = useState({
    name: "",
    category,
  });

  const handleChangeObjective = (e) => {
    const { name, value } = e.target;
    setNewObjective({ ...newObjective, [name]: value });
  };

  const handleClickAddObjective = () => {
    addCerObjective(newObjective);
    setNewObjective({
      category,
      name: "",
    });
  };

  return (
    <li className="list-group-item bg-body d-flex flex-row align-items-center">
      <span className="badge badge-primary mr-2">objectif</span>

      <Control
        className="flex-fill py-0"
        placeholder="Nom de l'objectif"
        type="text"
        name="name"
        change={handleChangeObjective}
        value={tools.capitalize(newObjective.name)}
      />
      {newObjective.name && newObjective.category && (
        <button className="btn btn-success" onClick={handleClickAddObjective}>
          Ajouter
        </button>
      )}
    </li>
  );
};

export default AddObjectiveItem;
