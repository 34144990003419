import { Page } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { sidePanelActions } from "../../../context/actions/sidePanelActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Pagination from "../../common/Pagination";
import AssignToAgent from "./AssignToAgent";

var searchTimeout = null;

const useReorientations = (fetchId, autoSearch = false) => {
  const [state, dispatch] = useStore();
  const spActions = sidePanelActions(state, dispatch);
  const constants = state.constants.items;
  const me = state.auth.user;
  const [organizations, setOrganizations] = useState(false);
  const [fromSites, setFromSites] = useState([]);
  const [toSites, setToSites] = useState([]);
  const [reorientations, setReorientations] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("ReorientationList", null);
  const [search, setSearch] = useState({
    orientation: {
      searchString: "",
      userRecipient: fetchId,
      fromPartner: "",
      fromSites: [],
      toPartner: "",
      toSites: [],
      isFinished: false,
      status: "",
      receivedBy: false,
      //isReorientationInProgress: "",
    },
    page: 1,
    nbItemsByPage: 25,
  });
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [myOrgas, setMyOrgas] = useState([]);
  const [isInit, setIsInit] = useState(false);

  const fetchUsers = () => {
    dataService.get("managers/agents/mine", setUsers);
  };
  const fetchMyOrgas = () => {
    dataService.get(`organizations/mine`, setMyOrgas);
  };

  useEffect(() => {
    if (myOrgas.length && state.auth.user.role == "AGENT_PARTNER") {
      setSearch({
        ...search,
        orientation: { ...search.orientation, toPartner: myOrgas[0].id },
      });
    }
  }, [myOrgas]);

  const fetchReorientations = () => {
    setIsLoading(true);
    var searchData = search;
    tools.saveState("ReorientationList", "search", JSON.stringify(searchData));
    if (fetchId) {
      dataService.get(
        `orientations/recipients/${fetchId}`,
        (datas) => {
          setIsInit(true);
          setReorientations(datas);
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      fetchReorientationsPage(searchData);
    }
  };
  const fetchReorientationsPage = (searchData) => {
    // let isReorientationInProgressValue = undefined;
    // if (searchData.orientation.isReorientationInProgress) {
    //   if (searchData.orientation.isReorientationInProgress == "true") {
    //     isReorientationInProgressValue = true;
    //   } else {
    //     isReorientationInProgressValue = false;
    //   }
    // }
    dataService.post(
      "orientations/search",
      {
        ...searchData,
        orientation: {
          ...searchData.orientation,
          //isReorientationInProgress: isReorientationInProgressValue,
        },
      },
      (datas) => {
        setIsInit(true);
        setReorientations(datas.data);
        setNbPages(Math.ceil(parseInt(datas.count) / search.nbItemsByPage));
        setNbResults(datas.count);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };
  const fetchOrgas = () => {
    setIsLoading(true);

    dataService.get("organizations", setOrganizations, setErrors, () => setIsLoading(false));
  };
  useEffect(() => {
    fetchMyOrgas();
    if (autoSearch) fetchReorientations();
    fetchOrgas();
    fetchUsers();
  }, []);

  useEffect(() => {
    // clearTimeout(searchTimeout);
    // if (isInit) {
    //   searchTimeout = setTimeout(() => {
    //     fetchReorientations();
    //   }, 1500);
    // }
  }, [search]);

  useEffect(() => {
    if (search.orientation.fromPartner) {
      getSites(search.orientation.fromPartner, setFromSites);
    } else {
      setSearch({
        ...search,
        orientation: { ...search.orientation, fromSites: [] },
      });
    }
  }, [search.orientation.fromPartner]);
  useEffect(() => {
    if (search.orientation.toPartner) {
      getSites(search.orientation.toPartner, setToSites);
    } else {
      setSearch({
        ...search,
        orientation: { ...search.orientation, toSites: [] },
      });
    }
  }, [search.orientation.toPartner]);

  const getSites = async (orga, setter) => {
    const result = await fetchSitesOfOrga(orga).catch((err) => ({
      errors: err,
    }));
    if (!result.errors) {
      setter(result);
    }
  };

  const fetchSitesOfOrga = async (orga) => {
    return new Promise((res, rej) => {
      dataService.get(`organizations/${orga}/sites`, res, rej);
    });
  };

  function updateSearch(e) {
    var { value, name, checked, type } = e.target;
    var su = { ...search };
    if (type == "checkbox") {
      value = checked ? true : false;
    }
    if (value == "" && typeof value != "object") {
      delete su.orientation[name];
    } else {
      su.orientation[name] = value;
    }

    setSearch(su);
  }

  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
    fetchReorientationsPage({ ...search, page: i });
  }
  function changeNbItemsByPage(i) {
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
    fetchReorientationsPage({ ...search, nbItemsByPage: i });
  }

  const openAssignModal = (reorientation) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <AssignToAgent
          reorientation={reorientation}
          users={users}
          assignToAgent={assignToAgent}
          toSites={toSites}
        />
      ),
    });
  };

  const assignToAgent = (reo, receivedBy) => {
    dataService.patch(
      `orientations/${reo.id}/assign`,
      {
        receivedBy,
      },
      fetchReorientations,
      setErrors
    );
  };

  const acceptReo = (reo) => {
    if (window.confirm("Confirmez-vous accepter l'allocataire dans votre organisme ?")) {
      dataService.patch(`orientations/${reo.id}/accept`, {}, fetchReorientations, setErrors);
    }
  };
  const refuseReo = (reo) => {
    if (
      window.confirm("Confirmez-vous la discussion de cette réorientation en entretien croisé ?")
    ) {
      dataService.patch(
        `orientations/${reo.id}/crossinterview`,
        {},
        fetchReorientations,
        setErrors
      );
    }
  };

  const keepReo = (reo) => {
    if (
      window.confirm(
        "Confirmez-vous de conserver l'orientation de l'allocataire dans l'organisme cédant ?"
      )
    ) {
      dataService.patch(`orientations/${reo.id}/cancel`, {}, fetchReorientations, setErrors);
    }
  };
  const resendReo = (reo) => {
    if (window.confirm("Confirmez-vous le renvoi de la demande de réorientation ?")) {
      dataService.patch(`orientations/${reo.id}/resend`, {}, fetchReorientations, setErrors);
    }
  };
  const sendToEp = (reo) => {
    if (window.confirm("Confirmez-vous l'inscription de l'allocataire à la prochaine EP ?")) {
      dataService.patch(`orientations/${reo.id}/ep`, {}, fetchReorientations, setErrors);
    }
  };

  const deleteReo = (id) => {
    setIsLoading(true);

    dataService.remove(`orientations/${id}`, {}, fetchReorientations, setErrors, () =>
      setIsLoading(false)
    );
  };

  return {
    isLoading,
    organizations,
    reorientations,
    users,
    search,
    errors,
    changePage,
    changeNbItemsByPage,
    updateSearch,
    nbPages,
    nbResults,
    constants,
    openAssignModal,
    myOrgas,
    acceptReo,
    refuseReo,
    keepReo,
    resendReo,
    sendToEp,
    fetchReorientations,
    deleteReo,
    fromSites,
    toSites,
  };
};

export default useReorientations;
