import { atom } from "jotai";

export const currentAmendmentAtom = atom({});
export const cerAmendmentsAtom = atom([]);
export const isCurrentAmendmentDraftAtom = atom((get) => {
  return get(currentAmendmentAtom).status == "DRAFT" ? true : false;
});
export const isCurrentAmendmentRenewAtom = atom((get) => {
  return get(currentAmendmentAtom).type == "RENEWED" ? true : false;
});
export const isLoadingAmendmentAtom = atom(false);
export const amendmentErrorsAtom = atom({});
