import React, { useContext } from "react";
import tools from "../../../../../../../../../../helpers/tools";
import { ObjectiveContext } from "../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveContext";
import { OperatorContext } from "../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/OperatorList/OperatorContext";
import { StepContext } from "../../../../../../../common/CERGoalsForm/ObjectivesList/ObjectiveItem/StepsList/StepContext";
import AmendmentOperatorView from "./AmendmentOperatorView/AmendmentOperatorView";

const AmendmentOperatorsList = () => {
  const objective = useContext(ObjectiveContext);
  const step = useContext(StepContext);

  return (
    <ul className="list-group">
      {step.operators.map((operator, ok) => {
        const operatorKey = `objoperator-${objective.pathType}-${objective.goal}-${step.step}-${operator.operator}`;

        return (
          <li className="list-group-item border-0 p-0 mb-2" key={operatorKey}>
            <OperatorContext.Provider value={operator}>
              <div
                className="pr-0  py-1"
                style={{
                  borderLeft: `28px solid ${tools.getCERElementUpdateTypeColor(
                    operator.updateType
                  )}`,
                }}
              >
                <AmendmentOperatorView goToEditMode={() => {}} />
              </div>
            </OperatorContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};

export default AmendmentOperatorsList;
