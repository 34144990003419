import React from "react";
import useStore from "../../../../../../../context/useStore";
import tools from "../../../../../../../helpers/tools";
import useCERModule from "../../../hooks/useCERModule";
import CERInfosListItem from "./CERInfosListItem";

const ListItemIcon = ({ icon = "clock" }) => {
  return <i style={{ width: 20 }} className={`fa fa-${icon} mr-1`} />;
};

const CERInfosList = () => {
  const { recipientCER } = useCERModule();

  const [state] = useStore();
  const constants = state.constants.items;

  const getCERPeriod = () => {
    return `Du ${tools.getISOFormatted(recipientCER.beginAt)} au ${tools.formatDate(
      recipientCER.endAt
    )}`;
  };

  return (
    <ul style={{ listStyle: "none" }} className="pl-0">
      <CERInfosListItem>
        <ListItemIcon />
        Créé le {tools.getISOFormatted(recipientCER.createdAt)}
      </CERInfosListItem>
      <CERInfosListItem>
        <ListItemIcon icon="calendar-alt" />
        {getCERPeriod()}
      </CERInfosListItem>
      {recipientCER.status == "SIGNED" && (
        <>
          <CERInfosListItem>
            <ListItemIcon icon="signature" />
            Signature initiale le {tools.getISOFormatted(recipientCER.signedAt)}
          </CERInfosListItem>
          {recipientCER.signUpdatedAt && (
            <CERInfosListItem>
              <ListItemIcon icon="signature" />
              Dernière signature le {tools.getISOFormatted(recipientCER.signUpdatedAt)}
            </CERInfosListItem>
          )}
        </>
      )}
    </ul>
  );
};

export default CERInfosList;
