import { api_url } from "../config";
import FileDownload from "js-file-download";
import Axios from "axios";
import { toast } from "react-toastify";

const downloadTools = {
  downloadFile: (path, datas, filename, filenameFromHeader = false) => {
    return new Promise((resolve, reject) => {
      Axios({
        url: api_url + path,
        method: "POST",
        data: datas,
        responseType: "blob",
      })
        .then((res) => {
          var trueFilename = filenameFromHeader
            ? res.headers["content-disposition"].split("=")[1]
            : filename;

          FileDownload(res.data, trueFilename);
          resolve(true);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            toast.error("Ce fichier est vide, n'existe pas ou ne peut être téléchargé");

            reject(err.response.data);
          } else {
            toast.error("Une erreur inconnue est survenue.");
            reject(err);
          }
        });
    });
  },
};

export default downloadTools;
